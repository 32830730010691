<script lang="ts" setup>
/**
 * AutoPlayVideo.vue
 * 映像自動再生コンポーネント
 * 
 * 親コンポーネント
 * @/components/parts/common/KinesisVideoStreamsWebrtc.vue
 */
// ==================================
// import
// ==================================
import { computed } from 'vue'

// ==================================
// interface
// ==================================
interface Props {
  videoId: string;
  isPlay: boolean;
  videoStream: MediaStream;
  class: string;
}

// ==================================
// data
// ==================================
const props = defineProps<Props>()

// ==================================
// computed
// ==================================
const isPlay = computed(() => {
  return props.isPlay
})

const videoStream = computed(() => {
  return props.videoStream
})

const videoId = computed(() => {
  return props.videoId
})

const divClass = computed(() => {
  return props.class
})
</script>
<template>
  <div :class="divClass">
    <div :class="divClass + '__spinner'">
      <v-progress-circular
        v-show="!videoStream && isPlay == true"
        :size="70"
        color="red"
        indeterminate
      />
    </div>

    <div
      v-show="isPlay == false"
      :class="divClass + '__comment'"
    >
      <div :class="divClass + '__comment__notplay'">
        再生できません
      </div>
    </div>
    <video
      v-show="videoStream"
      :id="videoId"
      autoplay="true"
      controls="true"
    />
  </div>
</template>
<style lang="scss" scoped>
  .real {
    background-color: #353535;
    height: 60vh;
    width: 100%;
    position: relative;
    &__spinner {
      position: absolute;
      display: flex;
      justify-content: center;
      margin: 25% 45% 40%;
    }
    &__comment {
      position: absolute;
      display: flex;
      justify-content: center;
      margin: 30% 40% 40%;
      &__notplay {
        width: 210px;
        text-align: center;
        padding: 20px;
        border: solid 1px #fff;
        color: #fff;
      }
    }
    video {
      width: 100%;
      height: 100%;
    }
  }
  .multi {
    background-color: #353535;
    height: 38vh;
    width: 100%;
    position: relative;
    &__spinner {
      position: absolute;
      display: flex;
      justify-content: center;
      margin: 20% 45% 40%;
    }
    &__comment {
      position: absolute;
      display: flex;
      justify-content: center;
      margin: 30% 40% 40%;
      &__notplay {
        width: 210px;
        text-align: center;
        padding: 20px;
        border: solid 1px #fff;
        color: #fff;
      }
    }
    video {
      width: 100%;
      height: 100%;
    }
  }
</style>
