<script lang="ts" setup>
/**
 * MacroAnalysisHistgram.vue
 * マクロ分析棒グラフ
 * 
 * 親コンポーネント
 * @/components/parts/macroAnalysis/MacroAnalysisDetailInfoTable.vue
 * @/components/parts/macroAnalysis/MacroAnalysisTable.vue
 */
// ==================================
// import
// ==================================
import { computed } from 'vue'

import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
import { Bar } from 'vue-chartjs'
// グラフ表示部品を登録
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

// ==================================
// interface
// ==================================
interface Props {
  xAxes: any;
  yAxes: any;
  legend?: any;
  title?: {
    display: boolean;
    text: string;
  },
  backgroundColor: string;
  labelName?: string;
  data: any;
  isSuggested: boolean;
  max: number;
  min?: number;
  maxTicksLimit?: number;
}

interface Emits {
  (e: 'showModal', component: string, props: any): void;
}

// ==================================
// data
// ==================================
const props = defineProps<Props>()

// ==================================
// computed
// ==================================
/**
 * グラフデータの設定
 */
const chartData = computed(() => {
  return {
    labels: ['0-10km/h', '10-20km/h', '20-30km/h', '30-40km/h', '40-50km/h', '50-60km/h'],
    datasets: [
      {
        label: props.labelName !== void 0 ? props.labelName : '',
        backgroundColor: props.backgroundColor,
        data: props.data
      }
    ]
  }
})

/**
 * グラフ表示オプションの設定
 */
const options = computed(() => {
  return {
    title: {
      display: props.title !== void 0 ? props.title.display : '',
      text: props.title !== void 0 ? props.title.text : ''
    },
    plugins: {
      legend: {
        display: false
      }    
    },
    scales: {
      x: {
        title: {
          display: props.xAxes.scaleLabelDisplay,
          text: props.xAxes.labelString
        },
        ticks: {
          maxTicksLimit: 6
        }
      },
      y: {
        position: 'left',
        beginAtZero: true,
        min: props.min,
        max: props.max,
        title: {
          display: props.yAxes.scaleLabelDisplay,
          text: props.yAxes.labelString
        },
        ticks: {
          maxTicksLimit: props.maxTicksLimit
        }
      }
    },
    onClick: showModal
  }
})

// ==================================
// method
// ==================================
const emit = defineEmits<Emits>()

/**
 * グラフをクリックしたときにモーダル表示するよう通知
 */
const showModal = () => {
  emit('showModal', 'MacroAnalysisHistgram', props)
}
</script>
<template>
  <div class="macro-analysis-histgram">
    <div class="macro-analysis-histgram__chart">
      <Bar
        :data="chartData"
        :options="options"
      />
    </div>
  </div>
</template>
<style lang="scss" scoped>
  .macro-analysis-histgram {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    &__chart {
      width: 100%;
      min-width: 400px;
      height: auto;
    }
  }
</style>
