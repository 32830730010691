/**
 * plugins/index.ts
 *
 * Automatically included in `./src/main.ts`
 */

// Types
import type { App } from 'vue'

// Plugins
import { loadFonts } from './webfontloader' // eslint-disable-line
import vuetify from './vuetify' // eslint-disable-line
// import pinia from '../store'
import router from '../router'
import pinia from '../store'

// other
import 'vue3-tree/dist/style.css'
import DatePicker from 'vue-datepicker-next'
import Tree from 'vue3-tree'
import 'vue-datepicker-next/index.css'
import 'vue-datepicker-next/locale/ja'

export function registerPlugins(app: App) {
  loadFonts()
  app.use(vuetify).use(router).use(pinia).component('vue3-tree',Tree).component('vue-datepicker-next',DatePicker)
}
