<script lang="ts" setup>
/**
 * VirtualMap.vue
 * ポール選択後に表示する地図コンポーネント
 * 
 * 親コンポーネント
 * @/components/parts/realTime/VirtualVideo.vue
 * @/components/parts/virtual/VirtualVideo.vue
 */
// ==================================
// import
// ==================================
import { onBeforeMount, onMounted, watch, ref } from 'vue'

import {
  useSelectPoleStore,
  usePoleInfoStore,
  usePosListStore,
} from '@/store/app'

import { getSensorSetting } from '@/mixins/commonFunction'
import {
  DEFAULT_MARKER_ICON,
  LED_MARKER_ICON,
  getTileLayer,
  getMapMarker,
  ABS_ON_POSITION,
  ABS_ON_ICON,
  BRAKE_ON_POSITION,
  BRAKE_ON_ICON,
} from '@/mixins/mapFunction'

import { SensorIdData } from '@/types/Interfaces'

import { VIRTUAL_MAP } from '@/setting/setting'

import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import 'leaflet-semicircle'
import 'leaflet-rotatedmarker'

// ==================================
// interface
// ==================================
interface Props {
  selectSensorList: SensorIdData[];
}

// ==================================
// data
// ==================================
const props = defineProps<Props>()

// 選択ポール情報ストア
const selectPoleStore = useSelectPoleStore()

// ポール情報ストア
const poleInfoStore = usePoleInfoStore()

// 物標情報リスト
const posListStore = usePosListStore()

const map = ref<any>()

const poleMarkers = ref<Array<any>>([])

const objectMarkers = ref<Array<any>>([])

const sensorAreaMarkers = ref<Array<any>>([])

const ledMarkers = ref<Array<any>>([])

const brakeOnMarkers = ref<Array<any>>([])

const absOnMarkers = ref<Array<any>>([])

const targetArea = ref<number>(0)

// フィリピンのエリアID(暫定対応)
// ※汎用化する場合は別途検討予定
const PHI_AREA_ID = 2146830336

// ==================================
// watch
// ==================================
watch(
  () => props.selectSensorList,
  () => {
    updateSensorAreaAndLedPole()
    updatePosObject()
  }
)

// ==================================
// hook
// ==================================
onBeforeMount(() => {
  targetArea.value = selectPoleStore.$state.areaId
})

onMounted(() => {
  // マップ生成
  L.Icon.Default.mergeOptions(DEFAULT_MARKER_ICON)
  map.value = L.map('virtual-map', {
    dragging: true,
    touchZoom: true,
    scrollWheelZoom: true,
    doubleClickZoom: true,
    boxZoom: true,
    tap: true,
    keyboard: true,
    zoomControl: true,
    minZoom: VIRTUAL_MAP.zoom.min,
    maxZoom: VIRTUAL_MAP.zoom.max,
  }).setView(
    [selectPoleStore.latlng.lat, selectPoleStore.latlng.lng],
    VIRTUAL_MAP.zoom.default
  )
  L.control
    .layers(getTileLayer(map.value, VIRTUAL_MAP.zoom.max, undefined))
    .addTo(map.value)
})

// ==================================
// method
// ==================================
/**
 * ポールマーカー設定
 * @param lat - 緯度
 * @param lng - 経度
 */
const addPoleMarker = (lat: number, lng: number) => {
  poleMarkers.value.push(
    L.marker([lat, lng], {
      icon: L.icon(DEFAULT_MARKER_ICON),
    }).addTo(map.value)
  )
}
/**
 * ポールマーカー設定削除
 * 現状使用していないが使うタイミングでコメントはずしてください
 */
// const removePoleMarker = () => {
//   for (const item of poleMarkers.value) {
//     map.value.removeLayer(item);
//   }
//   // 一度データを初期化
//   poleMarkers.value.splice(0);
// };

/**
 * センサーのエリア範囲設定
 */
const addSensorArea = () => {
  for (const sensor of poleInfoStore.sensorList) {
    let TypeAndcheckedlist = checkSensorTypeAndchecked(sensor.sensorId)
    let type = TypeAndcheckedlist[0]
    let ischeck = TypeAndcheckedlist[1]
    if (ischeck && type != '表示板') {
      let settingSensor: any = getSensorSetting(sensor.sensorId)
      addSensorAreaMarker(sensor, settingSensor.color, settingSensor.name)
    } else if (ischeck && type == '表示板') {
      addLedMarker(sensor.latitude, sensor.longitude)
    }
  }
}

/**
 * 急減速地点、ABS ON地点設定
 */
const addBrakeAbsOnPoint = () => {
  const demoItems = props.selectSensorList.filter((value) => {
    return value.kind === '急減速地点' || value.kind === 'ABS ON地点'
  })
  if (demoItems.length === 0) return

  for (const item of demoItems) {
    if (item.kind === '急減速地点') {
      addBrakeOnPoint(item.value)
    } else if (item.kind === 'ABS ON地点') {
      addAbsOnPoint(item.value)
    }
  }
}

/**
 * 急減速地点の位置指定
 * 塩尻、水道橋限定のデモ向け機能
 * @param area - エリア名
 */
const addBrakeOnPoint = (area: string) => {
  const LATITUDE = 0
  const LONGITUDE = 1
  for (const pos of BRAKE_ON_POSITION) {
    if (pos.area == area) {
      brakeOnMarkers.value.push(
        L.polyline([pos.startLatlng, pos.endLatlng], {color: 'red', weight: 5}).arrowheads({yawn: 30, fill: true}).addTo(map.value)
      )
      brakeOnMarkers.value.push(
        L.marker([(pos.startLatlng[LATITUDE] + pos.endLatlng[LATITUDE]) / 2, (pos.startLatlng[LONGITUDE] + pos.endLatlng[LONGITUDE]) / 2], {
          icon: L.icon(BRAKE_ON_ICON),
        }).addTo(map.value)
      )
    }
  }
}

/**
 * ABS ON地点の位置指定
 * 塩尻、水道橋限定のデモ向け機能
 * @param area - 場所名
 */
const addAbsOnPoint = (area: string) => {
  for (const abs of ABS_ON_POSITION) {
    if (abs.area === area) {
      absOnMarkers.value.push(
        L.marker(abs.latlng, {
          icon: L.icon(ABS_ON_ICON),
        }).addTo(map.value)
      )
    }
  }
}

/**
 * 急原則地点マーカ削除
 */
const removeBrakeOnMarker = () => {
  for (const item of brakeOnMarkers.value) {
    map.value.removeLayer(item)
  }

  // 一度データを初期化
  brakeOnMarkers.value.splice(0)
}

/**
 * ABS ON地点マーカ削除
 */
const removeAbsOnMarker = () => {
  for (const item of absOnMarkers.value) {
    map.value.removeLayer(item)
  }

  // 一度データを初期化
  absOnMarkers.value.splice(0)
}

/**
 * センサーのエリアマーカ設定
 * @param sensor - センサー情報
 * @param color - マーカー色
 * @param name - センサー名
 */
const addSensorAreaMarker = (sensor: any, color: string, name: any) => {
  let directionMin = sensor.detectDirectionMin
  let directionMax = sensor.detectDirectionMax
  let radius = sensor.detectDistanceMax
  if (directionMin == 65535 || directionMax == 65535 || radius == 65535) {
    return
  }
  if (sensor.detectDirectionMin > sensor.detectDirectionMax) {
    directionMax += 360
  }
  let semicircle = L.semiCircle([sensor.latitude, sensor.longitude], {
    radius: radius,
    startAngle: directionMin,
    stopAngle: directionMax,
    color: color,
    opacity: 0.0,
    fillOpacity: 0.3,
    weight: 1,
  })
  semicircle.bindTooltip(name, {
    className: 'sensor-area',
    direction: 'center',
    offset: L.point(20, -50),
    opacity: 0.6,
  })
  semicircle.addTo(map.value)

  sensorAreaMarkers.value.push({
    sensorId: sensor.sensorId,
    marker: semicircle,
  })
}

/**
 * 選択済みのセンサー情報取得
 * @param sensorId - センサーID
 */
const checkSensorTypeAndchecked = (sensorId: number) => {
  let type = ''
  let results = false
  for (const sensor of props.selectSensorList) {
    if (sensorId == Number(sensor.value)) {
      type = sensor.kind
      results = true
      break
    }
  }
  return [type, results]
}

/**
 * センサーエリア範囲設定削除
 */
const removeSensorAreaMarker = () => {
  sensorAreaMarkers.value.forEach((marker: any) => {
    map.value.removeLayer(marker.marker)
  })
  // 一度データを初期化
  sensorAreaMarkers.value.splice(0)
}

/**
 * LED表示板マーカ設定
 * @param lat - 緯度
 * @param lng - 経度
 */
const addLedMarker = (lat: number, lng: number) => {
  ledMarkers.value.push(
    L.marker([lat, lng], {
      icon: L.icon(LED_MARKER_ICON),
    }).addTo(map.value)
  )
}

/**
 * LED表示板マーカ削除
 */
const removeLedMarker = () => {
  for (const item of ledMarkers.value) {
    map.value.removeLayer(item)
  }

  // 一度データを初期化
  ledMarkers.value.splice(0)
}

/**
 * センサーエリア範囲及びLED表示板位置更新
 */
const updateSensorAreaAndLedPole = () => {
  removeSensorAreaMarker()
  removeLedMarker()
  removeBrakeOnMarker()
  removeAbsOnMarker()
  addSensorArea()
  addBrakeAbsOnPoint()
}

/**
 * 物標を更新
 */
const updatePosObject = () => {
  removePosObjectMarker()
  for (const pos of posListStore.posList) {
    for (const sensor of props.selectSensorList) {
      if (pos.sensorId == Number(sensor.value)) {
        updateObjectMarker(pos.posList, pos.sensorId, sensor.name)
        break
      }
    }
  }
}

/**
 * 物標を更新
 * @param posList - 物標一覧
 * @param sensorId - センサーID
 * @param name - センサー名
 */
const updateObjectMarker = (posList: any, sensorId: number, name: string) => {
  posList.forEach((pos: any) => {
    addObjectMarker(
      sensorId,
      pos.vehicleId,
      pos.vehicleRoleClassification,
      [pos.latitude, pos.longitude],
      getMapMarker(pos.vehicleSizeClassification, pos.vehicleRoleClassification, false),
      name
    )
  })
}

/**
 * 物標をマップに追加
 * @param sensorId - センサーID
 * @param vehicleId - 物標ID
 * @param roleKind - 物標アイコン用途種別ID
 * @param latlng - 座標
 * @param icon - アイコンURL
 * @param name - センサー名
 */
const addObjectMarker = (
  sensorId: number,
  vehicleId: number,
  roleKind: number,
  latlng: any,
  icon: any,
  name: string
) => {
  // アイコンの色と吹き出しの色を設定する
  const colorClass = setIconColor(name, roleKind)
  const tooltipShape = setTootipShape(name, roleKind)
  let Icon = L.icon({
    iconUrl: icon,
    iconSize: [20, 20], // アイコンサイズ
    className: colorClass
  })
  let direction = 0
  let marker = L.marker(latlng, {
    icon: Icon,
    rotationAngle: direction,
    rotationOrigin: 'center center',
  })
  marker.bindTooltip(vehicleId.toString(), {
    // className: 'marker-object',
    className: tooltipShape,
    direction: 'center',
    offset: L.point(10, -25),
    permanent: true,
    opacity: 0.6,
  })
  marker.addTo(map.value)

  objectMarkers.value.push({
    sensorId: sensorId,
    vehicleId: vehicleId,
    marker: marker,
  })
}

/**
 * 物標マーカー削除
 */
const removePosObjectMarker = () => {
  for (const item of objectMarkers.value) {
    map.value.removeLayer(item.marker)
  }
  // 一度データを初期化
  objectMarkers.value.splice(0)
}

/**
 * マップ表示の中央位置を設定
 * @param latlng - 緯度経度
 */
const setCenter = (latlng: number[]) => {
  map.value.setView(latlng, map.value.getZoom())
}

/**
 * 物標アイコンの色を設定する
 * @param name - センサー名(センサーIDの16進数表記)
 * @param roleKind - 物標アイコン用途種別ID
 * @returns アイコンの色設定を行うスタイル名
 */
const setIconColor = (name: string, roleKind: number): string => {
  // フィリピンのポールにのみ、色分け対応を行う
  if (targetArea.value === PHI_AREA_ID) {
    switch (name) {
      // CMOS
      case '050e01':
        return 'blue-icon'
      // LiDAR
      case '020c01':
        return 'cyan-icon'
      // それ以外
      default:
        return roleKind === 1 ? 'red-icon' : 'black-icon'
    }
    // フィリピン以外のエリアでは黒表示にする
  } else {
    return roleKind === 1 ? 'red-icon' : 'black-icon'
  }
}

/**
 * ツールチップ（吹き出し）の色を設定する
 * @param name - センサー名(センサーIDの16進数表記)
 * @param roleKind - 物標アイコン用途種別ID
 * @return 色設定を行うスタイル名
 */
const setTootipShape = (name: string, roleKind: number): string => {
  if (targetArea.value === PHI_AREA_ID) {
    switch (name) {
      case '050e01':
        return 'default_tooltip'
      case '020c01':
        return 'diamond_tooltip'
      default:
        return roleKind === 1 ? 'marker-ambulance' : 'marker-object'
    }
  } else {
    return roleKind === 1 ? 'marker-ambulance' : 'marker-object'
  }
}

defineExpose({
  addPoleMarker,
  updatePosObject,
  setCenter,
})
</script>
<template>
  <div id="virtual-map" />
  <v-main>
    <RouterView />
  </v-main>
</template>
<style>
  #virtual-map {
    position: absolute;
    top: 55px;
    bottom: 0;
    width: 100%;
    z-index: 99;
  }
  .marker-object {
    background: darkcyan;
    color: white;
    font-size: 7px;
    font-weight: bold;
    text-align: center;
    border: 2px solid gray;
    border-radius: 40px;
    width: 40px;
    height: 30px;
  }
  .marker-ambulance {
    background: red;
    color: white;
    font-size: 7px;
    font-weight: bold;
    text-align: center;
    border: 2px solid gray;
    border-radius: 40px;
    width: 40px;
    height: 30px;
  }
  /* フィリピンでのデフォルトの吹き出し形状 */
  .default_tooltip {
    background: #33c;
    color: white;
    font-size: 7px;
    font-weight: bold;
    text-align: center;
    border: 2px solid gray;
    border-radius: 40px;
    width: 40px;
    height: 30px;
  }
  /* ひし形 */
  .diamond_tooltip {
    background: #0cf;
    color: #333;
    font-size: 7px;
    font-weight: bold;
    text-align: center;
    border: 2px solid gray;
    width: 40px;
    height: 30px;
    clip-path: polygon(50% 0, 100% 50%, 50% 100%, 0 50%);
  }
  /* 爆発型 */
  .exploded_tooltip {
    background: #363;
    color: white;
    font-size: 7px;
    font-weight: bold;
    text-align: center;
    border: 2px solid gray;
    width: 40px;
    height: 30px;
    clip-path: polygon(28% 23%, 50% 5%, 68% 18%, 92% 7%, 88% 28%, 100% 45%, 76% 58%, 96% 87%, 60% 75%, 53% 100%, 45% 85%, 28% 100%, 32% 83%, 0 80%, 15% 62%, 4% 47%, 18% 36%, 0 3%);
  }
  /* 吹き出し形状 */
  .speech_bubble_tooltip {
    background: #039;
    color: white;
    font-size: 7px;
    font-weight: bold;
    text-align: center;
    border: 2px solid gray;
    width: 40px;
    height: 30px;
    border-radius: 40px;
    clip-path: polygon(20% 10%, 50% 0, 85% 15%, 95% 40%, 100% 50%, 95% 65%, 80% 75%, 70% 75%, 40% 72%, 50% 100%, 25% 72%, 10% 65%, 0 50%, 7% 30%);
  }
  /* アイコン色設定(画像の色相などを変化させる) */
  .black-icon {
    filter: brightness(0);
  }
  .gray-icon {
    filter: contrast(0);
  }
  .red-icon {
    filter: hue-rotate(0deg);
  }
  .orange-icon {
    filter: hue-rotate(60deg);
  }
  .yellow-icon {
    filter: hue-rotate(75deg) brightness(0.6);
  }
  .green-icon {
    filter: hue-rotate(150deg);
  }
  .cyan-icon {
    filter: hue-rotate(192deg);
  }
  .blue-icon {
    filter: hue-rotate(240deg) brightness(0.8) contrast(0.75);
  }
  .purple-icon {
    filter: hue-rotate(285deg);
  }
</style>
