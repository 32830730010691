<script lang="ts" setup>
/**
 * SearchFrame.vue
 * 検索エリア
 * 
 * 親コンポーネント
 * @/views/Accident.vue
 */
// ==================================
// import
// ==================================
import { ref, computed, onMounted } from 'vue'

import { useSelectPoleStore, useSearchConditionStore } from '@/store/app'

import { DIALOG_ERROR_INFO } from '@/mixins/commonFunction'
import { updateNearMissesData, updateAccidentsData } from '@/mixins/communicationFunction'

import { SearchConditionStore } from '@/types/Interfaces'

import { DateTime } from 'luxon'

// ==================================
// interface
// ==================================
interface Emits {
  (e: 'start-loading'): void;
  (e: 'stop-loading'): void;
  (e: 'add-near-miss-marker'): void;
  (e: 'add-accident-marker'): void;
  (e: 'update-near-misses-list'): void;
  (e: 'update-accident-list'): void;
  (e: 'open-error-dialog', value1: string, value2: string): void;
}

// ==================================
// data
// ==================================
// 選択ポール情報ストア
const selectPoleStore = useSelectPoleStore()

// 検索方法
const retrievalItems = [
  { title: '日付日時範囲検索', value: 0 },
  { title: '日付日時個別検索', value: 1 },
  { title: '日付範囲-日時個別検索', value: 2 },
]

const retrievalMethodValue = ref(0)

// 重複行排除可否
const isDeduplication = ref(true)

// TimeGap初期入力チェック可否
const valid = ref(true)

// 日付初期値設定
const initDateTime = DateTime.now()

// 前日
const fromDateTime: any = DateTime.fromObject({
  year: initDateTime.year,
  month: initDateTime.month,
  day: initDateTime.day,
  hour: 12,
  minute: 30,
  second: 30,
}).minus({
  day: 1,
})

// 当日
const toDateTime: any = DateTime.fromObject({
  year: initDateTime.year,
  month: initDateTime.month,
  day: initDateTime.day,
  hour: 12,
  minute: 30,
  second: 30,
})

// 検索条件初期値設定
const searchConditions = ref<SearchConditionStore>({
  dates1: [new Date(fromDateTime.ts), new Date(toDateTime.ts)],
  dates2: [new Date(toDateTime.ts)],
  dates3: [new Date(fromDateTime.ts), new Date(toDateTime.ts)],
  fromTime: new Date(toDateTime.ts),
  toTime: new Date(toDateTime.ts),
  threshold: 30,
  startDateTime: '',
  endDateTime: '',
})

const searchConditionStore = useSearchConditionStore()

// 閾値バリデーション定義
const thresholdRules = [(v: number) => !!v || 'TimeGapは必須です。', (v: number) => v >= 0 || '閾値は0以上でなければなりません', (v: number) => v <= 999.999 || '閾値は999.999以下でなければなりません', (v: number) => /^(\d{0,3}\.\d{0,3}|\d{0,3})$/.test(String(v)) || '閾値は小数点第3位まで出なければなりません']

// ==================================
// computed
// ==================================
// 時刻エラーメッセージ
const timeErrorMessage = computed(() => {
  switch (retrievalMethodValue.value) {
    case retrievalItems[1].value:
    case retrievalItems[2].value:
      if (searchConditions.value.fromTime == null) {
        return '開始日は必須です。'
      }
      if (searchConditions.value.toTime == null) {
        return '終了日は必須です。'
      }
      break
    default:
      return ''
  }
  return ''
})

// 対象日エラーメッセージ
const dateErrorMessage = computed(() => {
  switch (retrievalMethodValue.value) {
    case retrievalItems[0].value:
      if (searchConditions.value.dates1 == null || searchConditions.value.dates1.length != 2 || searchConditions.value.dates1.includes(null)) {
        return '対象日は必須です。'
      }
      break
    case retrievalItems[1].value:
      if (searchConditions.value.dates2 == null || searchConditions.value.dates2.length == 0 || searchConditions.value.dates2.includes(null)) {
        return '対象日は必須です。'
      }
      break
    case retrievalItems[2].value:
      if (searchConditions.value.dates3 == null || searchConditions.value.dates3.length == 0 || searchConditions.value.dates3.includes(null)) {
        return '対象日は必須です。'
      }
      break
    default:
      return '対象日は必須です。'
  }
  return ''
})

// 決定ボタン押下可否
const isProcessing = computed(() => {
  let tempThreshold = Number(searchConditions.value.threshold)
  if (Number.isNaN(tempThreshold) || !/^(\d{0,3}\.\d{0,3}|\d{0,3})$/.test(String(searchConditions.value.threshold))) {
    return true
  }
  if (tempThreshold < 0 || tempThreshold > 999.999) {
    return true
  }
  switch (retrievalMethodValue.value) {
    case retrievalItems[0].value:
      if (searchConditions.value.dates1 == null || searchConditions.value.dates1.length != 2 || searchConditions.value.dates1.includes(null)) {
        return true
      }
      break
    case retrievalItems[1].value:
      if (searchConditions.value.dates2 == null || searchConditions.value.dates2.length == 0 || searchConditions.value.dates2.includes(null)) {
        return true
      }
      if (searchConditions.value.fromTime == null || searchConditions.value.toTime == null) {
        return true
      }
      break
    case retrievalItems[2].value:
      if (searchConditions.value.dates3 == null || searchConditions.value.dates3.length == 0 || searchConditions.value.dates3.includes(null)) {
        return true
      }
      if (searchConditions.value.fromTime == null || searchConditions.value.toTime == null) {
        return true
      }
      break
    default:
      return true
  }
  return false
})

// ==================================
// hook
// ==================================
onMounted(() => {
  // 初期検索
  searchConditionStore.clearData()
  search()
})

// ==================================
// method
// ==================================
const emit = defineEmits<Emits>()
/**
 * ローディング処理開始
 */
const startLoading = () => {
  emit('start-loading')
}
/**
 * ローディング処理終了
 */
const stopLoading = () => {
  emit('stop-loading')
}
/**
 * ヒヤリハット情報マーカー設定
 */
const addNearMissMarker = () => {
  emit('add-near-miss-marker')
}
/**
 * 事故発生情報マーカー設定
 */
const addAccidentMarker = () => {
  emit('add-accident-marker')
}
/**
 * ヒヤリハット情報一覧更新
 */
const updateNearMissesList = () => {
  emit('update-near-misses-list')
}
/**
 * 事故発生情報一覧更新
 */
const updateAccidentList = () => {
  emit('update-accident-list')
}
/**
 * エラーダイアログを開く
 */
const openErrorDialog = () => {
  emit('open-error-dialog', DIALOG_ERROR_INFO.title.getError, DIALOG_ERROR_INFO.message.getError)
}

/**
 * 検索処理
 */
const search = () => {
  searchConditions.value.startDateTime = DateTime.now().toFormat('yyyyddMM_HHmmss')
  let fromTime = ''
  let toTime = ''
  let dates: string[] = []
  let isRange = false
  switch (retrievalMethodValue.value) {
    case retrievalItems[0].value:
      /* @ts-expect-error : elementはnullableのためエラーが発生するがdate1プロパティにnullが格納されている場合はsearchメソッドを実行できない*/
      searchConditions.value.dates1.forEach((element: Date, index: number) => {
        if (index == 0) {
          fromTime = DateTime.fromJSDate(element).toFormat('HH:mm:ss')
        } else {
          toTime = DateTime.fromJSDate(element).toFormat('HH:mm:ss')
        }
        dates.push(DateTime.fromJSDate(element).toFormat('yyyy/MM/dd'))
      })
      isRange = true
      break
    case retrievalItems[1].value:
      /* @ts-expect-error : date1と同じ */
      searchConditions.value.dates2.forEach((element: Date) => {
        dates.push(DateTime.fromJSDate(element).toFormat('yyyy/MM/dd'))
      })
      fromTime = DateTime.fromJSDate(searchConditions.value.fromTime).toFormat('HH:mm:ss')
      toTime = DateTime.fromJSDate(searchConditions.value.toTime).toFormat('HH:mm:ss')
      isRange = false

      break
    case retrievalItems[2].value:
      /* @ts-expect-error : date1と同じ */
      for (let d = searchConditions.value.dates3[0]; d <= searchConditions.value.dates3[1]; d.setDate(d.getDate() + 1)) {
        /* @ts-expect-error : date1と同じ */
        let formatedDate = d.getFullYear() + '/' + ('0' + (d.getMonth() + 1)).slice(-2) + '/' + ('0' + d.getDate()).slice(-2)
        dates.push(formatedDate)
      }
      fromTime = DateTime.fromJSDate(searchConditions.value.fromTime).toFormat('HH:mm:ss')
      toTime = DateTime.fromJSDate(searchConditions.value.toTime).toFormat('HH:mm:ss')
      isRange = false
      break
    default:
  }
  searchConditionStore.setData(searchConditions.value)
  // ヒヤリハット事故情報一覧取得
  const promise1 = updateNearMissesData(selectPoleStore.poleId, dates, fromTime, toTime, searchConditions.value.threshold, isDeduplication.value, isRange)
  // 事故発生一覧取得
  const promise2 = updateAccidentsData(selectPoleStore.poleId, dates, fromTime, toTime, isRange)
  startLoading()
  Promise.all([promise1, promise2])
    .then(() => {
      addNearMissMarker()
      addAccidentMarker()
      updateNearMissesList()
      updateAccidentList()
      searchConditions.value.endDateTime = DateTime.now().toFormat('yyyyddMM_HHmmss')
      searchConditionStore.setData(searchConditions.value)
      stopLoading()
    })
    .catch(() => {
      addNearMissMarker()
      addAccidentMarker()
      updateNearMissesList()
      updateAccidentList()
      stopLoading()
      openErrorDialog()
    })
}
</script>
<template>
  <v-card
    class="search-frame-method-card"
    elevation="1"
  >
    <v-card-title class="search-frame-method-card__header py-0">
      <span class="py-0">検索方法</span>
    </v-card-title>
    <v-select
      v-model="retrievalMethodValue"
      label="検索方法指定"
      :items="retrievalItems"
    />
  </v-card>
  <v-card
    class="search-frame-search-card"
    elevation="1"
  >
    <v-card-title class="search-frame-search-card__header py-0">
      <span class="py-0">日付(表示範囲)</span>
    </v-card-title>
    <div class="search-frame-search-card__body ml-5">
      <!-- 日付日時範囲検索 -->
      <vue-datepicker-next
        v-show="retrievalMethodValue == retrievalItems[0].value"
        v-model:value="searchConditions.dates1"
        class="search-frame-search-card__input-dates"
        type="datetime"
        range
        placeholder="対象日及び時刻"
        format="YYYY/MM/DD HH:mm:ss"
      />
      <!-- 日付日時個別検索 -->
      <vue-datepicker-next
        v-show="retrievalMethodValue == retrievalItems[1].value"
        v-model:value="searchConditions.dates2"
        class="search-frame-search-card__input-dates"
        type="date"
        multiple
        placeholder="対象日"
        format="YYYY/MM/DD"
      />
      <!-- 日付範囲-日時個別検索 -->
      <vue-datepicker-next
        v-show="retrievalMethodValue == retrievalItems[2].value"
        v-model:value="searchConditions.dates3"
        class="search-frame-search-card__input-dates"
        type="date"
        multiple
        range
        placeholder="対象日"
        format="YYYY/MM/DD"
      />
      <p class="search-frame-search-card__error">
        {{ dateErrorMessage }}
      </p>
      <!-- 日時個別検索可能な場合表示 -->
      <div
        v-show="retrievalMethodValue == retrievalItems[1].value || retrievalMethodValue == retrievalItems[2].value"
        class="d-flex"
      >
        <vue-datepicker-next
          v-show="retrievalMethodValue == retrievalItems[1].value || retrievalMethodValue == retrievalItems[2].value"
          v-model:value="searchConditions.fromTime"
          type="time"
          placeholder="開始時刻"
        />
        <vue-datepicker-next
          v-show="retrievalMethodValue == retrievalItems[1].value || retrievalMethodValue == retrievalItems[2].value"
          v-model:value="searchConditions.toTime"
          type="time"
          placeholder="終了時刻"
        />
      </div>
      <p class="search-frame-search-card__error">
        {{ timeErrorMessage }}
      </p>
      <v-form v-model="valid">
        <v-text-field
          v-model="searchConditions.threshold"
          class="search-frame-search-card__input-threshold"
          variant="underlined"
          :rules="thresholdRules"
          label="TimeGapの閾値を入力してください"
          required
        />
      </v-form>
      <div class="d-flex">
        <v-switch
          v-model="isDeduplication"
          color="primary"
          label="ヒヤリハット一覧 重複行を除外する"
        />
        <v-btn
          :disabled="isProcessing"
          class="search-frame-search-card__button"
          rounded
          :color="isProcessing ? '' : 'primary'"
          @click="search()"
        >
          決定
        </v-btn>
      </div>
    </div>
  </v-card>
</template>
<style lang="scss" scoped>
  .search-frame-method-card {
    display: block;
    &__header {
      font-size: 22px;
      font-weight: bold;
      color: white;
      background-color: #0041c0;
      height: auto;
    }
  }
  .search-frame-search-card {
    &__header {
      font-size: 22px;
      font-weight: bold;
      color: white;
      background-color: #0041c0;
      height: auto;
    }
    &__body {
      height: 35vh;
    }
    &__input-dates {
      width: 90%;
      height: 5vh;
      line-height: 10px;
    }
    &__error {
      color: rgb(230, 11, 11);
      font-size: 0.75rem !important;
      line-height: 2.5rem;
      letter-spacing: 0.0073529412em !important;
      font-weight: 400;
      font-family: Roboto, sans-serif !important;
    }
    &__input-threshold {
      width: 50%;
    }
    &__button {
      width: 50%;
      height: 5vh;
    }
  }
</style>
<style>
  .mx-input {
    border: none !important;
    border-bottom: 0.5px solid #8b8686e7 !important;
    box-shadow: none !important;
    border-radius: 0px !important;
  }
</style>
