<script lang="ts" setup>
/**
 * NearMissList.vue
 * ヒヤリハット一覧
 * 
 * 親コンポーネント
 * @/views/Accident.vue
 */
// ==================================
// import
// ==================================
import { computed, ref } from 'vue'

import NearMissPlayVideo from '@/components/parts/accident/NearMissPlayVideo.vue'

import { useNearMissListStore, useSelectPoleStore, useSearchConditionStore } from '@/store/app'

import { S3BUCKETURL } from '@/mixins/commonFunction'
import { CSV_DOWNLOAD_ICON } from '@/mixins/mapFunction'

import { NearMissStore } from '@/types/Interfaces'

import { NEAR_MISSES_LIST_HEADER, POLE_ON_GRAFANA } from '@/setting/setting'

import { DateTime } from 'luxon'

// ==================================
// interface
// ==================================
interface Search {
  nearMissIdFilterValue: string;
  nearMissAreaFilterValue: string;
  nearMissVehicleId1FilterValue: string;
  nearMissVehicleId1KindFilterValue: string;
  nearMissVehicleId1DirectionFilterValue: string;
  nearMissVehicleId2FilterValue: string;
  nearMissVehicleId2KindFilterValue: string;
  nearMissVehicleId2DirectionFilterValue: string;
  nearMissLatitudeFilterValue: string;
  nearMissLongitudeFilterValue: string;
  nearMissTimeFilterValue: string;
  nearMissTimeGapFilterValue: string;
}

interface Source {
  src: string;
  type: string;
}

interface VideoOption {
  isShowMovie: boolean;
  options: {
    autoplay: boolean;
    controls: boolean;
    sources: Source[];
  }
  nearMissesInfo: {
    id: string;
    area: string;
    vehicleId1Kind: string;
    vehicleId1KindDirection: string;
    vehicleId2Kind: string;
    vehicleId2KindDirection: string;
    nearMissTime: string;
    timeGap: string;
  };
}

interface PoleInfo {
  poleId: number;
  name: string;
  path: string;
  nearMissPath: string;
}

// ==================================
// data
// ==================================
// 選択ポール情報ストア
const selectPoleStore = useSelectPoleStore()

// ヒヤリハット一覧情報ストア
const nearMissListStore = useNearMissListStore()

const searchConditionsStore = useSearchConditionStore()

// 検索条件
const search = ref<Search>({
  nearMissIdFilterValue: '',
  nearMissAreaFilterValue: '',
  nearMissVehicleId1FilterValue: '',
  nearMissVehicleId1KindFilterValue: '',
  nearMissVehicleId1DirectionFilterValue: '',
  nearMissVehicleId2FilterValue: '',
  nearMissVehicleId2KindFilterValue: '',
  nearMissVehicleId2DirectionFilterValue: '',
  nearMissLatitudeFilterValue: '',
  nearMissLongitudeFilterValue: '',
  nearMissTimeFilterValue: '',
  nearMissTimeGapFilterValue: '',
})

// ヒヤリハット一覧情報リスト
const nearMissesList = ref<NearMissStore[]>([])

// ビデオ関連情報
const videoOption = ref<VideoOption>({
  isShowMovie: false,
  options: {
    autoplay: false,
    controls: true,
    sources: [
      {
        src: '',
        type: 'video/mp4',
      },
    ],
  },
  nearMissesInfo: {
    id: '',
    area: '',
    vehicleId1Kind: '',
    vehicleId1KindDirection: '',
    vehicleId2Kind: '',
    vehicleId2KindDirection: '',
    nearMissTime: '',
    timeGap: '',
  },
})

// ==================================
// computed
// ==================================
// リスト一覧生成
const nearMisses = computed(() => {
  return filterItems(nearMissesList.value, search.value)
})

// ヘッダー情報
const nearMissesTableHeader = computed(() => {
  return NEAR_MISSES_LIST_HEADER
})

// ==================================
// method
// ==================================
/**
 * リストフィルター処理
 * @param arr - ヒヤリハット情報
 * @param word - 検索条件
 * @returns 検索条件を満たすヒヤリハット情報リスト
 */
const filterItems = (arr: NearMissStore[], word: Search) => {
  return arr.filter((item: NearMissStore) => {
    let nearMissId = true
    let area = true
    let vehicleId_1 = true
    let vehicleId_1_Kind = true
    let vehicleId_1_direction = true
    let vehicleId_2 = true
    let vehicleId_2_kind = true
    let vehicleId_2_direction = true
    let latitude = true
    let longitude = true
    let nearMisstime = true
    let timeGap = true

    if (word.nearMissIdFilterValue) {
      nearMissId = String(item.nearMissId).toLowerCase().includes(String(word.nearMissIdFilterValue).toLowerCase())
    }
    if (word.nearMissAreaFilterValue) {
      area = String(item.area).toLowerCase().includes(String(word.nearMissAreaFilterValue).toLowerCase())
    }
    if (word.nearMissVehicleId1FilterValue) {
      vehicleId_1 = String(item.vehicleId_1).toLowerCase().includes(String(word.nearMissVehicleId1FilterValue).toLowerCase())
    }
    if (word.nearMissVehicleId1KindFilterValue) {
      vehicleId_1_Kind = String(item.vehicleId_1_Kind).toLowerCase().includes(String(word.nearMissVehicleId1KindFilterValue).toLowerCase())
    }
    if (word.nearMissVehicleId1DirectionFilterValue) {
      vehicleId_1_direction = String(item.vehicleId_1_direction).toLowerCase().includes(String(word.nearMissVehicleId1DirectionFilterValue).toLowerCase())
    }
    if (word.nearMissVehicleId2FilterValue) {
      vehicleId_2 = String(item.vehicleId_2).toLowerCase().includes(String(word.nearMissVehicleId2FilterValue).toLowerCase())
    }
    if (word.nearMissVehicleId2KindFilterValue) {
      vehicleId_2_kind = String(item.vehicleId_2_kind).toLowerCase().includes(String(word.nearMissVehicleId2KindFilterValue).toLowerCase())
    }
    if (word.nearMissVehicleId2DirectionFilterValue) {
      vehicleId_2_direction = String(item.vehicleId_2_direction).toLowerCase().includes(String(word.nearMissVehicleId2DirectionFilterValue).toLowerCase())
    }
    if (word.nearMissLatitudeFilterValue) {
      latitude = String(item.latitude).toLowerCase().includes(String(word.nearMissLatitudeFilterValue).toLowerCase())
    }
    if (word.nearMissLongitudeFilterValue) {
      longitude = String(item.longitude).toLowerCase().includes(String(word.nearMissLongitudeFilterValue).toLowerCase())
    }
    if (word.nearMissTimeFilterValue) {
      nearMisstime = String(item.nearMisstime).toLowerCase().includes(String(word.nearMissTimeFilterValue).toLowerCase())
    }
    if (word.nearMissTimeGapFilterValue) {
      timeGap = String(item.timeGap).toLowerCase().includes(String(word.nearMissTimeGapFilterValue).toLowerCase())
    }
    return nearMissId && area && vehicleId_1 && vehicleId_1_Kind && vehicleId_1_direction && vehicleId_2 && vehicleId_2_kind && vehicleId_2_direction && latitude && longitude && nearMisstime && timeGap
  })
}

/**
 * リスト情報更新
 */
const updateNearMissesList = () => {
  nearMissesList.value = nearMissListStore.nearMissList
}

/**
 * TimeGapを小数点第3位まで残す
 * @param timeGap - TimeGap値
 * @returns 成型後TimeGap値
 */
const createTimegap4Disp = (timeGap: number) => {
  let n = 3
  return Math.floor(Number(timeGap) * Math.pow(10, n)) / Math.pow(10, n)
}

/**
 * 映像表示
 * @param item - ヒヤリハット情報
 */
const videoPlay = (item: NearMissStore) => {
  const videUrl = S3BUCKETURL.videoStrageBucketUrl + item.videoPath
  videoOption.value.options.sources[0].src = videUrl
  videoOption.value.nearMissesInfo.id = String(item.nearMissId)
  videoOption.value.nearMissesInfo.area = item.area
  videoOption.value.nearMissesInfo.vehicleId1Kind = item.vehicleId_1_Kind
  videoOption.value.nearMissesInfo.vehicleId1KindDirection = item.vehicleId_1_direction
  videoOption.value.nearMissesInfo.vehicleId2Kind = item.vehicleId_2_kind
  videoOption.value.nearMissesInfo.vehicleId2KindDirection = item.vehicleId_2_direction
  videoOption.value.nearMissesInfo.nearMissTime = item.nearMisstime.split('.')[0]
  videoOption.value.nearMissesInfo.timeGap = String(item.timeGap)
  videoOption.value.isShowMovie = true
}

/**
 * 映像を閉じる
 */
const videoClose = () => {
  videoOption.value.isShowMovie = false
}

/**
 * 映像ダウンロード
 * @param item - ヒヤリハット情報
 */
const downloadData = (item: NearMissStore) => {
  const url = S3BUCKETURL.videoStrageBucketUrl + item.videoPath
  const a = document.createElement('a')
  document.body.appendChild(a)
  a.href = url
  a.click()
  a.remove()
}

/**
 * ヒヤリハット一覧をCSVファイルとして出力する
 */
const DownloadCsv = () => {
  const searchConditions = searchConditionsStore.$state.searchCondition
  // CSVファイル内容を作成する
  const csvSubHeader = '\ufeff' + ',,優先方路,,非優先方路,,,\n'
  const csvHeader = 'Date,Id,物標,方路,物標,方路,エリア,TG<' + search.value.nearMissTimeGapFilterValue + '\n'
  let csv = csvSubHeader + csvHeader
  nearMissesList.value.forEach((data: NearMissStore) => {
    let line =
      data['nearMisstime'] +
      ',' +
      data['nearMissId'] +
      ',' +
      data['vehicleId_1_Kind'] +
      ',' +
      data['vehicleId_1_direction'] +
      ',' +
      data['vehicleId_2_kind'] +
      ',' +
      data['vehicleId_2_direction'] +
      ',' +
      data['area'] +
      ',' +
      data['timeGap'] +
      '\n'
    csv += line
  })
  // ページ内部にCSVファイルへのリンクを作成し、クリックアクションを入れて自動ダウンロードさせる
  const blob = new Blob([csv], { type: 'text/csv' })
  let link = document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  if ('startDateTime' in searchConditions && 'endDateTime' in searchConditions) {
    link.download = 'ヒヤリハット一覧' + '_' + searchConditions.startDateTime + '-' + searchConditions.endDateTime + '.csv'
  }
  link.click()
}

/**
 * ヒヤリハットGrafana遷移
 * @param item - ヒヤリハット情報
 */
const viewNearMissinfo = (item: NearMissStore) => {
  const baseUrl = 'https://g-339943402d.grafana-workspace.ap-northeast-1.amazonaws.com'
  const tableName = 't_corrected_pos_info'
  const sensorId = '13633281'
  const type1 = 'speed'
  const type2 = 'acceleration'
  const vid1 = item.vehicleId_2
  const vid2 = item.vehicleId_1
  const class1 = checkTargetType(item.vehicleId_2_kind, true)
  const class2 = checkTargetType(item.vehicleId_1_Kind, false)
  const utcTimestamp = DateTime.utc(Number(item.nearMisstime))
  const tenSecondsBefore = utcTimestamp.minus({ seconds: 10 })
  const tenSecondsAfter = utcTimestamp.plus({ seconds: 10 })
  const poleList = POLE_ON_GRAFANA.poleInfo
  poleList.forEach((pole: PoleInfo) => {
    if (selectPoleStore.$state.poleId == pole.poleId) {
      const dashboardSlug = pole.poleId
      let dashboardUrl = `${baseUrl}/d/${dashboardSlug}&from=${tenSecondsBefore}&to=${tenSecondsAfter}&var-poleid=${selectPoleStore.$state.poleId}&var-table_name1=${tableName}&var-sensorid1=${sensorId}&${class1}&var-vid1=${vid1}&var-table_name2=${tableName}&var-sensorid2=${sensorId}&${class2}&var-vid2=${vid2}&var-val_type1=${type1}&var-val_type2=${type2}`
      window.open(dashboardUrl, '_blank')
    }
  })
}

/**
 * 物標種別判定処理
 * @param kind - 物標種別
 * @param isClass1 - 車両1or2判別フラグ
 * @returns URLパラメータ
 */
const checkTargetType = (kind: string, isClass1: boolean) => {
  let result = undefined
  let classNumber = undefined
  if (isClass1) {
    classNumber = '1'
  } else {
    classNumber = '2'
  }
  switch (kind) {
    case 'Vehicle':
      result = `var-class${classNumber}=0&var-class${classNumber}=1&var-class${classNumber}=2`
      break
    case 'Motorcycle':
      result = `var-class${classNumber}=3`
      break
    case 'Bicycle':
      result = `var-class${classNumber}=4`
      break
    case 'Pedestrian':
      result = `var-class${classNumber}=6`
      break
  }
  return result
}

defineExpose({
  updateNearMissesList,
})
</script>
<template>
  <v-card
    class="near-miss-list-main"
    elevation="1"
  >
    <v-card-title class="near-miss-list-main__header py-0">
      <span class="py-0">ヒヤリハット一覧</span>
      <v-btn
        class="near-miss-list-main__csv-button"
        variant="text"
        color="primary"
        @click="DownloadCsv"
      >
        <img
          class="near-miss-list-main__csv-icon"
          :src="CSV_DOWNLOAD_ICON"
        >
      </v-btn>
    </v-card-title>
    <!-- Filter-->
    <v-row dense>
      <v-col
        class="near-miss-list-main__search-input--narrow"
        cols="1"
      >
        <v-row class="pa-5">
          <v-text-field
            v-model="search.nearMissIdFilterValue"
            type="text"
            label="ID"
            variant="underlined"
          />
        </v-row>
      </v-col>
      <v-col
        class="near-miss-list-main__search-input"
        cols="1"
      >
        <v-row class="pa-5">
          <v-text-field
            v-model="search.nearMissAreaFilterValue"
            type="text"
            label="判定エリア"
            variant="underlined"
          />
        </v-row>
      </v-col>
      <v-col
        class="near-miss-list-main__search-input"
        cols="1"
      >
        <v-row class="pa-5">
          <v-text-field
            v-model="search.nearMissVehicleId1FilterValue"
            type="text"
            label="車両1ID"
            variant="underlined"
          />
        </v-row>
      </v-col>
      <v-col
        class="near-miss-list-main__search-input"
        cols="1"
      >
        <v-row class="pa-5">
          <v-text-field
            v-model="search.nearMissVehicleId1KindFilterValue"
            type="text"
            label="車両1物標種別"
            variant="underlined"
          />
        </v-row>
      </v-col>
      <v-col
        class="near-miss-list-main__search-input"
        cols="1"
      >
        <v-row class="pa-5">
          <v-text-field
            v-model="search.nearMissVehicleId1DirectionFilterValue"
            type="text"
            label="車両1方路"
            variant="underlined"
          />
        </v-row>
      </v-col>
      <v-col
        class="near-miss-list-main__search-input"
        cols="1"
      >
        <v-row class="pa-5">
          <v-text-field
            v-model="search.nearMissVehicleId2FilterValue"
            type="text"
            label="車両2ID"
            variant="underlined"
          />
        </v-row>
      </v-col>
      <v-col
        class="near-miss-list-main__search-input"
        cols="1"
      >
        <v-row class="pa-5">
          <v-text-field
            v-model="search.nearMissVehicleId2KindFilterValue"
            type="text"
            label="車両2物標種別"
            variant="underlined"
          />
        </v-row>
      </v-col>
      <v-col
        class="near-miss-list-main__search-input"
        cols="1"
      >
        <v-row class="pa-5">
          <v-text-field
            v-model="search.nearMissVehicleId2DirectionFilterValue"
            type="text"
            label="車両2方路"
            variant="underlined"
          />
        </v-row>
      </v-col>
      <v-col
        class="near-miss-list-main__search-input"
        cols="1"
      >
        <v-row class="pa-5">
          <v-text-field
            v-model="search.nearMissLatitudeFilterValue"
            type="text"
            label="緯度"
            variant="underlined"
          />
        </v-row>
      </v-col>
      <v-col
        class="near-miss-list-main__search-input"
        cols="1"
      >
        <v-row class="pa-5">
          <v-text-field
            v-model="search.nearMissLongitudeFilterValue"
            type="text"
            label="経度"
            variant="underlined"
          />
        </v-row>
      </v-col>
      <v-col cols="2">
        <v-row class="pa-5">
          <v-text-field
            v-model="search.nearMissTimeFilterValue"
            type="text"
            label="発生時刻"
            variant="underlined"
          />
        </v-row>
      </v-col>
      <v-col cols="1">
        <v-row class="pa-5">
          <v-text-field
            v-model="search.nearMissTimeGapFilterValue"
            type="text"
            label="TimeGap"
            variant="underlined"
          />
        </v-row>
      </v-col>
    </v-row>
    <v-data-table
      :headers="nearMissesTableHeader"
      :items="nearMisses"
      density="compact"
    >
      <template #[`item.nearMisstime`]="{ item }">
        {{ item.nearMisstime }}
      </template>
      <template #[`item.timeGap`]="{ item }">
        {{ createTimegap4Disp(item.timeGap) }}
      </template>
      <template #[`item.videoPath`]="{ item }">
        <v-icon
          v-if="item.videoPath == undefined || item.videoPath == null || item.videoPath == ''"
          class="mr-2"
          small
          color="blue-grey"
        >
          mdi-video
        </v-icon>
        <v-icon
          v-else
          class="mr-2"
          small
          color="purple"
          @click="videoPlay(item)"
        >
          mdi-video
        </v-icon>
      </template>
      <template #[`item.downloadVidePath`]="{ item }">
        <v-icon
          v-if="item.downloadVidePath == undefined || item.downloadVidePath == null || item.downloadVidePath == ''"
          class="mr-2"
          dense
        >
          mdi-multimedia
        </v-icon>
        <v-icon
          v-else
          class="mr-2"
          dense
          color="blue"
          @click="downloadData(item)"
        >
          mdi-multimedia
        </v-icon>
      </template>
      <template #[`item.nearMissinfo`]="{ item }">
        <v-icon
          class="mr-2"
          small
          @click="viewNearMissinfo(item)"
        >
          mdi-details
        </v-icon>
      </template>
    </v-data-table>
  </v-card>

  <div
    v-if="videoOption.isShowMovie"
    class="near-miss-list-video-player"
  >
    <NearMissPlayVideo
      v-if="videoOption.isShowMovie"
      :options="videoOption.options"
      :near-misses-info="videoOption.nearMissesInfo"
      @video-close="videoClose"
    />
  </div>
</template>
<style scoped lang="scss">
  .near-miss-list-main {
    &__header {
      font-size: 22px;
      font-weight: bold;
      color: white;
      background-color: #0041c0;
      height: 3vh;
    }
    &__csv-button {
      position: absolute;
      right: 5px;
      width: 25px;
      top: 2px;
    }
    &__csv-icon {
      width: 32px;
      height: 34px;
    }
    &__search-input {
      flex: 0 0 8.3333333333%;
      max-width: 7.333333%;
      &--narrow {
        max-width: 5.333333%;
      }
    }
  }
  .near-miss-list-video-player {
    position: absolute;
    right: 30px;
    bottom: -20px;
  }
</style>
