import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import { useUserInfoStore } from '@/store/app'

import Accident from '@/views/Accident.vue'
import Aggregation from '@/views/Aggregation.vue'
import Area from '@/views/Area.vue'
// import Parking from '@/views/Parking.vue'
// import Summally from '@/views/Summally.vue'
// import Dashboard from '@/views/Dashboard.vue'
import Collections from '@/views/Collections.vue'
import GroupSetting from '@/views/GroupSetting.vue'
import HistoryData from '@/views/HistoryData.vue'
import HistoryList from '@/views/HistoryList.vue'
import Login from '@/views/Login.vue'
import MacroAnalysis from '@/views/MacroAnalysis.vue'
import MacroAnalysisInfoDataDownload from '@/views/MacroAnalysisInfoDataDownload.vue'
import MultiCamera from '@/views/MultiCamera.vue'
import RealTime from '@/views/RealTime.vue'
import Virtual from '@/views/Virtual.vue'
import Watch from '@/views/Watch.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/selectarea',
    name: 'Area',
    component: Area
  },
  {
    path: '/realtime',
    name: 'Realtime',
    component: RealTime
  },
  {
    path: '/multicamera',
    name: 'MultiCamera',
    component: MultiCamera
  },
  {
    path: '/virtual',
    name: 'Virtual',
    component: Virtual
  },
  {
    path: '/historylist',
    name: 'HistoryList',
    component: HistoryList
  },
  {
    path: '/historyData',
    name: 'HistoryData',
    component: HistoryData
  },
  {
    path: '/accident',
    name: 'Accident',
    component: Accident
  },
  {
    path: '/aggregation',
    name: 'Aggregation',
    component: Aggregation
  },
  /*
  {
    path: '/parking',
    name: 'Parking',
    component: Parking
  },
  */
  {
    path: '/watching',
    name: 'Watch',
    component: Watch
  },
  /*
  {
    path: '/summaries',
    name: 'Summally',
    component: Summally
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard
  },
  */
  {
    path: '/macroAnalysis',
    name: 'MacroAnalysis',
    component: MacroAnalysis
  },
  {
    path: '/macroAnalysisInfoDataDownload',
    name: 'MacroAnalysisInfoDataDownload',
    component: MacroAnalysisInfoDataDownload
  },
  {
    path: '/collection',
    name: 'Collection',
    component: Collections
  },
  {
    path: '/groupsetting',
    name: 'GroupSetting',
    component: GroupSetting
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
// 認証処理が行われていない場合はログイン画面へ遷移
router.beforeEach((to, _from, next) => {
  // ユーザー情報ストア
  const userInfoStore = useUserInfoStore()
  if (to.name !== 'Login' && Object.keys(userInfoStore.CognitoUser2).length == 0) next({ name: 'Login' })
  else next()
})

export default router