<script lang="ts" setup>
/**
 * DirectionList.vue
 * 方路一覧表示
 * 
 * 親コンポーネント
 * @/components/parts/aggregation/AggreMenu.vue
 */
// ==================================
// import
// ==================================
import { onMounted, watch, ref } from 'vue'

import { useAggregationFiltersStore } from '@/store/app'

import { DIRECTIONS } from '@/setting/setting'

import directionIcon from '@/assets/img/object/direction.svg'

// ==================================
// interface
// ==================================
interface Props {
  mode: number;
  dir: number;
}

// ==================================
// data
// ==================================
const filterStore = useAggregationFiltersStore()

const props = defineProps<Props>()

const state = ref({
  directionList: [] as any[],
  selectedDirection: [] as any[]
})

// ==================================
// watch
// ==================================
watch(() => state.value.selectedDirection, () => {
  updateSelectedDirectionList()
})

// ==================================
// hook
// ==================================
onMounted(() => {
  initialize()
})

// ==================================
// method
// ==================================
/**
 * 初期処理
 */
const initialize = () => {
  state.value.directionList = setDirList()
  state.value.directionList.forEach(direction => {
    state.value.selectedDirection.push(direction.value)
  })
  updateSelectedDirectionList()
}

/**
 * 集計対象とIN,OUTの分類に応じて、対象のPiniaストアの値を更新する
 */
const updateSelectedDirectionList = () => {
  // 集計対象
  switch(props.mode) {
      // 通過台数
    case 0:
      // dir 0:OUT、1:IN
      if (props.dir === 1) {
        filterStore.setFilterngDirIn(state.value.selectedDirection)
      } else {
        filterStore.setFilterngDirOut(state.value.selectedDirection)
      }
      break
      // ヒヤリハット優先
    case 1:
      if (props.dir === 1) {
        filterStore.setPriorityDirIn(state.value.selectedDirection)
      } else {
        filterStore.setPriorityDirOut(state.value.selectedDirection)
      }
      break
      // ヒヤリハット非優先
    case 2:
      if (props.dir === 1) {
        filterStore.setNonPriorityDirIn(state.value.selectedDirection)
      } else {
        filterStore.setNonPriorityDirOut(state.value.selectedDirection)
      }
      break
    default:
  }
}

/**
 * 一覧表を生成する
 * @returns 方路情報リスト
 */
const setDirList = () => {
  let work: any[] = []
  for (let dir of DIRECTIONS) {
    const iconStyle = {
      width: '16px',
      height: '16px',
      transform: 'rotate(' + dir.deg + 'deg)',
      margin: '0 2px 0 0'      
    }
    work.push({
      name: dir.name,
      value: dir.deg,
      url: directionIcon,
      style: iconStyle      
    })
  }
  return work
}
</script>
<template>
  <div class="direction-list">
    <v-table
      density="compact"
      theme="light"
    >
      <tbody>
        <tr
          v-for="item in state.directionList"
          :key="item.value"
        >
          <td class="direction-list__checkbox">
            <v-checkbox
              v-model="state.selectedDirection"
              :value="item.value"
              density="compact"
              hide-details              
            >
              <template #label>
                <img
                  :src="item.url"
                  :style="item.style"
                >
                <span class="direction-list__obj-name">{{ item.name }}</span>
              </template>
            </v-checkbox>
          </td>
        </tr>
      </tbody>
    </v-table>
  </div>
</template>
<style lang="scss" scoped>
  .direction-list {
    &__checkbox {
      padding: 0 !important;
    }
    &__obj-name {
      font-size: 0.8em;
    }
  }
</style>
