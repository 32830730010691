<script lang="ts" setup>
/**
 * VirtualVideo.vue
 * 検索エリア
 * 
 * 親コンポーネント
 * @/views/Accident.vue
 */
// ==================================
// import
// ==================================
import { onMounted, ref } from 'vue'

import { useSelectPoleStore, useAccidentListStore, useNearMissListStore } from '@/store/app'

import { POLE_ICON, NEAR_MISS_ICON, ACCIDENT_ICON, getTileLayer } from '@/mixins/mapFunction'

import { VIRTUAL_MAP } from '@/setting/setting'

import L from 'leaflet'
import 'leaflet/dist/leaflet.css'

// ==================================
// interface
// ==================================
interface MarkerList {
  poleMarkers: Array<any>;
  nearMissMarker: Array<any>;
  accidentMarker: Array<any>;
  markers: Array<any>;
}

// ==================================
// data
// ==================================
// 選択ポール情報ストア
const selectPoleStore = useSelectPoleStore()

// ヒヤリハット情報一覧
const nearMissListStore = useNearMissListStore()

// 事故情報一覧ストア
const accidentListStore = useAccidentListStore()

const markerList = ref<MarkerList>({
  poleMarkers: [],
  nearMissMarker: [],
  accidentMarker: [],
  markers: []
})

const map = ref<any>()

// ==================================
// hook
// ==================================
onMounted(() => {
  // マップ生成
  L.Icon.Default.mergeOptions(POLE_ICON)
  map.value = L.map('accident-map', {
    dragging: true,
    touchZoom: true,
    scrollWheelZoom: true,
    doubleClickZoom: true,
    boxZoom: true,
    tap: true,
    keyboard: true,
    zoomControl: true,
    minZoom: VIRTUAL_MAP.zoom.min,
    maxZoom: VIRTUAL_MAP.zoom.max,
  }).setView([selectPoleStore.latlng.lat, selectPoleStore.latlng.lng], VIRTUAL_MAP.zoom.default)
  L.control.layers(getTileLayer(map.value, VIRTUAL_MAP.zoom.max, undefined)).addTo(map.value)
})

// ==================================
// method
// ==================================
/**
 * ポールマーカー設定
 * @param lat - 緯度
 * @param lng - 経度
 */
const addPoleMarker = (lat: number, lng: number) => {
  removePoleMarker()
  markerList.value.poleMarkers.push(
    L.marker([lat, lng], {
      icon: L.icon(POLE_ICON),
    }).addTo(map.value)
  )
}

/**
 * ポールマーカー設定削除
 */
const removePoleMarker = () => {
  for (const item of markerList.value.poleMarkers) {
    map.value.removeLayer(item)
  }
  markerList.value.poleMarkers.splice(0)
}

/**
 * ヒヤリハットマーカー設定
 */
const addNearMissMarker = () => {
  removeNearMissMarker()
  for (const item of nearMissListStore.nearMissList) {
    markerList.value.nearMissMarker.push(
      L.marker([item.latitude, item.longitude], {
        icon: L.icon(NEAR_MISS_ICON),
      }).addTo(map.value)
    )
  }
}

/**
 * ヒヤリハットマーカー削除
 */
const removeNearMissMarker = () => {
  for (const item of markerList.value.nearMissMarker) {
    map.value.removeLayer(item)
  }
  markerList.value.nearMissMarker.splice(0)
}

/**
 * 事故情報マーカー設定
 */
const addAccidentMarker = () => {
  removeAccidentMarker()
  for (const item of accidentListStore.accidentList) {
    markerList.value.accidentMarker.push(
      L.marker([item.latitude, item.longitude], {
        icon: L.icon(ACCIDENT_ICON),
      })
        .bindPopup(
          `<ul>
							<li>事故ID: ${item.accidentId}</li>
							<li>事故発生時刻: ${item.accidentTime}</li>
						</ul>`
        )
        .addTo(map.value)
    )
  }
}

/**
 * 事故情報マーカー設定削除
 */
const removeAccidentMarker = () => {
  for (const item of markerList.value.accidentMarker) {
    map.value.removeLayer(item)
  }
  markerList.value.accidentMarker.splice(0)
}

defineExpose({
  addPoleMarker,
  addNearMissMarker,
  addAccidentMarker,
})
</script>
<template>
  <v-card
    class="virtual-video"
    elevation="1"
    outlined
  >
    <v-card-title class="virtual-video__header py-0 d-flex">
      <span class="py-5">VIRTUAL映像</span>
    </v-card-title>
    <v-divider />
    <div class="virtual-video__body pt-0">
      <div
        id="accident-map"
        class="virtual-video__accident-map"
      />
    </div>
  </v-card>
</template>
<style lang="scss" scoped>
  .virtual-video {
    height: 50vh;
    position: relative;
    &__header {
      font-size: 22px;
      font-weight: bold;
      color: white;
      background-color: #0041c0;
      height: auto;
    }
    &__body {
      text-align: center;
    }
    &__accident-map {
      position: absolute;
      top: 55px;
      bottom: 0;
      width: 100%;
      z-index: 99;
    }
  }
</style>
