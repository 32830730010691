// interfaces
import {
  LatLng,
  NearMissSummary,
  SummaryReportElement,
  AxlAppAveReport,
  AxlAppMaxReport,
  SummaryReports,
  AccidentStore,
  AggregationFilterStore,
  AreaListStore,
  DistanceFromPoleToVehicleByAreaId,
  LedListStore,
  MacroAnalysisStore,
  NearMissStore,
  PoleInfo,
  PoleInfoStore,
  PoleListStore,
  PosListStore,
  SearchConditionStore,
  SelectAccidentStore,
  SelectAreaStore,
  SelectPoleStore,
  SensorInfo,
  UserInfoStore,
  GroupListStore,
  PoleGroupData,
} from '@/types/Interfaces'

// Utilities
import { DateTime } from 'luxon'
import { defineStore } from 'pinia'


// ==================================
// ↓↓↓API取得時にデータ更新を行う↓↓↓
// ==================================
// ユーザー情報
export const useUserInfoStore = defineStore('userInfoStore', {
  state: () =>
    ({
      CognitoUser2: {},
    } as {
      CognitoUser2: UserInfoStore
    }),
  getters: {
    getJwtToken(state: any) {
      return state.CognitoUser2.signInUserSession.accessToken.jwtToken
    },
  },
  actions: {
    setData(res: UserInfoStore) {
      this.CognitoUser2 = res
    },
    clearData() {
      this.CognitoUser2 = {}
    },
  },
  persist: true
}
)

// エリア情報
export const useAreaListStore = defineStore('areaListStore', {
  state: () =>
    ({
      areaList: [],
    } as {
      areaList: AreaListStore[]
    }),
  getters: {},
  actions: {
    setData(res: AreaListStore[]) {
      this.areaList = res
    },
    clearData() {
      this.areaList = []
    },
  },
  persist: true,  
})

// ポール一覧情報
export const usePoleListStore = defineStore('poleListStore', {
  state: () =>
    ({
      poleList: [],
    } as {
      poleList: PoleListStore[]
    }),
  getters: {},
  actions: {
    setData(res: PoleListStore[]) {
      this.poleList = res
    },
    clearData() {
      this.poleList = []
    },
  },
  persist: true,  
})

// ポール情報
export const usePoleInfoStore = defineStore('poleInfoStore', {
  state: () =>
    ({
      poleId: 0,
      areaId: 0,
      areaName: '',
      latitude: 0,
      longitude: 0,
      altitude: 0,
      sensorList: [],
    } as PoleInfoStore),
  getters: {},
  actions: {
    setData(res: PoleInfo[], name: string) {
      this.poleId = res[0].poleId
        ;(this.areaId = res[0].areaId),
        (this.areaName = name),
        (this.latitude = res[0].latitude),
        (this.longitude = res[0].longitude),
        (this.altitude = res[0].altitude),
        (this.sensorList = res[0].sensorList)
    },
    clearData() {
      this.poleId = 0
        ;(this.areaId = 0),
        (this.areaName = ''),
        (this.latitude = 0),
        (this.longitude = 0),
        (this.altitude = 0),
        (this.sensorList = [])
    },
  },
  persist: true,  
})

// 物標情報リスト
export const usePosListStore = defineStore('posListStore', {
  state: () =>
    ({
      posList: [],
    } as {
      posList: PosListStore[]
    }),
  getters: {},
  actions: {
    setData(res: PosListStore[]) {
      this.posList = res
    },
    clearData() {
      this.posList = []
    },
  },
  persist: true,  
})
// LED表示板情報リスト
export const useLedListStore = defineStore('ledListStore', {
  state: () =>
    ({
      ledList: [],
    } as {
      ledList: LedListStore[]
    }),
  getters: {},
  actions: {
    setData(res: LedListStore[]) {
      this.ledList = res
    },
    clearData() {
      this.ledList = []
    },
  },
  persist: true,  
})
// ヒヤリハット一覧情報リスト
export const useNearMissListStore = defineStore('nearMissStore', {
  state: () =>
    ({
      nearMissList: [],
    } as {
      nearMissList: NearMissStore[]
    }),
  getters: {},
  actions: {
    setData(res: NearMissStore[]) {
      this.nearMissList = res
    },
    clearData() {
      this.nearMissList = []
    },
  },
  persist: true,  
})

// ヒヤリハット件数情報リスト
export const useNearMissSummaryListStore = defineStore('nearMissSummaryStore', {
  state: () =>
    ({
      nearMissSummaryList: []
    } as {
      nearMissSummaryList: NearMissSummary[]
    }),
  getters: {},
  actions: {
    setData(res: NearMissSummary[]) {
      this.nearMissSummaryList = res
    },
    clearData() {
      this.nearMissSummaryList = []
    }
  }
})

// 事故発生一覧情報リスト
export const useAccidentListStore = defineStore('accidentStore', {
  state: () =>
    ({
      accidentList: [],
    } as {
      accidentList: AccidentStore[]
    }),
  getters: {},
  actions: {
    setData(res: AccidentStore[]) {
      this.accidentList = res
    },
    clearData() {
      this.accidentList = []
    },
  },
  persist: true,  
})

/**
 * ヒヤリハット検索条件内容
 */
export const useSearchConditionStore = defineStore('searchConditionStore', {
  state: () =>
  ({
    searchCondition: {}
  } as {
    searchCondition: SearchConditionStore | object
  }),
  getters: {},
  actions: {
    setData(res: SearchConditionStore) {
      this.searchCondition = res
    },
    clearData() {
      this.searchCondition = {}
    }
  },
  persist: true
})

// ==================================
// ↓↓↓画面遷移時にデータ更新を行う↓↓↓
// ==================================
// エリア選択情報
export const useSelectAreaStore = defineStore('selectAreaStore', {
  state: () =>
    ({
      areaId: 0,
      name: '',
      latlng: { lat: 0, lng: 0 },
    } as SelectAreaStore),
  getters: {},
  actions: {
    setData(areaId: number, name: string, latlng: LatLng) {
      this.areaId = areaId
      this.name = name
      this.latlng = latlng
    },
    clearData() {
      this.areaId = 0
      this.name = ''
      this.latlng = { lat: 0, lng: 0 }
    },
  },
  persist: true,  
})

// ポール選択情報
export const useSelectPoleStore = defineStore('selectPoleStore', {
  state: () =>
    ({
      poleId: 0,
      areaId: 0,
      name: '',
      latlng: { lat: 0, lng: 0 },
      sensorList: []
    } as SelectPoleStore),
  getters: {
    getSelectPole(): SelectPoleStore {
      return {
        poleId: this.poleId,
        areaId: this.areaId,
        name: this.name,
        latlng: this.latlng,
        sensorList: this.sensorList
      }
    }
  },
  actions: {
    setData(poleId: number, areaId: number, name: string, latlng: LatLng, sensorList: SensorInfo[]) {
      this.poleId = poleId
      this.areaId = areaId
      this.name = name
      this.latlng = latlng
      this.sensorList = sensorList
    },
    clearData() {
      this.poleId = 0
      this.areaId = 0
      this.name = ''
      this.latlng = { lat: 0, lng: 0 }
      this.sensorList = []
    },
  },
  persist: true,  
})

/**
 * 集計グラフ画面用
 */
export const useAggregationDataStore = defineStore('aggregationDataStore', {
  state: () => ({
    // 集計データ
    aggregationData: {
      // 時間別
      daily: {
        date: DateTime.now(),
        nearMissReportList: [],
        trafficReportList: [],
        axlApproachReportList: []
      } as {
        date: DateTime,
        nearMissReportList: SummaryReportElement[],
        trafficReportList: SummaryReportElement[],
        axlApproachReportList: {
          accelerationApproachData: SummaryReportElement[],
          accelerationApproachAverageData: AxlAppAveReport[]
          accelerationApproachMaxData: AxlAppMaxReport[]
        }[]
      },
      // 日別
      weekly: {
        date: DateTime.now(),
        nearMissReportList: [],
        trafficReportList: [],
        axlApproachReportList: []
      } as {
        date: DateTime,
        nearMissReportList: SummaryReportElement[],
        trafficReportList: SummaryReportElement[],
        axlApproachReportList: {
          accelerationApproachData: SummaryReportElement[],
          accelerationApproachAverageData: AxlAppAveReport[]
          accelerationApproachMaxData: AxlAppMaxReport[]
        }[]     
      },
      // 月別
      yearly: {
        date: DateTime.now(),
        nearMissReportList: [],
        trafficReportList: [],
        axlApproachReportList: []
      } as {
        date: DateTime,
        nearMissReportList: SummaryReportElement[],
        trafficReportList: SummaryReportElement[],
        axlApproachReportList: {
          accelerationApproachData: SummaryReportElement[],
          accelerationApproachAverageData: AxlAppAveReport[]
          accelerationApproachMaxData: AxlAppMaxReport[]
        }[]    
      }    
    },
    // サマリーデータ
    summaries: {
      dailyList: {},
      weeklyList: {},
      yearlyList: {}
    } as {
      dailyList: SummaryReports
      weeklyList: SummaryReports
      yearlyList: SummaryReports
    }
  }),
  getters: {},
  actions: {
    updateDate(switcher: number, date: DateTime) {
      switch (switcher) {
        case 0:
          this.aggregationData.daily.date = date
          break
        case 1:
          this.aggregationData.weekly.date = date
          break
        case 2:
          this.aggregationData.yearly.date = date
          break
        default:
      }
    },
    updateNearMiss(switcher: number, report: SummaryReportElement[] ) {
      switch (switcher) {
        case 0:
          this.aggregationData.daily.nearMissReportList = report
          break
        case 1:
          this.aggregationData.weekly.nearMissReportList = report
          break
        case 2:
          this.aggregationData.yearly.nearMissReportList = report
          break
        default:
      }
    },
    updateTraffic(switcher: number, report: SummaryReportElement[]) {
      switch (switcher) {
        case 0:
          this.aggregationData.daily.trafficReportList = report
          break
        case 1:
          this.aggregationData.weekly.trafficReportList = report
          break
        case 2:
          this.aggregationData.yearly.trafficReportList = report
          break
        default:
      }
    },
    updateAxlApproach(switcher: number, report: any[]) {
      switch (switcher) {
        case 0:
          this.aggregationData.daily.axlApproachReportList = report
          break
        case 1:
          this.aggregationData.weekly.axlApproachReportList = report
          break
        case 2:
          this.aggregationData.yearly.axlApproachReportList = report
          break
        default:
      }
    },
    updateSummary(switcher: number, data: SummaryReports) {
      switch (switcher) {
        case 0:
          this.summaries.dailyList = data
          break
        case 1:
          this.summaries.weeklyList = data
          break
        case 2:
          this.summaries.yearlyList = data
          break
        default:
      }
    },
    resetAggregationData() {
      this.aggregationData = {
        daily: {
          date: DateTime.now(),
          nearMissReportList: [],
          trafficReportList: [],
          axlApproachReportList: []
        },
        weekly: {
          date: DateTime.now(),
          nearMissReportList: [],
          trafficReportList: [],
          axlApproachReportList: []  
        },
        yearly: {
          date: DateTime.now(),
          nearMissReportList: [],
          trafficReportList: [],
          axlApproachReportList: []
        }
      }
    },
    resetSummaries() {
      this.summaries = {
        dailyList: <SummaryReports>{},
        weeklyList: <SummaryReports>{},
        yearlyList: <SummaryReports>{}
      }
    },
    resetSummary(flag: number) {
      switch (flag) {
        case 1:
          this.summaries.dailyList = <SummaryReports>{}
          break
        case 2:
          this.summaries.weeklyList = <SummaryReports>{}
          break
        case 3:
          this.summaries.yearlyList = <SummaryReports>{}
          break
        default:
      }
    },
    resetAll() {
      this.resetAggregationData()
      this.resetSummaries()
    }
  }
})

/**
 * 集計フィルタ用ストア
 */
export const useAggregationFiltersStore = defineStore('aggregationFiltersStore', {
  state: () => ({
    mode: {
      category: 0,
      span: 0
    },
    // 優先物標
    priorityObj: [],
    // 非優先物標
    nonPriorityObj: [],
    // 優先物標に対する方路IN
    priorityDirIn: [],
    // 非優先物標に対する方路IN
    nonPriorityDirIn: [],
    // 優先物標に対する方路OUT
    priorityDirOut: [],
    // 非優先物標に対する方路OUT
    nonPriorityDirOut: [],
    // 対象物標
    filteringObj: [],
    // 対象方路IN
    filteringDirIn: [],
    // 対象方路OUT
    filteringDirOut: []
  } as AggregationFilterStore),
  getters: {},
  actions: {
    /**
     * 全データの初期化
     */
    clearData() {
      this.mode = {
        category: 0,
        span: 0
      }
      this.priorityObj = []
      this.nonPriorityObj = []
      this.priorityDirIn = []
      this.nonPriorityDirIn = []
      this.priorityDirOut = []
      this.nonPriorityDirOut = []
      this.filteringObj = []
      this.filteringDirIn = []
      this.filteringDirOut = []
    },
    /**
     * 全データの更新
     * @param {object} filter 集計フィルタ値
     */
    setData(filter: AggregationFilterStore) {
      this.mode = filter.mode
      this.priorityObj = filter.priorityObj
      this.nonPriorityObj = filter.nonPriorityObj
      this.priorityDirIn = filter.priorityDirIn
      this.nonPriorityDirIn = filter.nonPriorityDirIn
      this.priorityDirOut = filter.priorityDirOut
      this.nonPriorityDirOut = filter.nonPriorityDirOut
      this.filteringObj = filter.filteringObj
      this.filteringDirIn = filter.filteringDirIn
      this.filteringDirOut = filter.filteringDirOut
    },
    setMode(category: number, span: number) {
      this.mode = {
        category: category,
        span: span
      }
    },
    setPriorityObj(obj: string[]) {
      this.priorityObj = obj
    },
    setPriorityDirIn(obj: number[]) {
      this.priorityDirIn = obj
    },
    setPriorityDirOut(obj: number[]) {
      this.priorityDirOut = obj
    },
    setFilterngObj(obj: string[]) {
      this.filteringObj = obj
    },
    setFilterngDirIn(obj: number[]) {
      this.filteringDirIn = obj
    },
    setFilterngDirOut(obj: number[]) {
      this.filteringDirOut = obj
    },
    setNonPriorityObj(obj: string[]) {
      this.nonPriorityObj = obj
    },
    setNonPriorityDirIn(obj: number[]) {
      this.nonPriorityDirIn = obj
    },
    setNonPriorityDirOut(obj: number[]) {
      this.nonPriorityDirOut = obj
    }
  }
})

export const useSelectAccidentStore = defineStore('selectAccidentStore', {
  state: () => ({
    accidentId: undefined,
    poleId: undefined,
    latlng: undefined,
    location: undefined,
    accidentTime: undefined,
    acceleration: undefined,
    movieList: []
  } as SelectAccidentStore),
  getters: {},
  actions: {
    setSelectAccident(val: SelectAccidentStore) {
      this.accidentId = val.accidentId
      this.poleId = val.poleId
      this.latlng = val.latlng
      this.location = val.location
      this.accidentTime = val.accidentTime
      this.acceleration = val.acceleration
      this.movieList = val.movieList
    }
  }
})

/**
 * マクロ分析用ストア
 */
export const useMacroAnalysisInfoStore = defineStore('macroAnalysisStore', {
  state: () => ({
    macroAnalysisInfo: {},
    macroAnalysisDetaiInfo: {},
    macroAnalysisInfoDataForExcel: {},
  } as MacroAnalysisStore),
  getters: {},
  actions: {
    setMacroAnalysisInfo(val: any) {
      this.macroAnalysisInfo = val
    },
    setMacroAnalysisDetailInfo(val: any) {
      this.macroAnalysisDetaiInfo = val
    },
    setMacroAnalysisInfoDataForExcel(val: any) {
      this.macroAnalysisInfoDataForExcel = val
    }
  }
})

interface Period {
  start: string;
  end: string;
}

/**
 * マクロ分析絞り込みデータ
 */
export const useMacroAnalysisSearchDataStore = defineStore('macroAnalysisSearchDataStore', {
  state: () => ({
    aggregationPeriod: {
      start: '',
      end: ''
    },
    comparisonPeriod: {
      start: '',
      end: ''
    },
    directionValue: '',
    ledValue: '',
    aggregationTargetValue: '',
    aggregationMethodValue: ''
  } as {
    aggregationPeriod: Period,
    comparisonPeriod: Period,
    directionValue: string,
    ledValue: string,
    aggregationTargetValue: string,
    aggregationMethodValue: string
  }),
  getters: {
    getAggregationPeriod(): Period {
      return this.aggregationPeriod
    },
    getComparisonPeriod(): Period {
      return this.comparisonPeriod
    }
  },
  actions: {
    /**
     * 集計期間の開始日を更新する
     * @param {String} start 集計期間開始日
     * @returns {Boolean} 処理結果
     */
    setAggregationPeriodStart(start: string): boolean {
      const format = 'yyyy/MM/dd'
      const targetDate = DateTime.fromFormat(start, format)
      if (targetDate.isValid) {
        this.aggregationPeriod.start = start
        return true
      } else {
        return false
      }
    },
    /**
     * 集計期間の終了日を更新する
     * @param {String} end 集計期間終了日
     * @returns {Boolean} 処理結果
     */
    setAggregationPeriodEnd(end: string): boolean {
      const format = 'yyyy/MM/dd'
      const targetDate = DateTime.fromFormat(end, format)
      if (targetDate.isValid) {
        this.aggregationPeriod.end = end
        return true
      } else {
        return false
      }
    },
    /**
     * 比較期間の開始日を更新する
     * @param {String} start 比較期間開始日
     * @returns {Boolean} 処理結果
     */
    setComparisonPeriodStart(start: string): boolean {
      const format = 'yyyy/MM/dd'
      const targetDate = DateTime.fromFormat(start, format)
      if (targetDate.isValid) {
        this.comparisonPeriod.start = start
        return true
      } else {
        return false
      }
    },
    /**
     * 比較期間の終了日を更新する
     * @param {String} end 比較期間終了日
     * @returns {Boolean} 処理結果
     */
    setComparisonPeriodEnd(end: string): boolean {
      const format = 'yyyy/MM/dd'
      const targetDate = DateTime.fromFormat(end, format)
      if (targetDate.isValid) {
        this.comparisonPeriod.end = end
        return true
      } else {
        return false
      }
    },
    /**
     * デートピッカーで指定された集計期間の開始日を更新する
     * @param {String} start 集計期間開始日
     * @returns {Boolean} 処理結果
     */
    setAggregationPeriodStartFromPicker(start: string): boolean {
      const format = 'yyyy-MM-dd'
      const targetDate = DateTime.fromFormat(start, format)
      if (targetDate.isValid) {
        this.aggregationPeriod.start = start.replaceAll('-', '/')
        return true
      } else {
        return false
      }
    },
    /**
     * デートピッカーで指定された集計期間の終了日を更新する
     * @param {String} end 集計期間終了日
     * @returns {Boolean} 処理結果
     */
    setAggregationPeriodEndFromPicker(end: string): boolean {
      const format = 'yyyy-MM-dd'
      const targetDate = DateTime.fromFormat(end, format)
      if (targetDate.isValid) {
        this.aggregationPeriod.end = end.replaceAll('-', '/')
        return true
      } else {
        return false
      }
    },
    /**
     * デートピッカーで指定された比較期間の開始日を更新する
     * @param {String} start 比較期間開始日
     * @returns {Boolean} 処理結果
     */
    setComparisonPeriodStartFromPicker(start: string): boolean {
      const format = 'yyyy-MM-dd'
      const targetDate = DateTime.fromFormat(start, format)
      if (targetDate.isValid) {
        this.comparisonPeriod.start = start.replaceAll('-', '/')
        return true
      } else {
        return false
      }
    },
    /**
     * デートピッカーで指定された比較期間の終了日を更新する
     * @param {String} end 比較期間終了日
     * @returns {Boolean} 処理結果
     */
    setComparisonPeriodEndFromPicker(end: string): boolean {
      const format = 'yyyy-MM-dd'
      const targetDate = DateTime.fromFormat(end, format)
      if (targetDate.isValid) {
        this.comparisonPeriod.end = end.replaceAll('-', '/')
        return true
      } else {
        return false
      }
    },
    setDirectionValue(val: string): void {
      this.directionValue = val
    },
    setLedValue(val: string): void {
      this.ledValue = val
    },
    setAggregationTargetValue(val: string): void {
      this.aggregationTargetValue = val
    },
    setAggregationMethodValue(val: string): void {
      this.aggregationMethodValue = val
    },
    setMacroAnalysisSearchData(data: any): boolean {
      if (!this.setAggregationPeriodStart(data.aggregationPeriod.start)) {
        return false
      }
      if (!this.setAggregationPeriodEnd(data.aggregationPeriod.end)) {
        return false
      }
      if (!this.setComparisonPeriodStart(data.comparisonPeriod.start)) {
        return false
      }
      if (!this.setComparisonPeriodEnd(data.comparisonPeriod.end)) {
        return false
      }
      this.setDirectionValue(data.directionValue)
      this.setLedValue(data.ledValue)
      this.setAggregationTargetValue(data.aggregationTargetValue)
      this.setAggregationMethodValue(data.aggregationMethodValue)
      return true
    }
  }
})

interface ExcelData {
  createDate: string;
  areaName: string;
  searchCondition: string;
  creationStatus: string;
  downloadDataPath: string;
}

/**
 * Excel出力用マクロ分析データ
 */
export const useMacroAnalysisExcelDataStore = defineStore('macroAnalysisExcelDataStore', {
  state: () => ({
    data: [] as ExcelData[]
  }),
  getters: {},
  actions: {
    setMacroAnalysisExcelData(data: ExcelData[]): void {
      this.data = data
    }
  }
})

/**
 * 見守りデータ
 */
export const useWatchingListStore = defineStore('watchingListStore', {
  state: () => ({
    watchingList: []
  } as {
    watchingList: DistanceFromPoleToVehicleByAreaId
  }),
  actions: {
    setWatchingList(val: DistanceFromPoleToVehicleByAreaId) {
      this.watchingList = val
    }
  }
})

/**
 * 集計用データ
 */
// 物標件数
interface CollectionObject {
  date?: string;
  poleId?: number;
  vehiclesizeclassification?: number;
  accessroad?: string;
  Vehiclecount?: number;
}

/**
 * 通過物標件数ストア
 */
export const usePassingObjectsCollectionListStore = defineStore('passingObjectsCollectionListStore', {
  state: () => ({
    poleId: 0,
    startDate: '',
    endDate: '',
    passingObjectsCollectionList: []
  } as { 
    poleId: number,
    startDate: string,
    endDate: string,
    passingObjectsCollectionList: CollectionObject[]
  }),
  getters: {},
  actions: {
    /**
     * ポールIDを更新する
     * @param id - ポールID
     */
    setPoleId(id: number) {
      this.poleId = id
    },
    /**
     * 指定日付範囲を更新する
     * @param start - 開始日
     * @param end - 終了日
     */
    setDate(start: string, end: string) {
      this.startDate = start
      this.endDate = end
    },
    /**
     * 通過物標件数リストを更新する
     * @param val - 通過物標件数リスト
     */
    setPassingObjectsCollectionList(val: CollectionObject[]) {
      this.passingObjectsCollectionList = val
    },
    /**
     * ステートをリセットする
     */
    resetData() {
      this.poleId = 0
      this.startDate = ''
      this.endDate = ''
      this.passingObjectsCollectionList = []
    }
  }
})

// 一時停止物標件数ストア
export const useStopObjectsCollectionListStore = defineStore('stopObjectsCollectionListStore', {
  state: () => ({
    poleId: 0,
    startDate: '',
    endDate: '',
    stopObjectsCollectionList: []
  } as { 
    poleId: number,
    startDate: string,
    endDate: string,
    stopObjectsCollectionList: CollectionObject[]
  }),
  getters: {},
  actions: {
    /**
     * ポールIDを更新する
     * @param id - ポールID
     */
    setPoleId(id: number) {
      this.poleId = id
    },
    /**
     * 指定日付範囲を更新する
     * @param start - 開始日
     * @param end - 終了日
     */
    setDate(start: string, end: string) {
      this.startDate = start
      this.endDate = end
    },
    /**
     * 通過物標件数リストを更新する
     * @param val - 通過物標件数リスト
     */
    setStopObjectsCollectionList(val: CollectionObject[]) {
      this.stopObjectsCollectionList = val
    },
    /**
     * ステートをリセットする
     */
    resetData() {
      this.poleId = 0
      this.startDate = ''
      this.endDate = ''
      this.stopObjectsCollectionList = []
    }
  }
})

// ヒヤリハット件数ストア
export const useNearMissObjectsCollectionListStore = defineStore('nearMissObjectsCollectionListStore', {
  state: () => ({
    poleId: 0,
    startDate: '',
    endDate: '',
    nearMissObjectsCollectionList: []
  } as { 
    poleId: number,
    startDate: string,
    endDate: string,
    nearMissObjectsCollectionList: CollectionObject[]
  }),
  getters: {},
  actions: {
    /**
     * ポールIDを更新する
     * @param id - ポールID
     */
    setPoleId(id: number) {
      this.poleId = id
    },
    /**
     * 指定日付範囲を更新する
     * @param start - 開始日
     * @param end - 終了日
     */
    setDate(start: string, end: string) {
      this.startDate = start
      this.endDate = end
    },
    /**
     * 通過物標件数リストを更新する
     * @param val - 通過物標件数リスト
     */
    setNearMissObjectsCollectionList(val: CollectionObject[]) {
      this.nearMissObjectsCollectionList = val
    },
    /**
     * ステートをリセットする
     */
    resetData() {
      this.poleId = 0
      this.startDate = ''
      this.endDate = ''
      this.nearMissObjectsCollectionList = []
    }
  }
})

// 方位リスト
export const useVdiParamListStore = defineStore('vdiParamListStore', {
  state: () => ({
    poleId: 0,
    vdiParamList: []
  } as {
    poleId: number
    vdiParamList: string[]
  }),
  getters: {},
  actions: {
    /**
     * ポールIDを更新する
     * @param val - ポールID
     */
    setPoleId(val: number) {
      this.poleId = val
    },
    /**
     * 方位リストを更新する
     * @param val - 方位リスト
     */
    setVdiParamList(val: string[]) {
      this.vdiParamList = val
    },
    /**
     * ステートをリセットする
     */
    resetData() {
      this.poleId = 0
      this.vdiParamList = []
    }
  }
})

/**
 * グループ一覧
 */
export const useGroupListStore = defineStore('groupListStore', {
  state: () => ({
    groupList: []
  } as {
    groupList: GroupListStore[]
  }),
  getters: {},
  actions: {
    setGroupList(list: GroupListStore[]): void {
      this.groupList = list
    }
  }
})

/**
 * ポールグループ一覧
 */
export const usePoleGroupListStore = defineStore('poleGroupListStore', {
  state: () => ({
    poleGroupList: []
  } as {
    poleGroupList: PoleGroupData[]
  }),
  getters: {},
  actions: {
    setPoleGroupList(poleGroupList: PoleGroupData[]): void {
      this.poleGroupList = poleGroupList
    }
  }
})
