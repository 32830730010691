<script lang="ts" setup>
/**
 * VirtualVideo.vue
 * バーチャル映像コンポーネント
 * 
 * 親コンポーネント
 * @/views/RealTime.vue
 */
// ==================================
// import
// ==================================
import { ref } from 'vue'

import SelectSensor from '@/components/parts/common/SelectSensor.vue'
import VirtualMap from '@/components/parts/common/VirtualMap.vue'

import { SensorIdData } from '@/types/Interfaces'

// ==================================
// interface
// ==================================
interface Emits {
  (e: 'select-sensor-list-hand-over', value: SensorIdData[]): void;
}

// ==================================
// data
// ==================================
const selectSensorList = ref<SensorIdData[]>([])

const selectSensorComponent = ref()
const virtualMapComponent = ref()

// ==================================
// computed
// ==================================
/**
 * 選択済みのセンサーを更新
 * @param value - 選択済みセンサー情報
 */
const updateSelectSensorList = (value: SensorIdData[]) => {
  selectSensorList.value = value
  selectSensorListHandOver(selectSensorList.value)
}

// ==================================
// method
// ==================================
const emit = defineEmits<Emits>()
/**
 * 選択済みセンサー情報を親コンポーネントへ渡す
 * @param sensorlist - 選択済みセンサー情報
 */
const selectSensorListHandOver = (sensorlist: SensorIdData[]) => {
  emit('select-sensor-list-hand-over', sensorlist)
}

/**
 * センサー初期設定
 */
const initSensorList = () => {
  selectSensorComponent.value.initSensorList()
}
/**
 * ポールマーカー設定
 * @param lat - 緯度
 * @param lng - 経度
 */
const addPoleMarker = (lat: number, lng: number) => {
  virtualMapComponent.value.addPoleMarker(lat, lng)
}
/**
 * 物標を更新
 */
const updatePosObject = () => {
  virtualMapComponent.value.updatePosObject()
}
/**
 * マップ表示の中央位置を設定
 * @param latlng - 緯度経度
 */
const setCenter = (latlng: number[]) => {
  virtualMapComponent.value.setCenter(latlng)
}

defineExpose({
  initSensorList,
  addPoleMarker,
  updatePosObject,
  setCenter,
})
</script>
<template>
  <v-card
    class="virtual-video"
    elevation="5"
    outlined
  >
    <v-card-title class="virtual-video__title py-0 d-flex">
      <span class="py-5">VIRTUAL映像</span>
      <SelectSensor
        ref="selectSensorComponent"
        @select-sensor-list-hand-over="updateSelectSensorList"
      />
    </v-card-title>
    <v-divider />
    <VirtualMap
      ref="virtualMapComponent"
      :select-sensor-list="selectSensorList"
    />
  </v-card>
</template>
<style lang="scss" scoped>
  .virtual-video {
    height: 65vh;
    position: relative;
    &__title {
      font-size: 22px;
      font-weight: bold;
      color: white;
      background-color: #0041c0;
      height: auto;
    }
  }
</style>
