<script lang="ts" setup>
/**
 * GroupDeleteDialog.vue
 * グループ削除
 * 
 * 親コンポーネント
 * @/views/GroupSetting.vue
 */
// ==================================
// import
// ==================================
import { onBeforeMount, computed, ref } from 'vue'

import ErrorDialog from '@/components/parts/common/ErrorDialog.vue'
import Loading from '@/components/parts/common/Loading.vue'

import { DIALOG_ERROR_INFO } from '@/mixins/commonFunction'
import { deleteGroupInfo } from '@/mixins/communicationFunction'
import { toHexNumber } from '@/utils/math'

import { ErrorDialogInfo, GroupListStore } from '@/types/Interfaces'

// ==================================
// interface
// ==================================
interface Props {
  dialog: {
    isDialog: boolean;
    item: GroupListStore;
  }
}

interface Emits {
  (e: 'show-error-dialog', result: boolean, title: string, message: string): void;
  (e: 'dialogClose'): void;
}

// ==================================
// data
// ==================================
const props = defineProps<Props>()

// ローディングアイコン表示判定
const isLoading = ref<boolean>(false,)

// グループ情報
const groupInfo = ref<GroupListStore>({
  groupid: 0,
  groupname: ''
})

const errDialog = ref<ErrorDialogInfo>({
  isShow: false,
  title: '',
  message: ''
})

// ==================================
// computed
// ==================================
// ダイアログの表示判定用
const isDialog = computed(() => {
  return props.dialog.isDialog
})

// ==================================
// hook
// ==================================
onBeforeMount(() => {
  initialize()
})

// ==================================
// method
// ==================================
const emit = defineEmits<Emits>()

/**
 * 初期処理
 */
const initialize = () => {
  groupInfo.value.groupid = props.dialog.item.groupid
  groupInfo.value.groupname = props.dialog.item.groupname
}

/**
 * グループ情報削除処理
 */
const onClickDelete = () => {
  isLoading.value = true
  // グループ削除APIを呼び出す
  let promise = deleteGroupInfo(groupInfo.value.groupid)
  promise
    .then(() => {
      isLoading.value = false
      emit('show-error-dialog', true, 'グループ削除成功','グループを削除しました。\nグループID: ' + toHexNumber(groupInfo.value.groupid) + '、グループ名: ' + groupInfo.value.groupname)
    })
    .catch(() => {
      isLoading.value = false
      // エラーメッセージを設定する
      let title = DIALOG_ERROR_INFO.title.deleteError
      let message = DIALOG_ERROR_INFO.message.deleteErrorGroupInfo
      emit('show-error-dialog', false, title, message)
    })
}

/**
 * ダイアログ閉じる処理
 */
const dialogClose = () => {
  emit('dialogClose')
}
</script>
<template>
  <v-dialog
    v-model="isDialog"
    class="group-delete-dialog"
    width="400px"
    persistent
  >
    <v-card>
      <v-container>
        <v-card-title
          class="group-delete-dialog__title"
          nav
          density="compact"
        >
          グループ削除
        </v-card-title>
        <v-divider />
        <v-card-text>以下のグループを削除してもよろしいですか？</v-card-text>
        <v-card-text>
          <strong>{{ groupInfo.groupname }}</strong>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary darken-2"
            class="group-delete-dialog__delete-btn"
            @click="onClickDelete"
          >
            削除
          </v-btn>
          <v-btn
            color="grey darken-2"
            dark
            @click="dialogClose"
          >
            キャンセル
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
    <ErrorDialog :error-dialog="errDialog" />
    <Loading v-show="isLoading" />
  </v-dialog>
</template>
<style lang="scss" scoped>
  .group-delete-dialog {
    &__title {
      height: 5vh;
      font-size: 18px;
      font-weight: bold;
    }
    &__delete-btn {
      width: 24%;
    }
  }
  .v-input :deep(.v-messages) {
    margin-top: 10px;
  }
</style>
