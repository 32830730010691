<script lang="ts" setup>
/**
 * Button.vue
 * 汎用ボタン
 * 
 * 親コンポーネント
 * @/components/parts/aggregation/AggreMenu.vue
 * @/views/Aggregation.vue
 * @/views/MacroAnalysis.vue
 * @/views/Watch.vue
 */
// ==================================
// interface
// ==================================
interface Props {
  label: string;
  disabled: boolean;
}

interface Emits {
  (e: 'click'): void;
}

// ==================================
// data
// ==================================
const props = defineProps<Props>()

// ==================================
// method
// ==================================
const emit = defineEmits<Emits>()
/**
 * クリック
 */
const onclick = () => {
  emit('click')
}
</script>
<template>
  <!-- dark -->
  <v-btn
    :disabled="props.disabled"
    theme="light"
    rounded
    color="primary"
    block
    @click="onclick()"
  > 
    {{ label }} 
  </v-btn>
</template>
