<script lang="ts" setup>
/**
 * MultiCameraFootage.vue
 * マルチカメラコンポーネント 
 * 
 * 親コンポーネント
 * @/views/MultiCamera.vue
 */
// ==================================
// import
// ==================================
import { computed, ref } from 'vue'

import KinesisVideoStreamsWebrtc from '@/components/parts/common/KinesisVideoStreamsWebrtc.vue'

// ==================================
// interface
// ==================================
interface Props {
  isSelctSensor: boolean;
}

interface CameraInfo {
  sensorId: number;
  poleId: number;
  cameraKind: number;
  name: string;
}

// ==================================
// data
// ==================================
const props = defineProps<Props>()

const cameraList = ref<string[] | CameraInfo[]>(['選択可能なカメラが存在しません'])

const selectCamera = ref<string | CameraInfo>('選択可能なカメラが存在しません')

const showCamera = ref< boolean>(false)

// ==================================
// computed
// ==================================
const isSelctSensor = computed(() => {
  return !props.isSelctSensor
})

// ==================================
// methods
// ==================================
/**
 * カメラ初期設定
 * @param cameraListFromParent - カメラ情報
 */
const initCamera = (cameraListFromParent: CameraInfo[]) => {
  if (cameraListFromParent.length != 0) {
    cameraList.value = cameraListFromParent
    selectCamera.value = cameraList.value[0]
    showCamera.value = true
  }
}

defineExpose({
  initCamera,
})
</script>
<template>
  <v-card
    class="multi-camera-footage"
    elevation="5"
    outlined
  >
    <v-card-title class="multi-camera-footage__title py-0 d-flex">
      <v-select
        v-model="selectCamera"
        class="multi-camera-footage__select"
        variant="underlined"
        :items="cameraList"
        theme="dark"
        item-title="name"
        item-value="channel"
        label="カメラ選択"
        autowidth
        return-object
        :disabled="isSelctSensor"
      />
    </v-card-title>
    <v-divider />
    <div
      align="center"
      class="multi-camera-footage__video-frame"
    >
      <KinesisVideoStreamsWebrtc
        v-if="showCamera"
        :camera="selectCamera"
        :class="'multi-video-container'"
      />
    </div>
  </v-card>
</template>
<style lang="scss" scoped>
  .multi-camera-footage {
    &__title {
      font-size: 22px;
      font-weight: bold;
      color: white;
      background-color: #0041c0;
      height: auto;
    }
    &__select {
      width: 10vw;
      height: 6vh;
    }
    &__video-frame {
      height: 38vh;
      background-color: rgb(56, 54, 54);
      min-height: 25vh;
    }
  }
</style>
