<script lang="ts" setup>
/**
 * LedDisplayDataInformation.vue
 * 交差点内物標情報コンポーネント
 * 
 * 親コンポーネント
 * @/views/RealTime.vue
 */
// ==================================
// import
// ==================================
import { ref, computed } from 'vue'

import LedInfoTable from '@/components/parts/common/LedInfoTable.vue'

import { SensorIdData } from '@/types/Interfaces'

// ==================================
// interface
// ==================================
interface Props {
  selectSensorList: SensorIdData[];
}

// ==================================
// data
// ==================================
const props = defineProps<Props>()

const ledInfoTableComponent = ref()

// ==================================
// computed
// ==================================
const selectSensorList = computed(() => {
  return props.selectSensorList
})

// ==================================
// method
// ==================================
/**
 * LED情報取得
 */
const initLedList = () => {
  ledInfoTableComponent.value.initLedList()
}
/**
 * LED情報一覧更新
 */
const updateList = () => {
  ledInfoTableComponent.value.updateList()
}

defineExpose({
  initLedList,
  updateList,
})
</script>
<template>
  <v-card
    class="led-display-data-information"
    elevation="5"
    outlined
  >
    <v-card-title class="led-display-data-information__title py-0 d-flex">
      <span class="py-0">LED表示板</span>
    </v-card-title>
    <v-divider />
    <LedInfoTable
      ref="ledInfoTableComponent"
      :select-sensor-list="selectSensorList"
      :items-per-page="3"
      mode="realTime"
    />
  </v-card>
</template>
<style lang="scss" scoped>
  .led-display-data-information {
    height: auto;
    &__title {
      font-size: 22px;
      font-weight: bold;
      color: white;
      background-color: #0041c0;
      height: auto;
    }
  }
</style>
