<script lang="ts" setup>
/**
 * TrafficDailyChart.vue
 * 時間別通過台数グラフ
 * 
 * 親コンポーネント
 * @/components/parts/aggregation/charts/daily/AggreDaily.vue
 */
// ==================================
// import
// ==================================
import { onMounted, computed, watch, ref } from 'vue'

import { useAggregationDataStore } from '@/store/app'

import { ChartData, SummaryReportElement } from '@/types/Interfaces'

// chart.js Library
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale } from 'chart.js'
import { DateTime } from 'luxon'
import 'chartjs-adapter-luxon'
import { Line } from 'vue-chartjs'
// ChartJSのコンポーネント登録
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale)


// ==================================
// interface
// ==================================
interface Props {
  label: string[];
  toDate: DateTime;
}

// ==================================
// data
// ==================================
const dataStore = useAggregationDataStore()

const props = defineProps<Props>()

// グラフデータ
const chartData = ref<ChartData>({
  data: {
    labels: [],
    datasets: []
  },
  options: {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        text: '通過台数'
      }
    }
  }
})

const chartContent = ref<SummaryReportElement>({
  current: [],
  currentTotal: 0,
  prev: [],
  prevTotal: 0
})

const isShowChart = ref<boolean>(false)

const graphData = computed(() => {
  return chartData.value.data
})

// ==================================
// watch
// ==================================
watch(() => chartContent.value, () => {
  // チャートデータを取得したらグラフの更新を開始する
  updateChartData()
})

// ==================================
// hook
// ==================================
onMounted(() => {
  // 時間別の通過台数データの1件目を取得する
  chartContent.value = dataStore.$state.aggregationData.daily.trafficReportList[0]
})

// ==================================
// method
// ==================================
/**
 * グラフデータの更新
 */
const updateChartData = () => {
  isShowChart.value = false
  chartData.value.data.labels = props.label
  chartData.value.data.datasets = [
    {
      title: '当日台数',
      label: '当日台数: ' + chartContent.value.currentTotal + '台',
      borderColor: '#c00',
      fill: false,
      data: chartContent.value.current,
      tension: 0
    },
    {
      title: '前日台数',
      label: '前日台数: ' + chartContent.value.prevTotal + '台',
      borderColor: '#393',
      fill: false,
      data: chartContent.value.prev,
      tension: 0
    }
  ]
  // グラフを更新したらグラフを表示する
  isShowChart.value = true
}
</script>
<template>
  <Line
    v-if="isShowChart"
    :data="graphData"
    :options="chartData.options"
  />  
</template>
