<script lang="ts" setup>
/**
 * MacroAnalysisInfoDataDownload.vue
 * マクロ分析データダウンロード
 */
// ==================================
// import
// ==================================
import { onMounted, ref } from 'vue'

import ErrorDialog from '@/components/parts/common/ErrorDialog.vue'
import Loading from '@/components/parts/common/Loading.vue'
import TitleHeader from '@/components/parts/common/TitleHeader.vue'

import { useSelectPoleStore, useMacroAnalysisExcelDataStore } from '@/store/app'

import { DIALOG_ERROR_INFO, S3BUCKETURL } from '@/mixins/commonFunction'
import { updateMacroAnalysisInfoDataForExcel } from '@/mixins/communicationFunction'

import { ErrorDialogInfo, TitleInfo } from '@/types/Interfaces'

import { MENU } from '@/setting/setting'

// ==================================
// interface
// ==================================
interface TableHeader {
  title: string;
  value: string;
  align?: 'start' | 'center' | 'end';
  sortable?: boolean;
  filterable?: boolean;
  groupable?: boolean;
  divider?: boolean;
  class?: string | string[];
  width?: string | number;
  filter?: (value: any, search: string, item: any) => boolean;
  sort?: (a: any, b: any) => number;
}

interface TableData {
  createDate: string;
  areaName: string;
  searchCondition: string;
  creationStatus: string;
  downloadDataPath: string;
}

// ==================================
// data
// ==================================
const selectPoleStore = useSelectPoleStore()
const macroAnalysisExcelDataStore = useMacroAnalysisExcelDataStore()

const titleInfo = ref<TitleInfo>({
  title: 'マクロ分析ダウンロード',
  pointList: [],
  menuList: MENU.macroAnalysisInfoDataDownload,
  show: {
    realtime: true,
    multicamera: true,
    virtual: true,
    menu: true,
    point: true
  }
})

const poleId = ref<number>(0)

const isLoading = ref<boolean>(false)

const search = ref('')

const dialog = ref<ErrorDialogInfo>({
  message: '',
  title: '',
  isShow: false
})

const headers = ref<TableHeader[]>([
  { title: 'データ作成日時', align: 'start', sortable: true, value: 'createDate' },
  { title: 'エリア名', sortable: true, value: 'areaName' },
  { title: '地域', sortable: true, value: 'cityName' },
  { title: '条件', sortable: true, value: 'searchCondition' },
  { title: '作成ステータス', sortable: true, value: 'creationStatus' },
  { title: 'エクスポート', sortable: false, value: 'downloadDataPath' }
])

const datas = ref<TableData[]>([])

// ==================================
// hook
// ==================================
onMounted(() => {
  initialize()
})

// ==================================
// method
// ==================================
/**
 * 画面描画後の処理
 */
const initialize = () => {
  poleId.value = selectPoleStore.$state.poleId
  setData()
}

/**
 * APIからExcel出力済みのデータ一覧を取得
 */
const setData = () => {
  isLoading.value = true
  const promise = updateMacroAnalysisInfoDataForExcel(poleId.value)
  promise
    .then(() => {
      datas.value = macroAnalysisExcelDataStore.$state.data
      isLoading.value = false
    })
    .catch(() => {
      // 取得に失敗したらエラーメッセージを表示
      isLoading.value = false
      dialog.value.title = DIALOG_ERROR_INFO.title.getError
      dialog.value.message = DIALOG_ERROR_INFO.message.getErrorMacroAnalysisInfoDataForExcel
      dialog.value.isShow = true
    })
}

/**
 * Excelデータのダウンロード
 * @param item - Excel出力済みデータ情報
 */
const exportData = (item: TableData): void  => {
  const url = S3BUCKETURL.spitsDownloadBucketUrl + item.downloadDataPath
  // データに含まれるURLからExcelデータをダウンロードできるよう、アンカー要素を作って
  // 自動クリックを行ってダウンロードを実行する
  const anchorElement = document.createElement('a')
  document.body.appendChild(anchorElement)
  anchorElement.href = url
  anchorElement.click()
  anchorElement.remove()
}
</script>
<template>
  <TitleHeader :title-info="titleInfo" />
  <div class="macro-analysis-info-data-download">
    <v-text-field
      v-model="search"
      append-icon="mdi-magnify"
      label="検索"
      single-line
      hide-details
      class="search-field"
    />
    <v-data-table
      :headers="headers"
      :items="datas"
      :items-per-page="5"
      :footer-props="{ 'items-per-page-text': '行/ページ:' }"
      :search="search"
      class="elevation-1"
    >
      <template #[`item.downloadDataPath`]="{ item }">
        <v-icon
          v-if="item.downloadDataPath !== null"
          large
          class="mr-2"
          color="green"
          @click="exportData(item)"
        >
          mdi-file-excel
        </v-icon>
        <v-icon
          v-else
          large
          class="mr-2"
        >
          mdi-file-excel
        </v-icon>
      </template>
    </v-data-table>
  </div>
  <ErrorDialog
    :error-dialog="dialog"
    @on-click-close-error-dialog="dialog.isShow = false"
  />
  <Loading v-show="isLoading" />    
</template>
<style lang="scss" scoped>
  .macro-analysis-info-data-download {
    width: 97%;
    margin-left: 45px;
    margin-top: 45px;
    position: absolute;
  }
  .v-data-table {
    white-space: pre-line;
  }
</style>
