<script lang="ts" setup>
/**
 * Collections.vue
 * 集計画面
 */
// ==================================
// import
// ==================================
import { onBeforeMount, ref } from 'vue'

import PassingObjectCollection from '@/components/parts/collections/PassingObjectsCollection.vue'
import StopObjectsCollection from '@/components/parts/collections/StopObjectsCollection.vue'
import ErrorDialog from '@/components/parts/common/ErrorDialog.vue'
import Loading from '@/components/parts/common/Loading.vue'
import TitleHeader from '@/components/parts/common/TitleHeader.vue'

import { ErrorDialogInfo, TitleInfo } from '@/types/Interfaces'

import { MENU } from '@/setting/setting'

// ==================================
// data
// ==================================
// タイトル情報
const titleInfo = ref<TitleInfo>({
  title: '集計画面',
  show: {
    logout: true,
    point: false,
    menu: true
  },
  pointList: [],
  menuList: []
})

// ダイアログ表示内容
const dialogMessage = ref<ErrorDialogInfo>({
  isShow: false,
  title: '',
  message: ''
})

// 集計画面メニュータブ
const collectionTabMenu = ref([
  { tab: '通過物標件数', disabled: false },
  { tab: '一時停止件数', disabled: false },
  { tab: 'ヒヤリハット件数', disabled: true }
])

const selectedTabName = ref('')

// ローディングアイコン表示フラグ
const isLoading = ref<boolean>(false)

// ==================================
// hook
// ==================================
onBeforeMount(() => {
  // 右サイドメニューを取得する
  titleInfo.value.menuList = MENU.collection
})

// ==================================
// method
// ==================================
/**
 * ローディングアイコン表示を切り替える
 * @param flag - 表示フラグ
 */
const setLoading = (flag: boolean) => {
  isLoading.value = flag
}

/**
 * タイトル、メッセージを設定してダイアログを表示する
 * @param title - タイトル
 * @param message - メッセージ
 */
const displayDialog = (title: string, message: string) => {
  dialogMessage.value.title = title
  dialogMessage.value.message = message
  dialogMessage.value.isShow = true
}

/**
 * エラーダイアログを閉じる
 */
const onClickCloseErrorDialog = () => {
  dialogMessage.value.isShow = false
}
</script>
<template>
  <TitleHeader
    :title-info="titleInfo"
  />
  <div class="collections">
    <v-container class="collections__container">
      <v-row>
        <v-col>
          <v-tabs
            v-model="selectedTabName"
            bg-color="#eef"
            color="#06c"
          >
            <v-tab
              v-for="item in collectionTabMenu"
              :key="item.tab"
              :disabled="item.disabled"
              class="collections__tab-text py-0"
              base-color="#036"
            >
              {{ item.tab }}
            </v-tab>
          </v-tabs>
          <v-tabs-window v-model="selectedTabName">
            <v-tabs-window-item>
              <PassingObjectCollection
                @set-loading="setLoading"
                @set-error="displayDialog"
              />
            </v-tabs-window-item>
            <v-tabs-window-item>
              <StopObjectsCollection
                @set-loading="setLoading"
                @set-error="displayDialog"
              />
            </v-tabs-window-item>
            <v-tabs-window-item>ヒヤリハット件数一覧 ダミー</v-tabs-window-item>
          </v-tabs-window>
        </v-col>
      </v-row>
    </v-container>
  </div>
  <ErrorDialog
    :error-dialog="dialogMessage"
    @on-click-close-error-dialog="onClickCloseErrorDialog"
  />
  <Loading v-show="isLoading" />
</template>
<style lang="scss" scoped>
  .collections {
    position: absolute;
    top: 48px;
    height: calc(100vh - 48px);
    width: calc(100vw - 20px);
    &__container {
      max-width: 100vw;
    }
    &__tab-text {
      font-size: 16px;
      font-weight: bold;
    }
  }
</style>
