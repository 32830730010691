<script lang="ts" setup>
/**
 * AggreMenu.vue
 * 集計メニュー
 * 
 * 親コンポーネント
 * @/components/parts/aggregation/AggreMain.vue
 */
// ==================================
// import
// ==================================
import { onBeforeMount, watch, ref } from 'vue'

import DirectionList from '@/components/parts/aggregation/DirectionList.vue'
import ObjectList from '@/components/parts/aggregation/ObjectList.vue'
import Button from '@/components/parts/common/Button.vue'

import { useAggregationFiltersStore } from '@/store/app'

import { DIRECTIONS } from '@/setting/setting'

import { DateTime } from 'luxon'

// ==================================
// interface
// ==================================
interface Props {
  targetDate: DateTime;
  selectedCategory: number;
}

interface Emits {
  (e: 'update-aggregation', flag: boolean): void;
  (e: 'update-target-date', type: number, diff: number): void;
}

interface Direction {
  name: string;
  deg: number;
}
interface State {
  dispSpanSwitch: number;
  isFilterOpen?: number;
  dateString: string;
  day: number;
  weekday: number;
  dayStringList: string[];
  isToday: boolean;
  directionList: Direction[];
}

// ==================================
// data
// ==================================
const filterStore = useAggregationFiltersStore()

const props = defineProps<Props>()

const state = ref<State>({
  dispSpanSwitch: 0,
  isFilterOpen: undefined,
  dateString: '',
  day: 0,
  weekday: 0,
  dayStringList: ['日', '月', '火', '水', '木', '金', '土'],
  isToday: true,
  directionList: [],
})

// ==================================
// watch
// ==================================
watch(() => props.targetDate, (val: DateTime) => {
  // 現在時刻を設定
  let latest = DateTime.now()
  // 表示モードに合わせて、最新の日付の場合は「次」のボタンクリックを無効にする
  switch (state.value.dispSpanSwitch) {
    case 1:
      // 対象の日付が最新の場合、「次の日」のボタンクリックを無効にする
      state.value.isToday = latest.diff(val, 'days').days < 1
      break
    case 2:
      // 対象の日付の週が最新以上の場合、「次の週」のボタンクリックを無効にする
      state.value.isToday = latest.year <= val.year && latest.weekNumber <= val.weekNumber
      break
  }
  // 日付表示用に文字列として処理する
  state.value.dateString = val.toFormat('yyyy/MM/dd')
  state.value.day = val.day
  state.value.weekday = val.weekday - 1
})

watch(() => props.selectedCategory, (val: number) => {
    state.value.isFilterOpen = val !== 0 ? 0: void 0
})

// ==================================
// hook
// ==================================
onBeforeMount(() => {
  initialize()
})

// ==================================
// method
// ==================================
const emit = defineEmits<Emits>()

/**
 * 初期処理
 */
const initialize = () => {
  // 親コンポーネントから送られた日付データを画面表示用に文字列処理する
  state.value.dateString = props.targetDate.toFormat('yyyy/MM/dd')
  state.value.day = props.targetDate.day
  state.value.weekday = props.targetDate.weekday - 1
  state.value.directionList = DIRECTIONS
}

/**
 * 日の更新
 * @param diff - 差分
 */
const updateDate = (diff: number) => {
  emit('update-target-date', 1, diff)
}

/**
 * 週の更新
 * @param diff - 差分
 */
const updateWeek = (diff: number) => {
  emit('update-target-date', 2, diff)
}

/**
 * 集計期間の変更に伴う処理
 * @param val - 集計期間コード
 */
const setDispSpanSwitch = (val: number) => {
  state.value.dispSpanSwitch = val
  emit('update-aggregation', false)
  filterStore.setMode(props.selectedCategory, val)
}
</script>
<template>
  <div class="aggre-menu">
    <v-expansion-panels
      v-model="state.isFilterOpen"
      theme="dark"
      :disabled="state.isFilterOpen !== void 0 && props.selectedCategory === 0"
    >
      <v-expansion-panel
        color="#0041c0"
        title="絞り込み項目"
      >
        <v-expansion-panel-text>
          <v-container>
            <v-row v-if="selectedCategory === 1">
              <v-col>
                <v-card
                  color="#eee"
                  density="compact"
                >
                  <v-toolbar
                    theme="dark"
                    density="compact"
                    color="#c00"
                    title="優先"
                  />
                  <v-card-text>
                    <v-row dense>
                      <v-col>
                        <v-card
                          height="240px"
                          theme="light"
                          density="compact"
                        >
                          <v-toolbar
                            color="primary"
                            density="compact"
                            title="物標"
                          />
                          <v-card-text class="aggre-menu__filter-list">
                            <ObjectList :mode="1" />
                          </v-card-text>
                        </v-card>
                      </v-col>
                      <v-col>
                        <v-card
                          height="240px"
                          theme="light"
                          density="compact"
                        >
                          <v-toolbar
                            color="primary"
                            density="compact"
                            title="IN方路"
                          />
                          <v-card-text class="aggre-menu__filter-list">
                            <DirectionList
                              :mode="1"
                              :dir="1"
                            />
                          </v-card-text>
                        </v-card>
                      </v-col>
                      <v-col>
                        <v-card
                          height="240px"
                          theme="light"
                          density="compact"
                        >
                          <v-toolbar
                            color="primary"
                            density="compact"
                            title="OUT方路"
                          />
                          <v-card-text class="aggre-menu__filter-list">
                            <DirectionList
                              :mode="1"
                              :dir="2"
                            />
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col>
                <v-card
                  color="#eee"
                  density="compact"
                >
                  <v-toolbar
                    theme="dark"
                    density="compact"
                    color="#090"
                    title="非優先"
                  />                  
                  <v-card-text>
                    <v-row>
                      <v-col>
                        <v-card
                          height="240px"
                          theme="light"
                          density="compact"
                        >
                          <v-toolbar
                            color="primary"
                            density="compact"
                            title="物標"
                          />
                          <v-card-text class="aggre-menu__filter-list">
                            <ObjectList :mode="2" />
                          </v-card-text>
                        </v-card>
                      </v-col>
                      <v-col>
                        <v-card
                          height="240px"
                          theme="light"
                          density="compact"
                        >
                          <v-toolbar
                            color="primary"
                            density="compact"
                            title="IN方路"
                          />
                          <v-card-text class="aggre-menu__filter-list">
                            <DirectionList
                              :mode="2"
                              :dir="1"
                            />
                          </v-card-text>
                        </v-card>
                      </v-col>
                      <v-col>
                        <v-card
                          height="240px"
                          theme="light"
                          density="compact"
                        >
                          <v-toolbar
                            color="primary"
                            density="compact"
                            title="OUT方路"
                          />
                          <v-card-text class="aggre-menu__filter-list">
                            <DirectionList
                              :mode="2"
                              :dir="2"
                            />
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row v-else-if="selectedCategory > 1">
              <v-col>
                <v-row>
                  <v-col>
                    <v-card
                      height="240px"
                      theme="light"
                      density="compact"
                    >
                      <v-toolbar
                        color="primary"
                        density="compact"
                        title="物標"
                      />
                      <v-card-text class="aggre-menu__filter-list">
                        <ObjectList :mode="0" />
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col>
                    <v-card
                      height="240px"
                      theme="light"
                      density="compact"
                    >
                      <v-toolbar
                        color="primary"
                        density="compact"
                        title="IN方路"
                      />
                      <v-card-text class="aggre-menu__filter-list">
                        <DirectionList
                          :mode="0"
                          :dir="1"
                        />
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col>
                    <v-card
                      height="240px"
                      theme="light"
                      density="compact"
                    >
                      <v-toolbar
                        color="primary"
                        density="compact"
                        title="OUT方路"
                      />
                      <v-card-text class="aggre-menu__filter-list">
                        <DirectionList
                          :mode="0"
                          :dir="2"
                        />
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-container>
      <v-row>
        <v-col>
          <Button
            label="時間別"
            :disabled="state.dispSpanSwitch === 1"
            @click="setDispSpanSwitch(1)"
          />
        </v-col>
        <v-col>
          <Button
            label="日別"
            :disabled="state.dispSpanSwitch === 2"
            @click="setDispSpanSwitch(2)"
          />
        </v-col>
        <v-col>
          <Button
            label="月別"
            :disabled="state.dispSpanSwitch === 3"
            @click="setDispSpanSwitch(3)"
          />
        </v-col>
      </v-row>
      <v-row class="aggre-menu__switch-button">
        <!-- 前後期間切り替えボタン -->
        <v-col>
          <v-btn
            v-if="state.dispSpanSwitch === 1"
            block
            light
            @click="updateDate(-1)"
          >
            ＜ 前の日
          </v-btn>
          <v-btn
            v-if="state.dispSpanSwitch === 2"
            block
            light
            @click="updateWeek(-1)"
          >
            ＜ 前の週
          </v-btn>
        </v-col>
        <v-spacer />
        <v-col style="font-size: 1.6em;">
          {{ state.dateString + " (" + state.dayStringList[state.weekday] + ")" }}
        </v-col>
        <v-spacer />
        <v-col>
          <v-btn
            v-if="state.dispSpanSwitch === 1"
            block
            light
            :disabled="state.isToday"
            @click="updateDate(1)"
          >
            次の日 ＞
          </v-btn>
          <v-btn
            v-if="state.dispSpanSwitch === 2"
            block
            light
            :disabled="state.isToday"
            @click="updateWeek(1)"
          >
            次の週 ＞
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<style lang="scss" scoped>
  .aggre-menu {
    background-color: #eee;
    &__filter-list {
      position: relative;
      padding: 0;
      height: 200px;
      overflow-y: auto;
    }
    &__switch-button {
      color: #000;
    }
  }
  .v-application--is-ltr .v-input--selection-controls__input {
    margin-right: 0px !important;
  }
  .v-input__slot {
    margin-bottom: 0px !important;
  }
  .v-messages {
    min-height: 0px !important;
  }
  .v-expansion-panel-title {
    background-color: #0041c0;
    padding: 8px;
    min-height: auto;
  }
  .v-expansion-panel-title--active {
    min-height: auto !important;
  }
  .v-expansion-panel-text {
    background-color: #eee;
    color: #333;
  }
  .v-expansion-panel-text__wrap {
    padding: 0;
  }
</style>
