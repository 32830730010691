<script lang="ts" setup>
/**
 * RealTime.vue
 * リアルタイムモニター
 */
// ==================================
// import
// ==================================
import { ref, onMounted, onBeforeUnmount } from 'vue'

import ErrorDialog from '@/components/parts/common/ErrorDialog.vue'
import Loading from '@/components/parts/common/Loading.vue'
import TitleHeader from '@/components/parts/common/TitleHeader.vue'
import CameraFootage from '@/components/parts/realTime/CameraFootage.vue'
import InformationInTheIntersection from '@/components/parts/realTime/InformationInTheIntersection.vue'
import LedDisplayDataInformation from '@/components/parts/realTime/LedDisplayDataInformation.vue'
import VirtualVideo from '@/components/parts/realTime/VirtualVideo.vue'

import { useSelectPoleStore, usePoleInfoStore } from '@/store/app'

import { DIALOG_ERROR_INFO } from '@/mixins/commonFunction'
import {
  getPoleList,
  // updatePoleListAsPoleId,
  updatePosListReal,
} from '@/mixins/communicationFunction'
import { timerFunction } from '@/mixins/timerFunction'

import { ErrorDialogInfo, GrafanaPoleList, SensorIdData, SensorInfo, TitleInfo } from '@/types/Interfaces'

import { MENU, POSUP_DATE_INTERVAL_REAL, POLE_ON_GRAFANA } from '@/setting/setting'

import * as log from 'loglevel'

// ==================================
// data
// ==================================
const selectPoleStore = useSelectPoleStore()
const poleInfoStore = usePoleInfoStore()

const cameraFootageComponent = ref()
const virtualVideoComponent = ref()
const informationInTheIntersectionComponent = ref()
const ledDisplayDataInformationComponent = ref()

// タイトル
const titleinfo = ref<TitleInfo>({
  title: selectPoleStore.name + ' リアルタイムモニター',
  pointList: [],
  menuList: MENU.realtime,
  show: {
    grafana: false,
    multicamera: true,
    virtual: true,
    menu: true,
  },
  poleId: undefined,
  poleList: undefined,
})

// エラーダイアログ情報
const errorDialog = ref<ErrorDialogInfo>({
  message: '',
  title: '',
  isShow: false,
})

// 画面項目制御
const isLoading = ref<boolean>(true)

// 選択済みセンサー情報
const selectSensorList = ref<SensorIdData[]>([])

// ==================================
// hook
// ==================================
onMounted(async () => {
  // ポール設定
  /*
  console.log(selectPoleStore.latlng)
  console.log(selectPoleStore.poleId)
  console.log(typeof selectPoleStore.poleId)
  */
  addPoleMarker(selectPoleStore.latlng.lat, selectPoleStore.latlng.lng)
  // ポールIDに紐づくセンサー情報を取得
  // await updatePoleListAsPoleId(selectPoleStore.poleId)
  initShowGrafana()
  await getPoleList(null, selectPoleStore.poleId, null, true)
    .then(() => {
      initCameraList()
      initSensorList()
      initLedList()
      timerFunction.methods.start(
        selectPoleStore.poleId,
        POSUP_DATE_INTERVAL_REAL,
        timeoutPosListRealTimer
      )
      isLoading.value = false
    })
    .catch((err) => {
      log.error(err)
      errorDialog.value.title = DIALOG_ERROR_INFO.title.getError
      errorDialog.value.message = DIALOG_ERROR_INFO.message.getErrorSensorInfo
      errorDialog.value.isShow = true
      isLoading.value = false
    })
})

onBeforeUnmount(() => {
  timerFunction.methods.stop()
})

// ==================================
// method
// ==================================
/**
 * ポール情報設定
 * @param lat - 緯度
 * @param lng - 経度
 */
const addPoleMarker = (lat: number, lng: number) => {
  virtualVideoComponent.value.addPoleMarker(lat, lng)
}

/**
 * Grafanaボタン表示初期化処理
 */
const initShowGrafana = () => {
  titleinfo.value.poleId = selectPoleStore.poleId
  titleinfo.value.poleList = POLE_ON_GRAFANA.poleInfo
  titleinfo.value.poleList.forEach((pole: GrafanaPoleList) => {
    if (titleinfo.value.poleId == pole.poleId) {
      // 対象ポールの場合、GRAFANAボタンを表示する
      titleinfo.value.show.grafana = true
    }
  })
}

/**
 * カメラリスト初期化
 */
const initCameraList = () => {
  cameraFootageComponent.value.initCameraList()
}

/**
 * センサーリスト初期化
 */
const initSensorList = () => {
  virtualVideoComponent.value.initSensorList()
}

/**
 * LED情報取得
 */
const initLedList = () => {
  ledDisplayDataInformationComponent.value.initLedList()
}

/**
 * 物標定期更新処理
 * @param poleId - ポールID
 */
const timeoutPosListRealTimer = async (poleId: number) => {
  let sensorIds = poleInfoStore.sensorList.map((obj: SensorInfo) => obj.sensorId)
  updatePosListReal(poleId, sensorIds)
    .then(() => {
      updatePosObject()
      intersectionUpdateList()
      letUpdateList()
    })
    .catch((err: Promise<void>) => {
      log.error(err)
    })
}

/**
 * 物標情報更新
 */
const updatePosObject = () => {
  virtualVideoComponent.value.updatePosObject()
}

/**
 * 交差点情報一覧更新
 */
const intersectionUpdateList = () => {
  informationInTheIntersectionComponent.value.updateList()
}

/**
 * LED情報一覧更新
 */
const letUpdateList = () => {
  ledDisplayDataInformationComponent.value.updateList()
}

/**
 * エラーダイアログを閉じる
 */
const onClickCloseErrorDialog = () => {
  errorDialog.value.isShow = false
}

/**
 * 選択済みのセンサーを更新
 * @param value - 選択済みセンサー情報
 */
const updateSelectSensorList = (value: SensorIdData[]) => {
  selectSensorList.value = value
}

/**
 * 交差点情報一覧の物標選択時マップ中央に表示
 * @param latlng - 緯度経度
 */
const setCenter = (latlng: number[]) => {
  virtualVideoComponent.value.setCenter(latlng)
}
</script>
<template>
  <TitleHeader :title-info="titleinfo" />
  <v-container
    fluid
    fill-height
  >
    <v-row dense>
      <v-col cols="6">
        <CameraFootage ref="cameraFootageComponent" />
      </v-col>
      <v-col cols="6">
        <VirtualVideo
          ref="virtualVideoComponent"
          @select-sensor-list-hand-over="updateSelectSensorList"
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="6">
        <InformationInTheIntersection
          ref="informationInTheIntersectionComponent"
          :select-sensor-list="selectSensorList"
          @select-object="setCenter"
        />
      </v-col>
      <v-col cols="6">
        <LedDisplayDataInformation
          ref="ledDisplayDataInformationComponent"
          :select-sensor-list="selectSensorList"
        />
      </v-col>
    </v-row>
    <Loading v-show="isLoading" />
    <ErrorDialog
      :error-dialog="errorDialog"
      @on-click-close-error-dialog="onClickCloseErrorDialog"
    />
  </v-container>
</template>
