<script lang="ts" setup>
/**
 * LedInfoTable.vue
 * LED表示板情報一覧コンポーネント
 * 
 * 親コンポーネント
 * @/components/parts/realTime/LedDisplayDataInformation.vue
 * @/components/parts/virtual/LedDisplayDataInformation.vue
 */
// ==================================
// import
// ==================================
import { computed, watch, ref } from 'vue'

import { useLedListStore } from '@/store/app'

import { getSensorSettingList } from '@/mixins/commonFunction'
import {
  DIRECTION_ICON,
  LED_DISP_KIND,
  getLedObjectMarker,
} from '@/mixins/mapFunction'

import { LedListStore, SensorIdData } from '@/types/Interfaces'

import { LED_INFOTABLE_TYPE, LED_DISPLAY_LEVEL } from '@/setting/setting'

import imgObjectQuestion from '@/assets/img/object/question.svg'

// ==================================
// interface
// ==================================
interface Props {
  itemsPerPage: number;
  selectSensorList: SensorIdData[];
  mode: string;
}

interface ListHeader {
  title: string;
  key: string;
  sortable: boolean;
}
interface TargetLedSensorList {
  id: string;
  updatetime: number;
  isScondMinuteUpdate: boolean;
  isShow: boolean;
  resTime: number | string;
  dispKind: string;
  mark1: string;
  mark1Object: typeof imgObjectQuestion,
  mark1direction: string;
  mark1directionStyle: string | typeof imgObjectQuestion | {
    width: string;
    height: string;
    transform: string;
  }
  mark2: string;
  mark2Object: typeof imgObjectQuestion,
  mark2direction: string;
  mark2directionStyle: string | typeof imgObjectQuestion | {
    width: string;
    height: string;
    transform: string;
  }
  dispLevel: string;
}

// ==================================
// data
// ==================================
// LED表示板情報リスト
const ledListStore = useLedListStore()

const props = defineProps<Props>()

const listHeaders = ref<ListHeader[]>(LED_INFOTABLE_TYPE.listHeader)

const targetLedSensorList = ref<TargetLedSensorList[]>([])

const targetLedSensorDisplayList = ref<TargetLedSensorList[]>([])

// ==================================
// watch
// ==================================
watch(
  () => props.selectSensorList,
  () => {
    updateList()
  }
)

// ==================================
// computed
// ==================================
// 一覧表示数
const itemsPerPage = computed(() => {
  return props.itemsPerPage
})
// テーブル表示項目
const shownHeaders = computed(() => {
  return listHeaders.value
})

// テーブルデータ
const itemList = computed(() => {
  return targetLedSensorDisplayList.value
})

// ==================================
// method
// ==================================
/**
 * LED情報取得
 */
const initLedList = () => {
  targetLedSensorList.value = []
  const sensorSettingList = getSensorSettingList(false)
  for (const sensorList of sensorSettingList) {
    if (sensorList.label == '表示板') {
      for (const ledSensor of sensorList.nodes) {
        let sensorInfo = JSON.parse(ledSensor.id)
        let info = {
          id: sensorInfo.name,
          updatetime: new Date().getTime(),
          isScondMinuteUpdate: true,
          isShow: false,
          resTime: '',
          dispKind: '',
          mark1: '',
          mark1Object: '',
          mark1direction: '',
          mark1directionStyle: '',
          mark2: '',
          mark2Object: '',
          mark2direction: '',
          mark2directionStyle: '',
          dispLevel: '',
        }
        targetLedSensorList.value.push(info)
      }
    }
  }
}

/**
 * LED情報一覧更新
 */
const updateList = () => {
  // 最新データを設定
  ledListStore.ledList.forEach((sensorLed: LedListStore) => {
    targetLedSensorList.value.forEach((led: TargetLedSensorList) => {
      const leftPadding = '000000'
      let hexSensorId = leftPadding + sensorLed.sensorId.toString(16)
      hexSensorId = hexSensorId.slice(-6)
      if (led.id == hexSensorId) {
        led.updatetime = new Date().getTime()
        led.resTime = new Date(sensorLed.ledList[0].resTime).getTime()
        led.dispKind = sensorLed.ledList[0].dispKind
        led.mark1 = sensorLed.ledList[0].dispInfo1
        led.mark1Object = getLedObjectMarker(
          sensorLed.ledList[0].dispKind,
          sensorLed.ledList[0].dispInfo1
        )
        led.mark1direction = sensorLed.ledList[0].direction1
        led.mark1directionStyle = getLedDirectionStyle(
          sensorLed.ledList[0].direction1
        )
        led.mark2 = sensorLed.ledList[0].dispInfo2
        led.mark2Object = getLedObjectMarker(
          sensorLed.ledList[0].dispKind,
          sensorLed.ledList[0].dispInfo2
        )
        led.mark2direction = sensorLed.ledList[0].direction2
        led.mark2directionStyle = getLedDirectionStyle(
          sensorLed.ledList[0].direction2
        )
        led.dispLevel = getDisplayLevel(
          sensorLed.ledList[0].dispKind,
          sensorLed.ledList[0].dispLevel
        )
      }
    })
  })
  // 2分間更新が行われていないかチェック
  targetLedSensorList.value.forEach((led: TargetLedSensorList) => {
    if (led.resTime == '') {
      led.isScondMinuteUpdate = false
    } else {
      // リアルタイム画面の場合のみフロント側で測定
      if (props.mode == 'realTime' && typeof led.resTime === 'number' ) {
        led.isScondMinuteUpdate = updatetimeCheck(led.resTime, led.updatetime)
      }
    }
  })
  // 選択した選択したセンサーを表示対象に変更
  targetLedSensorDisplayList.value = []
  props.selectSensorList.forEach((sensor) => {
    targetLedSensorList.value.forEach((led) => {
      if (led.id == sensor.name) {
        targetLedSensorDisplayList.value.push(led)
      }
    })
  })
targetLedSensorDisplayList.value.forEach((led) => {
    led.isShow = checkDisplay(led)
  })
}

/**
 * LED方向取得
 * @param direction - 回転角度
 * @returns 方向指定スタイルor？アイコン
 */
const getLedDirectionStyle = (direction: string) => {
  // -01：不明 -10：なし
  if (direction == '-10') {
    return ''
  } else if (direction == '-01') {
    return imgObjectQuestion
  }
  return {
    width: '20px',
    height: '20px',
    transform: 'rotate(' + direction + 'deg)',
  }
}

/**
 * 表示レベル取得
 * @param dispKind - 表示種別
 * @param dispLevel - 表示レベルコード
 * @returns 表示レベル
 */
const getDisplayLevel = (dispKind: string, dispLevel: string) => {
  let level = ''
  // ブランクなら取得しない 000
  if (dispKind == LED_DISP_KIND.blank) {
    return level
  }
  let targetList = null
  if (dispKind == LED_DISP_KIND.safeDrivingSupportDispKind) {
    targetList = LED_DISPLAY_LEVEL.safeDrivingSupport
  } else if (dispKind == LED_DISP_KIND.disasterDispKind) {
    targetList = LED_DISPLAY_LEVEL.disaster
  }
  if (targetList != null) {
    for (const item of targetList) {
      if (dispLevel == item.kind) {
        level = item.displayName
        break
      }
    }
  }
  return level
}

/**
 * 更新チェック
 * @param resTime - 
 * @param updatetime - 更新日時
 * @returns 更新有無
 */
const updatetimeCheck = (resTime: number, updatetime: number) => {
  let d1 = new Date(resTime)
  let diffTime = d1.getTime() - updatetime
  let diffSecond = Math.floor(diffTime / 1000)
  let isUpdated = false
  if (diffSecond <= 120) {
    isUpdated = true
  }
  return isUpdated
}

/**
 * 表示可否判定
 * @param led - LEDセンサーリスト
 * @returns 表示可否
 */
const checkDisplay = (led: TargetLedSensorList) => {
  return led.dispKind != '000' && led.isScondMinuteUpdate && led.resTime != ''
}

defineExpose({
  updateList,
  initLedList,
})
</script>
<template>
  <v-container class="led-info-table">
    <v-data-table
      density="compact"
      :icon="DIRECTION_ICON"
      :headers="shownHeaders"
      :items="itemList"
      :items-per-page="itemsPerPage"
      :footer-props="{ itemsPerPageOptions: [itemsPerPage] }"
    >
      <template #[`item.id`]="{ item }">
        {{ item.id }}
      </template>
      <template #[`item.dispLevel`]="{ item }">
        {{ item.isShow ? item.dispLevel : "" }}
      </template>
      <template #[`item.mark1Object`]="{ item }">
        <img
          v-if="item.mark1Object != '' && item.isShow"
          :src="item.mark1Object"
          class="led-info-table__item--black"
        >
      </template>
      <template #[`item.mark1directionStyle`]="{ item }">
        <img
          v-if="item.mark1direction == '-01' && item.isShow"
          :src="item.mark1directionStyle"
          class="led-info-table__item"
        >
        <img
          v-if="
            item.mark1direction != '-01' &&
              item.mark1direction != '-10' &&
              item.isShow
          "
          :src="DIRECTION_ICON"
          :style="item.mark1directionStyle"
        >
      </template>
      <template #[`item.mark2Object`]="{ item }">
        <img
          v-if="item.mark2Object != '' && item.isShow"
          :src="item.mark2Object"
          class="led-info-table__item--black"
        >
      </template>
      <template #[`item.mark2directionStyle`]="{ item }">
        <img
          v-if="item.mark2direction == '-01' && item.isShow"
          :src="item.mark2directionStyle"
          class="led-info-table__item"
        >
        <img
          v-if="
            item.mark2direction != '-01' &&
              item.mark2direction != '-10' &&
              item.isShow
          "
          :src="DIRECTION_ICON"
          :style="item.mark2directionStyle"
        >
      </template>
    </v-data-table>
  </v-container>
</template>
<style lang="scss" scoped>
  .led-info-table {
    &__item {
      width: 20px;
      height: 20px;
      &--black {
        width: 20px;
        height: 20px;
        filter: brightness(0);
      }
    }
  }
</style>
<style>
  .v-data-table__th {
    background-color: lightgray !important;
    font-size: 13.5px !important;
  }
  .v-data-table__td {
    font-size: 13.5px !important;
  }
  .v-data-table-footer {
    display: flex !important;
    align-items: center !important;
    flex-wrap: wrap !important;
    padding: 0 0px !important;
    justify-content: flex-end !important;
    font-size: 11px !important;
  }
  .v-data-table-footer .v-input__control {
    max-height: 35px !important;
  }
  .v-data-table-footer .v-field__input {
    padding-top: 0% !important;
    padding-bottom: 0% !important;
    min-height: auto !important;
  }
</style>
