<script lang="ts" setup>
/**
 * GroupUpdateDialog.vue
 * グループの更新ダイアログ
 * 
 * 親コンポーネント
 * @/views/GroupSetting.vue
 */
// ==================================
// import
// ==================================
import { onBeforeMount, computed, ref } from 'vue'

import ErrorDialog from '@/components/parts/common/ErrorDialog.vue'
import Loading from '@/components/parts/common/Loading.vue'

import { DIALOG_ERROR_INFO } from '@/mixins/commonFunction'
import { updateGroupInfo } from '@/mixins/communicationFunction'
import { toHexNumber } from '@/utils/math'

import { ErrorDialogInfo, GroupListStore } from '@/types/Interfaces'

// ==================================
// interface
// ==================================
interface Props {
  dialog: {
    isDialog: boolean;
    item: GroupListStore;
  }
}

interface Emits {
  (e: 'show-error-dialog', result: boolean, title: string, message: string): void;
  (e: 'dialogClose'): void;
}

// ==================================
// data
// ==================================
const props = defineProps<Props>()

// ローディングアイコン表示
const isLoading = ref<boolean>(false)

// 元々のグループ名
const originalGroupName = ref<string>('')

// グループ情報
const groupInfo = ref<GroupListStore>({
  groupid: 0,
  groupname: ''
})

const errDialog = ref<ErrorDialogInfo>({
  isShow: false,
  title: '',
  message: ''
})

const rules = ref({
  /**
   * グループ名のバリデーションチェック
   * @param value - フォーム入力値
   * @returns エラー文字列、成功判定
   */
  checkInValidGroupName: (value: string | null): string | boolean => {
    if (!value) {
      // 値が空ではないこと
      return '値を入力してください'
    } else if (value == originalGroupName.value) {
      // 古い値ではないこと
      return '新しいグループ名を入力してください'
    } else if (value.length > 50) {
      // グループ名文字数が1~50の範囲内であること
      return 'グループ名が長すぎます'
    }
    return true
  }
})

// ==================================
// computed
// ==================================
// ダイアログの表示判定用
const isDialog = computed(() => {
  return props.dialog.isDialog
})

// ==================================
// hook
// ==================================
onBeforeMount(() => {
  initialize()
})

// ==================================
// method
// ==================================
const emit = defineEmits<Emits>()

/**
 * 初期処理
 */
const initialize = () => {
  // 親コンポーネントから送られたデータを設定する
  groupInfo.value.groupid = props.dialog.item.groupid
  groupInfo.value.groupname = props.dialog.item.groupname
  originalGroupName.value = props.dialog.item.groupname
}

/**
 * 変更ボタン活性/非活性の判定
 */
const isValidation = () => {
  // 入力されたグループ名がNULLではなく、50文字以内で、元々のグループ名と重複しないかで判定
  return (groupInfo.value.groupname !== null && groupInfo.value.groupname.length <= 50 && originalGroupName.value !== groupInfo.value.groupname)
}

/**
 * グループ情報更新処理
 */
const onClickUpdate = async() => {
  isLoading.value = true
  // グループ更新APIを呼び出す
  let promise = updateGroupInfo(groupInfo.value.groupid, groupInfo.value.groupname)
  promise
    .then(() => {
      isLoading.value = false
      emit('show-error-dialog', true, 'グループ更新成功','グループを更新しました。\nグループID: ' + toHexNumber(groupInfo.value.groupid) + '、グループ名: ' + groupInfo.value.groupname)
    })
    .catch(() => {
      isLoading.value = false
      // エラーメッセージを設定する
      let title = DIALOG_ERROR_INFO.title.updateError
      let message = DIALOG_ERROR_INFO.message.updateErrorGroupInfo
      emit('show-error-dialog', false, title, message)
    })
}

/**
 * ダイアログ閉じる処理
 */
const dialogClose = () => {
  emit('dialogClose')
}
</script>
<template>
  <v-dialog
    v-model="isDialog"
    class="group-update-dialog"
    width="500px"
    persistent
  >
    <v-card>
      <v-container>
        <v-card-title
          class="group-update-dialog__title"
          nav
          density="compact"
        >
          グループ編集
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-form>
            <v-row>
              <v-col cols="3">
                <span>グループID</span>
              </v-col>
              <v-col cols="9">
                <span>{{ toHexNumber(groupInfo.groupid) }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">
                <span>グループ名</span>
              </v-col>
              <v-col cols="9">
                <v-text-field
                  v-model="groupInfo.groupname"
                  :rules="[rules.checkInValidGroupName]"
                  clearable
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            :disabled="!isValidation()"
            color="primary darken-2"
            class="group-update-dialog__update-btn"
            @click="onClickUpdate"
          >
            変更
          </v-btn>
          <v-btn
            color="grey darken-2"
            dark
            @click="dialogClose"
          >
            キャンセル
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
    <ErrorDialog :error-dialog="errDialog" />
    <Loading v-show="isLoading" />
  </v-dialog>
</template>
<style lang="scss" scoped>
  .group-update-dialog {
    &__title {
      height: 5vh;
      font-size: 18px;
      font-weight: bold;
    }
    &__update-btn {
      width: 19%;
    }
  }
  .v-text-field {
    padding: 0px;
    margin: 0px;
  }
  .v-input :deep(.v-messages) {
    margin-top: 10px;
  }
</style>
