<script lang="ts" setup>
/**
 * PoleGroupUpdateDialog.vue
 * ポールグループ情報の更新ダイアログ
 * 
 * 親コンポーネント
 * @/views/GroupSetting.vue
 */
// ==================================
// import
// ==================================
import { onBeforeMount, computed, ref } from 'vue'

import ErrorDialog from '@/components/parts/common/ErrorDialog.vue'
import Loading from '@/components/parts/common/Loading.vue'

import { usePoleGroupListStore } from '@/store/app'

import { DIALOG_ERROR_INFO } from '@/mixins/commonFunction'
import { updatePoleGroupInfo } from '@/mixins/communicationFunction'
import { toHexNumber } from '@/utils/math'

import { ErrorDialogInfo, PoleGroupData } from '@/types/Interfaces'

// ==================================
// interface
// ==================================
interface Props {
  dialog: {
    isDialog: boolean;
    item: PoleGroupData;
  }
}

interface Emits {
  (e: 'show-error-dialog', result: boolean, title: string, message: string): void;
  (e: 'dialogClose'): void;
}

// ==================================
// data
// ==================================
const poleGroupListStore = usePoleGroupListStore()

const props = defineProps<Props>()

// ローディングアイコン表示判定
const isLoading = ref<boolean>(false)

// ポールグループ一覧
const groups = ref<PoleGroupData[]>([])

// 選択したグループID
const selected = ref<unknown | number>()

// 元々のグループID
const originalSelected = ref<number>()

// 選択されたポール情報
const poleInfo = ref({
  poleid: 0,
  polename: ''
})

// エラーダイアログ情報
const errDialog = ref<ErrorDialogInfo>({
  isShow: false,
  title: '',
  message: ''
})

// バリデーションチェック
const rules = ref({
  /**
   * ポールグループ名のチェック
   * @param {Number} value フォームで選択したグループID
   * @returns エラー文字列、成功判定
   */
  checkInValidGroupName: (value: number | null): string | boolean => {
    if (value === void 0 || value === null) {
      // 値が空ではないこと
      return '値を選択してください'
    } else if (value === originalSelected.value) {
      // 古い値ではないこと
      return '新しいグループ名を選択してください'
    }
    return true
  }
})

// ==================================
// computed
// ==================================
// ダイアログの表示判定用
const isDialog = computed(() => {
  return props.dialog.isDialog
})

// ==================================
// hook
// ==================================
onBeforeMount(() => {
  initialize()
})

// ==================================
// method
// ==================================
const emit = defineEmits<Emits>()

/**
 * 初期処理
 */
const initialize = () => {
  // Piniaストアからポールグループ一覧を取得して初期値を設定する
  groups.value = poleGroupListStore.$state.poleGroupList
  selected.value = props.dialog.item.groupid
  originalSelected.value = props.dialog.item.groupid
  poleInfo.value.poleid = props.dialog.item.poleid
  poleInfo.value.polename = props.dialog.item.polename
}

/**
 * 変更ボタン活性/非活性の判定
 */
const isValidation = (): boolean => {
  return ((selected.value !== void 0 || selected.value !== null) && originalSelected.value !== selected.value)
}

/**
 * ポールのグループ情報更新処理
 */
const onClickUpdate = () => {
  // 更新先のグループ名を取得する
  let groupName = ''
  const targetGroup = groups.value.find(group => group.groupid === selected.value)
  if (targetGroup !== void 0) {
    groupName = targetGroup.groupname
  }
  isLoading.value = true
  // APIにアクセス
  if (typeof selected.value === 'number') {
    let promise = updatePoleGroupInfo(poleInfo.value.poleid, selected.value)
    // 成否に関わらず、ダイアログは閉じる
    promise
      .then(() => {
        isLoading.value = false
        emit('show-error-dialog', true, 'ポールグループ更新成功','ポールグループを更新しました。\nポールグループID: ' + toHexNumber(poleInfo.value.poleid) + '、ポールグループ名: ' + groupName)
      })
      .catch(() => {
        isLoading.value = false
        // エラーメッセージを設定する
        let title = DIALOG_ERROR_INFO.title.updateError
        let message = DIALOG_ERROR_INFO.message.updateErrorPoleGroupInfo
        emit('show-error-dialog', false, title, message)
      })
  }
}

/**
 * ダイアログ閉じる処理
 */
const dialogClose = () => {
  emit('dialogClose')
}
</script>
<template>
  <v-dialog
    v-model="isDialog"
    class="pole-group-update-dialog"
    width="500px"
    persistent
  >
    <v-card>
      <v-container>
        <v-card-title
          class="pole-group-update-dialog__title"
          nav
          density="compact"
        >
          ポールのグループ編集
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-form>
            <v-row>
              <v-col cols="3">
                <span>ポールID</span>
              </v-col>
              <v-col cols="9">
                <span>{{ toHexNumber(poleInfo.poleid) }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">
                <span>ポール名</span>
              </v-col>
              <v-col cols="9">
                <span>{{ poleInfo.polename }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">
                <span>グループ名</span>
              </v-col>
              <v-col cols="9">
                <v-autocomplete
                  v-model="selected"
                  :rules="[rules.checkInValidGroupName]"
                  :items="groups"
                  item-title="groupname"
                  item-value="groupid"
                  clearable
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            :disabled="!isValidation()"
            color="primary darken-2"
            class="pole-group-update-dialog__update-btn"
            @click="onClickUpdate"
          >
            変更
          </v-btn>
          <v-btn
            color="grey darken-2"
            dark
            @click="dialogClose"
          >
            キャンセル
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
    <ErrorDialog :error-dialog="errDialog" />
    <Loading v-show="isLoading" />
  </v-dialog>
</template>
<style lang="scss" scoped>
  .pole-group-update-dialog {
    &__title {
      height: 5vh;
      font-size: 18px;
      font-weight: bold;
    }
    &__update-btn {
      width: 19%;
    }
  }
  .v-text-field {
    padding: 0px;
    margin: 0px;
  }
  .v-input :deep(.v-messages) {
    margin-top: 10px;
  }
</style>
