/**
 * math.ts
 * 数式関連メソッド
 */

/**
 * 対象の数値を16進表記にする
 * @param val - 数値
 * @returns 16進表記文字列
 */
export const toHexNumber = (val:number): string => {
  return '0x' + val.toString(16).padStart(8, '0')
}