<script lang="ts" setup>
/**
 * MacroAnalysisDetailInfoTable.vue
 * マクロ分析詳細画面
 * 
 * 親コンポーネント
 * @/components/parts/macroAnalysis/MacroAnalysisTable.vue
 */
// ==================================
// import
// ==================================
import { onBeforeMount, ref } from 'vue'

import MacroAnalysisHistgram from '@/components/parts/macroAnalysis/MacroAnalysisHistgram.vue'
import ModalMacroAnalysisHistgram from '@/components/parts/macroAnalysis/ModalMacroAnalysisHistgram.vue'

import { useMacroAnalysisInfoStore } from '@/store/app'

// ==================================
// interface
// ==================================
interface Emits {
  (e: 'close-section-detail'): void;
}

// ==================================
// data
// ==================================
const macroAnalysisInfoStore = useMacroAnalysisInfoStore()

const showDialog = ref<boolean>(true)

const isDisplay = ref<boolean>(false)

const prefix = ref({
  component: null,
  props: {}
})

// 分析詳細データ
const data = ref({
  kind: undefined,
  columns: [],
  termA: {
    number: [],
    avgSpeed: [],
    maxSpeed: [],
    mode: [],
    std: [],
    avgHist: [],
    maxHist: []
  },
  termB: {
    number: [],
    avgSpeed: [],
    maxSpeed: [],
    mode: [],
    std: [],
    avgHist: [],
    maxHist: []
  },
  diff: {
    number: [],
    avgSpeed: [],
    tTest: [],
    effectSize: [],
    avgHist: []
  }
})

const isViewGraph = ref<boolean>(false)

// グラフ表示オプション
const avgSpeedGraphSetting = ref({
  xAxes: {
    scaleLabelDisplay: true,
    labelString: '平均速度'
  },
  yAxes: {
    scaleLabelDisplay: true,
    labelString: '台数'
  },
  termA: {
    backgroundColor: 'rgba(65, 158, 143, 0.8)'
  },
  termB: {
    backgroundColor: 'rgba(160, 158, 34, 0.8)'
  }
})
const avgSpeedHistGraphSetting = ref({
  xAxes: {
    scaleLabelDisplay: true,
    labelString: '平均速度'
  },
  yAxes: {
    scaleLabelDisplay: true,
    labelString: '差分'
  },
  title: {
    display: true,
    text: '期間Aから期間Bを引いた差分値'
  },
  backgroundColor: 'rgba(236, 97, 4, 0.8)'
})
const maxSpeedGraphSetting = ref({
  xAxes: {
    scaleLabelDisplay: true,
    labelString: '最高速度'
  },
  yAxes: {
    scaleLabelDisplay: true,
    labelString: '台数'
  },
  termA: {
    backgroundColor: 'rgba(65, 158, 143, 0.8)'
  },
  termB: {
    backgroundColor: 'rgba(160, 158, 34, 0.8)'
  }
})
const sectionMaxBorder = ref({
  termA: {
    avg: {
      suggestedMax: 1
    },
    max: {
      suggestedMax: 1
    }
  },
  termB: {
    avg: {
      suggestedMax: 1
    },
    max: {
      suggestedMax: 1
    }
  },
  diff: {
    avg: {
      suggestedMax: 1,
      suggestedMin: 0,
      maxTicksLimit: 2
    }
  }
})

// ==================================
// hook
// ==================================
onBeforeMount(() => {
  initialize()
})

// ==================================
// method
// ==================================
const emit = defineEmits<Emits>()

/**
 * 初期処理
 */
const initialize = () => {
  // 表示データからグラフ上の最大値を設定する
  data.value = macroAnalysisInfoStore.$state.macroAnalysisDetaiInfo
  sectionMaxBorder.value.termA.avg.suggestedMax = getBorderMaxData(data.value.termA.avgHist)
  sectionMaxBorder.value.termA.max.suggestedMax = getBorderMaxData(data.value.termA.maxHist)
  sectionMaxBorder.value.termB.avg.suggestedMax = getBorderMaxData(data.value.termB.avgHist)
  sectionMaxBorder.value.termB.max.suggestedMax = getBorderMaxData(data.value.termB.maxHist)
  sectionMaxBorder.value.diff.avg = getDiffBorderData(data.value.diff.avgHist)
  isViewGraph.value = true
}

/**
 * グラフで表示する上での最大値を取得する
 * @param history - 速度一覧
 * @returns 最大値
 */
const getBorderMaxData = (history: any): number => {
  let mainArray: any[] = []
  for (const item of history) {
    let subArray: any[] = Object.values(item)
    mainArray = mainArray.concat(subArray)
  }
  let maxVal = Math.max(...mainArray)
  if (maxVal === 0) {
    maxVal = 1
  }
  return maxVal
}

/**
 * 差分データを表示する際の最大、最小値を取得する
 * @param history - 速度一覧
 * @returns 最大、最小値
 */
const getDiffBorderData = (history: any): any => {
  let mainArray: any[] = []
  for (const item of history) {
    let subArray: any[] = Object.values(item)
    mainArray = mainArray.concat(subArray)
  }
  let maxVal = Math.max(...mainArray)
  let minVal = Math.min(...mainArray)

  let suggestedMaxVal = 1
  let suggestedMinVal = 0
  let maxTicksLimit = 2

  if (maxVal >= 1 && minVal >= 0) {
    suggestedMaxVal = maxVal
    suggestedMinVal = 0
  } else if (maxVal <= 0 && minVal < 0) {
    suggestedMaxVal = 0
    suggestedMinVal = minVal
  } else if (maxVal === 0 && minVal === 0) {
    suggestedMaxVal = 1
    suggestedMinVal = 0
    maxTicksLimit = 2
  } else {
    suggestedMaxVal = maxVal
    suggestedMinVal = minVal
    maxTicksLimit = 3    
  }

  return {
    suggestedMax: suggestedMaxVal,
    suggestMin: suggestedMinVal,
    maxTicksLimit: maxTicksLimit
  }
}

/**
 * 親コンポーネントからの指示でモーダルダイアログを表示する
 * @param component - グラフコンポーネント
 * @param props - グラフ表示プロパティ
 */
const showModal = (component: any, props: any) => {
  prefix.value.component = component
  prefix.value.props = props
  isDisplay.value = true
}

/**
 * 分析詳細画面を閉じる
 */
const close = () => {
  emit('close-section-detail')
  showDialog.value = false
}
</script>
<template>
  <v-dialog
    v-model="showDialog"
    class="macro-analysis-detail-info-table"
    fullscreen
    transition="dialog-bottom-transition"
  >
    <div class="macro-analysis-detail-info-table__container">
      <v-card 
        density="compact"
        class="macro-analysis-detail-info-table__card"
      >
        <v-card-text>
          <v-table class="macro-analysis-detail-info-table__table">
            <thead>
              <tr>
                <th class="macro-analysis-detail-info-table__column1--title">
                  期間
                </th>
                <th class="macro-analysis-detail-info-table__column2--title" />
                <th
                  v-for="(header, index) in data.columns"
                  :key="index"
                  class="macro-analysis-detail-info-table__column1"
                >
                  {{ header }}
                </th>
              </tr>
            </thead>
            <tbody>
              <!-- 期間A -->
              <tr>
                <td
                  rowspan="7"
                  class="macro-analysis-detail-info-table__column1--period-a"
                >
                  期間A
                </td>
                <th class="macro-analysis-detail-info-table__column2--period-a">
                  平均速度グラフ
                </th>
                <td
                  v-for="(avgSpeedHistA, index) in data.termA.avgHist"
                  :key="index"
                  class="macro-analysis-detail-info-table__period-a"
                >
                  <v-card class="macro-analysis-detail-info-table__graph">
                    <MacroAnalysisHistgram
                      v-if="isViewGraph"
                      :x-axes="avgSpeedGraphSetting.xAxes"
                      :y-axes="avgSpeedGraphSetting.yAxes"
                      :background-color="avgSpeedGraphSetting.termA.backgroundColor"
                      :data="Object.values(avgSpeedHistA)"
                      :max="sectionMaxBorder.termA.avg.suggestedMax"
                      :is-suggested="true"
                      @show-modal="showModal"
                    />
                  </v-card>
                </td>
              </tr>
              <tr>
                <th class="macro-analysis-detail-info-table__column2--period-a">
                  最高速度グラフ
                </th>
                <td
                  v-for="(maxSpeedHistA, index) in data.termA.maxHist"
                  :key="index"
                  class="macro-analysis-detail-info-table__period-a"
                >
                  <v-card class="macro-analysis-detail-info-table__graph">
                    <MacroAnalysisHistgram
                      v-if="isViewGraph"
                      :x-axes="maxSpeedGraphSetting.xAxes"
                      :y-axes="maxSpeedGraphSetting.yAxes"
                      :background-color="maxSpeedGraphSetting.termA.backgroundColor"
                      :data="Object.values(maxSpeedHistA)"
                      :is-suggested="true"
                      :max="sectionMaxBorder.termA.max.suggestedMax"
                      @show-modal="showModal"
                    />
                  </v-card>
                </td>
              </tr>
              <tr>
                <th class="macro-analysis-detail-info-table__column2--period-a">
                  台数
                </th>
                <td
                  v-for="(termANumber, index) in data.termA.number"
                  :key="index"
                  class="macro-analysis-detail-info-table__period-a"
                >
                  {{ termANumber }}
                </td>
              </tr>
              <tr>
                <th class="macro-analysis-detail-info-table__column2--period-a">
                  平均速度
                </th>
                <td
                  v-for="(termAAvgSpeed, index) in data.termA.avgSpeed"
                  :key="index"
                  class="macro-analysis-detail-info-table__period-a"
                >
                  {{ termAAvgSpeed }}
                </td>
              </tr>
              <tr>
                <th class="macro-analysis-detail-info-table__column2--period-a">
                  最高速度
                </th>
                <td
                  v-for="(termAMaxSpeed, index) in data.termA.maxSpeed"
                  :key="index"
                  class="macro-analysis-detail-info-table__period-a"
                >
                  {{ termAMaxSpeed }}
                </td>
              </tr>
              <tr>
                <th class="macro-analysis-detail-info-table__column2--period-a">
                  最頻値
                </th>
                <td
                  v-for="(termAMode, index) in data.termA.mode"
                  :key="index"
                  class="macro-analysis-detail-info-table__period-a"
                >
                  {{ termAMode }}
                </td>
              </tr>
              <tr>
                <th class="macro-analysis-detail-info-table__column2--period-a">
                  標準偏差
                </th>
                <td
                  v-for="(termAStd, index) in data.termA.std"
                  :key="index"
                  class="macro-analysis-detail-info-table__period-a"
                >
                  {{ termAStd }}
                </td>
              </tr>

              <!-- 期間B -->
              <tr>
                <th
                  rowspan="7"
                  class="macro-analysis-detail-info-table__column1--period-b"
                >
                  期間B
                </th>
                <th class="macro-analysis-detail-info-table__column2--period-b">
                  平均速度グラフ
                </th>
                <td
                  v-for="(avgSpeedHistB, index) in data.termB.avgHist"
                  :key="index"
                  class="macro-analysis-detail-info-table__period-b"
                >
                  <v-card class="macro-analysis-detail-info-table__graph">
                    <MacroAnalysisHistgram
                      v-if="isViewGraph"
                      :x-axes="avgSpeedGraphSetting.xAxes"
                      :y-axes="avgSpeedGraphSetting.yAxes"
                      :background-color="avgSpeedGraphSetting.termB.backgroundColor"
                      :data="Object.values(avgSpeedHistB)"
                      :is-suggested="true"
                      :max="sectionMaxBorder.termB.avg.suggestedMax"
                      @show-modal="showModal"
                    />
                  </v-card>
                </td>
              </tr>
              <tr>
                <th class="macro-analysis-detail-info-table__column2--period-b">
                  最高速度グラフ
                </th>
                <td
                  v-for="(maxSpeedHistB, index) in data.termB.maxHist"
                  :key="index"
                  class="macro-analysis-detail-info-table__period-b"
                >
                  <v-card class="macro-analysis-detail-info-table__graph">
                    <MacroAnalysisHistgram
                      v-if="isViewGraph"
                      :x-axes="maxSpeedGraphSetting.xAxes"
                      :y-axes="maxSpeedGraphSetting.yAxes"
                      :background-color="maxSpeedGraphSetting.termB.backgroundColor"
                      :data="Object.values(maxSpeedHistB)"
                      :is-suggested="true"
                      :max="sectionMaxBorder.termB.max.suggestedMax"
                      @show-modal="showModal"
                    />
                  </v-card>
                </td>
              </tr>
              <tr>
                <th class="macro-analysis-detail-info-table__column2--period-b">
                  台数
                </th>
                <td
                  v-for="(termBNumber, index) in data.termB.number"
                  :key="index"
                  class="macro-analysis-detail-info-table__period-b"
                >
                  {{ termBNumber }}
                </td>
              </tr>
              <tr>
                <th class="macro-analysis-detail-info-table__column2--period-b">
                  平均速度
                </th>
                <td
                  v-for="(termBAvgSpeed, index) in data.termB.avgSpeed"
                  :key="index"
                  class="macro-analysis-detail-info-table__period-b"
                >
                  {{ termBAvgSpeed }}
                </td>
              </tr>
              <tr>
                <th class="macro-analysis-detail-info-table__column2--period-b">
                  最高速度
                </th>
                <td
                  v-for="(termBMaxSpeed, index) in data.termB.maxSpeed"
                  :key="index"
                  class="macro-analysis-detail-info-table__period-b"
                >
                  {{ termBMaxSpeed }}
                </td>
              </tr>
              <tr>
                <th class="macro-analysis-detail-info-table__column2--period-b">
                  最頻値
                </th>
                <td
                  v-for="(termBMode, index) in data.termB.mode"
                  :key="index"
                  class="macro-analysis-detail-info-table__period-b"
                >
                  {{ termBMode }}
                </td>
              </tr>
              <tr>
                <th class="macro-analysis-detail-info-table__column2--period-b">
                  標準偏差
                </th>
                <td
                  v-for="(termBStd, index) in data.termB.std"
                  :key="index"
                  class="macro-analysis-detail-info-table__period-b"
                >
                  {{ termBStd }}
                </td>
              </tr>

              <!-- 差分 -->
              <tr>
                <th
                  rowspan="5"
                  class="macro-analysis-detail-info-table__column1--diff"
                >
                  差分
                </th>
                <th class="macro-analysis-detail-info-table__column2--diff">
                  平均速度グラフ
                </th>
                <td
                  v-for="(diffAvgSpeedHist, index) in data.diff.avgHist"
                  :key="index"
                  class="macro-analysis-detail-info-table__diff"
                >
                  <v-card class="macro-analysis-detail-info-table__graph">
                    <MacroAnalysisHistgram
                      v-if="isViewGraph"
                      :x-axes="avgSpeedHistGraphSetting.xAxes"
                      :y-axes="avgSpeedHistGraphSetting.yAxes"
                      :background-color="avgSpeedHistGraphSetting.backgroundColor"
                      :data="Object.values(diffAvgSpeedHist)"
                      :is-suggested="true"
                      :max="sectionMaxBorder.diff.avg.suggestedMax"
                      :min="sectionMaxBorder.diff.avg.suggestedMin"
                      :max-ticks-limit="sectionMaxBorder.diff.avg.maxTicksLimit"
                      @show-modal="showModal"
                    />
                  </v-card>
                </td>
              </tr>
              <tr>
                <th class="macro-analysis-detail-info-table__column2--diff">
                  台数
                </th>
                <td
                  v-for="(diffNumber, index) in data.diff.number"
                  :key="index"
                  class="macro-analysis-detail-info-table__diff"
                >
                  {{ diffNumber }}
                </td>
              </tr>
              <tr>
                <th class="macro-analysis-detail-info-table__column2--diff">
                  平均速度
                </th>
                <td
                  v-for="(diffAvgSpeed, index) in data.diff.avgSpeed"
                  :key="index"
                  class="macro-analysis-detail-info-table__diff"
                >
                  {{ diffAvgSpeed }}
                </td>
              </tr>
              <tr>
                <th class="macro-analysis-detail-info-table__column2--diff">
                  W-t検定
                </th>
                <td
                  v-for="(diffTTest, index) in data.diff.tTest"
                  :key="index"
                  class="macro-analysis-detail-info-table__diff"
                >
                  {{ diffTTest }}
                </td>
              </tr>
              <tr>
                <th class="macro-analysis-detail-info-table__column2--diff">
                  効果量
                </th>
                <td
                  v-for="(diffEffectSize, index) in data.diff.effectSize"
                  :key="index"
                  class="macro-analysis-detail-info-table__diff"
                >
                  {{ diffEffectSize }}
                </td>
              </tr>
            </tbody>
          </v-table>
        </v-card-text>
        <v-card-actions>
          <div class="macro-analysis-detail-info-table__close-button-container">
            <v-btn
              color="blue"
              class="macro-analysis-detail-info-table__close-button"
              block
              @click="close"
            >
              閉じる
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
      <ModalMacroAnalysisHistgram
        :value="isDisplay"
        text="errorText"
        :prefix="prefix"
        @input="isDisplay = $event"
      />
    </div>
  </v-dialog>
</template>
<style lang="scss" scoped>
  .macro-analysis-detail-info-table {
    &__container {
      position: relative;
      top: 5vh;
      text-align: center;
      width: 95vw;
      height: 50vh;
      left: 2.5%;
    }
    &__card {
      overflow: auto;
      width: 100%;
      height: 80vh;
    }
    &__graph {
      height: auto;
      width: 100%;
      cursor: pointer;
      outline: none;
    }
    &__table {
      width: 100%;
      height: 70vh;
    }
    &__close-button-container {
      left: 3%;
      width: 90%;
      top: 20px;
      position: relative;
      color: white;
    }
    &__close-button {
      color: white;
    }
    &__column1 {
      position: sticky;
      top: 0;
      height: auto;
      left: 0;
      z-index: 1;
      &--title {
        position: sticky;
        top: 0;
        height: auto;
        left: 0;
        z-index: 1;
        width: 0.5px;
      }
      &--period-a {
        position: sticky;
        top: 0;
        height: auto;
        left: 0;
        z-index: 1;
        background-color: rgba(65, 158, 143);
      }
      &--period-b {
        position: sticky;
        top: 0;
        height: auto;
        left: 0;
        z-index: 1;
        background-color: rgba(160, 158, 34);
      }
      &--diff {
        position: sticky;
        top: 0;
        height: auto;
        left: 0;
        z-index: 1;
        background-color: rgba(236, 97, 4);
      }
    }
    &__column2 {
      &--title {
        position: sticky;
        top: 0;
        height: auto;
        left: 39.5px;
        z-index: 2;
        width: 0.5px;
      }
      &--period-a {
        position: sticky;
        top: 0;
        height: auto;
        left: 39.5px;
        z-index: 2;
        background-color: rgba(65, 158, 143);
      }
      &--period-b {
        position: sticky;
        top: 0;
        height: auto;
        left: 39.5px;
        z-index: 2;
        background-color: rgba(160, 158, 34);
      }
      &--diff {
        position: sticky;
        top: 0;
        height: auto;
        left: 39.5px;
        z-index: 2;
        background-color: rgba(236, 97, 4);
      }
    }
    &__period-a {
      background-color: rgba(65, 158, 143, 0.4);
    }
    &__period-b {
      background-color: rgba(160, 158, 34, 0.3);
    }
    &__diff {
      background-color: rgba(236, 97, 4, 0.3);
    }
  }
  table {
    width: 200px;
    height: 100%;
    th {
      text-align: center;
      color: white;
      background: rgb(0, 132, 255);
      border-left: 1px solid #3c6690;
      border-top: 1px solid #3c6690;
      border-bottom: 1px solid #3c6690;
      width: auto;
      white-space: nowrap;
    }
    td {
      text-align: center;
      border-left: 0.5px solid #0d0d0e;
      border-bottom: 0.5px solid #0d0d0e;
      border-top: none;
      padding: 5px 0;
    }
  }
</style>
