<script lang="ts" setup>
/**
 * SelectAreaList.vue
 * 地点リストコンポーネント
 * 
 * 親コンポーネント
 * @/components/parts/common/TitleHeader.vue
 */
// ==================================
// import
// ==================================
import { computed } from 'vue'

// ==================================
// interface
// ==================================
interface Props {
  pointList: [any];
}

interface Emits {
  (e: 'on-select-point', value: any): void;
  (e: 'on-enter-point', value: any): void;
}

// ==================================
// data
// ==================================
const props = defineProps<Props>()

// ==================================
// computed
// ==================================
const pointList = computed(() => {
  return props.pointList
})

// ==================================
// method
// ==================================
const emit = defineEmits<Emits>()
/**
 * 地点リスト選択時画面中央に表示
 * @param value - 地点リスト
 */
const onSelectPoint = (value: any) => {
  emit('on-select-point', value)
}
/**
 * エリア情報設定
 * @param value - 地点リスト
 */
const onEnterPoint = (value: any) => {
  emit('on-enter-point', value)
}
</script>
<template>
  <v-list
    class="select-area-list"
    nav
    dense
  >
    <v-list-subheader class="select-area-list__subheader">
      地点リスト
    </v-list-subheader>
    <v-list-item
      v-for="(item, index) in pointList"
      :key="index"
      @click="onSelectPoint(item)"
    >
      <v-btn
        icon
        size=""
      >
        <v-icon @click="onEnterPoint(item)">
          mdi-map-marker
        </v-icon>
      </v-btn>
      <v-list-item-title class="select-area-list__title">
        {{
          item.name
        }}
      </v-list-item-title>
    </v-list-item>
  </v-list>
</template>
<style lang="scss" scoped>
  .select-area-list {
    &__subheader {
      font-size: 15px;
    }
    &__title {
      font-size: 15px;
    }
  }
</style>
