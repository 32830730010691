<script lang="ts" setup>
/**
 * GroupSetting.vue
 * グループ設定
 */
// ==================================
// import
// ==================================
import { onBeforeMount, ref } from 'vue'

import ErrorDialog from '@/components/parts/common/ErrorDialog.vue'
import Loading from '@/components/parts/common/Loading.vue'
import TitleHeader from '@/components/parts/common/TitleHeader.vue'
import GroupCreateDialog from '@/components/parts/groupSetting/GroupCreateDialog.vue'
import GroupDeleteDialog from '@/components/parts/groupSetting/GroupDeleteDialog.vue'
import GroupUpdateDialog from '@/components/parts/groupSetting/GroupUpdateDialog.vue'
import PoleGroupUpdateDialog from '@/components/parts/groupSetting/PoleGroupUpdateDialog.vue'

import { useGroupListStore, usePoleGroupListStore } from '@/store/app'

import { DIALOG_ERROR_INFO } from '@/mixins/commonFunction'
import { getGroupInfoList, getPoleGroupInfoList, apiResponseErrorCode } from '@/mixins/communicationFunction'
import { toHexNumber } from '@/utils/math'

import { ErrorDialogInfo, GroupListStore, PoleGroupData, TitleInfo } from '@/types/Interfaces'

import { MENU } from '@/setting/setting'

// ==================================
// data
// ==================================
const groupListStore = useGroupListStore()
const poleGroupListStore = usePoleGroupListStore()

// タイトル
const titleinfo = ref<TitleInfo>({
  title: 'グループ設定',
  pointList: [],
  menuList: MENU.watch,
  show: {
    multicamera: false,
    virtual: false,
    menu: true,
  },
})

// エラーダイアログ
const errDialog = ref<ErrorDialogInfo>({
  isShow: false,
  title: '',
  message: ''
})

// ローディングアイコン表示判定
const isLoading = ref<boolean>(false)

// 一覧表検索キーワード
const groupSearch = ref('')
const poleGroupSearch = ref('')

// 各ダイアログ定義内容
const isCreateGroupDialog = ref({
  isDialog: false
})
const isUpdateGroupDialog = ref({
  isDialog: false,
  item: {
    groupid: 0,
    groupname: ''
  }
})
const isDeleteGroupDialog = ref({
  isDialog: false,
  item: {
    groupid: 0,
    groupname: ''
  }
})
const isUpdatePoleGroupDialog = ref({
  isDialog: false,
  item: {
    poleid: 0,
    polename: '',
    groupid: 0,
    groupname: ''
  }
})

// 一覧表ヘッダ
const groupHeaders = ref([
  {
    title: 'グループID',
    key: 'groupid'
  },
  { title: 'グループ名', key: 'groupname' },
  {
    title: '編集',
    key: 'edit'
  },
  {
    title: '削除',
    key: 'delete'
  }
])
const poleHeaders = ref([
  {
    title: 'ポールID',
    key: 'poleid'
  },
  { title: 'ポール名', key: 'polename' },
  { title: 'グループ名', key: 'groupname' },
  {
    title: '編集',
    key: 'edit'
  }
])

// グループ一覧
const groupItems = ref<GroupListStore[]>([])

// ポールグループ一覧
const poleItems = ref<PoleGroupData[]>([])

// グループのCRUD成否判定
const CrudStatus = ref<boolean>(false)

// ==================================
// hook
// ==================================
onBeforeMount(() => {
  initialize()
})

// ==================================
// method
// ==================================
/**
 * 初期処理
 */
const initialize = () => {
  // 右サイドメニューを取得
  titleinfo.value.menuList = MENU.groupSetting
  // 一覧取得
  getList()
}

/**
 * グループ一覧、ポールのグループ一覧取得処理
 */
const getList = () => {
  // グループ一覧取得
  groupInfoCall()
  // ポールのグループ一覧取得
  poleGroupInfoCall()
}

/**
 * グループ一覧取得処理
 */
const groupInfoCall = () => {
  isLoading.value = true
  // 一覧取得APIを呼び出す
  let promise = getGroupInfoList()
  promise
    .then(() => {
      // Piniaストアに格納されたグループ一覧を設定する
      groupItems.value = groupListStore.$state.groupList
      isLoading.value = false
    })
    .catch(error => {
      isLoading.value = false
      // エラーメッセージを設定する
      let title = DIALOG_ERROR_INFO.title.getError
      let message = DIALOG_ERROR_INFO.message.getErrorGroupInfo
      if (error === apiResponseErrorCode.noContent) {
        message = DIALOG_ERROR_INFO.message.getErrorGroupNoContent
      }
      showErrorDialog(title, message)
    })
}

/**
 * ポールのグループ一覧取得処理
 */
const poleGroupInfoCall = () => {
  isLoading.value = true
  // 一覧取得APIを呼び出す
  const promise = getPoleGroupInfoList()
  promise
    .then(() => {
      // Piniaストアに格納されたポールグループ一覧を設定する
      poleItems.value = poleGroupListStore.$state.poleGroupList
      isLoading.value = false
    })
    .catch(error => {
      isLoading.value = false
      // エラーメッセージを設定する
      let title = DIALOG_ERROR_INFO.title.getError
      let message = DIALOG_ERROR_INFO.message.getErrorPoleGroupInfo
      if (error == apiResponseErrorCode.noContent) {
        message = DIALOG_ERROR_INFO.message.getErrorPoleGroupNoContent
      }
      showErrorDialog(title, message)
    })
}

/**
 * ダイアログ上にエラーメッセージを設定して表示
 * @param title - タイトル
 * @param message - メッセージ
 */
const showErrorDialog = (title: string, message: string) => {
  errDialog.value.title = title
  errDialog.value.message = message
  errDialog.value.isShow = true
}

/**
 * グループ作成処理
 */
const onClickCreateGroup = () => {
  isCreateGroupDialog.value.isDialog = true
}

/**
 * グループ更新処理
 * @param item - グループ情報
 */
const onClickUpdateGroup = (item: GroupListStore) => {
  isUpdateGroupDialog.value.isDialog = true
  isUpdateGroupDialog.value.item = item
}

/**
 * グループ削除処理
 * @param item - グループ情報
 */
const onClickDeleteGroup = (item: GroupListStore) => {
  isDeleteGroupDialog.value.isDialog = true
  isDeleteGroupDialog.value.item = item
}

/**
 * ポールのグループ更新処理
 * @param item - ポールのグループ情報
 */
const onClickUpdatePoleGroup = (item: PoleGroupData) => {
  isUpdatePoleGroupDialog.value.isDialog = true
  isUpdatePoleGroupDialog.value.item = item
}

/**
 * ダイアログ閉じる処理
 */
const dialogClose = () => {
  resetData()
}

/**
 * API呼び出し処理成功した後ダイアログ閉じて一覧を再取得処理
 */
const successClose = () => {
  resetData()
  // 一覧更新
  getList()
}

/**
 * 作成、更新、削除処理結果をダイアログで表示させる
 * @param result - 処理結果
 * @param title - ダイアログタイトル
 * @param message - ダイアログメッセージ
 */
const showUpdateResult = (result: boolean, title: string, message: string): void => {
  CrudStatus.value = result
  showErrorDialog(title, message)
}

/**
 * エラーダイアログを閉じたときの処理
 */
const closeErrorDialog = () => {
  errDialog.value.isShow = false
  // 処理結果に応じて一覧の表示処理の切り替えを行う
  if (CrudStatus.value) {
    successClose()
  } else {
    dialogClose()
  }
}

/**
 * リセット処理
 */
const resetData = () => {
  isCreateGroupDialog.value.isDialog = false
  isUpdateGroupDialog.value.isDialog = false
  isDeleteGroupDialog.value.isDialog = false
  isUpdatePoleGroupDialog.value.isDialog = false
}
</script>
<template>
  <TitleHeader :title-info="titleinfo" />
  <div class="group-setting">
    <div class="group-setting__data">
      <v-card elevation="3">
        <v-card-title class="group-setting__card py-0">
          <span>グループ一覧</span>
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="groupSearch"
            append-icon="mdi-magnify"
            label="検索"
            single-line
            hide-details
            clearable
          />
          <v-data-table
            :headers="groupHeaders"
            :items="groupItems"
            :search="groupSearch"
            :items-per-page="5"
          >
            <template #[`item.groupid`]="{ item }">
              {{ toHexNumber(item.groupid) }}
            </template>
            <template #[`item.edit`]="{ item }">
              <v-icon
                small
                class="mr-2"
                @click="onClickUpdateGroup(item)"
              >
                mdi-pencil
              </v-icon>
            </template>
            <template #[`item.delete`]="{ item }">
              <v-icon
                small
                @click="onClickDeleteGroup(item)"
              >
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
      <v-btn
        color="primary darken-2"
        class="group-setting__create-btn"
        @click="onClickCreateGroup"
      >
        グループ作成
      </v-btn>
      <v-card
        class="group-setting__pole-card"
        elevation="3"
      >
        <v-card-title class="group-setting__card py-0">
          <span>ポールのグループ一覧</span>
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="poleGroupSearch"
            append-icon="mdi-magnify"
            label="検索"
            single-line
            hide-details
            clearable
          />
          <v-data-table
            :headers="poleHeaders"
            :items="poleItems"
            :search="poleGroupSearch"
            :items-per-page="5"
          >
            <template #[`item.poleid`]="{ item }">
              {{ toHexNumber(item.poleid) }}
            </template>
            <template #[`item.edit`]="{ item }">
              <v-icon
                small
                class="mr-2"
                @click="onClickUpdatePoleGroup(item)"
              >
                mdi-pencil
              </v-icon>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </div>
    <v-main>
      <RouterView />
    </v-main>
    <ErrorDialog
      :error-dialog="errDialog"
      @on-click-close-error-dialog="closeErrorDialog"
    />
    <Loading v-show="isLoading" />
    
    <GroupCreateDialog
      v-if="isCreateGroupDialog"
      :dialog="isCreateGroupDialog"
      @show-error-dialog="showUpdateResult"
      @dialog-close="dialogClose"
    />
    <GroupUpdateDialog
      v-if="isUpdateGroupDialog.isDialog"
      :dialog="isUpdateGroupDialog"
      @show-error-dialog="showUpdateResult"
      @dialog-close="dialogClose"
    />
    <GroupDeleteDialog
      v-if="isDeleteGroupDialog.isDialog"
      :dialog="isDeleteGroupDialog"
      @show-error-dialog="showUpdateResult"
      @dialog-close="dialogClose"
    />
    <PoleGroupUpdateDialog
      v-if="isUpdatePoleGroupDialog.isDialog"
      :dialog="isUpdatePoleGroupDialog"
      @show-error-dialog="showUpdateResult"
      @dialog-close="dialogClose"
    />
  </div>
</template>
<style lang="scss" scoped>
  .group-setting {
    &__data {
      width: 80%;
      margin: auto;
      padding-top: 30px;
      position: absolute;
      top: 5%;
      left: 10%;
    }
    &__card {
      font-size: 22px;
      font-weight: bold;
      color: white;
      background-color: #0041c0;
      height: 3vh;
    }
    &__create-btn {
      position: absolute;
      right: 10%;
      margin-top: 10px;
      width: 10%;
    }
    &__pole-card {
      margin-top: 10%;
    }
  }
</style>
