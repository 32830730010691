<script lang="ts" setup>
/**
 * MenuList.vue
 * 共通メニューコンポーネント
 * 
 * 親コンポーネント
 * @/components/parts/common/TitleHeader.vue
 */
// ==================================
// import
// ==================================
import { computed } from 'vue'

import { MenuInfo } from '@/types/Interfaces'

// ==================================
// interface
// ==================================
interface Props {
  menuList: MenuInfo[];
}

interface Emits {
  (e: 'on-select-menu', value: MenuInfo): void;
}

// ==================================
// data
// ==================================
const props = defineProps<Props>()

// ==================================
// computed
// ==================================
// メニューリスト表示
const menuList = computed(() => {
  return props.menuList
})

// ==================================
// method
// ==================================
const emit = defineEmits<Emits>()
/**
 * メニュータブから画面遷移
 * @param value - クリックしたメニュー情報
 */
const onClickMenu = (value: MenuInfo) => {
  emit('on-select-menu', value)
}
</script>
<template>
  <v-list
    nav
    dense
  >
    <v-list-subheader style="font-size: 15px">
      メニュー
    </v-list-subheader>
    <v-list-item
      v-for="(item, index) in menuList"
      :key="index"
      @click="onClickMenu(item)"
    >
      <v-list-item-title style="font-size: 15px">
        {{
          item.name
        }}
      </v-list-item-title>
    </v-list-item>
  </v-list>
</template>
