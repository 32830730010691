<script lang="ts" setup>
/**
 * Watch.vue
 * 見守り画面
 */
// ==================================
// import
// ==================================
import { onBeforeMount, onMounted, ref } from 'vue'
import 'leaflet/dist/leaflet.css'
import 'leaflet-arrowheads'

import Button from '@/components/parts/common/Button.vue'
import ErrorDialog from '@/components/parts/common/ErrorDialog.vue'
import Loading from '@/components/parts/common/Loading.vue'
import TitleHeader from '@/components/parts/common/TitleHeader.vue'

import { useSelectAreaStore, useSelectPoleStore } from '@/store/app'

import { updatePoleListAsAreaId, updateWatchingData } from '@/mixins/communicationFunction'
import { getTileLayer } from '@/mixins/mapFunction'
import { getInitFromDateTime, getDateTimeNow } from '@/mixins/timerFunction'
import {
    poleIcon,
    blueIcon,
    redIcon,
    greenIcon,
    yellowIcon,
    lightblueIcon,
    pinkIcon,
    lightgreenIcon,
    orangeIcon,
    blackIcon,
    purpleIcon,
    wineIcon,
    blueTIcon,
    redTIcon,
    greenTIcon,
    yellowTIcon,
    lightblueTIcon,
    pinkTIcon,
    lightgreenTIcon,
    orangeTIcon,
    blackTIcon,
    purpleTIcon,
    wineTIcon,
    blueSIcon,
    redSIcon,
    greenSIcon,
    yellowSIcon,
    lightblueSIcon,
    pinkSIcon,
    lightgreenSIcon,
    orangeSIcon,
    blackSIcon,
    purpleSIcon,
    wineSIcon
  } from '@/utils/mapIcon'

import { DistanceFromPoleToVehicle, DistanceFromPoleToVehicleByAreaId, ErrorDialogInfo, PoleInfo, TitleInfo } from '@/types/Interfaces'

import { MENU, VIRTUAL_MAP, SELECT_POLE } from '@/setting/setting'

import L from 'leaflet'

// ==================================
// data
// ==================================
const selectAreaStore = useSelectAreaStore()
const selectPoleStore = useSelectPoleStore()

// タイトル
const titleinfo = ref<TitleInfo>({
  title: selectAreaStore.name + ' 見守り画面',
  pointList: [],
  menuList: MENU.watch,
  show: {
    multicamera: false,
    virtual: false,
    menu: true,
  },
})

// エラーダイアログ情報
const errorDialog = ref<ErrorDialogInfo>({
  message: '',
  title: '',
  isShow: false,
})

const isLoading = ref<boolean>(false)

const areaId = ref(selectPoleStore.$state.areaId)

const actionHistoryTableHeader = ref([
  {
    value: 'detectedTime'
  },
  { 
    value: 'vehicleId'
  },
  { 
    value: 'status'
  }
])

const lineColors = ref([
  '#3d46cd',
  '#ed161f',
  '#1cb24b',
  '#f7f700',
  '#99d9ea',
  '#ffaec9',
  '#b6e717',
  '#ff7f27',
  '#000000',
  '#a447a5',
  '#880015',
  '#3d46cd',
  '#ed161f',
  '#1cb24b',
  '#f7f700',
  '#99d9ea',
  '#ffaec9',
  '#b6e717',
  '#ff7f27',
  '#000000',
  '#a447a5',
  '#880015',
  '#3d46cd',
  '#ed161f',
  '#1cb24b',
  '#f7f700',
  '#99d9ea',
  '#ffaec9',
  '#b6e717',
  '#ff7f27',
  '#000000',
  '#a447a5',
  '#880015'
])

const idIconColors = ref<any[]>([])

const iconList = ref([
  poleIcon,
  blueIcon,
  redIcon,
  greenIcon,
  yellowIcon,
  lightblueIcon,
  pinkIcon,
  lightgreenIcon,
  orangeIcon,
  blackIcon,
  purpleIcon,
  wineIcon,
  blueTIcon,
  redTIcon,
  greenTIcon,
  yellowTIcon,
  lightblueTIcon,
  pinkTIcon,
  lightgreenTIcon,
  orangeTIcon,
  blackTIcon,
  purpleTIcon,
  wineTIcon,
  blueSIcon,
  redSIcon,
  greenSIcon,
  yellowSIcon,
  lightblueSIcon,
  pinkSIcon,
  lightgreenSIcon,
  orangeSIcon,
  blackSIcon,
  purpleSIcon,
  wineSIcon    
])

const searchConditions = ref({
  fromDate: '',
  toDate: '',
  fromTime: '',
  toTime: '',
  targetvehicleId: [] as number[]
})

const editingData = ref({
  areaName: '',
  mapLatlng: selectPoleStore.$state.latlng,
  sensorList: [] as {
    poleId: number
    latlng: [number, number]
  }[],
  poleMarkers: [] as any[],
  markerLatlng: [0, 0],
  idList: [] as number[],
  actionHistories: [] as DistanceFromPoleToVehicleByAreaId,
  actionHistoryList: [] as {
    detectedTime: string
    vehicleId: number
    status: string
  }[],
  targetVehicleList: [] as DistanceFromPoleToVehicleByAreaId[],
  poleInfoList: [] as {
    vehicleId: string
    poleId: number
    detectedTime: string
    status: string
  }[]
})

const map = ref<any>({})

const filter = ref<boolean>(false)

// ==================================
// hook
// ==================================
onBeforeMount(() => {
  initialize()
})

onMounted(() => {
  setPoleList(areaId.value)
})

// ==================================
// method
// ==================================
/**
 * 初期処理
 */
const initialize = () => {
  const from = getInitFromDateTime()
  const to = getDateTimeNow()
  searchConditions.value = {
    fromDate: from[0],
    fromTime: from[1],
    toDate: to[0],
    toTime: to[1],
    targetvehicleId: []
  }
}

/**
 * ポール一覧情報を取得
 * @param areaId - エリアID
 */
const setPoleList = async(areaId: number) => {
  isLoading.value = true
  const promise = updatePoleListAsAreaId(areaId)
  promise
    .then((response: PoleInfo[]) => {
      for (let pole of response) {
        editingData.value.sensorList.push({
          poleId: pole.poleId,
          latlng: [pole.latitude, pole.longitude]
        })
      }
    })
    .then(() => {
      search()
    })
    .catch(() => {
      isLoading.value = false
      errorDialog.value.title = 'ポール一覧取得失敗'
      errorDialog.value.message = 'ポール一覧情報の取得に失敗しました。'
      errorDialog.value.isShow = true
    })
}

/**
 * 見守り情報検索
 */
const search = async() => {
  editingData.value.actionHistories = []
  editingData.value.actionHistoryList = []
  editingData.value.poleInfoList = []
  // フォーム上の指定日時を取得する
  const from = searchConditions.value.fromDate + ' ' + searchConditions.value.fromTime
  const to = searchConditions.value.toDate + ' ' + searchConditions.value.toTime
  isLoading.value = true
  // 見守り情報を取得する
  const promise = updateWatchingData(areaId.value, from.replaceAll('-', '/'), to.replaceAll('-', '/'))
  promise
    .then(val => {
      isLoading.value = false
      editingData.value.actionHistories = val
      // actionHistoriesのキーを抽出して整数配列としてidListに追加する
      const keys = Object.keys(editingData.value.actionHistories)
      for (let key of keys) {
        // キーが整数以外の場合はスキップする
        if (!isNaN(parseInt(key))) {
          editingData.value.idList.push(parseInt(key))
        }
      }
    })
    .then(() => {
      // 見守りデータが空の場合、初期値を登録する
      const data: DistanceFromPoleToVehicleByAreaId = editingData.value.actionHistories
      for (let i = 0; i < editingData.value.idList.length; i++) {
        const key = editingData.value.idList[i]
        for (let j = 0; j < data[key].length; j++) {
          if (data[key][j].poleId === null){
            data[key][j].poleId = 0
          }
          if (data[key][j].latitude === null) {
            data[key][j].latitude = 0
          }
          if (data[key][j].longitude === null) {
            data[key][j].longitude = 0
          }
          if (data[key][j].vehicleStat === null) {
            data[key][j].vehicleStat = 2
          }
          if (data[key][j].detectedTime === null) {
            data[key][j].detectedTime = 'データなし'
          }
        }
      }      
    })
    .then(() => {
      let i = 0
      // アイコンの色リストを取得する
      editingData.value.idList.forEach(id => {
        idIconColors.value[id] = iconList.value[i & 31]
        i++
      })
      editingData.value.poleMarkers = editingData.value.sensorList[0].latlng
    })
    .then(() => {
      showInfoByVehicleId((filter.value = true))
    })
    .catch(() => {
      isLoading.value = false
    })
}

/**
 * フィルタリングのフォーム情報をもとに地図表示を更新する
 * @param filter - フィルタリング判定
 */
const showInfoByVehicleId = (filter: boolean) => {
  // フィルタリングがない場合は処理を行わない
  if (!filter) return
  editingData.value.targetVehicleList = []
  const filterList: DistanceFromPoleToVehicleByAreaId = {}
  for (let key of searchConditions.value.targetvehicleId) {
    if (editingData.value.actionHistories[key]) {
      filterList[key] = editingData.value.actionHistories[key]
    }
  }
  editingData.value.targetVehicleList.push(filterList)
  setMonitorMap(editingData.value.targetVehicleList[0])
  setActionHistoryList(editingData.value.targetVehicleList[0])
}

/**
 * エリア地図の生成
 * @param data - 見守り情報
 */
const setMonitorMap = (data: DistanceFromPoleToVehicleByAreaId) => {
  let latlng = selectAreaStore.$state.latlng
  let zoom = SELECT_POLE.zoom.default
  let zoom_min = VIRTUAL_MAP.zoom.min
  let zoom_max = VIRTUAL_MAP.zoom.max
  if (Object.keys(map.value).length === 0) {
    const lMap = L.map('monitorMap', {
      dragging: true,
      touchZoom: true,
      scrollWheelZoom: true,
      doubleClickZoom: true,
      boxZoom: true,
      tap: true,
      keyboard: true,
      zoomControl: true,
      minZoom: zoom_min,
      maxZoom: zoom_max
    }).setView(latlng, zoom)
    map.value = lMap
  } else {
    map.value.remove()
    const lMap = L.map('monitorMap', {
      dragging: true,
      touchZoom: true,
      scrollWheelZoom: true,
      doubleClickZoom: true,
      boxZoom: true,
      tap: true,
      keyboard: true,
      zoomControl: true,
      minZoom: zoom_min,
      maxZoom: zoom_max
    }).setView(latlng, zoom)
    map.value = lMap
  }
  L.control.layers(getTileLayer(map.value, VIRTUAL_MAP.zoom.max, undefined)).addTo(map.value)

  let polePoint: any = ''
  let bindPopup: any = ''
  let list: any = ''

  // センサー情報をもとに地図上にマーカーを追加する
  if (editingData.value.sensorList.length !== 0) {
    for (let sensorList of editingData.value.sensorList) {
      editingData.value.poleMarkers = sensorList.latlng
      polePoint = L.marker(editingData.value.poleMarkers, { icon: poleIcon })
      if (editingData.value.poleInfoList.length !== 0) {
        bindPopup = document.createElement('ul')
          for (let poleInfo of editingData.value.poleInfoList) {          
          if (sensorList.poleId === poleInfo.poleId) {
            list = document.createElement('li')
            list.textContent = `${poleInfo.vehicleId} 
                      ${poleInfo.detectedTime} 
                        ${poleInfo.status}`
            bindPopup.appendChild(list)
          }
        }
        if (bindPopup.innerText !== '') {
          polePoint.bindPopup(bindPopup)
        } else {
          polePoint.bindPopup('接近情報はありません')
        }
      } else {
        polePoint.bindPopup('接近情報はありません')
      }
      polePoint.addTo(map.value)
    }
  }

  // 地図上に物標情報を追加する
  if (editingData.value.actionHistories) {
    let targetVehiclePoint: [number, number][] = []
    let i = 0
    for (let id of editingData.value.idList) {
      if (data[id]) {
        for (let val of data[id]) {
          targetVehiclePoint.push([val.latitude, val.longitude])
          L.marker([val.latitude, val.longitude], { icon: idIconColors.value[id] }).addTo(map.value)
        }
        L.polyline(targetVehiclePoint, {
          color: lineColors.value[i & 31],
          weight: 3,
          dashArray: '10, 10',
          dashOffset: 1
        })
        .arrowheads({ size: '20px', fill: true })
        .addTo(map.value)
      }
      targetVehiclePoint = []
      i++
    }
  }
}

/**
 * 物標の移動履歴を生成
 * @param actionHistoryList - 移動履歴一覧
 */
const setActionHistoryList = (actionHistoryList: DistanceFromPoleToVehicleByAreaId) => {
  editingData.value.actionHistoryList = []
  editingData.value.poleInfoList = []
  const targetStatus = [
    'から 離脱',
    '付近 接近',
    'へのデータなし'
  ]
  const status = [
    '離脱',
    '接近',
    'データなし'
  ]
  // 移動履歴のキー単位で、移動履歴とポール一覧を登録する
  Object.keys(actionHistoryList).forEach(key => {
    const targetKey = parseInt(key)
    actionHistoryList[targetKey].forEach((data: DistanceFromPoleToVehicle) => {
      // 移動履歴とポール情報を抽出する
      const tableData = {
        detectedTime: data.detectedTime,
        vehicleId: targetKey,
        status: `ポールID${data.poleId}${targetStatus[data.vehicleStat]}`
      }

      const poleInfo = {
        vehicleId: key,
        poleId: data.poleId,
        detectedTime: data.detectedTime,
        status: `${status[data.vehicleStat]}`
      }
      // 一覧に追加する
      editingData.value.actionHistoryList.push(tableData);
      editingData.value.poleInfoList.push(poleInfo);
    })
  })
  // 時系列ごとにソートする
  editingData.value.actionHistoryList.sort((a, b) => {
    if (a.detectedTime !== b.detectedTime) {
      return new Date(b.detectedTime).getTime() - new Date(a.detectedTime).getTime()
    }
    if (a.vehicleId !== b.vehicleId) {
      return (b.vehicleId - a.vehicleId) * -1
    }
    return 0
  })
}

/**
 * エラーダイアログを閉じる
 */
const onClickCloseErrorDialog = () => {
  errorDialog.value.isShow = false
}
</script>
<template>
  <TitleHeader :title-info="titleinfo" />
  <v-container class="watch">
    <v-row>
      <v-col cols="7">
        <v-card elevation="10">
          <v-card-title
            class="py-0 watch__window-title"
          >
            <span class="py-0">VIRTUAL映像</span>
          </v-card-title>

          <v-card-text class="dataScreen cols-7">
            <div
              id="monitorMap"
              class="monitorMap"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="5">
        <v-container>
          <v-row>
            <v-col md="12">
              <v-card elevation="3">
                <v-card-title
                  class="py-0 watch__window-title"
                >
                  <span class="py-0">日付(表示範囲)</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row justify="center">
                      <v-spacer />
                      <v-col md="3">
                        <v-text-field 
                          v-model="searchConditions.fromDate"
                          type="date"
                        />
                      </v-col>
                      <v-col md="2">
                        <v-text-field
                          v-model="searchConditions.fromTime"
                          type="time"
                        />
                      </v-col>
                      <v-col>
                        <p
                          class="pt-3"
                          style="text-align: center;"
                        >
                          ～
                        </p>
                      </v-col>
                      <v-col md="3">
                        <v-text-field 
                          v-model="searchConditions.toDate"
                          type="date"
                        />
                      </v-col>
                      <v-col md="2">
                        <v-text-field
                          v-model="searchConditions.toTime"
                          type="time"
                        />
                      </v-col>
                      <v-spacer />
                    </v-row>
                    <v-row>
                      <v-col>
                        <Button
                          label="決定"
                          :disabled="false"
                          @click="search()"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
        <v-container>
          <v-row>
            <v-col md="12">
              <v-card elevation="3">
                <v-card-title
                  class="py-0 watch__window-title"
                >
                  <span class="py-0">ID</span>
                </v-card-title>
                <v-card-text class="watch__id-list">
                  <div
                    v-for="(item, index) in editingData.idList"
                    :key="index"
                  >
                    <v-checkbox
                      v-model="searchConditions.targetvehicleId"
                      :value="item"
                      :label="item.toString()"
                      density="compact"
                      @change="showInfoByVehicleId((filter = true))"
                    />
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
        <v-container>
          <v-row>
            <v-col md="12">
              <v-card elevation="3">
                <v-card-title
                  class="py-0 watch__window-title"
                >
                  <span class="py-0">行動履歴</span>
                </v-card-title>
                <v-data-table
                  :headers="actionHistoryTableHeader"
                  :items="editingData.actionHistoryList"
                />
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
  <Loading v-show="isLoading" />
  <ErrorDialog
    :error-dialog="errorDialog"
    @on-click-close-error-dialog="onClickCloseErrorDialog"
  />
</template>
<style lang="scss" scoped>
  .watch {
    max-width: calc(100vw - 40px);
    &__window-title {
      font-size: 22px;
      font-weight: bold;
      color: white;
      background-color: #0041c0;
      height: 50px;
    }
    &__id-list {
      height: 30vh;
      overflow-y: auto;
    }
  }
  .monitorMap {
    z-index: 0;
    height: calc(100vh - 160px);
    width: 100%;
    margin: 0 auto;
  }
  .v-input--selection-controls {
    margin-top: 1px;
    padding-top: 1px;
  }
</style>
