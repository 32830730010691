<script lang="ts" setup>
/**
 * PoleListMini.vue
 * 集計画面用地図
 * 
 * 親コンポーネント
 * @/views/Aggregation.vue
 */
// ==================================
// import
// ==================================
import { onBeforeMount } from 'vue'

import { usePoleListStore } from '@/store/app'

import { PoleListStore } from '@/types/Interfaces'

// ==================================
// interface
// ==================================
interface Emits {
  (e: 'update-pole-data', poleId: number): void;
}

// ==================================
// data
// ==================================
const poleListStore = usePoleListStore()

const state = {
  poleListHeader: [
    { text: 'ポールID', value: 'poleId' },
    { text: 'ポール名', value: 'address' }    
  ] as {
    text: string,
    value: string
  }[],
  poleList: [] as PoleListStore[]
}

// ==================================
// hook
// ==================================
onBeforeMount(() => {
  state.poleList = poleListStore.$state.poleList
})

// ==================================
// method
// ==================================
const emit = defineEmits<Emits>()

/**
 * 対象ポールが変更されたら親コンポーネントにポールIDを伝える
 * @param poleId - ポールID
 */
const setPoleData = (poleId: number) => {
  emit('update-pole-data', poleId)
}
</script>
<template>
  <div class="pole-list-mini">
    <v-data-table
      theme="light"
      density="compact"
      hide-default-footer
      :headers="state.poleListHeader"
      :items="state.poleList"
      item-key="poleId"
    >
      <template #item.poleId="{ item }">
        <a @click="setPoleData(item.poleId)">{{ "0x" + item.poleId.toString(16) }}</a>
      </template>
    </v-data-table>
  </div>
</template>
<style lang="scss">
  .pole-list-mini {
    position: relative;
    overflow-y: hidden;
  }
</style>
