<script lang="ts" setup>
/**
 * Logout.vue
 * ログアウト用ダイアログ
 * 
 * 親コンポーネント
 * @/components/parts/common/TitleHeader.vue
 */
// ==================================
// import
// ==================================
import { ref } from 'vue'

// ==================================
// data
// ==================================
const dialog = ref<boolean>(false)

const resolve = ref<any>(false)

const reject = ref<any>(null)

// ==================================
// method
// ==================================
/**
 * ダイアログを開く(親コンポーネントからの呼び出し)
 */
const open = () => {
  dialog.value = true
  return new Promise((res, rej) => {
    // resolveとrejectのメソッドをステートオブジェクトに格納する
    resolve.value = res
    reject.value = rej
  })
}

/**
 * OKボタンをクリック
 */
const agree = () => {
  // 親コンポーネントへログアウト処理を伝達する
  resolve.value(true)
  dialog.value = false
}

/**
 * キャンセルボタンをクリック
 */
const cancel = () => {
  resolve.value(false)
  dialog.value = false
}

defineExpose({
  open
})
</script>
<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="15vw"
    >
      <v-card
        elevation="5"
        outlined
      >
        <v-card-title>ログアウト</v-card-title>
        <v-card-text>ログアウトしますか？</v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue-grey"
            variant="elevated"
            density="default"
            dark
            @click="agree"
          >
            OK
          </v-btn>
          <v-btn
            color="blue-grey"
            variant="elevated"
            density="default"
            dark
            @click="cancel"
          >
            NO
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
