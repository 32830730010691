<script lang="ts" setup>
/**
 * ListOfAccident.vue
 * 事故発生一覧
 * 
 * 親コンポーネント
 * @/views/Accident.vue
 */
// ==================================
// import
// ==================================
import { computed, ref } from 'vue'

import { useAccidentListStore } from '@/store/app'

import { AccidentStore } from '@/types/Interfaces'

import { ACCIDENT_LIST_HEADER } from '@/setting/setting'

// ==================================
// interface
// ==================================
interface Search {
  accidentIdFilterValue: string;
  accidentVehicleId1FilterValue: string;
  accidentVehicleId2FilterValue: string;
  accidentLatitudeFilterValue: string;
  accidentLongitudeFilterValue: string;
  accidentTimeFilterValue: string;
}

// ==================================
// data
// ==================================
const accidentListStore = useAccidentListStore()

// 検索条件
const search = ref<Search>({
  accidentIdFilterValue: '',
  accidentVehicleId1FilterValue: '',
  accidentVehicleId2FilterValue: '',
  accidentLatitudeFilterValue: '',
  accidentLongitudeFilterValue: '',
  accidentTimeFilterValue: '',
})

// 事故発生情報リスト
const accidentList = ref<AccidentStore[]>([])

// ==================================
// computed
// ==================================
// リスト一覧生成
const accident = computed(() => {
  return filterItems(accidentList.value, search.value)
})

// ヘッダー情報
const accidentsTableHeader = computed(() => {
  return ACCIDENT_LIST_HEADER
})

// ==================================
// method
// ==================================
/**
 * リストフィルター処理
 * @param arr - 事故情報
 * @param word - 検索条件
 * @returns 検索条件を満たす事故情報リスト
 */
const filterItems = (arr: AccidentStore[], word: Search) => {
  return arr.filter(function (item: AccidentStore) {
    let accidentId = true
    let vehicleId_1 = true
    let vehicleId_2 = true
    let latitude = true
    let longitude = true
    let accidentTime = true
    if (word.accidentIdFilterValue) {
      accidentId = String(item.accidentId).toLowerCase().includes(String(word.accidentIdFilterValue).toLowerCase())
    }
    if (word.accidentVehicleId1FilterValue) {
      vehicleId_1 = String(item.vehicleId_1).toLowerCase().includes(String(word.accidentVehicleId1FilterValue).toLowerCase())
    }
    if (word.accidentVehicleId2FilterValue) {
      vehicleId_2 = String(item.vehicleId_2).toLowerCase().includes(String(word.accidentVehicleId2FilterValue).toLowerCase())
    }
    if (word.accidentLatitudeFilterValue) {
      latitude = String(item.latitude).toLowerCase().includes(String(word.accidentLatitudeFilterValue).toLowerCase())
    }
    if (word.accidentLongitudeFilterValue) {
      longitude = String(item.longitude).toLowerCase().includes(String(word.accidentLongitudeFilterValue).toLowerCase())
    }
    if (word.accidentTimeFilterValue) {
      accidentTime = String(item.accidentTime).toLowerCase().includes(String(word.accidentTimeFilterValue).toLowerCase())
    }
    return accidentId && vehicleId_1 && vehicleId_2 && latitude && longitude && accidentTime
  })
}

/**
 * リスト情報更新
 */
const updateAccidentList = () => {
  accidentList.value = accidentListStore.accidentList
}

defineExpose({
  updateAccidentList,
})
</script>
<template>
  <v-card
    class="list-of-accident"
    elevation="3"
  >
    <v-card-title class="list-of-accident__header py-0">
      <span class="py-0">事故発生一覧</span>
    </v-card-title>
    <!-- Filter-->
    <v-row>
      <v-col cols="1">
        <v-row class="pa-5">
          <v-text-field
            v-model="search.accidentIdFilterValue"
            type="text"
            label="ID"
            variant="underlined"
          />
        </v-row>
      </v-col>
      <v-col cols="1">
        <v-row class="pa-5">
          <v-text-field
            v-model="search.accidentVehicleId1FilterValue"
            type="text"
            label="車両1ID"
            variant="underlined"
          />
        </v-row>
      </v-col>
      <v-col cols="1">
        <v-row class="pa-5">
          <v-text-field
            v-model="search.accidentVehicleId2FilterValue"
            type="text"
            label="車両2ID"
            variant="underlined"
          />
        </v-row>
      </v-col>
      <v-col cols="1">
        <v-row class="pa-5">
          <v-text-field
            v-model="search.accidentLatitudeFilterValue"
            type="text"
            label="緯度"
            variant="underlined"
          />
        </v-row>
      </v-col>
      <v-col cols="1">
        <v-row class="pa-5">
          <v-text-field
            v-model="search.accidentLongitudeFilterValue"
            type="text"
            label="経度"
            variant="underlined"
          />
        </v-row>
      </v-col>
      <v-col cols="2">
        <v-row class="pa-5">
          <v-text-field
            v-model="search.accidentTimeFilterValue"
            type="text"
            label="事故発生時刻"
            variant="underlined"
          />
        </v-row>
      </v-col>
    </v-row>
    <v-data-table
      :headers="accidentsTableHeader"
      :items="accident"
      density="compact"
    />
  </v-card>
</template>
<style lang="scss" scoped>
  .list-of-accident {
    &__header {
      font-size: 22px;
      font-weight: bold;
      color: white;
      background-color: #0041c0;
      height: 3vh;
    }
  }
</style>
