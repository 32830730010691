<script lang="ts" setup>
/**
 * GroupCreateDialog.vue
 * グループの作成ダイアログ
 * 
 * 親コンポーネント
 * @/views/GroupSetting.vue
 */
// ==================================
// import
// ==================================
import { computed, ref } from 'vue'

import Loading from '@/components/parts/common/Loading.vue'

import { DIALOG_ERROR_INFO } from '@/mixins/commonFunction'
import { createGroupInfo } from '@/mixins/communicationFunction'

// ==================================
// interface
// ==================================
interface Props {
  dialog: {
    isDialog: boolean;
  }
}

interface Emits {
  (e: 'show-error-dialog', result: boolean, title: string, message: string): void;
  (e: 'dialogClose'): void;
}

// ==================================
// data
// ==================================
const props = defineProps<Props>()

// ローディングアイコン表示判定
const isLoading = ref<boolean>(false)

// 作成ボタンの無効化判定
const isDisable = ref<boolean>(false)

const groupname = ref<string>('')

const rules = ref({
  /**
   * グループ名のバリデーションチェック
   * @param value - フォーム入力値
   * @returns エラー文字列、成功判定
   */
  checkInValidGroupName: (value: string | null): string | boolean => {
    if (!value) {
      // 値が空ではないこと
      return '値を入力してください'
    } else if (value.length > 50) {
      // グループ名文字数が1~50の範囲内であること
      return 'グループ名が長すぎます'
    }
    return true
  }
})

// ダイアログの表示判定用
const isDialog = computed(() => {
  return props.dialog.isDialog
})

// ==================================
// method
// ==================================
const emit = defineEmits<Emits>()

/**
 * 作成ボタン活性/非活性の判定
 */
const isValidation = ()  => {
  // グループ名がNULLではなく50文字以内かどうか
  isDisable.value = groupname.value !== null && groupname.value.length <= 50
  return isDisable.value
}

/**
 * グループ情報作成処理
 */
const onClickCreate = () => {
  isLoading.value = true
  // グループ名をパラメータとしてグループ作成のAPIを呼び出す
  let promise = createGroupInfo(groupname.value)
  promise
    .then(() => {
      isLoading.value = false
      emit('show-error-dialog', true, 'グループ作成成功', 'グループを作成しました: \n' + groupname.value)
    })
    .catch(() => {
      isLoading.value = false
      // エラーメッセージを設定する
      let title = DIALOG_ERROR_INFO.title.createError
      let message = DIALOG_ERROR_INFO.message.createErrorGroupInfo
      emit('show-error-dialog', false, title, message)
    })
}

/**
 * ダイアログ閉じる処理
 */
const dialogClose = () => {
  emit('dialogClose')
}
</script>
<template>
  <v-dialog
    v-model="isDialog"
    class="group-create-dialog"
    width="500px"
    persistent
  >
    <v-card>
      <v-container>
        <v-card-title
          class="group-create-dialog__title"
          nav
          denseity="compact"
        >
          グループ作成
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-form>
            <v-row>
              <v-col cols="3">
                <span>グループ名</span>
              </v-col>
              <v-col cols="9">
                <v-text-field
                  v-model="groupname"
                  :rules="[rules.checkInValidGroupName]"
                  placeholder="グループ名を入力してください。"
                  clearable 
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            :disabled="!isValidation()"
            class="group-create-dialog__create-button"
            color="primary darken-2"
            @click="onClickCreate"
          >
            作成
          </v-btn>
          <v-btn
            color="grey darken-2"
            dark
            @click="dialogClose"
          >
            キャンセル
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
    <Loading v-show="isLoading" />
  </v-dialog>
</template>
<style lang="scss" scoped>
  .group-create-dialog {
    &__title {
      height: 5vh;
      font-size: 18px;
      font-weight: bold;
    }
    &__create-button {
      width: 19%;
    }
  }
  .v-text-field {
    padding: 0px;
    margin: 0px;
  }
  .v-input :deep(.v-messages) {
    margin-top: 10px;
  }
</style>
