<script lang="ts" setup>
/**
 * TitleHeader.vue
 * 共通タイトルヘッダー
 * 
 * 親コンポーネント
 * @/views/Accident.vue
 * @/views/Aggregation.vue 
 * @/views/Area.vue
 * @/views/Collections.vue
 * @/views/GroupSetting.vue
 * @/views/HistoryList.vue
 * @/views/Login.vue
 * @/views/MacroAnalysis.vue
 * @/views/MacroAnalysisInfoDataDownload.vue
 * @/views/MultiCamera.vue
 * @/views/RealTime.vue
 * @/views/Virtual.vue
 * @/views/Watch.vue
 */
// ==================================
// import
// ==================================
import { ref, computed } from 'vue'
import { useRouter } from 'vue-router'

import SelectAreaList from '@/components/parts/area/SelectAreaList.vue'
import Logout from '@/components/parts/common/Logout.vue'
import MenuList from '@/components/parts/common/MenuList.vue'

import { useUserInfoStore, useAreaListStore, usePoleListStore, useSelectAreaStore, useSelectPoleStore } from '@/store/app'

import { MenuInfo, TitleInfo } from '@/types/Interfaces'

import { Auth } from 'aws-amplify'

// ==================================
// interface
// ==================================
interface Props {
  titleInfo: TitleInfo;
}

interface Emits {
  (e: 'on-select-point', value: any): void;
  (e: 'on-enter-point', value: any): void;
}

interface PoleList {
  poleId: number;
  name: string;
  path: string;
  nearMissPath: string;
}
// ==================================
// data
// ==================================
const router = useRouter()

const userInfoStore = useUserInfoStore()

const areaListStore = useAreaListStore()

const poleListStore = usePoleListStore()

const selectAreaStore = useSelectAreaStore()

const selectPoleStore = useSelectPoleStore()

const props = defineProps<Props>()

const logoutComponent = ref()

const isPoint = ref(false)

const isMenu = ref(false)

// ==================================
// computed
// ==================================
// タイトル表示
const showTitle = computed(() => {
  return props.titleInfo.title
})
// 地点リスト表示
const isShowPoint = computed(() => {
  return props.titleInfo.show.point
})
const pointList = computed(() => {
  return props.titleInfo.pointList
})

// メニューリスト表示
const menuList = computed(() => {
  return props.titleInfo.menuList
})

// ==================================
// method
// ==================================
const emit = defineEmits<Emits>()
/**
 * 地点リスト選択時画面中央に表示
 * @param data - 地点リスト
 */
const selectPoint = (data: any) => {
  emit('on-select-point', data)
}
/**
 * エリア情報設定
 * @param data - 地点リスト
 */
const enterPoint = (data: any) => {
  emit('on-enter-point', data)
}

/**
 * 地点リストアイコン押下
 */
const onClickPoint = () => {
  isPoint.value = !isPoint.value
  // 地点リスト以外はすべて閉じる
  if (isPoint.value) {
    isMenu.value = false
  }
}

/**
 * メニューリストアイコン押下
 */
const onClickMenu = () => {
  isMenu.value = !isMenu.value
  // メニュー以外はすべて閉じる
  if (isMenu.value) {
    isPoint.value = false
  }
}

/**
 * メニュータブから画面遷移
 * @param data - 選択したメニュー情報
 */
const selectMenu = (data: MenuInfo) => {
  isMenu.value = false
  switch (data.item) {
    case 'selectArea':
      router.push('/selectarea')
      break
    case 'selectPole':
      router.push('/selectpole')
      break
    case 'accidents':
      router.push('/accident')
      break
    case 'parking':
      router.push('/parking')
      break
    case 'summary':
      router.push('/summaries')
      break
    case 'aggregation':
      router.push('/aggregation')
      break
    case 'dashboard':
      router.push('/dashboard')
      break
    case 'history':
      router.push('/historylist')
      break
    case 'macroAnalysis':
      router.push('/macroAnalysis')
      break
    case 'macroAnalysisInfoDataDownload':
      router.push('/macroAnalysisInfoDataDownload')
      break
    case 'watching':
      router.push('/watching')
      break
    case 'collection': 
      router.push('/collection')
      break
    case 'groupSetting':
      router.push('/groupsetting')
      break
    case 'logout':
      openLogoutDialog()
    break
      
    default:
  }
}

/**
 * ログアウト処理
 */
const openLogoutDialog = async() => {
  const result = await logoutComponent.value.open()
  // ログアウトした場合、AWSのサインアウトと、エリア、ポール情報をクリアーにする
  if (result) {
    Auth.signOut()
    userInfoStore.clearData()
    areaListStore.clearData()
    poleListStore.clearData()
    selectAreaStore.clearData()
    selectPoleStore.clearData()
    router.push('/')
  }
}

/**
 * メニューMULTI CAMERAボタンの操作
 */
const onClickMultiCamera = () =>{
  router.push('/multicamera')
}
/**
 * メニューVIRTUALボタンの操作
 */
const onClickVirtual = () =>{
  router.push('/virtual')
}
/**
 * メニューREALTIMEボタンの操作
 */
const onClickRealtime = () =>{
  router.push('/realtime')
}
/**
 * メニュー前に戻るボタンの操作
 */
const onClickBack = () =>{
  router.go(-1)
}

/**
 * GRAFANAボタン押下時処理
 */
const onClickGrafana = () => {
  // 対象ポールの場合、Grafanaへの画面遷移
  const pId = props.titleInfo.poleId
  if (props.titleInfo.poleList !== undefined) {
    const poleList = props.titleInfo.poleList
    poleList.forEach((pole: PoleList) => {
      if (pId == pole.poleId) {
        window.open(pole.path, '_blank')
      }
    });
  }
}
</script>
<template>
  <v-app-bar
    app
    color="#0041c0"
    dark
    nav
    dense
    density="comfortable"
    class="title-header"
  >
    <v-app-bar-title>
      <span class="title-header__title">
        <span v-text="showTitle" />
      </span>
    </v-app-bar-title>
    <v-spacer />
    <template #append>
      <!-- GRAFANA画面遷移 -->
      <v-btn
        v-if="titleInfo.show.grafana == true"
        @click="onClickGrafana"
      >
        <span class="title-header__icon-text">GRAFANA</span>
        <v-icon color="white">
          mdi-arrange-send-backward
        </v-icon>
      </v-btn>
      <!-- RealTime画面遷移 -->
      <v-btn
        v-if="titleInfo.show.realtime == true"
        @click="onClickRealtime"
      >
        <span class="title-header__icon-text">REALTIME</span>
        <v-icon color="white">
          mdi-arrange-send-backward
        </v-icon>
      </v-btn>
      <!-- Virtual画面遷移 -->
      <v-btn
        v-if="titleInfo.show.virtual == true"
        @click="onClickVirtual"
      >
        <span class="title-header__icon-text">VIRTUAL</span>
        <v-icon color="white">
          mdi-arrange-send-backward
        </v-icon>
      </v-btn>
      <!-- MultiCamera画面遷移 -->
      <v-btn
        v-if="titleInfo.show.multicamera == true"
        @click="onClickMultiCamera"
      >
        <span class="title-header__icon-text">MULTI CAMERA</span>
        <v-icon color="white">
          mdi-arrange-send-backward
        </v-icon>
      </v-btn>
      <!-- 前画面遷移 -->
      <v-btn
        v-if="titleInfo.show.back == true"
        @click="onClickBack"
      >
        <span>前に戻る</span>
        <v-icon color="white">
          mdi-arrange-send-backward
        </v-icon>
      </v-btn>
      <!-- 地点リスト -->
      <v-app-bar-nav-icon
        v-if="isShowPoint == true && isPoint == false"
        @click="onClickPoint"
      >
        <v-icon color="white">
          mdi-view-list
        </v-icon>
      </v-app-bar-nav-icon>
      <v-app-bar-nav-icon
        v-if="isShowPoint == true && isPoint == true"
        @click="onClickPoint"
      >
        <v-icon color="white">
          mdi-view-list
        </v-icon>
      </v-app-bar-nav-icon>
      <v-spacer />
      <!-- メニューリスト -->
      <v-app-bar-nav-icon
        v-if="titleInfo.show.menu == true && isMenu == false"
        @click="onClickMenu"
      >
        <v-icon color="white">
          mdi-menu
        </v-icon>
      </v-app-bar-nav-icon>
      <v-app-bar-nav-icon
        v-if="titleInfo.show.menu == true && isMenu == true"
        @click="onClickMenu"
      >
        <v-icon color="white">
          mdi-menu
        </v-icon>
      </v-app-bar-nav-icon>
    </template>
  </v-app-bar>
  <!-- 地点リスト -->
  <v-navigation-drawer
    v-model="isPoint"
    absolute
    theme="dark"
    permanent
    location="right"
    width="350"
  >
    <SelectAreaList
      :point-list="pointList"
      @on-select-point="selectPoint"
      @on-enter-point="enterPoint"
    />
  </v-navigation-drawer>
  <!-- メニューリスト -->
  <v-navigation-drawer
    v-model="isMenu"
    absolute
    theme="dark"
    permanent
    location="right"
    width="350"
  >
    <MenuList
      :menu-list="menuList"
      @on-select-menu="selectMenu"
    />
  </v-navigation-drawer>
  <Logout 
    ref="logoutComponent"
  />
</template>
<style lang="scss" scoped>
  .title-header {
    &__title {
      font-size: 1.5rem !important;
      line-height: 2rem;
      letter-spacing: 0.0073529412em !important;
      font-weight: 400;
      font-family: Roboto, sans-serif !important;
      color: #fff !important;
      caret-color: #fff !important;
      position: relative;
    }
    &__icon-text {
      font-family: Roboto, sans-serif !important;
      color: #fff !important;
    }
  }
</style>
<style>
  .v-list-item__content {
    display: -webkit-inline-box;
  }
  .v-container--fluid {
    position: absolute !important;
    overflow: auto !important;
    /* max-height: 45vw; */
    max-height: 100%;
    top: 50px;
  }
</style>
