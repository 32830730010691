<script lang="ts" setup>
/**
 * HistoryListTable.vue
 * 過去データ一覧テーブルコンポーネント
 * 
 * 親コンポーネント
 * @/views/HistoryList.vue
 */
// ==================================
// import
// ==================================
import { ref, onBeforeMount } from 'vue'
import { useRouter } from 'vue-router'

import { useAccidentListStore, useSelectAccidentStore } from '@/store/app'

import { DIALOG_ERROR_INFO } from '@/mixins/commonFunction'
import { addressByCoordinates, apiResponseErrorCode, updateAccidentList } from '@/mixins/communicationFunction'

import { HISTORY_LIST_HEADER } from '@/setting/setting'

import * as log from 'loglevel'

// ==================================
// interface
// ==================================
interface Props {
  poleId: any | undefined;
  isLoading: boolean;
}

interface Emits {
  (e: 'open-error-dialog', value1: string, value2: string): void;
  (e: 'start-loading'): void;
  (e: 'stop-loading'): void;
}

// ==================================
// data
// ==================================
const accidentListStore = useAccidentListStore()

const selectAccidentStore = useSelectAccidentStore()

const props = defineProps<Props>()

const localPoleId = ref(props.poleId)

// 画面内ステータス
const search = ref<any | undefined>('')
const headers = ref<any | undefined>([])
const accidentList = ref<any | undefined>([])

// ルーター定義
const router = useRouter()

// ==================================
// hook
// ==================================
onBeforeMount(() => {
  initialize()
})

// ==================================
// method
// ==================================
const emit = defineEmits<Emits>()
/**
 * エラーダイアログ表示
 * @param title - エラータイトル
 * @param message - エラーメッセージ
 */
const openErrorDialog = (title: string, message: string) => {
  emit('open-error-dialog', title, message)
}
/**
 * ローディング開始処理
 */
const startLoading = () => {
  emit('start-loading')
}
/**
 * ローディング終了処理
 */
const stopLoading = () => {
  emit('stop-loading')
}

/**
 * 値初期化処理
 */
const initialize = () => {
headers.value = HISTORY_LIST_HEADER
  setAccidentList()
}

/**
* 事故情報一覧設定処理
*/
const setAccidentList = async () => {
  startLoading()
  headers.value = HISTORY_LIST_HEADER
  log.info('child: localPoleId: ' + localPoleId.value)
  let promise = updateAccidentList(localPoleId.value)
  promise
    .then(() => {
      createAccidentList()
    })
    .catch(error => {
      let title = DIALOG_ERROR_INFO.title.getError
      let message = DIALOG_ERROR_INFO.message.getErrorAccidentInfo
      if (error == apiResponseErrorCode.noContent) {
        message = DIALOG_ERROR_INFO.message.getErrorAccidentNoContent
      }
      openErrorDialog(title, message)
      stopLoading()
    })
}

/**
* 事故情報一覧作成処理
*/
const createAccidentList = async () => {
  let list = accidentListStore.accidentList
  for (let i = 0; i < list.length; i++) {
    try {
      const response = await addressByCoordinates(list[i].latitude, list[i].longitude)
      if (response.data !== void 0 && response.data !== null) {
        let address = ''
        if (response.data.address.postcode != undefined) {
          address += '〒' + response.data.address.postcode + ' '
        }
        if (response.data.address.province != undefined) {
          address += response.data.address.province
        }
        if (response.data.address.city != undefined) {
          address += response.data.address.city
        }
        if (response.data.address.country != undefined) {
          address += response.data.address.country
        }
        if (response.data.address.hamlet != undefined) {
          address += response.data.address.hamlet
        }
        if (response.data.address.suburb != undefined) {
          address += response.data.address.suburb
        }
        if (response.data.address.neighbourhood != undefined) {
          address += response.data.address.neighbourhood
        }
        if (response.data.address.road != undefined) {
          address += response.data.address.road
        }
        accidentList.value.push({
          accidentId: list[i].accidentId,
          poleId: list[i].poleId,
          accidentTime: list[i].accidentTime,
          location: address,
          lating: [list[i].latitude, list[i].longitude],
          acceleration: list[i].acceleration,
          movieList: list[i].movieList
        })
      }
    } catch {
      accidentList.value.push({
        accidentId: list[i].accidentId,
        poleId: list[i].poleId,
        accidentTime: list[i].accidentTime,
        location: 'unknown',
        lating: [list[i].latitude, list[i].longitude],
        acceleration: list[i].acceleration,
        movieList: list[i].movieList
      })
    }
  }
  stopLoading()
}

/**
* 事故情報選択時処理
* @param item - 選択事故情報
*/
const selectItem = (item: any) => {
  setSelectAccident(item)
  router.push('/historyData')
}

/**
* 選択事故情報設定処理
* @param item - 選択事故情報
*/
const setSelectAccident = (item: any) => {
  try {
    selectAccidentStore.setSelectAccident(item)
  } catch {
    log.error('setSelectAccident error')
  }
}
</script>
<template>
  <v-card elevation="5">
    <v-card-title
      class="py-0"
      style="
        font-size: 17px;
        font-weight: bold;
        height: 10%;
      "
    >
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      />
    </v-card-title>
    <v-dicider />
    <v-data-table
      v-if="props.isLoading == false"
      :headers="headers"
      :items="accidentList"
      :search="search"
      :item-per-page="10"
      :footer-props="{ itemsPerPageOptions: [5, 10] }"
    >
      <template #[`item.icon`]="{ item }">
        <v-icon @click="selectItem(item)">
          mdi-location-enter
        </v-icon>
      </template>
    </v-data-table>
  </v-card>
</template>
