<script lang="ts" setup>
/**
 * SelectArea.vue
 * 地域選択画面で表示する地図コンポーネント
 * 
 * 親コンポーネント
 * @/views/Area.vue
 */
// ==================================
// import
// ==================================
import { onMounted, ref } from 'vue'

import { useSelectAreaStore, usePoleListStore } from '@/store/app'

import {
  DEFAULT_MARKER_ICON,
  SELECT_MARKER_ICON,
  getTileLayer,
  getMarkerInfo,
} from '@/mixins/mapFunction'

import { PoleListStore } from '@/types/Interfaces'

import { SELECT_AREA } from '@/setting/setting'

// leaflet Library
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'

// ==================================
// interface
// ==================================
interface MarkerInfo {
  id: number;
  name: string;
  latlng: [number, number];
  marker: any;
} 

// ==================================
// data
// ==================================
// 選択エリア情報ストア
const selectAreaStore = useSelectAreaStore()

const poleListStore = usePoleListStore()

const markers = ref<MarkerInfo[]>([])

const selectId = ref<number | undefined>()

const map = ref<any>()

// ==================================
// hook
// ==================================
onMounted(() => {
  // マップ生成
  L.Icon.Default.mergeOptions(DEFAULT_MARKER_ICON)
  map.value = L.map('area-map', {
    dragging: true,
    touchZoom: true,
    scrollWheelZoom: true,
    doubleClickZoom: true,
    boxZoom: true,
    tap: true,
    keyboard: true,
    zoomControl: true,
    minZoom: SELECT_AREA.zoom.min,
    maxZoom: SELECT_AREA.zoom.max,
  }).setView(
    [SELECT_AREA.center.latitude, SELECT_AREA.center.longitude],
    SELECT_AREA.zoom.default
  )
  L.control
    .layers(getTileLayer(map.value, SELECT_AREA.zoom.max, undefined))
    .addTo(map.value)
})

// ==================================
// method
// ==================================
/**
 * ポール一覧からマーカーを設定する
 * @returns 実行完了フラグ
 */
const addAreaMarker = (): boolean => {
   poleListStore.poleList.forEach((pole: PoleListStore) => {
    if (pole.latlng != undefined && pole.address != undefined) {
    addMarker(pole.latlng, pole.address, pole.poleId, L.icon(DEFAULT_MARKER_ICON))
    }
  })
  return true
}

/**
 * 地点リスト選択時画面中央に表示
 * @param data - 地点リスト
 */
const setCenter = (data: any) => {
  // 具体的な場所を見やすくするために拡大
  let zoomLevel = map.value.getZoom();
  if (zoomLevel < 14) {
    zoomLevel = 14;
  }
  // 地図データの生成
  map.value.setView(data.latlng, zoomLevel)
  if (selectId.value !== void 0) {
    let marker = getMarkerInfo(selectId.value, markers.value)
    map.value.removeLayer(marker.marker)
    addMarker(
      marker.latlng,
      marker.name,
      marker.id,
      L.icon(DEFAULT_MARKER_ICON)
    )
  }

  // マーカーを生成
  let markerInfo = getMarkerInfo(data.poleId, markers.value)
  map.value.removeLayer(markerInfo.marker)
  addMarker(
    markerInfo.latlng,
    markerInfo.name,
    markerInfo.id,
    L.icon(SELECT_MARKER_ICON)
  )
  selectId.value = data.poleId
}

/**
 * マーカー設定
 * @param latlng - 緯度経度
 * @param name - ポール住所
 * @param poleId - ポールID
 * @param icon - アイコン情報
 */
const addMarker = (latlng: [number, number], name: string, poleId: number, icon: any) => {
  let marker = L.marker(latlng, {
    title: name,
    icon: icon,
  }).addTo(map.value)

  markers.value.push({
    id: poleId,
    name: name,
    latlng: latlng,
    marker: marker,
  })
}

/**
 * エリア情報設定
 * @param data - 地点リスト
 */
const enterPoint = (data: any) => {
  selectAreaStore.setData(data.id, data.name, data.latlng)
}

defineExpose({
  addAreaMarker,
  setCenter,
  enterPoint
})
</script>
<template>
  <div
    id="area-map"
    class="select-area"
  />
  <v-main class="select-area-router">
    <RouterView />
  </v-main>
</template>
<style scoped>
  .select-area {
    position: absolute;
    left: 400px;
    top: 55px;
    bottom: 0;
    width: calc(100vw - 310px);
    height: calc(100vh - 60px);
  }
  .select-area-router {
    padding: 0;
  }
</style>
