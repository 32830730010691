<script lang="ts" setup>
/**
 * NearMissPlayVideo.vue
 * ヒヤリハット映像再生用モーダル
 * 
 * 親コンポーネント
 * @/components/parts/accident/NearMissList.vue
 */
// ==================================
// import
// ==================================
import { onMounted, onBeforeUnmount, computed, ref } from 'vue'

import * as videojs from 'video.js'
import 'video.js/dist/video-js.css'
import Player from 'video.js/dist/types/player'

// ==================================
// interface
// ==================================
interface Emits {
  (e: 'video-close'): void;
}

interface Source {
  src: string;
  type: string;
}
interface Props {
  options: {
    autoplay: boolean;
    controls: boolean;
    sources: Source[];
  }
  nearMissesInfo: {
    id: string;
    area: string;
    vehicleId1Kind: string;
    vehicleId1KindDirection: string;
    vehicleId2Kind: string;
    vehicleId2KindDirection: string;
    nearMissTime: string;
    timeGap: string;
  };
}

// ==================================
// data
// ==================================
const props = defineProps<Props>()

const video = ref<Player | null>(null)

// ==================================
// computed
// ==================================
// 各詳細表示
const id = computed(() => {
  return props.nearMissesInfo.id
})

const nearMissTime = computed(() => {
  return props.nearMissesInfo.nearMissTime
})

const area = computed(() => {
  return props.nearMissesInfo.area
})

const vehicleId1Kind = computed(() => {
  return props.nearMissesInfo.vehicleId1Kind
})

const vehicleId1KindDirection = computed(() => {
  return props.nearMissesInfo.vehicleId1KindDirection
})

const vehicleId2Kind = computed(() => {
  return props.nearMissesInfo.vehicleId2Kind
})

const vehicleId2KindDirection = computed(() => {
  return props.nearMissesInfo.vehicleId2KindDirection
})

const timeGap = computed(() => {
  return props.nearMissesInfo.timeGap
})

// ==================================
// hook
// ==================================
onMounted(() => {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  video.value = videojs.default('video-player', props.options, function onPlayerReady() {})
})

onBeforeUnmount(async () => {
  if (video.value) {
    video.value.dispose()
  }
})

// ==================================
// method
// ==================================
const emit = defineEmits<Emits>()
/**
 * ビデオ表示を閉じる
 */
const videoClose = () => {
  emit('video-close')
}

/**
 * ビデオ表示を閉じる
 */
const close = () => {
  if (video.value) {
    video.value.dispose()
  }
  videoClose()
}
</script>
<template>
  <div class="near-miss-play-video">
    <div class="video-container">
      <v-card class="video-card">
        <v-card-item>
          <v-card-title class="video-card__header">
            ヒヤリハット映像情報
          </v-card-title>
        </v-card-item>
        <v-card-text>
          <div class="video-card__video-info">
            <span>ヒヤリハットID: {{ id }}</span>
            <span>発生時刻: {{ nearMissTime }}</span>
            <span>判定エリア: {{ area }}</span>
            <span>車両1 : {{ vehicleId1Kind }} ({{ vehicleId1KindDirection }})</span>
            <span>車両2 : {{ vehicleId2Kind }} ({{ vehicleId2KindDirection }})</span>
            <span>TimeGap: {{ timeGap }}</span>
          </div>
          <div>
            <video
              id="video-player"
              class="video-js vjs-big-play-centered video-card__video-player"
            />
          </div>
          <div class="video-card__close-button-area">
            <v-btn
              class="video-card__close-button"
              them="dark"
              color="blue-grey"
              @click="close"
            >
              閉じる
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </div>
    <div
      class="close-overLay"
      @click="close"
    />
  </div>
</template>
<style lang="scss" scoped>
  .near-miss-play-video {
    z-index: 2;
    width: 100%;
    height: 100%;
    background: #fefefe;
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    .video-container {
      position: absolute;
      top: 3vw;
      background-color: black;
      z-index: 4;
      .video-card {
        &__header {
          background-color: #0041c0;
          color: white;
        }
        &__video-info {
          font-size: 20px;
          text-align: left;
          display: inline-grid;
        }
        &__video-player {
          width: 30vw;
          height: 30vw;
        }
        &__close-button-area {
          padding: 10px;
        }
        &__close-button {
          width: 100%;
          padding: 10px;
        }
      }
    }
    .close-overLay {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 3;
    }
  }
</style>
