<script lang="ts" setup>
/**
 * AxlDailyChart.vue
 * 時間別交差点侵入速度グラフ
 * 
 * 親コンポーネント
 * @/components/parts/aggregation/charts/daily/AggreDaily.vue
 */
// ==================================
// import
// ==================================
import { onBeforeMount, computed, watch, ref } from 'vue'

import { useAggregationDataStore } from '@/store/app'

import { ChartData } from '@/types/Interfaces'

import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale } from 'chart.js'
import { DateTime } from 'luxon'
// chart.js Library
import 'chartjs-adapter-luxon'
import { Line } from 'vue-chartjs'
// ChartJSのコンポーネント登録
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale)

// ==================================
// interface
// ==================================
interface Props {
  label: string[];
  toDate: DateTime;
}

// ==================================
// data
// ==================================
const dataStore = useAggregationDataStore()

const props = defineProps<Props>()

// グラフデータ
const chartData = ref<ChartData>({
  data: {
    labels: [],
    datasets: []
  },
  options: {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        min: 0
      }
    },    
    plugins: {
      title: {
        display: true,
        text: '交差点侵入速度'
      },
      legend: {
        labels: {
          boxWidth: 10
        }
      }
    }
  }
})

const chartContent = ref<any>({})

const isShowChart = ref<boolean>(false)

// ==================================
// watch
// ==================================
watch(() => chartContent.value, () => {
  updateChartData()
})

// ==================================
// computed
// ==================================
const graphData = computed(() => {
  return chartData.value.data
})

// ==================================
// hook
// ==================================
onBeforeMount(() => {
  chartContent.value = dataStore.$state.aggregationData.daily.axlApproachReportList
})

// ==================================
// method
// ==================================
/**
 * グラフ内容の更新
 */
const updateChartData = () => {
  isShowChart.value = false
  chartData.value.data.labels = props.label
  chartData.value.data.datasets = [
    {
      title: '当日平均速度',
      label: '当日平均速度: 最大' + chartContent.value[0].currentMaxAverage + 'km/h',
      borderColor: '#f00',
      fill: false,
      data: chartContent.value[0].currentAverage,
      tension: 0
    },
    {
      title: '前日平均速度',
      label: '前日平均速度: 最大' + chartContent.value[0].prevMaxAverage + 'km/h',
      borderColor: '#3c3',
      fill: false,
      data: chartContent.value[0].prevAverage,
      tension: 0
    },
    {
      title: '当日最高速度',
      label: '当日最高速度: 最大' + chartContent.value[1].currentTopAcceleration + 'km/h',
      borderColor: '#600',
      fill: false,
      data: chartContent.value[1].currentMaxAcceleration,
      tension: 0
    },
    {
      title: '前日最高速度',
      label: '前日最高速度: 最大' + chartContent.value[1].prevTopAcceleration + 'km/h',
      borderColor: '#060',
      fill: false,
      data: chartContent.value[1].prevMaxAcceleration,
      tension: 0
    }
  ]
  isShowChart.value = true
}
</script>
<template>
  <Line
    v-if="isShowChart"
    :data="graphData"
    :options="chartData.options"
  />  
</template>
