<script lang="ts" setup>
/**
 * SelectSensor.vue
 * センサー選択コンポーネント
 * 
 * 親コンポーネント
 * @/components/parts/realTime/VirtualVideo.vue
 * @/components/parts/virtual/VirtualVideo.vue
 */
// ==================================
// import
// ==================================
import { computed, ref } from 'vue'

import { getSensorSettingList } from '@/mixins/commonFunction'

import { SensorAndNodesInfo, SensorIdData } from '@/types/Interfaces'

// ==================================
// interface
// ==================================
interface Emits {
  (e: 'select-sensor-list-hand-over', value: SensorIdData[]): void;
}

// ==================================
// data
// ==================================
const items = ref<SensorAndNodesInfo[]>([])

const ischeck = ref<boolean>(false)

// ==================================
// computed
// ==================================
// 選択済みセンサー一覧表示
const selectSensorDisplay = computed(() => {
  let displaySelectSensorSort = []
  let sensorlist: SensorIdData[] = []
  if (items.value.length != 0) {
    for (const item of items.value) {
      for (const i of item.nodes) {
        if (i.checked) {
          displaySelectSensorSort.push(i.label)
          try {
            let sensorInfo = JSON.parse(i.id)
            sensorlist.push(sensorInfo)
          } catch {
            continue
          }
        }
      }
    }
  }
  // 選択済みのセンサー一覧情報をJson形式に変換して親コンポネントに渡す
  selectSensorListHandOver(sensorlist)
  return displaySelectSensorSort.sort()
})

// ==================================
// method
// ==================================
const emit = defineEmits<Emits>()
/**
 * 選択済みセンサー一覧情報を親コンポーネントに渡す
 * @param sensorlist - 選択済みセンサー一覧情報
 */
const selectSensorListHandOver = (sensorlist: SensorIdData[]) => {
  emit('select-sensor-list-hand-over', sensorlist)
}

/**
 * センサー初期設定
 */
const initSensorList = async () => {
  const sensorSettingList = getSensorSettingList(true)
  items.value = sensorSettingList
}

/**
 * 親要素選択時に子の要素を強制チェックする
 * @param nodes - 選択したセンサー情報
 */
const onUpdate = (nodes: SensorAndNodesInfo[]) => {
  ischeck.value = true
  for (const item of nodes) {
    if (item.checked) {
      for (const i of item.nodes) {
        i.checked = true
      }
    }
  }
  items.value = nodes
}

/**
 * センサー選択一覧表示
 */
const onclick = () => {
  ischeck.value = !ischeck.value
}

/**
 * センサー選択項目枠外押下時に選択項目を閉じる
 */
const closeVue3Tree = () => {
  ischeck.value = false
}

defineExpose({
  initSensorList,
})
</script>
<template>
  <span class="select-sensor">
    <div class="select-sensor__container">
      <v-text-field
        v-if="items.length != 0"
        v-model="selectSensorDisplay"
        variant="underlined"
        label="センサー選択"
        readonly
        @click="onclick"
      />
      <v-text-field
        v-else
        variant="underlined"
        label="選択できるセンサーが存在しません"
        readonly
      />
      <vue3-tree
        v-if="ischeck"
        :nodes="items"
        :use-checkbox="true"
        :use-icon="true"
        @update:nodes="onUpdate"
      />
    </div>
    <div
      v-if="ischeck"
      class="select-sensor__close-overlay"
      @click="closeVue3Tree"
    />
  </span>
</template>
<style lang="scss" scoped>
  .select-sensor {
    width: -webkit-fill-available;
    &__container {
      position: relative;
      height: auto !important;
      width: auto !important;
      z-index: 1000;
      color: white;
      background-color: rgba(128, 128, 128, 0.932);
      font-size: 1.125rem !important;
      line-height: 0.8rem;
      letter-spacing: 0.0073529412em !important;
      font-weight: 400;
      font-family: Roboto, sans-serif !important;
      margin-left: 30% !important;
    }
  }
</style>
<style lang="scss">
  .select-sensor {
    & .v-input__details {
      display: none !important;
    }
    & .v-input__control {
      background-color: #0041c0;
    }
    &__close-overlay {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 999;
    }
  }
</style>
