<script lang="ts" setup>
/**
 * Accident.vue
 * ヒヤリハット
 */
// ==================================
// import
// ==================================
import { ref, onMounted } from 'vue'

import ListOfAccident from '@/components/parts/accident/ListOfAccident.vue'
import NearMissList from '@/components/parts/accident/NearMissList.vue'
import SearchFrame from '@/components/parts/accident/SearchFrame.vue'
import VirtualVideo from '@/components/parts/accident/VirtualVideo.vue'
import ErrorDialog from '@/components/parts/common/ErrorDialog.vue'
import Loading from '@/components/parts/common/Loading.vue'
import TitleHeader from '@/components/parts/common/TitleHeader.vue'

import { useSelectPoleStore } from '@/store/app'

import { ErrorDialogInfo, TitleInfo } from '@/types/Interfaces'

import { MENU } from '@/setting/setting'

// ==================================
// data
// ==================================
// 選択ポール情報ストア
const selectPoleStore = useSelectPoleStore()

const virtualVideoComponent = ref()
const nearMissListComponent = ref()
const listOfAccidentComponent = ref()

// エラーダイアログ情報
const errorDialog = ref<ErrorDialogInfo>({
  message: '',
  title: '',
  isShow: false,
})

// 画面項目制御
const isLoading = ref<boolean>(false)

// タイトル
const titleinfo = ref<TitleInfo>({
  title: selectPoleStore.name + ' ヒヤリハット・事故情報',
  pointList: [],
  menuList: MENU.accidents,
  show: {
    realtime: true,
    multicamera: true,
    virtual: true,
    menu: true,
  },
})

// ==================================
// hook
// ==================================
onMounted(() => {
  addPoleMarker(selectPoleStore.latlng.lat, selectPoleStore.latlng.lng)
})

// ==================================
// method
// ==================================
/**
 * ポールマーカー設定
 * @param lat - 緯度
 * @param lng - 経度
 */
const addPoleMarker = (lat: number, lng: number) => {
  virtualVideoComponent.value.addPoleMarker(lat, lng)
}

/**
 * エラーダイアログを開く
 * @param title - エラータイトル
 * @param message - エラーメッセージ
 */
const openErrorDialog = (title: string, message: string) => {
  errorDialog.value.message = message
  errorDialog.value.title = title
  errorDialog.value.isShow = true
}

/**
 * エラーダイアログを閉じる
 */
const onClickCloseErrorDialog = () => {
  errorDialog.value.isShow = false
}

/**
 * ローディング処理開始
 */
const startLoading = () => {
  isLoading.value = true
}

/**
 * ローディング処理停止
 */
const stopLoading = () => {
  isLoading.value = false
}

/**
 * ヒヤリハット情報マーカー設定
 */
const addNearMissMarker = () => {
  virtualVideoComponent.value.addNearMissMarker()
}

/**
 * 事故発生情報マーカー設定
 */
const addAccidentMarker = () => {
  virtualVideoComponent.value.addAccidentMarker()
}

/**
 * ヒヤリハット情報一覧更新
 */
const updateNearMissesList = () => {
  nearMissListComponent.value.updateNearMissesList()
}

/**
 * 事故発生情報一覧更新
 */
const updateAccidentList = () => {
  listOfAccidentComponent.value.updateAccidentList()
}
</script>
<template>
  <TitleHeader :title-info="titleinfo" />
  <v-container
    class="accident"
    fluid
    fill-height
  >
    <v-row dense>
      <v-col cols="6">
        <VirtualVideo ref="virtualVideoComponent" />
      </v-col>
      <v-col cols="6">
        <SearchFrame
          @open-error-dialog="openErrorDialog"
          @update-accident-list="updateAccidentList"
          @update-near-misses-list="updateNearMissesList"
          @start-loading="startLoading"
          @stop-loading="stopLoading"
          @add-near-miss-marker="addNearMissMarker"
          @add-accident-marker="addAccidentMarker"
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="20">
        <NearMissList ref="nearMissListComponent" />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="20">
        <ListOfAccident ref="listOfAccidentComponent" />
      </v-col>
    </v-row>
    <Loading v-show="isLoading" />
    <ErrorDialog
      :error-dialog="errorDialog"
      @on-click-close-error-dialog="onClickCloseErrorDialog"
    />
  </v-container>
</template>
<style scoped>
  .accident {
    max-height: none;
  }
</style>
