<script lang="ts" setup>
/**
 * KinesisVideoStreamsWebrtc.vue
 * kinesis video stream webrtc 読み込みコンポーネント
 * 
 * 親コンポーネント
 * @/components/parts/multiCamera/MultiCameraFootage.vue
 * @/components/parts/realTime/CameraFootage.vue
 */
// ==================================
// import
// ==================================
import { ref, watch, onMounted, onBeforeUnmount,computed } from 'vue'

import AutoPlayVideo from '@/components/parts/common/AutoPlayVideo.vue'

import {
  streamingStart,
  streamingChkResp,
  streamingActiveNotify,
  streamingCheckRespCode,
} from '@/mixins/communicationFunction'

import { ChannelName } from '@/types/Interfaces'

import CognitoInfo from '@/setting/aws-cognito'
import { REAL_VIDEO, STREAMING } from '@/setting/setting'

import { SignalingClient } from 'amazon-kinesis-video-streams-webrtc'
import { Auth } from 'aws-amplify'
import AWS from 'aws-sdk'
import * as log from 'loglevel'
import { DateTime } from 'luxon'
import { v4 as uuidv4 } from 'uuid'

// ==================================
// interface
// ==================================
interface Props {
  camera: any;
  class: string;
}

interface Streaming {
  status: string | undefined;
  channel: string | undefined;
  respTimer: any;
}

interface AwsInfo {
  region: string;
  channelARN: any | undefined;
  role: Role;
  credentials: any | undefined;
  endpointsByProtocol: any | undefined;
  peerConnection: any | undefined;
  client: any | undefined;
  useTrickleICE: boolean;
  remoteView: any | undefined;
  remoteStream: any | undefined;
  isPlay: boolean;
  videoId: string;
}

// ==================================
// data
// ==================================
const props = defineProps<Props>()

enum Role {
  MASTER = 'MASTER',
  VIEWER = 'VIEWER',
}

const streaming = ref<Streaming>({
  status: undefined,
  channel: undefined,
  respTimer: [],
})

const aws = ref<AwsInfo>({
  region: CognitoInfo.region,
  channelARN: null,
  role: Role.VIEWER,
  credentials: null,
  endpointsByProtocol: null,
  peerConnection: null,
  client: null,
  useTrickleICE: true,
  remoteView: undefined,
  remoteStream: undefined,
  isPlay: true,
  videoId: DateTime.now().valueOf().toString() + '_' + uuidv4(),
})

const selectCamera = ref(props.camera)

// ==================================
// watch
// ==================================
watch(
  () => props.camera,
  async(camera) => {
    if (camera.length == 0) {
      allClear()
      aws.value.isPlay = false
    } else {
      changeCamera(camera)
    }
  }
)

// ==================================
// computed
// ==================================
const divClass = computed(() => {
  return props.class
})

const videClass = computed(() => {
  return props.class.split('-')[0]
})

// ==================================
// hook
// ==================================
onMounted(() => {
  if (selectCamera.value.length != 0) {
    startStreaming()
  } else {
    aws.value.isPlay = false
  }
})

onBeforeUnmount(async() => {
  allClear()
})

// ==================================
// method
// ==================================
// カメラ切り替え時
const changeCamera = async(selectCameraLatest: any) => {
  selectCamera.value = selectCameraLatest
  allClear()
  setTimeout(function () {
    startStreaming()
  }, REAL_VIDEO.waitInterval)
}

// ストリーミング開始通知
const startStreaming = async () => {
  streamingStart(selectCamera.value.poleId, selectCamera.value.sensorId)
    .then((res: ChannelName) => {
      streaming.value.channel = res.channel
      streaming.value.status = 'RespCheck'
      startStreamingTimer(streaming.value.channel, STREAMING.respChkInterval)
    })
    .catch((err) => {
      log.error('startStreaming error ' + err)
    })
}

// ストリーミング定期通知開始
const startStreamingTimer = async(channel: any, interval: any) => {
  await stopStreamingTimer()
  streaming.value.respTimer.push(
    setInterval(function () {
      timeoutStreamingTimer(channel)
    }, interval)
  )
}

// ストリーミング定期通知停止
const stopStreamingTimer = async () => {
  if (streaming.value.respTimer.length > 0) {
    for (const respTimer of streaming.value.respTimer) {
      clearInterval(respTimer)
    }
  }
}

// ストリーミングタイムアウト設定
const timeoutStreamingTimer = async (channel: any) => {
  if (streaming.value.status == 'RespCheck') {
    checkStreaming(channel)
  } else if (streaming.value.status == 'Started') {
    notifyStreaming(channel)
  } else {
    await stopStreamingTimer()
  }
}

// kinesisVideoが接続状態になった確認
const checkStreaming = async (channel: any) => {
  streamingChkResp(channel)
    .then(async (res: any) => {
      if (res.responseStatus == streamingCheckRespCode.ok) {
        stopStreamingTimer()
        streaming.value.status = 'Started'
        startWebrtcViewer()
        startStreamingTimer(streaming.value.channel, STREAMING.notifyInterval)
      } else if (res.responseStatus == streamingCheckRespCode.ng) {
        stopStreamingTimer()
        streaming.value.status = undefined
        streaming.value.channel = undefined
        aws.value.isPlay = false
      }
    })
    .catch(() => {
      stopStreamingTimer()
      streaming.value.status = undefined
      streaming.value.channel = undefined
      aws.value.isPlay = false
    })
}

// kinesisVideoに接続を維持するように通知
const notifyStreaming = async(channel: any) => {
  streamingActiveNotify(channel)
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    .then(() => {})
    .catch(async() => {
      allClear()
      aws.value.isPlay = false
    })
}

// 再生関連の処理をすべて停止状態に変更
const allClear = async () => {
  streaming.value.status = undefined
  streaming.value.channel = undefined
  await stopStreamingTimer()
  await endWebrtcViewer()
}

// =======================
// amazon-kinesis-video-streams-webrtcの設定
// =======================
// webrtc 通信開始
const startWebrtcViewer = async () => {
  try {
    aws.value.peerConnection = await connectSignalingChannel(aws.value.role)
    if (aws.value.peerConnection != null) {
      aws.value.client = await generateSignalingClientViewer(
        aws.value.peerConnection,
        aws.value.useTrickleICE
      )
    }
    if (aws.value.client) {
      aws.value.client.open()
    } else {
      await allClear()
      aws.value.isPlay = false
    }
  } catch (error) {
    await allClear()
    aws.value.isPlay = false
    log.error('startWebrtcViewer error ' + error)
  }
}

// webrtc 通信終了
const endWebrtcViewer = async () => {
  try {
    if (aws.value.client != null) {
      aws.value.client.close()
      aws.value.client = null
    }
    if (aws.value.peerConnection) {
      aws.value.peerConnection.close()
      aws.value.peerConnection = null
    }
    if (aws.value.remoteStream) {
      aws.value.remoteStream.getTracks().forEach((track: any) => track.stop())
      aws.value.remoteStream = null
    }
    if (aws.value.remoteView) {
      aws.value.remoteView.srcObject = null
    }
    aws.value.isPlay = true
  } catch (error) {
    log.error('endWebrtcViewer error ' + error)
  }
}

// シグナルチャンネル接続
const connectSignalingChannel = async (role: string) => {
  aws.value.credentials = await getCredential()

  if (!aws.value.credentials) return null

  const kvClient = new AWS.KinesisVideo({
    region: aws.value.region,
    credentials: aws.value.credentials,
  })

  await kvClient
    .describeSignalingChannel({ ChannelName: streaming.value.channel })
    .promise()
    .then((res: any) => {
      aws.value.channelARN = res.ChannelInfo.ChannelARN
    })
    .catch((err: any) => {
      log.error(err)
    })

  aws.value.endpointsByProtocol = await fetchEndpoints(
    kvClient,
    aws.value.channelARN,
    role
  )

  const kvsChannelsClient = new AWS.KinesisVideoSignalingChannels({
    region: aws.value.region,
    credentials: aws.value.credentials,
    endpoint: aws.value.endpointsByProtocol.HTTPS,
  })

  const iceServers = await fetchTURNServers(
    kvsChannelsClient,
    aws.value.channelARN,
    aws.value.region
  )

  return new RTCPeerConnection({ iceServers })
}

// 認証情報取得
const getCredential = async () => {
  try {
    await Auth.currentAuthenticatedUser()
    const credentials = await Auth.currentCredentials()
    return await Auth.essentialCredentials(credentials)
  } catch (err) {
    log.error('getCredential error ' + err)
    return null
  }
}

// エンドポイント取得
const fetchEndpoints = async (kvClient: any, channelARN: any, role: any) => {
  const getSignalingChannelEndpointResponse = await kvClient
    .getSignalingChannelEndpoint({
      ChannelARN: channelARN,
      SingleMasterChannelEndpointConfiguration: {
        Protocols: ['WSS', 'HTTPS'],
        Role: role,
      },
    })
    .promise()
  const endpointsByProtocol =
    getSignalingChannelEndpointResponse.ResourceEndpointList.reduce(
      (endpoints: any, endpoint: any) => {
        endpoints[endpoint.Protocol] = endpoint.ResourceEndpoint
        return endpoints
      },
      {}
    )
  return endpointsByProtocol
}

// TURNサーバ取得
const fetchTURNServers = async (
  kvsChannelsClient: any,
  channelARN: any,
  region: any
) => {
  const getIceServerConfigResponse = await kvsChannelsClient
    .getIceServerConfig({
      ChannelARN: channelARN,
    })
    .promise()

  const iceServers: any = [
    { urls: `stun:stun.kinesisvideo.${region}.amazonaws.com:443` },
  ]
  getIceServerConfigResponse.IceServerList.forEach((iceServer: any) =>
    iceServers.push({
      urls: iceServer.Uris,
      username: iceServer.Username,
      credential: iceServer.Password,
    })
  )
  return iceServers
}

// webビュワー生成
const generateSignalingClientViewer = async (
  peerConnection: any,
  useTrickleICE: any
) => {
  if (!aws.value.credentials) return null
  const signalingClient = new SignalingClient({
    channelARN: aws.value.channelARN,
    channelEndpoint: aws.value.endpointsByProtocol.WSS,
    clientId: `c${Date.now()}`,
    role: Role.VIEWER,
    region: aws.value.region,
    credentials: aws.value.credentials,
  })

  signalingClient.on('open', async () => {
    // log.info('[VIEWER] Connected to signaling service')
    // log.info('[VIEWER] Creating SDP offer')
    await peerConnection.setLocalDescription(
      await peerConnection.createOffer({
        offerToReceiveAudio: true,
        offerToReceiveVideo: true,
      })
    )
    // log.info('[VIEWER] Sending SDP offer')
    signalingClient.sendSdpOffer(peerConnection.localDescription)
  })
  signalingClient.on('sdpAnswer', async (answer: any) => {
    // log.info('[VIEWER] Received SDP answer')
    await peerConnection.setRemoteDescription(answer)
  })
  signalingClient.on('iceCandidate', (candidate: any) => {
    // log.info('[VIEWER] Received ICE candidate')
    peerConnection.addIceCandidate(candidate)
  })
  signalingClient.on('close', () => {
    // log.info('[VIEWER] Disconnected from signaling channel')
  })

  signalingClient.on('error', (error: any) => {
    log.error('[VIEWER] Signaling client error: ' + error)
  })

  // Send any ICE candidates to the other peer
  peerConnection.addEventListener(
    'icecandidate',
    ({ candidate }: { candidate: any }) => {
      if (candidate) {
        // log.info('[VIEWER] Generated ICE candidate')

        // When trickle ICE is enabled, send the ICE candidates as they are generated.
        if (useTrickleICE) {
          // log.info('[VIEWER] Sending ICE candidate')
          signalingClient.sendIceCandidate(candidate)
        }
      } else {
        // log.info('[VIEWER] All ICE candidates have been generated')

        // When trickle ICE is disabled, send the offer now that all the ICE candidates have ben generated.
        if (!useTrickleICE) {
          // log.info('[VIEWER] Sending SDP offer')
          signalingClient.sendSdpOffer(peerConnection.localDescription)
        }
      }
    }
  )

  // As remote tracks are received, add them to the remote view
  peerConnection.addEventListener('track', (event: any) => {
    // log.info('[VIEWER] Received remote track')
    aws.value.remoteView = document.getElementById(aws.value.videoId)
    if (!aws.value.remoteView.srcObject && event.streams) {
      aws.value.remoteStream = event.streams[0]
      aws.value.remoteView.srcObject = aws.value.remoteStream
    }
  })

  return signalingClient
}
</script>
<template>
  <div :class="divClass">
    <div class="conference-container__videos">
      <div class="conference-container__videos--remote">
        <div class="video">
          <AutoPlayVideo
            :video-id="aws.videoId"
            :is-play="aws.isPlay"
            :video-stream="aws.remoteStream"
            :class="videClass"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
  .real-video-container {
    background-color: black;
    height: 60vh;
    width: 100%;
    &__header {
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: 60vh;
      background-color: black;
      .md-button {
        transform: translateY(7px);
      }
      .md-icon.md-theme-default.md-icon-font {
        color: white;
      }
    }
    &__videos {
      position: relative;
      border: solid 1px #ffffff78;
      &--remote {
        position: relative;
      }
    }
    h3 {
      padding-left: 1rem;
      color: white;
    }
  }
  .multi-video-container {
    background-color: black;
    height: 38vh;
    width: 100%;
    &__header {
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: 38vh;
      background-color: black;
      .md-button {
        transform: translateY(7px);
      }
      .md-icon.md-theme-default.md-icon-font {
        color: white;
      }
    }
    &__videos {
      position: relative;
      border: solid 1px #ffffff78;
      &--remote {
        position: relative;
      }
    }
    h3 {
      padding-left: 1rem;
      color: white;
    }
  }
</style>
