<script lang="ts" setup>
/**
 * MacroAnalysisTable.vue
 * マクロ分析一覧画面
 * 
 * 親コンポーネント
 * @/views/MacroAnalysis.vue
 */
// ==================================
// import
// ==================================
import { shallowRef, ref } from 'vue'

import ErrorDialog from '@/components/parts/common/ErrorDialog.vue'
import Loading from '@/components/parts/common/Loading.vue'
import MacroAnalysisDetailInfoTable from '@/components/parts/macroAnalysis/MacroAnalysisDetailInfoTable.vue'
import MacroAnalysisHistgram from '@/components/parts/macroAnalysis/MacroAnalysisHistgram.vue'
import ModalMacroAnalysisHistgram from '@/components/parts/macroAnalysis/ModalMacroAnalysisHistgram.vue'

import { useMacroAnalysisInfoStore } from '@/store/app'

import { DIALOG_ERROR_INFO } from '@/mixins/commonFunction'
import { updateMacroAnalysisInfo, updateMacroAnalysisDetaiInfo } from '@/mixins/communicationFunction'

import { ErrorDialogInfo } from '@/types/Interfaces'

// ==================================
// interface
// ==================================
interface MacroTermData {
  number: number;
  avgSpeed: number;
  maxSpeed: number;
  mode: number;
  median: number;
  std: number;
  avgSpeedHist: number[];
  maxSpeedHist: number[];
}
interface MacroDiffData {
  avgSpeedHist: number[];
  maxSpeedHist: number[];
}

// ==================================
// data
// ==================================
const macroAnalysisInfoStore = useMacroAnalysisInfoStore()

/**
 * モーダル表示用コンポーネント
 */
const shallowState = shallowRef({
  prefix: {
    component: null,
    props: {}
  } as {
    component: any,
    props: any
  }
})

const dialog = ref<ErrorDialogInfo>({
  message: '',
  title: '',
  isShow: false
})

const sendData = ref({
  poleId: 0 as number,
  address: '' as string,
  aggregationPeriod: {
    start: '' as string,
    end: '' as string
  },
  comparisonPeriod: {
    start: '' as string,
    end: '' as string
  },
  directionValue: '' as string,
  ledValue: '' as string,
  aggregationTargetValue: '' as string,
  aggregationMethodValue: '' as string
})

const isViewDetailInfo = ref<boolean>(false)

const isLoading = ref<boolean>(false)

const isDisplay = ref<boolean>(false)

const isViewGraph = ref<boolean>(false)

// 期間Aデータ
const termA = ref({
  enter: {
    number: 0,
    avgSpeed: 0,
    maxSpeed: 0,
    mode: 0,
    median: 0,
    std: 0,
    avgSpeedHist: [],
    maxSpeedHist: []
  } as MacroTermData,
  stopLine: {
    number: 0,
    avgSpeed: 0,
    maxSpeed: 0,
    mode: 0,
    median: 0,
    std: 0,
    avgSpeedHist: [],
    maxSpeedHist: []
  } as MacroTermData,
  stopLineMentalStopLine: {
    number: 0,
    avgSpeed: 0,
    maxSpeed: 0,
    mode: 0,
    median: 0,
    std: 0,
    avgSpeedHist: [],
    maxSpeedHist: []
  } as MacroTermData,
  mentalStopLine: {
    number: 0,
    avgSpeed: 0,
    maxSpeed: 0,
    mode: 0,
    median: 0,
    std: 0,
    avgSpeedHist: [],
    maxSpeedHist: []
  } as MacroTermData
})
// 期間Bデータ
const termB = ref({
  enter: {
    number: 0,
    avgSpeed: 0,
    maxSpeed: 0,
    mode: 0,
    median: 0,
    std: 0,
    avgSpeedHist: [],
    maxSpeedHist: []
  } as MacroTermData,
  stopLine: {
    number: 0,
    avgSpeed: 0,
    maxSpeed: 0,
    mode: 0,
    median: 0,
    std: 0,
    avgSpeedHist: [],
    maxSpeedHist: []
  } as MacroTermData,
  stopLineMentalStopLine: {
    number: 0,
    avgSpeed: 0,
    maxSpeed: 0,
    mode: 0,
    median: 0,
    std: 0,
    avgSpeedHist: [],
    maxSpeedHist: []
  } as MacroTermData,
  mentalStopLine: {
    number: 0,
    avgSpeed: 0,
    maxSpeed: 0,
    mode: 0,
    median: 0,
    std: 0,
    avgSpeedHist: [],
    maxSpeedHist: []
  } as MacroTermData
})

// 差分データ
const diff = ref({
  enter: {
    avgSpeedHist: [],
    maxSpeedHist: []
  } as MacroDiffData,
  stopLine: {
    avgSpeedHist: [],
    maxSpeedHist: []
  } as MacroDiffData,
  stopLineMentalStopLine: {
    avgSpeedHist: [],
    maxSpeedHist: []
  } as MacroDiffData,
  mentalStopLine: {
    avgSpeedHist: [],
    maxSpeedHist: []
  } as MacroDiffData
})

// グラフ表示オプション
const avgSpeedGraphSetting = ref({
  xAxes: {
    scaleLabelDisplay: true,
    labelString: '平均速度'
  },
  yAxes: {
    scaleLabelDisplay: true,
    labelString: '台数'
  },
  termA: {
    backgroundColor: 'rgba(65, 158, 143, 0.8)'
  },
  termB: {
    backgroundColor: 'rgba(160, 158, 34, 0.8)'
  }
})
const avgSpeedHistGraphSetting = ref({
  xAxes: {
    scaleLabelDisplay: true,
    labelString: '平均速度'
  },
  yAxes: {
    scaleLabelDisplay: true,
    labelString: '差分'
  },
  title: {
    display: true,
    text: '期間Aから期間Bを引いた差分値'
  },
  backgroundColor: 'rgba(236, 97, 4, 0.8)'
})
const maxSpeedGraphSetting = ref({
  xAxes: {
    scaleLabelDisplay: true,
    labelString: '最高速度'
  },
  yAxes: {
    scaleLabelDisplay: true,
    labelString: '台数'
  },
  termA: {
    backgroundColor: 'rgba(65, 158, 143, 0.8)'
  },
  termB: {
    backgroundColor: 'rgba(160, 158, 34, 0.8)'
  }
})
const maxSpeedHistGraphSetting = ref({
  xAxes: {
    scaleLabelDisplay: true,
    labelString: '最高速度'
  },
  yAxes: {
    scaleLabelDisplay: true,
    labelString: '差分'
  },
  title: {
    display: true,
    text: '期間Aから期間Bを引いた差分値'
  },
  backgroundColor: 'rgba(236, 97, 4, 0.8)'
})
const sectionMaxBorder = ref({
  avgSpeedHist: 1,
  maxSpeedHist: 1,
  diff: {
    avg: {
      suggestedMax: 1,
      suggestedMin: 0,
      maxTicksLimit: 2
    },
    max: {
      suggestedMax: 1,
      suggestedMin: 0,
      maxTicksLimit: 2
    }
  }
})

// ==================================
// watch
// ==================================
// ==================================
// computed
// ==================================
// ==================================
// hook
// ==================================
// ==================================
// method
// ==================================
/**
 * 速度グラフの最大値を取得する
 * @param array1 - 速度時系列リスト
 * @returns 最大速度
 */
const getBorderMaxData = (array1: number[][]): number => {
  let tempArray = []
  for (const data of array1)
    for (const item of data) {
      tempArray.push(item)
    }
  return Math.max(...tempArray)
}

/**
 * ヒストグラムの境界値を取得する
 * @param array1 - 速度時系列リスト
 * @returns 境界値リスト
 */
const getDiffBorderData = (array1: number[][]): any => {
  let tempArray = [];
  for (const data of array1)
    for (const item of data) {
      tempArray.push(item)
    }
  let max = Math.max(...tempArray)
  let min = Math.min(...tempArray)

  // 初期値設定
  let suggestedMaxValue = 1
  let suggestedMinValue = 0
  let maxTicksLimit = 2

  // boarder範囲の作成
  if (max >= 1 && min >= 0) {
    suggestedMaxValue = max
    suggestedMinValue = 0
  } else if (max <= 0 && min < 0) {
    suggestedMaxValue = 0
    suggestedMinValue = min
  } else if (max == 0 && min == 0) {
    suggestedMaxValue = 1
    suggestedMinValue = 0
    maxTicksLimit = 2
  } else {
    suggestedMaxValue = max
    suggestedMinValue = min
    maxTicksLimit = 3
  }
  return {
    suggestedMax: suggestedMaxValue,
    suggestedMin: suggestedMinValue,
    maxTicksLimit: maxTicksLimit
  }
}

/**
 * フォームで指定されたパラメータをもとに、マクロ分析データを取得する
 * @param sendParams - パラメータ群
 */
const getData = async(sendParams: any) => {
  sendData.value = sendParams
  isLoading.value = true
  isViewGraph.value = false
  let promise = updateMacroAnalysisInfo(
    sendData.value.poleId,
    sendData.value.aggregationPeriod.start.replaceAll('-', '/') + ' 00:00:00',
    sendData.value.aggregationPeriod.end.replaceAll('-', '/') + ' 00:00:00',
    sendData.value.comparisonPeriod.start.replaceAll('-', '/') + ' 00:00:00',
    sendData.value.comparisonPeriod.end.replaceAll('-', '/') + ' 00:00:00',
    sendData.value.directionValue,
    sendData.value.ledValue,
    sendData.value.aggregationTargetValue,
    sendData.value.aggregationMethodValue
  )
  promise
    .then(() => {
      // データが存在するか判定
      if (macroAnalysisInfoStore.$state.macroAnalysisInfo.enter.number.length !== 0) {
        setData()
      } else {
        // 存在しない場合は空のデータをセットする
        clearData()
        dialog.value.title = ''
        dialog.value.message = DIALOG_ERROR_INFO.message.getErrorMacroAnalysisInfoNoContent
        dialog.value.isShow = true
      }
    })
    .then(() => {
      isViewGraph.value = true
      isLoading.value = false
    })
    .catch(() => {
      // 取得に失敗したらエラーメッセージをダイアログで表示する
      isViewGraph.value = true
      isLoading.value = false
      dialog.value.title = DIALOG_ERROR_INFO.title.getError
      dialog.value.message = DIALOG_ERROR_INFO.message.getErrorMacroAnalysisInfo
      dialog.value.isShow = true
    })
}

/**
 * 分析用データを設定する
 */
const setData = () => {
  // 入力元データをオブジェクトとして設定
  const enter = macroAnalysisInfoStore.$state.macroAnalysisInfo.enter
  const stopLine = macroAnalysisInfoStore.$state.macroAnalysisInfo.stopLine
  const stopLineMentalStopLine = macroAnalysisInfoStore.$state.macroAnalysisInfo.stopLineMentalStopLine
  const mentalStopLine = macroAnalysisInfoStore.$state.macroAnalysisInfo.mentalStopLine
  // データA
  termA.value = {
    // 進入路
    enter: {
      number: enter.number[0],
      avgSpeed: enter.avgSpeed[0],
      maxSpeed: enter.maxSpeed[0],
      mode: enter.mode[0],
      median: enter.median[0],
      std: enter.std[0],
      avgSpeedHist: Object.values(enter.avgHist[0]),
      maxSpeedHist: Object.values(enter.maxHist[0])
    },
    // 停止線データ
    stopLine: {
      number: stopLine.number[0],
      avgSpeed: stopLine.avgSpeed[0],
      maxSpeed: stopLine.maxSpeed[0],
      mode: stopLine.mode[0],
      median: stopLine.median[0],
      std: stopLine.std[0],
      avgSpeedHist: Object.values(stopLine.avgHist[0]),
      maxSpeedHist: Object.values(stopLine.maxHist[0])       
    },
    // 停止線～心的停止データ
    stopLineMentalStopLine: {
      number: stopLineMentalStopLine.number[0],
      avgSpeed: stopLineMentalStopLine.avgSpeed[0],
      maxSpeed: stopLineMentalStopLine.maxSpeed[0],
      mode: stopLineMentalStopLine.mode[0],
      median: stopLineMentalStopLine.median[0],
      std: stopLineMentalStopLine.std[0],
      avgSpeedHist: Object.values(stopLineMentalStopLine.avgHist[0]),
      maxSpeedHist: Object.values(stopLineMentalStopLine.maxHist[0]) 
    },
    // 心的停止データ
    mentalStopLine: {
      number: mentalStopLine.number[0],
      avgSpeed: mentalStopLine.avgSpeed[0],
      maxSpeed: mentalStopLine.maxSpeed[0],
      mode: mentalStopLine.mode[0],
      median: mentalStopLine.median[0],
      std: mentalStopLine.std[0],
      avgSpeedHist: Object.values(mentalStopLine.avgHist[0]),
      maxSpeedHist: Object.values(mentalStopLine.maxHist[0])       
    }
  }

  // データB
  termB.value = {
    // 進入路
    enter: {
      number: enter.number[1],
      avgSpeed: enter.avgSpeed[1],
      maxSpeed: enter.maxSpeed[1],
      mode: enter.mode[1],
      median: enter.median[1],
      std: enter.std[1],
      avgSpeedHist: Object.values(enter.avgHist[1]),
      maxSpeedHist: Object.values(enter.maxHist[1])
    },
    // 停止線データ
    stopLine: {
      number: stopLine.number[1],
      avgSpeed: stopLine.avgSpeed[1],
      maxSpeed: stopLine.maxSpeed[1],
      mode: stopLine.mode[1],
      median: stopLine.median[1],
      std: stopLine.std[1],
      avgSpeedHist: Object.values(stopLine.avgHist[1]),
      maxSpeedHist: Object.values(stopLine.maxHist[1])       
    },
    // 停止線～心的停止データ
    stopLineMentalStopLine: {
      number: stopLineMentalStopLine.number[1],
      avgSpeed: stopLineMentalStopLine.avgSpeed[1],
      maxSpeed: stopLineMentalStopLine.maxSpeed[1],
      mode: stopLineMentalStopLine.mode[1],
      median: stopLineMentalStopLine.median[1],
      std: stopLineMentalStopLine.std[1],
      avgSpeedHist: Object.values(stopLineMentalStopLine.avgHist[1]),
      maxSpeedHist: Object.values(stopLineMentalStopLine.maxHist[1]) 
    },
    // 心的停止データ
    mentalStopLine: {
      number: mentalStopLine.number[1],
      avgSpeed: mentalStopLine.avgSpeed[1],
      maxSpeed: mentalStopLine.maxSpeed[1],
      mode: mentalStopLine.mode[1],
      median: mentalStopLine.median[1],
      std: mentalStopLine.std[1],
      avgSpeedHist: Object.values(mentalStopLine.avgHist[1]),
      maxSpeedHist: Object.values(mentalStopLine.maxHist[1])       
    }
  }

  // 差分データ
  diff.value = {
    enter: {
      avgSpeedHist: Object.values(enter.avgHist[2]),
      maxSpeedHist: Object.values(enter.maxHist[2])      
    },
    stopLine: {
      avgSpeedHist: Object.values(stopLine.avgHist[2]),
      maxSpeedHist: Object.values(stopLine.maxHist[2])
    },
    stopLineMentalStopLine: {
      avgSpeedHist: Object.values(stopLineMentalStopLine.avgHist[2]),
      maxSpeedHist: Object.values(stopLineMentalStopLine.maxHist[2])      
    },
    mentalStopLine: {
      avgSpeedHist: Object.values(mentalStopLine.avgHist[2]),
      maxSpeedHist: Object.values(mentalStopLine.maxHist[2])      
    }
  }

  // border設定
  sectionMaxBorder.value.avgSpeedHist = getBorderMaxData([
    termA.value.enter.avgSpeedHist,
    termB.value.enter.avgSpeedHist,
    termA.value.stopLine.avgSpeedHist,
    termB.value.stopLine.avgSpeedHist,
    termA.value.stopLineMentalStopLine.avgSpeedHist,
    termB.value.stopLineMentalStopLine.avgSpeedHist,
    termA.value.mentalStopLine.avgSpeedHist,
    termB.value.mentalStopLine.avgSpeedHist
  ])

  sectionMaxBorder.value.maxSpeedHist = getBorderMaxData([
    termA.value.enter.maxSpeedHist,
    termB.value.enter.maxSpeedHist,
    termA.value.stopLine.maxSpeedHist,
    termB.value.stopLine.maxSpeedHist,
    termA.value.stopLineMentalStopLine.maxSpeedHist,
    termB.value.stopLineMentalStopLine.maxSpeedHist,
    termA.value.mentalStopLine.maxSpeedHist,
    termB.value.mentalStopLine.maxSpeedHist
  ])

  sectionMaxBorder.value.diff.avg = getDiffBorderData([
    diff.value.enter.avgSpeedHist,
    diff.value.stopLine.avgSpeedHist,
    diff.value.stopLineMentalStopLine.avgSpeedHist,
    diff.value.mentalStopLine.avgSpeedHist
  ])

  sectionMaxBorder.value.diff.max = getDiffBorderData([
    diff.value.enter.maxSpeedHist,
    diff.value.stopLine.maxSpeedHist,
    diff.value.stopLineMentalStopLine.maxSpeedHist,
    diff.value.mentalStopLine.maxSpeedHist
  ])
}

/**
 * 分析用データを初期化する
 */
const clearData = () => {
  // 初期化データ
  const defaultDataSet: MacroTermData = {
    number: 0,
    avgSpeed: 0,
    maxSpeed: 0,
    mode: 0,
    median: 0,
    std: 0,
    avgSpeedHist: [],
    maxSpeedHist: []    
  }
  const defaultDiffDataSet: MacroDiffData = {
    avgSpeedHist: [],
    maxSpeedHist: []     
  }

  termA.value = {
    enter: defaultDataSet,
    stopLine: defaultDataSet,
    stopLineMentalStopLine: defaultDataSet,
    mentalStopLine: defaultDataSet
  }
  termB.value = {
    enter: defaultDataSet,
    stopLine: defaultDataSet,
    stopLineMentalStopLine: defaultDataSet,
    mentalStopLine: defaultDataSet
  }
  diff.value = {
    enter: defaultDiffDataSet,
    stopLine: defaultDiffDataSet,
    stopLineMentalStopLine: defaultDiffDataSet,
    mentalStopLine: defaultDiffDataSet
  }
}

/**
 * クリックしたグラフをモーダル表示させる
 * @param component - グラフ表示コンポーネント
 * @param props - グラフ表示プロパティ
 */
const showModal = (component: any, props: any) => {
  if (component === 'MacroAnalysisHistgram') {
    shallowState.value.prefix.component = MacroAnalysisHistgram
  }
  shallowState.value.prefix.props = props
  isDisplay.value = true
}

/**
 * 対象のデータセクションのグラフを表示する
 * @param section - セクション名
 */
const viewSectionDetail = (section: string): void => {
  // データが未定義の場合はエラーメッセージを出して終了する
  if (sendData.value === void 0) {
    dialog.value = {
      title: '',
      message: 'データ読込が行われていません。\r\nデータ読込を行ってください',
      isShow: true
    }
    return
  }
  // データが存在していない場合はエラーメッセージを出して終了する
  if (termA.value.enter.number === void 0) {
    dialog.value = {
      title: '',
      message: '現在のパラメータ設定ではデータが存在していません \r\n再度パラメータを設定後 \r\nデータ読込を行ってください',
      isShow: true
    }
    return
  }
  // 分析詳細データを取得する
  isLoading.value = true
  const promise = updateMacroAnalysisDetaiInfo(
    section,
    sendData.value.poleId,
    sendData.value.aggregationPeriod.start.replaceAll('-', '/') + ' 00:00:00',
    sendData.value.aggregationPeriod.end.replaceAll('-', '/') + ' 00:00:00',
    sendData.value.comparisonPeriod.start.replaceAll('-', '/') + ' 00:00:00',
    sendData.value.comparisonPeriod.end.replaceAll('-', '/') + ' 00:00:00',
    sendData.value.directionValue,
    sendData.value.ledValue,
    sendData.value.aggregationTargetValue,
    sendData.value.aggregationMethodValue
  )
  promise
    .then(() => {
      isLoading.value = false
      // データが存在するか判定
      const detailInfo = macroAnalysisInfoStore.$state.macroAnalysisDetaiInfo
      // if (this.$store.macroAnalysisDetaiInfo.value.termA.number.length != 0) {
      if (detailInfo.termA.number.length !== 0) {
        // データが存在する場合マクロ分析詳細表示
        isViewDetailInfo.value = true
      } else {
        isViewDetailInfo.value = false
        dialog.value = {
          title: '',
          message: DIALOG_ERROR_INFO.message.getErrorMacroAnalysisDetailInfoNoContent,
          isShow: true
        }
      }
    })
    .catch(() => {
      isLoading.value = false
      isViewDetailInfo.value = false
      dialog.value = {
        title:  DIALOG_ERROR_INFO.title.getError,
        message: DIALOG_ERROR_INFO.message.getErrorMacroAnalysisDetailInfo,
        isShow: true
      }
    })
}

/**
 * 分析詳細データ画面を消去する
 */
const closeSectionDetail = () => {
  isViewDetailInfo.value = false
}

defineExpose({
  getData
})
</script>
<template>
  <div class="macro-analysis-table">
    <Loading v-show="isLoading" />
    <v-card class="macro-analysis-table__card-table">
      <v-table>
        <thead>
          <tr>
            <th class="macro-analysis-table__sub-label">
              区間
            </th>
            <th colspan="2">
              進入路
              <v-icon
                large
                color="white"
                @click="viewSectionDetail('enter')"
              >
                mdi-table-arrow-up
              </v-icon>
            </th>
            <th colspan="2">
              停止線
              <v-icon
                large
                color="white"
                @click="viewSectionDetail('stopLine')"
              >
                mdi-table-arrow-up
              </v-icon>
            </th>
            <th colspan="2">
              停止線~心的停止線
              <v-icon
                large
                color="white"
                @click="viewSectionDetail('stopLineMentalStopLine')"
              >
                mdi-table-arrow-up
              </v-icon>
            </th>
            <th colspan="2">
              心的停止線
              <v-icon
                large
                color="white"
                @click="viewSectionDetail('mentalStopLine')"
              >
                mdi-table-arrow-up
              </v-icon>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="macro-analysis-table__empty" />
            <!-- 進入路 -->
            <td class="macro-analysis-table__period-a">
              期間A
            </td>
            <td class="macro-analysis-table__period-b">
              期間B
            </td>
            <!-- 停止線 -->
            <td class="macro-analysis-table__period-a">
              期間A
            </td>
            <td class="macro-analysis-table__period-b">
              期間B
            </td>
            <!-- 停止線~心的停止線 -->
            <td class="macro-analysis-table__period-a">
              期間A
            </td>
            <td class="macro-analysis-table__period-b">
              期間B
            </td>
            <!-- 心的停止線 -->
            <td class="macro-analysis-table__period-a">
              期間A
            </td>
            <td class="macro-analysis-table__period-b">
              期間B
            </td>
          </tr>
          <tr>
            <td>走行台数[台]</td>
            <!-- 進入路 -->
            <td class="macro-analysis-table__period-a">
              {{ termA.enter.number }}
            </td>
            <td class="macro-analysis-table__period-b">
              {{ termB.enter.number }}
            </td>
            <!-- 停止線 -->
            <td class="macro-analysis-table__period-a">
              {{ termA.stopLine.number }}
            </td>
            <td class="macro-analysis-table__period-b">
              {{ termB.stopLine.number }}
            </td>
            <!-- 停止線~心的停止線 -->
            <td class="macro-analysis-table__period-a">
              {{ termA.stopLineMentalStopLine.number }}
            </td>
            <td class="macro-analysis-table__period-b">
              {{ termB.stopLineMentalStopLine.number }}
            </td>
            <!-- 心的停止線 -->
            <td class="macro-analysis-table__period-a">
              {{ termA.mentalStopLine.number }}
            </td>
            <td class="macro-analysis-table__period-b">
              {{ termB.mentalStopLine.number }}
            </td>
          </tr>
          <tr>
            <td>平均速度[km/h]</td>
            <!-- 進入路 -->
            <td class="macro-analysis-table__period-a">
              {{ termA.enter.avgSpeed }}
            </td>
            <td class="macro-analysis-table__period-b">
              {{ termB.enter.avgSpeed }}
            </td>
            <!-- 停止線 -->
            <td class="macro-analysis-table__period-a">
              {{ termA.stopLine.avgSpeed }}
            </td>
            <td class="macro-analysis-table__period-b">
              {{ termB.stopLine.avgSpeed }}
            </td>
            <!-- 停止線~心的停止線 -->
            <td class="macro-analysis-table__period-a">
              {{ termA.stopLineMentalStopLine.avgSpeed }}
            </td>
            <td class="macro-analysis-table__period-b">
              {{ termB.stopLineMentalStopLine.avgSpeed }}
            </td>
            <!-- 心的停止線 -->
            <td class="macro-analysis-table__period-a">
              {{ termA.mentalStopLine.avgSpeed }}
            </td>
            <td class="macro-analysis-table__period-b">
              {{ termB.mentalStopLine.avgSpeed }}
            </td>
          </tr>
          <tr>
            <td>最高速度[km/h]</td>
            <!-- 進入路 -->
            <td class="macro-analysis-table__period-a">
              {{ termA.enter.maxSpeed }}
            </td>
            <td class="macro-analysis-table__period-b">
              {{ termB.enter.maxSpeed }}
            </td>
            <!-- 停止線 -->
            <td class="macro-analysis-table__period-a">
              {{ termA.stopLine.maxSpeed }}
            </td>
            <td class="macro-analysis-table__period-b">
              {{ termB.stopLine.maxSpeed }}
            </td>
            <!-- 停止線~心的停止線 -->
            <td class="macro-analysis-table__period-a">
              {{ termA.stopLineMentalStopLine.maxSpeed }}
            </td>
            <td class="macro-analysis-table__period-b">
              {{ termB.stopLineMentalStopLine.maxSpeed }}
            </td>
            <!-- 心的停止線 -->
            <td class="macro-analysis-table__period-a">
              {{ termA.mentalStopLine.maxSpeed }}
            </td>
            <td class="macro-analysis-table__period-b">
              {{ termB.mentalStopLine.maxSpeed }}
            </td>
          </tr>
          <tr>
            <td>最頻値</td>
            <!-- 進入路 -->
            <td class="macro-analysis-table__period-a">
              {{ termA.enter.mode }}
            </td>
            <td class="macro-analysis-table__period-b">
              {{ termB.enter.mode }}
            </td>
            <!-- 停止線 -->
            <td class="macro-analysis-table__period-a">
              {{ termA.stopLine.mode }}
            </td>
            <td class="macro-analysis-table__period-b">
              {{ termB.stopLine.mode }}
            </td>
            <!-- 停止線~心的停止線 -->
            <td class="macro-analysis-table__period-a">
              {{ termA.stopLineMentalStopLine.mode }}
            </td>
            <td class="macro-analysis-table__period-b">
              {{ termB.stopLineMentalStopLine.mode }}
            </td>
            <!-- 心的停止線 -->
            <td class="macro-analysis-table__period-a">
              {{ termA.mentalStopLine.mode }}
            </td>
            <td class="macro-analysis-table__period-b">
              {{ termB.mentalStopLine.mode }}
            </td>
          </tr>
          <tr>
            <td>中央値</td>
            <!-- 進入路 -->
            <td class="macro-analysis-table__period-a">
              {{ termA.enter.median }}
            </td>
            <td class="macro-analysis-table__period-b">
              {{ termB.enter.median }}
            </td>
            <!-- 停止線 -->
            <td class="macro-analysis-table__period-a">
              {{ termA.stopLine.median }}
            </td>
            <td class="macro-analysis-table__period-b">
              {{ termB.stopLine.median }}
            </td>
            <!-- 停止線~心的停止線 -->
            <td class="macro-analysis-table__period-a">
              {{ termA.stopLineMentalStopLine.median }}
            </td>
            <td class="macro-analysis-table__period-b">
              {{ termB.stopLineMentalStopLine.median }}
            </td>
            <!-- 心的停止線 -->
            <td class="macro-analysis-table__period-a">
              {{ termA.mentalStopLine.median }}
            </td>
            <td class="macro-analysis-table__period-b">
              {{ termB.mentalStopLine.median }}
            </td>
          </tr>
          <tr>
            <td>標準偏差[km/h]</td>
            <!-- 進入路 -->
            <td class="macro-analysis-table__period-a">
              {{ termA.enter.std }}
            </td>
            <td class="macro-analysis-table__period-b">
              {{ termB.enter.std }}
            </td>
            <!-- 停止線 -->
            <td class="macro-analysis-table__period-a">
              {{ termA.stopLine.std }}
            </td>
            <td class="macro-analysis-table__period-b">
              {{ termB.stopLine.std }}
            </td>
            <!-- 停止線~心的停止線 -->
            <td class="macro-analysis-table__period-a">
              {{ termA.stopLineMentalStopLine.std }}
            </td>
            <td class="macro-analysis-table__period-b">
              {{ termB.stopLineMentalStopLine.std }}
            </td>
            <!-- 心的停止線 -->
            <td class="macro-analysis-table__period-a">
              {{ termA.mentalStopLine.std }}
            </td>
            <td class="macro-analysis-table__period-b">
              {{ termB.mentalStopLine.std }}
            </td>
          </tr>
          <tr>
            <td class="macro-analysis-table__sub-label">
              平均速度グラフ
            </td>
            <!-- 進入路 -->
            <td class="macro-analysis-table__period-a">
              <v-card class="macro-analysis-table__card-graph">
                <MacroAnalysisHistgram
                  v-if="isViewGraph"
                  label-name="平均速度"
                  :x-axes="avgSpeedGraphSetting.xAxes"
                  :y-axes="avgSpeedGraphSetting.yAxes"
                  :background-color="avgSpeedGraphSetting.termA.backgroundColor"
                  :data="termA.enter.avgSpeedHist"
                  :max="sectionMaxBorder.avgSpeedHist"
                  :is-suggested="true"
                  @show-modal="showModal"
                />
              </v-card>
            </td>
            <td class="macro-analysis-table__period-b">
              <v-card class="macro-analysis-table__card-graph">
                <MacroAnalysisHistgram
                  v-if="isViewGraph"
                  :x-axes="avgSpeedGraphSetting.xAxes"
                  :y-axes="avgSpeedGraphSetting.yAxes"
                  :background-color="avgSpeedGraphSetting.termB.backgroundColor"
                  :data="termB.enter.avgSpeedHist"
                  :max="sectionMaxBorder.avgSpeedHist"
                  :is-suggested="true"
                  @show-modal="showModal"
                />
              </v-card>
            </td>
            <!-- 停止線 -->
            <td class="macro-analysis-table__period-a">
              <v-card class="macro-analysis-table__card-graph">
                <MacroAnalysisHistgram
                  v-if="isViewGraph"
                  :x-axes="avgSpeedGraphSetting.xAxes"
                  :y-axes="avgSpeedGraphSetting.yAxes"
                  :background-color="avgSpeedGraphSetting.termA.backgroundColor"
                  :data="termA.stopLine.avgSpeedHist"
                  :max="sectionMaxBorder.avgSpeedHist"
                  :is-suggested="true"
                  @show-modal="showModal"
                />
              </v-card>
            </td>
            <td class="macro-analysis-table__period-b">
              <v-card class="macro-analysis-table__card-graph">
                <MacroAnalysisHistgram
                  v-if="isViewGraph"
                  :x-axes="avgSpeedGraphSetting.xAxes"
                  :y-axes="avgSpeedGraphSetting.yAxes"
                  :background-color="avgSpeedGraphSetting.termB.backgroundColor"
                  :data="termB.stopLine.avgSpeedHist"
                  :max="sectionMaxBorder.avgSpeedHist"
                  :is-suggested="true"
                  @show-modal="showModal"
                />
              </v-card>
            </td>
            <!-- 停止線~心的停止線 -->
            <td class="macro-analysis-table__period-a">
              <v-card class="macro-analysis-table__card-graph">
                <MacroAnalysisHistgram
                  v-if="isViewGraph"
                  :x-axes="avgSpeedGraphSetting.xAxes"
                  :y-axes="avgSpeedGraphSetting.yAxes"
                  :background-color="avgSpeedGraphSetting.termA.backgroundColor"
                  :data="termA.stopLineMentalStopLine.avgSpeedHist"
                  :max="sectionMaxBorder.avgSpeedHist"
                  :is-suggested="true"
                  @show-modal="showModal"
                />
              </v-card>
            </td>
            <td class="macro-analysis-table__period-b">
              <v-card class="macro-analysis-table__card-graph">
                <MacroAnalysisHistgram
                  v-if="isViewGraph"
                  :x-axes="avgSpeedGraphSetting.xAxes"
                  :y-axes="avgSpeedGraphSetting.yAxes"
                  :background-color="avgSpeedGraphSetting.termB.backgroundColor"
                  :data="termB.stopLineMentalStopLine.avgSpeedHist"
                  :max="sectionMaxBorder.avgSpeedHist"
                  :is-suggested="true"
                  @show-modal="showModal"
                />
              </v-card>
            </td>
            <!-- 心的停止線 -->
            <td class="macro-analysis-table__period-a">
              <v-card class="macro-analysis-table__card-graph">
                <MacroAnalysisHistgram
                  v-if="isViewGraph"
                  :x-axes="avgSpeedGraphSetting.xAxes"
                  :y-axes="avgSpeedGraphSetting.yAxes"
                  :background-color="avgSpeedGraphSetting.termA.backgroundColor"
                  :data="termA.mentalStopLine.avgSpeedHist"
                  :max="sectionMaxBorder.avgSpeedHist"
                  :is-suggested="true"
                  @show-modal="showModal"
                />
              </v-card>
            </td>
            <td class="macro-analysis-table__period-b">
              <v-card class="macro-analysis-table__card-graph">
                <MacroAnalysisHistgram
                  v-if="isViewGraph"
                  :x-axes="avgSpeedGraphSetting.xAxes"
                  :y-axes="avgSpeedGraphSetting.yAxes"
                  :background-color="avgSpeedGraphSetting.termB.backgroundColor"
                  :data="termB.mentalStopLine.avgSpeedHist"
                  :max="sectionMaxBorder.avgSpeedHist"
                  :is-suggested="true"
                  @show-modal="showModal"
                />
              </v-card>
            </td>
          </tr>
          <tr>
            <td>平均速度ヒストグラム</td>
            <!-- 進入路 -->
            <td
              colspan="2"
              class="macro-analysis-table__period-a"
            >
              <v-card class="macro-analysis-table__card-graph">
                <MacroAnalysisHistgram
                  v-if="isViewGraph"
                  :x-axes="avgSpeedHistGraphSetting.xAxes"
                  :y-axes="avgSpeedHistGraphSetting.yAxes"
                  :background-color="avgSpeedHistGraphSetting.backgroundColor"
                  :title="avgSpeedHistGraphSetting.title"
                  :data="diff.enter.avgSpeedHist"
                  :is-suggested="true"
                  :max="sectionMaxBorder.diff.avg.suggestedMax"
                  :min="sectionMaxBorder.diff.avg.suggestedMin"
                  :max-ticks-limit="sectionMaxBorder.diff.avg.maxTicksLimit"
                  @show-modal="showModal"
                />
              </v-card>
            </td>
            <!-- 停止線 -->
            <td
              colspan="2"
              class="macro-analysis-table__period-a"
            >
              <v-card class="macro-analysis-table__card-graph">
                <MacroAnalysisHistgram
                  v-if="isViewGraph"
                  :x-axes="avgSpeedHistGraphSetting.xAxes"
                  :y-axes="avgSpeedHistGraphSetting.yAxes"
                  :background-color="avgSpeedHistGraphSetting.backgroundColor"
                  :title="avgSpeedHistGraphSetting.title"
                  :data="diff.stopLine.avgSpeedHist"
                  :is-suggested="true"
                  :max="sectionMaxBorder.diff.avg.suggestedMax"
                  :min="sectionMaxBorder.diff.avg.suggestedMin"
                  :max-ticks-limit="sectionMaxBorder.diff.avg.maxTicksLimit"
                  @show-modal="showModal"
                />
              </v-card>
            </td>
            <!-- 停止線~心的停止線 -->
            <td
              colspan="2"
              class="macro-analysis-table__period-a"
            >
              <v-card class="macro-analysis-table__card-graph">
                <MacroAnalysisHistgram
                  v-if="isViewGraph"
                  :x-axes="avgSpeedHistGraphSetting.xAxes"
                  :y-axes="avgSpeedHistGraphSetting.yAxes"
                  :background-color="avgSpeedHistGraphSetting.backgroundColor"
                  :title="avgSpeedHistGraphSetting.title"
                  :data="diff.stopLineMentalStopLine.avgSpeedHist"
                  :is-suggested="true"
                  :max="sectionMaxBorder.diff.avg.suggestedMax"
                  :min="sectionMaxBorder.diff.avg.suggestedMin"
                  :max-ticks-limit="sectionMaxBorder.diff.avg.maxTicksLimit"
                  @show-modal="showModal"
                />
              </v-card>
            </td>
            <!-- 心的停止線 -->
            <td
              colspan="2"
              class="macro-analysis-table__period-a"
            >
              <v-card class="macro-analysis-table__card-graph">
                <MacroAnalysisHistgram
                  v-if="isViewGraph"
                  :x-axes="avgSpeedHistGraphSetting.xAxes"
                  :y-axes="avgSpeedHistGraphSetting.yAxes"
                  :background-color="avgSpeedHistGraphSetting.backgroundColor"
                  :title="avgSpeedHistGraphSetting.title"
                  :data="diff.mentalStopLine.avgSpeedHist"
                  :is-suggested="true"
                  :max="sectionMaxBorder.diff.avg.suggestedMax"
                  :min="sectionMaxBorder.diff.avg.suggestedMin"
                  :max-ticks-limit="sectionMaxBorder.diff.avg.maxTicksLimit"
                  @show-modal="showModal"
                />
              </v-card>
            </td>
          </tr>
          <tr>
            <td>最高速度グラフ</td>
            <!-- 進入路 -->
            <td class="macro-analysis-table__period-a">
              <v-card class="macro-analysis-table__card-graph">
                <MacroAnalysisHistgram
                  v-if="isViewGraph"
                  :x-axes="maxSpeedGraphSetting.xAxes"
                  :y-axes="maxSpeedGraphSetting.yAxes"
                  :background-color="maxSpeedGraphSetting.termA.backgroundColor"
                  :data="termA.enter.maxSpeedHist"
                  :max="sectionMaxBorder.maxSpeedHist"
                  :is-suggested="true"
                  @show-modal="showModal"
                />
              </v-card>
            </td>
            <td class="macro-analysis-table__period-b">
              <v-card class="macro-analysis-table__card-graph">
                <MacroAnalysisHistgram
                  v-if="isViewGraph"
                  :x-axes="maxSpeedGraphSetting.xAxes"
                  :y-axes="maxSpeedGraphSetting.yAxes"
                  :background-color="maxSpeedGraphSetting.termB.backgroundColor"
                  :data="termB.enter.maxSpeedHist"
                  :max="sectionMaxBorder.maxSpeedHist"
                  :is-suggested="true"
                  @show-modal="showModal"
                />
              </v-card>
            </td>
            <!-- 停止線 -->
            <td class="macro-analysis-table__period-a">
              <v-card class="macro-analysis-table__card-graph">
                <MacroAnalysisHistgram
                  v-if="isViewGraph"
                  :x-axes="maxSpeedGraphSetting.xAxes"
                  :y-axes="maxSpeedGraphSetting.yAxes"
                  :background-color="maxSpeedGraphSetting.termA.backgroundColor"
                  :data="termA.stopLine.maxSpeedHist"
                  :max="sectionMaxBorder.maxSpeedHist"
                  :is-suggested="true"
                  @show-modal="showModal"
                />
              </v-card>
            </td>
            <td class="macro-analysis-table__period-b">
              <v-card class="macro-analysis-table__card-graph">
                <MacroAnalysisHistgram
                  v-if="isViewGraph"
                  :x-axes="maxSpeedGraphSetting.xAxes"
                  :y-axes="maxSpeedGraphSetting.yAxes"
                  :background-color="maxSpeedGraphSetting.termB.backgroundColor"
                  :data="termB.stopLine.maxSpeedHist"
                  :max="sectionMaxBorder.maxSpeedHist"
                  :is-suggested="true"
                  @show-modal="showModal"
                />
              </v-card>
            </td>
            <!-- 停止線~心的停止線 -->
            <td class="macro-analysis-table__period-a">
              <v-card class="macro-analysis-table__card-graph">
                <MacroAnalysisHistgram
                  v-if="isViewGraph"
                  :x-axes="maxSpeedGraphSetting.xAxes"
                  :y-axes="maxSpeedGraphSetting.yAxes"
                  :background-color="maxSpeedGraphSetting.termA.backgroundColor"
                  :data="termA.stopLineMentalStopLine.maxSpeedHist"
                  :max="sectionMaxBorder.maxSpeedHist"
                  :is-suggested="true"
                  @show-modal="showModal"
                />
              </v-card>
            </td>
            <td class="macro-analysis-table__period-b">
              <v-card class="macro-analysis-table__card-graph">
                <MacroAnalysisHistgram
                  v-if="isViewGraph"
                  :x-axes="maxSpeedGraphSetting.xAxes"
                  :y-axes="maxSpeedGraphSetting.yAxes"
                  :background-color="maxSpeedGraphSetting.termB.backgroundColor"
                  :data="termB.stopLineMentalStopLine.maxSpeedHist"
                  :max="sectionMaxBorder.maxSpeedHist"
                  :is-suggested="true"
                  @show-modal="showModal"
                />
              </v-card>
            </td>
            <!-- 心的停止線 -->
            <td class="macro-analysis-table__period-a">
              <v-card class="macro-analysis-table__card-graph">
                <MacroAnalysisHistgram
                  v-if="isViewGraph"
                  :x-axes="maxSpeedGraphSetting.xAxes"
                  :y-axes="maxSpeedGraphSetting.yAxes"
                  :background-color="maxSpeedGraphSetting.termA.backgroundColor"
                  :data="termA.mentalStopLine.maxSpeedHist"
                  :max="sectionMaxBorder.maxSpeedHist"
                  :is-suggested="true"
                  @show-modal="showModal"
                />
              </v-card>
            </td>
            <td class="macro-analysis-table__period-b">
              <v-card class="macro-analysis-table__card-graph">
                <MacroAnalysisHistgram
                  v-if="isViewGraph"
                  :x-axes="maxSpeedGraphSetting.xAxes"
                  :y-axes="maxSpeedGraphSetting.yAxes"
                  :background-color="maxSpeedGraphSetting.termB.backgroundColor"
                  :data="termB.mentalStopLine.maxSpeedHist"
                  :max="sectionMaxBorder.maxSpeedHist"
                  :is-suggested="true"
                  @show-modal="showModal"
                />
              </v-card>
            </td>
          </tr>
          <tr>
            <td>最高速度ヒストグラム</td>
            <!-- 進入路 -->
            <td
              colspan="2"
              class="macro-analysis-table__period-a"
            >
              <v-card class="macro-analysis-table__card-graph">
                <MacroAnalysisHistgram
                  v-if="isViewGraph"
                  :x-axes="maxSpeedHistGraphSetting.xAxes"
                  :y-axes="maxSpeedHistGraphSetting.yAxes"
                  :background-color="maxSpeedHistGraphSetting.backgroundColor"
                  :title="maxSpeedHistGraphSetting.title"
                  :data="diff.enter.maxSpeedHist"
                  :is-suggested="true"
                  :max="sectionMaxBorder.diff.max.suggestedMax"
                  :min="sectionMaxBorder.diff.max.suggestedMin"
                  :max-ticks-limit="sectionMaxBorder.diff.max.maxTicksLimit"
                  @show-modal="showModal"
                />
              </v-card>
            </td>
            <!-- 停止線 -->
            <td
              colspan="2"
              class="macro-analysis-table__period-a"
            >
              <v-card class="macro-analysis-table__card-graph">
                <MacroAnalysisHistgram
                  v-if="isViewGraph"
                  :x-axes="maxSpeedHistGraphSetting.xAxes"
                  :y-axes="maxSpeedHistGraphSetting.yAxes"
                  :background-color="maxSpeedHistGraphSetting.backgroundColor"
                  :title="maxSpeedHistGraphSetting.title"
                  :data="diff.stopLine.maxSpeedHist"
                  :is-suggested="true"
                  :max="sectionMaxBorder.diff.max.suggestedMax"
                  :min="sectionMaxBorder.diff.max.suggestedMin"
                  :max-ticks-limit="sectionMaxBorder.diff.max.maxTicksLimit"
                  @show-modal="showModal"
                />
              </v-card>
            </td>
            <!-- 停止線~心的停止線 -->
            <td
              colspan="2"
              class="macro-analysis-table__period-a"
            >
              <v-card class="macro-analysis-table__card-graph">
                <MacroAnalysisHistgram
                  v-if="isViewGraph"
                  :x-axes="maxSpeedHistGraphSetting.xAxes"
                  :y-axes="maxSpeedHistGraphSetting.yAxes"
                  :background-color="maxSpeedHistGraphSetting.backgroundColor"
                  :title="maxSpeedHistGraphSetting.title"
                  :data="diff.stopLineMentalStopLine.maxSpeedHist"
                  :is-suggested="true"
                  :max="sectionMaxBorder.diff.max.suggestedMax"
                  :min="sectionMaxBorder.diff.max.suggestedMin"
                  :max-ticks-limit="sectionMaxBorder.diff.max.maxTicksLimit"
                  @show-modal="showModal"
                />
              </v-card>
            </td>
            <!-- 心的停止線 -->
            <td
              colspan="2"
              class="macro-analysis-table__period-a"
            >
              <v-card class="macro-analysis-table__card-graph">
                <MacroAnalysisHistgram
                  v-if="isViewGraph"
                  :x-axes="maxSpeedHistGraphSetting.xAxes"
                  :y-axes="maxSpeedHistGraphSetting.yAxes"
                  :background-color="maxSpeedHistGraphSetting.backgroundColor"
                  :title="maxSpeedHistGraphSetting.title"
                  :data="diff.mentalStopLine.maxSpeedHist"
                  :is-suggested="true"
                  :max="sectionMaxBorder.diff.max.suggestedMax"
                  :min="sectionMaxBorder.diff.max.suggestedMin"
                  :max-ticks-limit="sectionMaxBorder.diff.max.maxTicksLimit"
                  @show-modal="showModal"
                />
              </v-card>
            </td>
          </tr>
        </tbody>
      </v-table>
      <!-- 分析詳細 -->
      <MacroAnalysisDetailInfoTable
        v-if="isViewDetailInfo"
        @close-section-detail="closeSectionDetail"
      />
    </v-card>
    <!-- モーダル表示 -->
    <ModalMacroAnalysisHistgram
      :value="isDisplay"
      text="errorText"
      :prefix="shallowState.prefix"     
      class="macro-analysis-table__modal-histgram"
      @input="isDisplay = $event"
    />
    <ErrorDialog :error-dialog="dialog" />
  </div>
</template>
<style lang="scss" scoped>
  .macro-analysis-table {
    position: relative;
    top: 20%;
    text-align: center;
    left: 1vw;
    min-width: 1800px;
    &__card-table {
      width: 100%;
    }
    &__empty {
      background: linear-gradient(#4334537a, #225588);
    }
    &__period-a {
      background-color: rgba(65, 158, 143, 0.4);
      width: 10%;
    }
    &__period-b {
      background-color: rgba(160, 158, 34, 0.3);
      width: 10%;
    }
    &__card-graph {
      height: 100%;
      cursor: pointer;
      outline: none;
    }
    &__modal-histgram {
      height: 50vh;
    }
  }
  table {
    width: 100%;
    height: 100%;
    border-collapse: separate;
    border-spacing: 0;
    thead {
      tr {
        th {
          text-align: center;
          color: white;
          background: linear-gradient(#4334537a, #225588);
          border-left: 1px solid #3c6690;
          border-top: 1px solid #3c6690;
          border-bottom: 1px solid #3c6690;
          width: 20%;       
          :last-child {
            border-radius: 0 5px 0 0;
            border-right: 1px solid #3c6690;
          }
          &.macro-analysis-table__sub-label {
            min-width: 200px;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          text-align: center;
          border-left: 0.5px solid #0d0d0e;
          border-bottom: 0.5px solid #0d0d0e;
          border-top: none;
          padding: 5px 0;
          :last-child {
            border-right: 1px solid #0d0d0e;
          }      
        }
        :last-child {
          td {
            :first-child {
              border-radius: 0 0 0 5px;
            }
            :last-child {
              border-radius: 0 0 5px 0;
            }
          }
        }
      }
    }
  }
</style>
