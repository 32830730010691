<script lang="ts" setup>
/**
 * VirtualVideo.vue
 * バーチャル映像コンポーネント
 * 
 * 親コンポーネント
 * @/views/Virtual.vue
 * 
 */
// ==================================
// import
// ==================================
import { ref, watch } from 'vue'

import SelectSensor from '@/components/parts/common/SelectSensor.vue'
import VirtualMap from '@/components/parts/common/VirtualMap.vue'

import { LatLng, SensorIdData } from '@/types/Interfaces'

import { DateTime } from 'luxon'

// ==================================
// interface
// ==================================
interface Emits {
  (e: 'select-sensor-list-hand-over', value: any): void;
  (e: 'play-pouse'): void;
  (e: 'play-realtime-mode'): void;
  (e: 'play-virtual-mode', time: Date): void;
  (e: 'set-error-dialog', title: string, message: string): void;
}
// ==================================
// data
// ==================================
const selectSensorList = ref<SensorIdData[]>([])

const selectSensorComponent = ref()
const virtualMapComponent = ref()

const datetime = ref<Date | undefined | null>()

const isDataAcquisition = ref<boolean>(true)

// ==================================
// watch
// ==================================
watch(() => datetime.value, val => {
  // 再生日時に任意の日時が入力されたら、バリデーションチェックを実行する
  if (val !== void 0 && val !== null) {
    checkDateTime(val)
  }
})

// ==================================
// method
// ==================================
const emit = defineEmits<Emits>()

/**
 * 再生日時のチェック
 * @param date - 入力された再生日時
 */
const checkDateTime = (date: Date): void => {
  const now = DateTime.now()
  const target = DateTime.fromJSDate(date)
  // アクセス時点よりも未来の日時を指定したらエラーを返す
  if (now.diff(target).milliseconds < 0) {
    emit('set-error-dialog', '不正な再生日時', '再生日時に未来の日時を指定できません')
  }
}

/**
 * 日付更新
 * @param updateScondValue - 更新秒数
 * @return 更新した日付データ
 */
const updateTime = (updateScondValue: number): Date | null | undefined => {
  // 入力した再生日時が存在する場合
  if (datetime.value !== null && datetime.value !== undefined) {
    // 指定された秒数だけ日時を更新する
    const tempDatetime: any = DateTime.fromJSDate(datetime.value).plus({
      seconds: updateScondValue,
    })
    // Date型に変換し再生日時を更新する
    datetime.value = new Date(tempDatetime.ts)
  }
  // 更新した日付を返す
  return datetime.value
}

/**
 * センサー一覧を初期化する
 */
const initSensorList = () => {
  selectSensorComponent.value.initSensorList()
}

/**
 * ポールマーカーを追加する
 * @param lat - 緯度
 * @param lng - 経度
 */
const addPoleMarker = (lat: number, lng: number) => {
  virtualMapComponent.value.addPoleMarker(lat, lng)
}

/**
 * 物標情報を更新
 */
const updatePosObject = () => {
  virtualMapComponent.value.updatePosObject()
}
/**
 * 地図の中心位置を更新する
 * @param latlng - 緯度経度情報
 */
const setCenter = (latlng: LatLng) => {
  virtualMapComponent.value.setCenter(latlng)
}

/**
 * 選択済みのセンサーを更新
 * @param value - 選択したセンサー一覧
 */
const updateSelectSensorList = (value: SensorIdData[]) => {
  selectSensorList.value = value
  // 親コンポーネントに伝達
  selectSensorListHandOver(selectSensorList.value)
}

/**
 * 親画面にセンサー一覧を送る
 * @param sensorlist - 選択済みセンサー情報
 */
const selectSensorListHandOver = (sensorlist: SensorIdData[]) => {
  emit('select-sensor-list-hand-over', sensorlist)
}

/**
 * 再生を実行
 */
const play = async () => {
  // 停止ボタンを表示
  isDataAcquisition.value = true
  // 再生日時が選択されていた過去データを再生する
  if (datetime.value !== null && datetime.value !== undefined) {
    playVirtualMode(datetime.value)
  } else {
    // 選択されてない場合はリアルタイムデータを再生する
    playRealtimeMode()
  }
}

/**
 * 過去のモニター内容を再生する
 * @param time - 再生日時
 */
const playVirtualMode = (time: Date) => {
  emit('play-virtual-mode', time)
}

/**
 * リアルタイム再生を実行する
 */
const playRealtimeMode = () => {
  emit('play-realtime-mode')
}

/**
 * 一時停止
 */
const pause = () => {
  // 再生ボタンを表示
  isDataAcquisition.value = false
  playPause()
}

/**
 * 再生を停止する
 */
const playPause = () => {
  emit('play-pouse')
}

/**
 * 10秒巻き戻し
 */
const rewind = () => {
  // 再生日時が入力済みの場合、10秒戻す
  if (datetime.value !== null && datetime.value !== undefined) {
    const tempDatetime: any = DateTime.fromJSDate(datetime.value).minus({
      seconds: 10,
    })
    datetime.value = new Date(tempDatetime.ts)
  }
}

defineExpose({
  initSensorList,
  addPoleMarker,
  updatePosObject,
  setCenter,
  updateTime,
  play
})
</script>
<template>
  <v-card
    class="virtual-video"
    elevation="10"
    outlined
  >
    <v-card-title class="virtual-video__title py-0 d-flex">
      <span class="py-5">VIRTUAL映像</span>
      <span class="py-2 ml-5">
        <vue-datepicker-next
          v-model:value="datetime"
          class="virtual-video__date"
          type="datetime"
          placeholder="再生日時選択"
          format="YYYY/MM/DD HH:mm:ss"
          formatted="YYYY/MM/DD HH:mm:ss"
          :disabled="isDataAcquisition"
        />
        <v-tooltip
          v-if="!isDataAcquisition"
          top
        >
          <template #activator="{ props }">
            <v-btn
              variant="plain"
              class="ml-5"
              icon="mdi-play"
              size="dense"
              v-bind="props"
              @click="play()"
            />
          </template>
          <span>再生開始</span>
        </v-tooltip>
        <v-tooltip
          v-if="isDataAcquisition"
          top
        >
          <template #activator="{ props }">
            <v-btn
              variant="plain"
              class="ml-5"
              icon="mdi-stop"
              size="dense"
              v-bind="props"
              @click="pause()"
            />
          </template>
          <span>停止</span>
        </v-tooltip>
        <v-tooltip top>
          <template #activator="{ props }">
            <v-btn
              variant="plain"
              class="ml-5"
              icon="mdi-rewind-10"
              size="dense"
              v-bind="props"
              @click="rewind()"
            />
          </template>
          <span>10秒巻き戻し</span>
        </v-tooltip>
      </span>
      <SelectSensor
        ref="selectSensorComponent"
        @select-sensor-list-hand-over="updateSelectSensorList"
      />
    </v-card-title>
    <v-divider />
    <div
      class="pt-0"
    >
      <VirtualMap
        ref="virtualMapComponent"
        :select-sensor-list="selectSensorList"
      />
    </div>
  </v-card>
</template>
<style lang="scss" scoped>
  .virtual-video {
    height: 90vh;
    position: relative;
    &__title {
      font-size: 22px;
      font-weight: bold;
      color: white;
      background-color: #0041c0;
      height: auto;
    }
    &__date {
      width: 12vw;
      height: 5vh;
      line-height: 10px;
    }
  }
  .icon {
    position: relative;
    display: inline-block;
  }
</style>
