// Composables
import { createApp } from 'vue'

// Components
import App from './App.vue'

import * as log from 'loglevel'

// Plugins
import { registerPlugins } from '@/plugins'

import './setting/aws-exports'

const app = createApp(App)

registerPlugins(app)

app
.mount('#app')

// ログレベル設定
const logLevel = log.levels.DEBUG
// const logLevel = log.levels.ERROR;
// const logLevel = log.levels.SILENT;
log.setLevel(logLevel, false)