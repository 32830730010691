<script lang="ts" setup>
/**
 * MAcroAnalysis.vue
 * マクロ分析画面
 */
// ==================================
// import
// ==================================
import { ref, onBeforeMount, watch } from 'vue'

import Button from '@/components/parts/common/Button.vue'
import ErrorDialog from '@/components/parts/common/ErrorDialog.vue'
import Loading from '@/components/parts/common/Loading.vue'
import TitleHeader from '@/components/parts/common/TitleHeader.vue'
import MacroAnalysisTable from '@/components/parts/macroAnalysis/MacroAnalysisTable.vue'

import { useSelectPoleStore, useMacroAnalysisInfoStore, useMacroAnalysisSearchDataStore } from '@/store/app'

import { addressByCoordinates, createMacroAnalysisInfoDataForExcel } from '@/mixins/communicationFunction'
import { setPoleName } from '@/mixins/mapFunction'

import { ErrorDialogInfo, TitleInfo } from '@/types/Interfaces'

import { MENU } from '@/setting/setting'

import { DateTime } from 'luxon'

// ==================================
// data
// ==================================
const selectPoleStore = useSelectPoleStore()
const macroAnalysisInfoStore = useMacroAnalysisInfoStore()
const searchDataStore = useMacroAnalysisSearchDataStore()

const MacroAnalysisTableRef = ref()

const titleInfo = ref<TitleInfo>({
  title: 'マクロ分析',
  pointList: [],
  menuList: MENU.macroAnalysis,
  show: {
    realtime: true,
    multicamera: true,
    virtual: true,
    menu: true,
    point: false
  }
})

const dialog = ref<ErrorDialogInfo>({
  message: '',
  title: '',
  isShow: false
})

const isLoading = ref<boolean>(false)

const aggregationPeriod = ref({
  start: '' as string,
  end: '' as string
})
const comparisonPeriod = ref({
  start: '' as string,
  end: '' as string
})

const directionItems = ref(['1', '2', '3', '4', '5'])
const directionValue = ref('1')

const ledItems = ref(['ON', 'OFF', 'EITHER'])
const ledValue = ref('ON')

const aggregationTargetItems = ref(['自動四輪車', '自動二輪車', '自転車', '歩行者'])
const aggregationTargetValue = ref('自転車')

const aggregationMethodItems = ref(['1hour', '3hours', '6hours', '12hours', '1day', '3days', '1week', '2weeks', '1month'])
const aggregationMethodValue = ref('1hour')

// 送信用オブジェクト
const sendData = ref({
  poleId: selectPoleStore.$state.poleId as number,
  address: '' as string,
  aggregationPeriod: {
    start: '' as string,
    end: '' as string
  },
  comparisonPeriod: {
    start: '' as string,
    end: '' as string
  },
  directionValue: '' as string,
  ledValue: '' as string,
  aggregationTargetValue: '' as string,
  aggregationMethodValue: '' as string
})
const datePickerEnabled = ref({
  aggStart: false,
  aggEnd: false,
  compStart: false,
  compEnd: false
})
const pickedDate = ref({
  aggStart: new Date(),
  aggEnd: new Date(),
  compStart: new Date(),
  compEnd: new Date()
})

// ==================================
// watch
// ==================================
watch(() => aggregationPeriod.value.start, (newVal, oldVal) => {
  if (oldVal !== newVal) {
    searchDataStore.setAggregationPeriodStart(newVal)
  }
})

watch(() => aggregationPeriod.value.end, (newVal, oldVal) => {
  if (oldVal !== newVal) {
    searchDataStore.setAggregationPeriodEnd(newVal)
  }
})

watch(() => comparisonPeriod.value.start, (newVal, oldVal) => {
  if (oldVal !== newVal) {
    searchDataStore.setComparisonPeriodStart(newVal)
  }
})

watch(() => comparisonPeriod.value.end, (newVal, oldVal) => {
  if (oldVal !== newVal) {
    searchDataStore.setComparisonPeriodEnd(newVal)
  }
})

watch(() => directionValue.value, (newVal, oldVal) => {
  if (oldVal !== newVal) {
    searchDataStore.setDirectionValue(newVal)
  }
})

watch(() => ledValue.value, (newVal, oldVal) => {
  if (oldVal !== newVal) {
    searchDataStore.setLedValue(newVal)
  }
})

watch(() => aggregationTargetValue.value, (newVal, oldVal) => {
  if (oldVal !== newVal) {
    searchDataStore.setAggregationTargetValue(newVal)
  }
})

watch(() => aggregationMethodValue.value, (newVal, oldVal) => {
  if (oldVal !== newVal) {
    searchDataStore.setAggregationMethodValue(newVal)
  }
})

// ==================================
// hook
// ==================================
onBeforeMount(() => {
  initalize()
})

// ==================================
// method
// ==================================
/**
 * 初期処理(Piniaストアから絞り込み項目を取得する)
 */
const initalize = () => {
  const today = DateTime.now()
  const todayString = getDate(today)
  const yesterday = DateTime.now().minus({ days: 1 })
  const yesterdayString = getDate(yesterday)
  // Piniaストアの値が初期値でなければ、ストアの値を設定する
  // 初期値の場合は前日から当日の間に設定する
  if (searchDataStore.$state.aggregationPeriod.start !== '') {
    aggregationPeriod.value.start = searchDataStore.$state.aggregationPeriod.start
  } else {
    aggregationPeriod.value.start = yesterdayString[0]
    searchDataStore.setAggregationPeriodStart(aggregationPeriod.value.start)
    pickedDate.value.aggStart = new Date(yesterday.year, yesterday.month - 1, yesterday.day)
  }

  if (searchDataStore.$state.aggregationPeriod.end !== '') {
    aggregationPeriod.value.end = searchDataStore.$state.aggregationPeriod.end
  } else {
    aggregationPeriod.value.end = todayString[0]
    searchDataStore.setAggregationPeriodEnd(aggregationPeriod.value.end)
  }

  if (searchDataStore.$state.comparisonPeriod.start !== '') {
    comparisonPeriod.value.start = searchDataStore.$state.comparisonPeriod.start
  } else {
    comparisonPeriod.value.start = yesterdayString[0]
    searchDataStore.setComparisonPeriodStart(comparisonPeriod.value.start)
  }

  if (searchDataStore.$state.comparisonPeriod.end !== '') {
    comparisonPeriod.value.end = searchDataStore.$state.comparisonPeriod.end
  } else {
    comparisonPeriod.value.end = todayString[0]
    searchDataStore.setComparisonPeriodEnd(comparisonPeriod.value.end)
  }

  if (searchDataStore.$state.directionValue !== '') {
    directionValue.value = searchDataStore.$state.directionValue
  } else {
    directionValue.value = directionItems.value[0]
  }
  
  if (searchDataStore.$state.ledValue !== '') {
    ledValue.value = searchDataStore.$state.ledValue
  } else {
    ledValue.value = ledItems.value[0]
  }
  
  if (searchDataStore.$state.aggregationTargetValue !== '') {
    aggregationTargetValue.value = searchDataStore.$state.aggregationTargetValue
  } else {
    aggregationTargetValue.value = aggregationTargetItems.value[0]
  }

  if (searchDataStore.$state.aggregationMethodValue !== '') {
    aggregationMethodValue.value = searchDataStore.$state.aggregationMethodValue
  } else {
    aggregationMethodValue.value = aggregationMethodItems.value[0]
  }
  getAddress()
}

/**
 * パラメータとして取得した日時から、日付度時間の文字列を出力する
 * @param date - 日時オブジェクト
 * @returns 日付、時刻の文字列
 */
const getDate = (date: DateTime): [string, string] => {
  const dateString = date.toFormat('yyyy/MM/dd')
  const timeString = date.toFormat('HH:mm:ss')
  return [dateString, timeString]
}

/**
 * ポールの住所、名称を設定する
 */
const getAddress = async() => {
  isLoading.value = true
  const latlng = selectPoleStore.$state.latlng
  const getAddressFunc = addressByCoordinates(latlng.lat, latlng.lng)
  getAddressFunc
    .then((res: any) => {
      if (res.data !== void 0 && res.data !== null) {
        const poleName = setPoleName(res.data.address)
        sendData.value.address = poleName.address
        isLoading.value = false
      }
    })
    .catch(() => {
      sendData.value.address = ''
      isLoading.value = false
    })
}

/**
 * APIへ送るパラメータを設定する
 */
const setSendData = () => {
  // Piniaストアの絞り込み項目を更新する
  const targetSearchData = {
    aggregationPeriod: aggregationPeriod.value,
    comparisonPeriod: comparisonPeriod.value,
    directionValue: directionValue.value,
    ledValue: ledValue.value,
    aggregationTargetValue: aggregationTargetValue.value,
    aggregationMethodValue: aggregationMethodValue.value
  }
  const result = searchDataStore.setMacroAnalysisSearchData(targetSearchData)
  if (!result) {
    dialog.value.title = '絞り込み項目不正'
    dialog.value.message = '絞り込み項目の集計期間、比較期間が正しいかを確認してください。'
    dialog.value.isShow = true
    return
  }
  sendData.value.aggregationPeriod = aggregationPeriod.value
  sendData.value.comparisonPeriod = comparisonPeriod.value
  sendData.value.directionValue = directionValue.value
  sendData.value.ledValue = ledValue.value
  sendData.value.aggregationMethodValue = aggregationMethodValue.value
  sendData.value.aggregationTargetValue = aggregationTargetValue.value
  getData(sendData.value)
}

/**
 * Excelシート生成
 */
const createDataExcel = () => {
  if (sendData.value.aggregationPeriod.start === void 0) {
    dialog.value.title = ''
    dialog.value.message = 'データ読込が行われていません。\nデータ読込を行ってください'
    dialog.value.isShow = true
    return
  }
  if (macroAnalysisInfoStore.$state.macroAnalysisInfo.enter.number.length === 0) {
    dialog.value.title = ''
    dialog.value.message = '現在のパラメータ設定ではデータが存在していません \r\n再度パラメータを設定後 \r\nデータ読込を行ってください'
    dialog.value.isShow = true
    return
  }
  isLoading.value = true
  let promise = createMacroAnalysisInfoDataForExcel(
    sendData.value.address,
    sendData.value.poleId,
    sendData.value.aggregationPeriod.start + ' 00:00:00',
    sendData.value.aggregationPeriod.end + ' 00:00:00',
    sendData.value.comparisonPeriod.start + ' 00:00:00',
    sendData.value.comparisonPeriod.end + ' 00:00:00',
    sendData.value.directionValue,
    sendData.value.ledValue,
    sendData.value.aggregationTargetValue,
    sendData.value.aggregationMethodValue
  )
  promise
    .then(() => {
      isLoading.value = false
      dialog.value.title = ''
      dialog.value.message = '作成依頼成功\r\nマクロ分析データダウンロード画面から\r\nファイルをダウンロードしてください\r\nファイル作成には時間がかかります。'
      dialog.value.isShow = true
    })
    .catch(() => {
      isLoading.value = false
      dialog.value.title = ''
      dialog.value.message = '作成依頼失敗'
      dialog.value.isShow = true
    })
}

/**
 * デートピッカーで指定した日付をテキストフィールドに設定する
 * @param category - 日付分類
 * @param key - 開始終了区分
 * @param date - 指定日付
 */
const setSearchDate = (category: string, key: string, date: Date) => {
  if (date !== void 0) {
    const distDate = DateTime.fromJSDate(date).toFormat('yyyy/MM/dd')
    if (category === 'aggregationPeriod') {
      if (key === 'start') {
        aggregationPeriod.value.start = distDate
        searchDataStore.setAggregationPeriodStart(distDate)
        datePickerEnabled.value.aggStart = false
      } else {
        aggregationPeriod.value.end = distDate
        searchDataStore.setAggregationPeriodEnd(distDate)
        datePickerEnabled.value.aggEnd = false
      }
    } else {
      if (key === 'start') {
        comparisonPeriod.value.start = distDate
        searchDataStore.setComparisonPeriodStart(distDate)
        datePickerEnabled.value.compStart = false
      } else {
        comparisonPeriod.value.end = distDate
        searchDataStore.setComparisonPeriodEnd(distDate)
        datePickerEnabled.value.compEnd = false
      }
    }
  }
}

/**
 * フォームで指定されたパラメータをもとに、マクロ分析データを取得する
 * @param data - パラメータ群
 */
const getData = (data: any) => {
  MacroAnalysisTableRef.value.getData(data)
}
</script>
<template>
  <TitleHeader :title-info="titleInfo" />
  <div class="macro-analysis">
    <v-card>
      <v-container>
        <v-row>
          <v-col md="3">
            <div>
              <span>集計期間[A] &nbsp;</span>
              <div class="d-flex">
                <v-text-field
                  v-model="aggregationPeriod.start"
                  label="YYYY/MM/DD"
                  append-inner-icon="mdi-calendar-blank"
                  @click:append-inner="datePickerEnabled.aggStart = !datePickerEnabled.aggStart"
                />
                <v-dialog
                  v-model="datePickerEnabled.aggStart"
                  class="macro-analysis__date-picker-dialog"
                >
                  <v-date-picker
                    v-model="pickedDate.aggStart"
                    @click="setSearchDate('aggregationPeriod', 'start', pickedDate.aggStart)"
                  />
                </v-dialog>
                <p class="pt-3">
                  ～&nbsp;
                </p>
                <v-text-field
                  v-model="aggregationPeriod.end"
                  label="YYYY/MM/DD"
                  append-inner-icon="mdi-calendar-blank"
                  @click:append-inner="datePickerEnabled.aggEnd = !datePickerEnabled.aggEnd"
                />
                <v-dialog
                  v-model="datePickerEnabled.aggEnd"
                  class="macro-analysis__date-picker-dialog"
                >
                  <v-date-picker
                    v-model="pickedDate.aggEnd"
                    @click="setSearchDate('aggregationPeriod', 'end', pickedDate.aggEnd)"
                  />
                </v-dialog>                
              </div>
            </div>
            <div>
              <span>比較期間[B] &nbsp;</span>
              <div class="d-flex">
                <v-text-field
                  v-model="comparisonPeriod.start"
                  label="YYYY/MM/DD"
                  append-inner-icon="mdi-calendar-blank"
                  @click:append-inner="datePickerEnabled.compStart = !datePickerEnabled.compStart"
                />
                <v-dialog
                  v-model="datePickerEnabled.compStart"
                  class="macro-analysis__date-picker-dialog"
                >
                  <v-date-picker
                    v-model="pickedDate.compStart"
                    @click="setSearchDate('comparisonPeriod', 'start', pickedDate.compStart)"
                  />
                </v-dialog>                
                <p class="pt-3">
                  ～&nbsp;
                </p>
                <v-text-field
                  v-model="comparisonPeriod.end"
                  label="YYYY/MM/DD"
                  append-inner-icon="mdi-calendar-blank"
                  @click:append-inner="datePickerEnabled.compEnd = !datePickerEnabled.compEnd"
                />
                <v-dialog
                  v-model="datePickerEnabled.compEnd"
                  class="macro-analysis__date-picker-dialog"
                >
                  <v-date-picker
                    v-model="pickedDate.compEnd"
                    @click="setSearchDate('comparisonPeriod', 'end', pickedDate.compEnd)"
                  />
                </v-dialog>                  
              </div>
            </div>
          </v-col>
          <v-col 
            md="9"
            class="d-flex"
          >
            <v-container>
              <v-row>
                <v-col md="2">
                  <v-select
                    v-model="directionValue"
                    :items="directionItems"
                    label="方路"
                    dense
                  />
                </v-col>
                <v-col
                  md="2"
                >
                  <v-select
                    v-model="ledValue"
                    :items="ledItems"
                    label="LED"
                    dense
                  />
                </v-col>
                <v-col md="2">
                  <v-select
                    v-model="aggregationTargetValue"
                    :items="aggregationTargetItems"
                    label="集計対象"
                    dense
                  />
                </v-col>
                <v-col md="2">
                  <v-select
                    v-model="aggregationMethodValue"
                    :items="aggregationMethodItems"
                    label="集計方法"
                    dense
                  />
                </v-col>
                <v-col>
                  <Button
                    label="分析データ作成"
                    :disabled="false"
                    @click="createDataExcel()"
                  />
                </v-col>
                <v-col>
                  <Button
                    label="データ読込"
                    :disabled="false"
                    @click="setSendData()"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
  <MacroAnalysisTable ref="MacroAnalysisTableRef" />
  <ErrorDialog
    :error-dialog="dialog"
    @on-click-close-error-dialog="dialog.isShow = false"
  />
  <Loading v-show="isLoading" />
</template>
<style lang="scss" scoped>
  .macro-analysis {
    position: absolute;
    top: 5%;
    left: 5%;
    width: 100%;
    margin: 0 auto;
    &__date-picker-dialog {
      position: absolute;
      left: calc(50vw - 100px);
      top: 10vh;
    }
  }
</style>
