<script lang="ts" setup>
/**
 * AggreMain.vue
 * 集計画面本体
 * 
 * 親コンポーネント
 * @/views/Aggregation.vue
 */
// ==================================
// import
// ==================================
import { onBeforeMount, watch, ref } from 'vue'

import AggreMenu from '@/components/parts/aggregation/AggreMenu.vue'
import AggreDaily from '@/components/parts/aggregation/charts/daily/AggreDaily.vue'
import AggreWeekly from '@/components/parts/aggregation/charts/weekly/AggreWeekly.vue'

import { useAggregationFiltersStore, useAggregationDataStore } from '@/store/app'

import { apiResponseErrorCode, updateSummariesDailyData, updateSummariesWeeklyData } from '@/mixins/communicationFunction'

import { SelectPoleStore } from '@/types/Interfaces'

import { DateTime } from 'luxon'

// ==================================
// interfaces
// ==================================
interface Props {
  poleData: SelectPoleStore;
  selectedCategory: number;
}

interface Emits {
  (e: 'loading-chart-data', flag: boolean): void;
  (e: 'loading-dialog'): void;
  (e: 'set-dialog', flag: boolean, title: string, message: string): void;
}

// ==================================
// data
// ==================================
const filtersStore = useAggregationFiltersStore()

const dataStore = useAggregationDataStore()

const props = defineProps<Props>()

const flags = ref({
  // グラフ表示フラグ
  isOpenDailyChart: false,
  isOpenWeeklyChart: false,
  // 集計ボタン押下フラグ
  isAggregate: false,
})

const search = ref({
  // 取得対象時刻(文字列形式、YYYY/MM/DD HH:mm:ss)
  fromDate: DateTime.now(),
  toDate: DateTime.now(),
  currentDate: DateTime.now(),
  fromDateString: '',
  toDateString: '',
})

// ==================================
// watch
// ==================================
watch(() => props.poleData, () => {
  if (filtersStore.$state.mode.category !== 0) {
    switch(filtersStore.$state.mode.span) {
      case 1:
        emit('loading-chart-data', true)
        flags.value.isOpenDailyChart = false
        getDailyData()
        break
      case 2:
        emit('loading-chart-data', true)
        flags.value.isOpenWeeklyChart = false
        getWeeklyData()
        break
      default:
    }
  }
})

// ==================================
// hook
// ==================================
onBeforeMount(() => {
  initialize()
})

// ==================================
// methods
// ==================================
const emit = defineEmits<Emits>()

/**
 * 初期処理
 */
const initialize = () => {
  // 時間別、日別、月別のデータをリセットする
  dataStore.resetSummaries()
  // 初期の日付を設定する
  search.value.currentDate = DateTime.now()
  setDateSpan()
}

/**
 * 取得期間を設定する
 */
const setDateSpan = () => {
  search.value.toDate = search.value.currentDate
  search.value.toDateString = search.value.toDate.toFormat('yyyy/MM/dd HH:mm:ss')
  // 取得開始日を取得終了日の7日前に設定する
  search.value.fromDate = search.value.toDate.minus({ days: 7 })
  search.value.fromDateString = search.value.fromDate.toFormat('yyyy/MM/dd HH:mm:ss')
}

/**
 * 表示対象の集計データが存在するかを確認する
 * @param val - 表示モード(1: 時間別、2: 日別、3: 月別)
 */
const checkChartData = (val: number) => {
  const data = dataStore.$state.aggregationData
  const summaries = dataStore.$state.summaries
  switch(val) {
    case 1:
      if (
        data.daily.date !== null &&
        typeof data.daily.date === 'object' &&
        data.daily.date.diff(search.value.toDate).days === 0 &&
        summaries.dailyList.nearMisses !== void 0 &&
        summaries.dailyList.nearMisses !== null &&
        summaries.dailyList.traffic !== void 0 &&
        summaries.dailyList.traffic !== null
      ) {
        dataStore.updateNearMiss(0, summaries.dailyList.nearMisses)
        dataStore.updateTraffic(0, summaries.dailyList.traffic)
        dataStore.updateAxlApproach(0, [summaries.dailyList.accelerationApproachAverageData, summaries.dailyList.accelerationApproachMaxData])
        flags.value.isOpenDailyChart = true
      } else {
        dataStore.updateDate(0, search.value.toDate)
        emit('loading-chart-data', true)
        flags.value.isOpenDailyChart = false
        getDailyData()
      }
      break
    case 2:
      if (
        data.weekly.date !== null &&
        typeof data.weekly.date === 'object' &&
        data.weekly.date.diff(search.value.fromDate).days === 0 &&
        summaries.weeklyList.nearMisses !== void 0 &&
        summaries.weeklyList.nearMisses !== null
      ) {
        dataStore.updateNearMiss(1, summaries.weeklyList.nearMisses)
        dataStore.updateTraffic(1, summaries.weeklyList.traffic)
        dataStore.updateAxlApproach(1, [summaries.weeklyList.accelerationApproachAverageData, summaries.weeklyList.accelerationApproachMaxData])
        flags.value.isOpenWeeklyChart = true;
      } else {
        dataStore.updateDate(1, search.value.fromDate)
        emit('loading-chart-data', true)
        flags.value.isOpenWeeklyChart = false
        getWeeklyData()
      }
      break
    default:
  }
}

/**
 * 時間別データを取得する
 */
const getDailyData = () => {
  const getDailyDataFunc = updateSummariesDailyData(props.poleData.poleId, search.value.toDateString, 20, 50)
  getDailyDataFunc
    .then(() => {
      // 日次データがVuexストア上に登録されたら成功と判定する
      if (dataStore.$state.summaries.dailyList !== void 0 && dataStore.$state.summaries.dailyList !== null) {
        return dataStore.$state.summaries.dailyList
      } else {
        throw new Error(apiResponseErrorCode.noContent.toString())
      }
    })
    .then(val => {
      // 日次データから各グラフ用データを抽出してストアに登録する
      dataStore.updateNearMiss(0, val.nearMisses)
      dataStore.updateTraffic(0, val.traffic)
      dataStore.updateAxlApproach(0, [val.accelerationApproachAverageData, val.accelerationApproachMaxData])
    })
    .then(() => {
      // ローディング画面を消去、各グラフコンポーネントを有効にする
      flags.value.isOpenDailyChart = true
      flags.value.isAggregate = true
      emit('loading-chart-data', false)
    })
    .catch(error => {
      if (error === apiResponseErrorCode.noContent) {
        emit('set-dialog', true, '時間別データなし', '時間別データが存在しません。');
      } else {
        emit('set-dialog', true, '時間別データ取得失敗', '時間別データの取得に失敗しました。:' + error);
      }
    });  
}

/**
 * 日別データを取得する
 */
const getWeeklyData = () => {
  const getWeeklyDataFunc = updateSummariesWeeklyData(props.poleData.poleId, search.value.fromDateString, 20, 50)
  getWeeklyDataFunc
    .then(() => {
      // 日次データがVuexストア上に登録されたら成功と判定する
      if (dataStore.$state.summaries.weeklyList !== void 0 && dataStore.$state.summaries.weeklyList !== null) {
        return dataStore.$state.summaries.weeklyList
      } else {
        throw new Error(apiResponseErrorCode.noContent.toString())
      }
    })
    .then(val => {
      // 日次データから各グラフ用データを抽出してストアに登録する
      dataStore.updateNearMiss(1, val.nearMisses)
      dataStore.updateTraffic(1, val.traffic)
      dataStore.updateAxlApproach(1, [val.accelerationApproachAverageData, val.accelerationApproachMaxData])
    })
    .then(() => {
      // ローディング画面を消去、各グラフコンポーネントを有効にする
      flags.value.isOpenDailyChart = true
      flags.value.isAggregate = true
      emit('loading-chart-data', false)
    })
    .catch(error => {
      if (error === apiResponseErrorCode.noContent) {
        emit('set-dialog', true, '日別データなし', '日別データが存在しません。')
      } else {
        emit('set-dialog', true, '日別データ取得失敗', '日別データの取得に失敗しました。:' + error)
      }
    })
}

/**
 * 集計対象の日付を変更し、データをリセットする
 * @param mode - 表示モード（1: 時間別、2: 日別、3: 月別)
 * @param diff - 前回からの差分
 */
 const updateTargetDate = (mode: number, diff: number): void => {
  const modeStringList = ['days', 'weeks', 'months', 'years']
  const key = modeStringList[mode - 1]
  let plusVal: any = {}
  // 差分がマイナスになった場合でも対応できるようにする
  if (diff >= 0) {
    plusVal[key] = diff
    search.value.currentDate = search.value.currentDate.plus(plusVal)
    // 更新した日付が当日よりも先になった場合、当日に変更する
    if (DateTime.now().diff(search.value.currentDate).days < 0) {
      search.value.currentDate = DateTime.now()
    }
  } else {
    const absDiff = Math.abs(diff)
    plusVal[key] = absDiff
    search.value.currentDate = search.value.currentDate.minus(plusVal)
  }
  dataStore.resetSummary(mode)
  setDateSpan()
}

/**
 * 集計ボタン押下状態の変更
 * @param val - 集計ボタン押下状態
 */
const updateAggregation = (val: boolean) => {
  flags.value.isAggregate = val
  // ボタンを押した状態の場合、対象データが存在するかチェックする
  if (flags.value.isAggregate) {
    checkChartData(filtersStore.$state.mode.span)
  }
}

defineExpose({
  updateAggregation
})
</script>
<template>
  <div>
    <v-container>
      <v-row>
        <v-col>
          <AggreMenu
            :target-date="search.toDate"
            :selected-category="selectedCategory"
            @update-target-date="updateTargetDate"
            @update-aggregation="updateAggregation"
          />
        </v-col>
      </v-row>
      <v-row v-if="flags.isAggregate">
        <v-col v-if="flags.isOpenDailyChart && filtersStore.$state.mode.span === 1">
          <AggreDaily :to-date="search.toDate" />
        </v-col>
        <v-col v-else-if="flags.isOpenWeeklyChart && filtersStore.$state.mode.span === 2">
          <AggreWeekly :to-date="search.toDate" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
