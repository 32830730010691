import CognitoInfo from '@/setting/aws-cognito'

import { Amplify } from 'aws-amplify'
import { EncryptStorage } from 'encrypt-storage'

// AWSへの接続内容を暗号化
const encryptStorage = new EncryptStorage('aws-config')

/**
 * aws-exports.ts
 * AWS Amplifyを使った認証設定およびAPIのURLを初期設定する
 */
// 環境変数に設定されている稼働環境の値に応じて、認証とAPIの設定を変える
Amplify.configure({
  Auth: {
    region: CognitoInfo.region,
    userPoolId: CognitoInfo.userPoolId,
    userPoolWebClientId: CognitoInfo.userPoolWebClientId,
    authenticationFlowType: CognitoInfo.authenticationFlowType,
    identityPoolId: CognitoInfo.identityPoolId,
    storage: encryptStorage
  },
  API: {
    endpoints: [
      {
        name: 'getSignedUrl',
        endpoint: process.env.VUE_APP_API_GET_SIGNED_URL
      },
      {
        name: 'spitsServer',
        endpoint: process.env.VUE_APP_API_SPITS_SERVER_URL
      },
      {
        name: 'spitsServerDev',
        endpoint: process.env.VUE_APP_API_SPITS_SERVER_DEV_URL
      },
      {
        name: 'spitsServerLocal',
        endpoint: process.env.VUE_APP_API_SPITS_SERVER_LOCAL_URL
      },
      {
        name: 'spitsServerCloud',
        endpoint: process.env.VUE_APP_API_SPITS_SERVER_CLOUD_URL,
        custom_header: async () => {
          return {
            'x-api-key': process.env.VUE_APP_API_SPITS_SERVER_CLOUD_API_KEY
          };
        }
      },
      {
        name: 'spitsAdminApiUrl',
        endpoint: process.env.VUE_APP_API_SPITS_ADMIN_URL,
        custom_header: async () => {
          return {
            'x-api-key': process.env.VUE_APP_API_SPITS_ADMIN_API_KEY
          };
        }
      }
    ]
  }
})
