<script lang="ts" setup>
/**
 * SelectPoleMenu.vue
 * ポール一覧メニュー
 * 
 * 親コンポーネント
 * @/views/Area.vue
 */
// ==================================
// import
// ==================================
import { ref, watch } from 'vue'

import { useAreaListStore, useSelectAreaStore, useSelectPoleStore } from '@/store/app'

import { AreaInfo, PoleListStore } from '@/types/Interfaces'

import { POLE_SUB_MENU } from '@/setting/setting'

import router from '@/router'

// ==================================
// interface
// ==================================
interface Work {
  areaId: number;
  name: string;
  latitude: number;
  longitude: number;
  latlng: [number, number];
  notificationFlag: boolean;
  poleList: PoleListStore[];
  poleNumber: number;
}

interface Props {
  areaPoleList: Work[];
}

interface Emits {
  (e: 'pole-selected', pole: PoleListStore): void;
}

// ==================================
// data
// ==================================
// エリア一覧Piniaストア
const areaListStore = useAreaListStore()

const selectAreaStore = useSelectAreaStore()

// 選択されたポール情報Piniaストア
const selectPoleStore = useSelectPoleStore()

const props = defineProps<Props>()

// メニュー表示フラグ
let isMenuOpen = false

// マーカーアイコン色設定
const iconColors: string[] = ['#999', '#0c0', '#F00']

const subMenu = ref(POLE_SUB_MENU)

const isMenuOpened = ref<number>(0)

// ==================================
// watch
// ==================================
watch(() => props.areaPoleList, val => {
  // 親コンポーネントからポール一覧を取得したらメニューを表示する
  if (val !== void 0 && val.length > 0) {
    isMenuOpen = true
  }
})

// ==================================
// method
// ==================================
const emit = defineEmits<Emits>()

/**
 * クリックした項目に合わせて、移動するページを設定する
 * @param action - 遷移ページ名
 */
const moveToNextPage = (action: string) => {
  switch (action) {
    // 現在データ
    case 'realTimeData':
      router.push({ path: '/realtime' })
      break
    // 過去データ
    case 'historyData':
      router.push({ path: '/historylist' })
      break
    // ヒヤリハット
    case 'accidentData':
      router.push({ path: '/accident' })
      break
    // 集計
    case 'collections':
      router.push({ path: '/collection' })
      break
    default:
  }
}

/**
 * ポール一覧から項目が選択された際に、ダイアログを表示させる
 * @param pole - ポール情報
 */
const poleSelected = (pole: PoleListStore) => {
  // 選択されたポールのあるエリアを登録する
  const areaList = areaListStore.$state.areaList
  const targetArea = areaList.find((area: AreaInfo) => area.areaId === pole.areaId)
  if (targetArea !== void 0) {
    selectAreaStore.setData(targetArea.areaId, targetArea.name, {lat: targetArea.latitude, lng: targetArea.longitude})
  }
  // ポールの位置情報を設定する
  const latlng = {
    lat: pole.latitude,
    lng: pole.longitude
  }
  const address = pole.address !== void 0 ? pole.address : ''
  selectPoleStore.setData(pole.poleId, pole.areaId, address, latlng, pole.sensorList)
  emit('pole-selected', pole)
}
</script>
<template>
  <div class="select-pole-menu">
    <v-container>
      <v-row>
        <v-col class="select-pole-menu__title">
          ポール一覧
        </v-col>
      </v-row>
    </v-container>
    <v-expansion-panels
      v-if="isMenuOpen"
      v-model="isMenuOpened"
      variant="accordion"
      theme="dark"
      flat
    >
      <v-expansion-panel>
        <v-expansion-panel-title color="#000">
          <span class="select-pole-menu__subtitle">エリア</span>
        </v-expansion-panel-title>
        <v-expansion-panel-text class="select-pole-menu__panel">
          <v-expansion-panels
            variant="accordion"
            theme="dark"
            flat
          >
            <v-expansion-panel
              v-for="item in areaPoleList"
              :key="item.areaId"
              rounded="false"
            >
              <v-expansion-panel-title
                color="#000"
                hide-actions
              >
                <template #default="{ expanded }">
                  <v-row
                    no-gutters
                    dense
                  >
                    <v-col md="8">
                      <span class="select-pole-menu__pole-name">{{ item.name }}</span>
                    </v-col>
                    <v-spacer />
                    <v-col
                      class="select-pole-menu__pole-count"
                      md="4"
                    >
                      <span v-if="!expanded">({{ item.poleNumber }})</span>
                      <v-icon
                        v-if="expanded"
                        icon="mdi-chevron-up"
                      />
                    </v-col>
                  </v-row>
                </template>
              </v-expansion-panel-title>
              <v-expansion-panel-text class="select-pole-menu__panel">
                <div
                  v-for="item2 in item.poleList"
                  :key="item2.poleId"
                  class="select-pole-menu__menu-frame"
                >
                  <v-btn
                    variant="plain"
                    theme="dark"
                    prepend-icon="mdi-map-marker"
                    class="select-pole-menu__menu-button"
                    @click="poleSelected(item2)"
                  >
                    <template #prepend>
                      <v-icon 
                        v-if="item2.status != undefined"
                        :color="iconColors[item2.status]" 
                      />
                    </template>
                    {{ item2.name !== '' ? item2.name : item2.address }}
                    <v-menu
                      activator="parent"
                      location="end"
                    >
                      <v-list class="select-pole-menu__sub-menu">
                        <v-list-item
                          v-for="item3 in subMenu"
                          :key="item3.action"
                        >
                          <v-list-item-title class="select-pole-menu__sub-menu-title">
                            <v-btn
                              variant="plain"
                              theme="dark"
                              class="select-pole-menu__sub-menu-button"
                              @click="moveToNextPage(item3.action)"
                            >
                              {{ item3.name }}
                            </v-btn>
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-btn>
                </div>
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>
<style lang="scss">
  $expansion-panel-text-padding: 0;
  .select-pole-menu {
    position: relative;
    left: 0px;
    top: 24px;
    width: 400px;
    height: calc(100vh - 60px);
    background-color: #000;
    color: #fff;
    &__title {
      font-size: 1.4em;
      font-weight: bold;
    }
    &__subtitle {
      font-size: 1.2em;
      padding-left: 0.7em;
      font-weight: bold;
    }
    &__panel {
      padding: 0;
    }
    &__pole-name {
      padding-left: 1.5em;
    }
    &__pole-count {
      text-align: right;
    }
    &__menu-frame {
      padding-left: 40px;
    }
    &__menu-button {
      display: block;
      text-align: left;
      font-size: 1em;
      width: 400px;
      span {
        &.v-btn__prepend {
          display: inline;
        }
        &.v-btn__content {
          display: inline;
        }
      }
    }
    &__sub-menu {
      opacity: 0.6;
      padding: 0;
      .v-list-item--density-default {
        &:not(.v-list-item--nav) {
          &.v-list-item--one-line {
            padding-inline: 0;
          }
        }
      }      
      .v-list-item {
        .v-list-item-out-line {
          padding: 0;
          padding-inline: 0px;
        }
      }
    }
    &__sub-menu-title {
      padding: 0;
    }
    &__sub-menu-button {
      text-align: left;
      padding: 0 8px;
      font-size: 1em;
    }
  }
  .v-expansion-panel-text__wrapper {
    padding: 0;
  }
</style>
