<script lang="ts" setup>
/**
 * ModalMacroAnalysisHistgram.vue
 * モーダル表示用グラフ表示コンポーネント
 * 
 * 親コンポーネント
 * @/components/parts/macroAnalysis/MacroAnalysisDetailInfoTable.vue
 * @/components/parts/macroAnalysis/MacroAnalysisTable.vue
 */
// ==================================
// import
// ==================================
import { computed, watch, ref } from 'vue'

// ==================================
// interface
// ==================================
interface Props {
  value: boolean;
  text: string;
  prefix: any;
}

interface Emits {
  (e: 'input', val: any): void;
}

// ==================================
// data
// ==================================
const props = defineProps<Props>()

const resetFlag = ref<boolean>(false)

// ==================================
// watch
// ==================================
watch(() => props.value, val => {
  resetFlag.value = val
})

// ==================================
// computed
// ==================================
/**
 * モーダルダイアログ表示設定
 */
const modal = computed({
  get() {
    return props.value
  },
  set(val) {
    emit('input', val)
  }
})

// ==================================
// method
// ==================================
const emit = defineEmits<Emits>()
</script>
<template>
  <v-dialog
    v-model="modal"
    :value="value"
    class="modal-macro-analysis-histgram"
  >
    <v-card>
      <v-card-title />
      <v-card-text>
        <component
          :is="prefix.component"
          v-bind="prefix.props"
          v-if="resetFlag"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<style lang="scss" scoped>
  .modal-macro-analysis-histgram {
    width: 50vw;
    height: auto;
  }
</style>
