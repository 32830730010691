/**
 * aws-cognito.ts
 * AWS Cognitoを使った認証情報を定義する
 */

interface cognitoType {
  region: string;
  userPoolId: string;
  userPoolWebClientId: string;
  authenticationFlowType: string;
  identityPoolId: string;
}

let CognitoInfo: cognitoType = <cognitoType> {
  region: '',
  userPoolId: '',
  userPoolWebClientId: '',
  authenticationFlowType: '',
  identityPoolId: ''
}

// 環境変数に設定されている稼働環境の値に応じて、認証とAPIの設定を変える
CognitoInfo = <cognitoType>{
  // REQUIRED - Amazon Cognito Region
  region: process.env.VUE_APP_AUTH_REGION,

  // OPTIONAL - Amazon Cognito User Pool ID
  userPoolId: process.env.VUE_APP_AUTH_USER_POOL_ID,

  // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
  userPoolWebClientId: process.env.VUE_APP_AUTH_USER_POOL_WEB_CLIENT_ID,

  // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
  authenticationFlowType: process.env.VUE_APP_AUTH_AUTHENTICATION_FLOW_TYPE,

  // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
  identityPoolId: process.env.VUE_APP_AUTH_IDENTIFY_POOL_ID
}

export default CognitoInfo
