<script lang="ts" setup>
/**
 * InformationInTheIntersection.vue
 * 交差点内物標情報コンポーネント
 * 
 * 親コンポーネント
 * @/views/RealTime.vue
 */
// ==================================
// import
// ==================================
import { ref, computed } from 'vue'

import LocationInfoTable from '@/components/parts/common/LocationInfoTable.vue'

import { SensorIdData } from '@/types/Interfaces'

import { LOCATION_INFOTABLE_TYPE1 } from '@/setting/setting'

// ==================================
// interface
// ==================================
interface Props {
  selectSensorList: SensorIdData[];
}

interface Emits {
  (e: 'select-object', value: number[]): void;
}

// ==================================
// data
// ==================================
const props = defineProps<Props>()

const locationInfoTableComponent = ref()

// ==================================
// computed
// ==================================
const selectSensorList = computed(() => {
  return props.selectSensorList
})

// ==================================
// method
// ==================================
const emit = defineEmits<Emits>()
/**
 * 位置情報を親コンポーネントへ渡す
 * @param data - 緯度経度
 */
const selectObject = (data: number[]) => {
  emit('select-object', data)
}

/**
 * テーブル表示項目設定ダイアログ表示
 */
const onClickTableSet = () => {
  locationInfoTableComponent.value.columSelectModalOpen()
}
/**
 * 物標情報更新
 */
const updateList = () => {
  locationInfoTableComponent.value.updateList()
}

defineExpose({
  updateList,
})
</script>
<template>
  <v-card
    class="information-in-the-intersection"
    elevation="5"
    outlined
  >
    <v-card-title class="information-in-the-intersection__title py-0 d-flex">
      <span class="py-0">交差点内物標情報</span>
      <v-spacer />
      <v-btn
        variant="flat"
        color="blue-grey"
        dark
        height="auto"
        @click="onClickTableSet"
      >
        <v-icon>mdi-table-settings</v-icon>
      </v-btn>
    </v-card-title>
    <v-divider />
    <LocationInfoTable
      ref="locationInfoTableComponent"
      :check-boxes="LOCATION_INFOTABLE_TYPE1.checkBoxes"
      :list-header="LOCATION_INFOTABLE_TYPE1.listHeader"
      :select-sensor-list="selectSensorList"
      :items-per-page="3"
      @select-object="selectObject"
    />
  </v-card>
</template>
<style lang="scss" scoped>
  .information-in-the-intersection {
    height: auto;
    &__title {
      font-size: 22px;
      font-weight: bold;
      color: white;
      background-color: #0041c0;
      height: auto;
    }
  }
</style>
