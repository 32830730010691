<script lang="ts" setup>
/**
 * HistoryList.vue
 * 過去データ一覧
 */
// ==================================
// import
// ==================================
import { onBeforeMount, ref } from 'vue'
import { useRoute } from 'vue-router'

import ErrorDialog from '@/components/parts/common/ErrorDialog.vue'
import Loading from '@/components/parts/common/Loading.vue'
import TitleHeader from '@/components/parts/common/TitleHeader.vue'
import HistoryListTable from '@/components/parts/historyList/HistoryListTable.vue'

import { useSelectPoleStore } from '@/store/app'

import { ErrorDialogInfo, TitleInfo } from '@/types/Interfaces'

import { MENU } from '@/setting/setting'

// ==================================
// data
// ==================================
// ルーター定義
const route = useRoute()

const selectPoleStore = useSelectPoleStore()

// タイトル
const titleinfo = ref<TitleInfo>({
  title: '',
  pointList: [],
  menuList: [],
  show: {
    menu: true,
  }
});

// エラーダイアログ
const errorDialog = ref<ErrorDialogInfo>({
  message: '',
  title: '',
  isShow: false,
});

// 他画面内ステータス
const poleid = ref<number>()
const directTransit = ref<any>(route.query.direct)
const isLoading = ref<boolean>(true)

// ==================================
// hook
// ==================================
 onBeforeMount(() => {
  initialize()
})

// ==================================
// method
// ==================================
/**
 * 値初期化処理
 */
const initialize = () => {
  directTransit.value = route.query.direct

  if (directTransit.value != 'false') {
    titleinfo.value.title = '過去データ(事故データ)一覧 最新20件'
    titleinfo.value.menuList = MENU.historyListDirect
  } else {
    titleinfo.value.title = '過去データ(事故データ)一覧 ' + selectPoleStore.$state.name
    titleinfo.value.menuList = MENU.historyList
    poleid.value = selectPoleStore.$state.poleId
  }
}

/**
 * エラーダイアログオープン処理
 * @param title - エラータイトル
 * @param message - エラーメッセージ
 */
const openErrorDialog = (title: string, message: string) => {
  errorDialog.value.title = title
  errorDialog.value.message = message
  errorDialog.value.isShow = true
}

/**
 * エラーダイアログクローズ処理
 */
const onClickCloseErrorDialog = () => {
  errorDialog.value.isShow = false
}

/**
 * ローディング処理開始
 */
const startLoading = () => {
  isLoading.value = true
}
/**
 * ローディング処理停止
 */
const stopLoading = () => {
  isLoading.value = false
}
</script>
<template>
  <TitleHeader :title-info="titleinfo" />
  <v-container fill-height>
    <v-row>
      <v-col
        id="history_list"
        cols="12"
      >
        <HistoryListTable
          ref="historyListTableComponent"
          :pole-id="poleid"
          :is-loading="isLoading"
          @open-error-dialog="openErrorDialog"
          @start-loading="startLoading"
          @stop-loading="stopLoading"
        />
      </v-col>
    </v-row>
  </v-container>
  <Loading v-show="isLoading" />
  <ErrorDialog
    :error-dialog="errorDialog"
    @on-click-close-error-dialog="onClickCloseErrorDialog"
  />
</template>   
<style lang="scss" scoped>
  .v-row {
    top: 10%;
    left:20%;
    position: absolute;
  }
</style>
