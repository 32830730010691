// Utilities
import { encryptStorage } from './encryptStorage'

import { createPinia } from 'pinia'
import { createPersistedState } from 'pinia-plugin-persistedstate'


const pinia = createPinia()
// Piniaストアの永続化
pinia.use(createPersistedState({
  // 保存内容を暗号化
  storage: encryptStorage
}))
export default pinia
