<script lang="ts" setup>
/**
 * CameraFootage.vue
 * リアルタイムカメラコンポーネント
 * 
 * 親コンポーネント
 * @/views/RealTime.vue
 * 
 */
// ==================================
// import
// ==================================
import { ref } from 'vue'

import KinesisVideoStreamsWebrtc from '@/components/parts/common/KinesisVideoStreamsWebrtc.vue'

import { getCameraList } from '@/mixins/commonFunction'

import { CameraInfo } from '@/types/Interfaces'

// ==================================
// data
// ==================================
const cameraList = ref<CameraInfo[]>([])

const selectCamera = ref<CameraInfo | undefined>()

const showCamera = ref<boolean>(false)

// ==================================
// method
// ==================================
/**
 * カメラ初期設定
 */
const initCameraList = () => {
  cameraList.value = getCameraList()
  if (cameraList.value.length != 0) {
    selectCamera.value = cameraList.value[0]
    showCamera.value = true
  }
}

defineExpose({
  initCameraList,
})
</script>
<template>
  <v-card
    class="camera-footage"
    elevation="5"
    outlined
  >
    <v-card-title class="camera-footage__title py-0 d-flex ">
      <span class="py-5">カメラ映像</span>
      <v-spacer />
      <v-select
        v-model="selectCamera"
        class="camera-footage__select"
        variant="underlined"
        :items="cameraList"
        theme="dark"
        item-title="name"
        item-value="channel"
        label="カメラ選択"
        autowidth
        return-object
      />
    </v-card-title>
    <v-divider />
    <div align="center">
      <KinesisVideoStreamsWebrtc
        v-if="showCamera"
        :camera="selectCamera"
        :class="'real-video-container'"
      />
    </div>
  </v-card>
</template>
<style lang="scss" scoped>
  .camera-footage {
    height: 65vh;
    background-color: rgb(56, 54, 54);
    min-height: 25vh;
    &__title {
      font-size: 22px;
      font-weight: bold;
      color: white;
      background-color: #0041c0;
      height: auto;
    }
    &__select {
      width: 10vw;
      height: 6vh;
    }
  }
</style>
