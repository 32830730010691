<script lang="ts" setup>
/**
 * Login.vue
 * ログイン
 */
// ==================================
// import
// ==================================
import { onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'

import ErrorDialog from '@/components/parts/common/ErrorDialog.vue'
import Loading from '@/components/parts/common/Loading.vue'
import TitleHeader from '@/components/parts/common/TitleHeader.vue'

import { useUserInfoStore } from '@/store/app'

import { DIALOG_ERROR_INFO } from '@/mixins/commonFunction'

import { ErrorDialogInfo, TitleInfo } from '@/types/Interfaces'

import CognitoInfo from '@/setting/aws-cognito'

// AWS Library
import { Auth } from 'aws-amplify'
import AWS from 'aws-sdk'
import * as log from 'loglevel'

// ==================================
// interface
// ==================================
interface UserInfo {
  userName: string;
  password: string;
}

// ==================================
// data
// ==================================
// ルーター定義
const router = useRouter()

// ユーザー情報ストア
const userInfoStore = useUserInfoStore()

// タイトル
const titleinfo = ref<TitleInfo>({
  title: '',
  pointList: [],
  menuList: [],
  show: {
    logout: false,
    point: false,
    menu: false,
  },
})

// エラーダイアログ情報
const errorDialog = ref<ErrorDialogInfo>({
  message: '',
  title: '',
  isShow: false,
})

// ユーザー情報
const userInfo = ref<UserInfo>({
  userName: '',
  password: '',
})

// 画面項目制御
const isShowPassword = ref<boolean>(false)
const isLoading = ref<boolean>(false)

// ==================================
// hook
// ==================================
onMounted(() => {
  // ログアウト後に残っているPiniaストアを消去する
  sessionStorage.clear()
})

// ==================================
// method
// ==================================
/**
 * ログイン処理
 */
const onClickLogin = () => {
  if (loginCheck()) {
    isLoading.value = true
    signIn()
  }
}

/**
 * ログイン情報入力チェック
 */
const loginCheck = () => {
  let result = false
  if (userInfo.value.userName === '') {
    errorDialog.value.title = DIALOG_ERROR_INFO.title.authError
    errorDialog.value.message = DIALOG_ERROR_INFO.message.authErrorUser
    errorDialog.value.isShow = true
  } else if (userInfo.value.password === '') {
    errorDialog.value.title = DIALOG_ERROR_INFO.title.authError
    errorDialog.value.message = DIALOG_ERROR_INFO.message.authErrorPassword
    errorDialog.value.isShow = true
  } else {
    result = true
  }
  return result
}

/**
 * cognitoユーザーログイン処理
 */
const signIn = async () => {
  await Auth.signIn(userInfo.value.userName, userInfo.value.password)
    .then((user: any) => {
      // ユーザー情報保存
      userInfoStore.setData(user)
      setCognitoIdentityCredentials(user)
      isLoading.value = false
    })
    .catch((err: any) => {
      log.error(err)
      errorDialog.value.title = DIALOG_ERROR_INFO.title.authError
      errorDialog.value.message = DIALOG_ERROR_INFO.message.authErrorUserPassword
      errorDialog.value.isShow = true
      isLoading.value = false
    })
}

/**
 * Cognito アイデンティティユーザー認証
 * @param user - Cognitユーザー情報
 */
const setCognitoIdentityCredentials = async (user: any) => {
  await user.getSession(function (err: any, session: any) {
    if (session) {
      AWS.config.region = CognitoInfo.region
      let identityPoolId = CognitoInfo.identityPoolId
      let logins: any = {}
      const key = 'cognito-idp.' + CognitoInfo.region + '.amazonaws.com/' + CognitoInfo.userPoolId
      logins[key] = session.getIdToken().getJwtToken()
      const cognitoIdentityParams = {
        IdentityPoolId: identityPoolId,
        Logins: logins,
      }
      AWS.config.credentials = new AWS.CognitoIdentityCredentials(cognitoIdentityParams)
    } else {
      log.error(err)
    }
  })
  router.push('/selectarea')
}

/**
 * エラーダイアログを閉じる
 */
const onClickCloseErrorDialog = () => {
  errorDialog.value.isShow = false
}
</script>
<template>
  <TitleHeader :title-info="titleinfo" />
  <v-card
    class="login mx-auto mt-10"
    width="30vw"
  >
    <v-card-title>
      <h1 class="login__title">
        ログイン
      </h1>
    </v-card-title>
    <v-card-text>
      <v-form>
        <v-text-field
          v-model="userInfo.userName"
          variant="underlined"
          color="primary"
          prepend-icon="mdi-account-circle"
          label="ユーザ名"
        />
        <v-text-field
          v-model="userInfo.password"
          variant="underlined"
          prepend-icon="mdi-lock"
          label="パスワード"
          color="primary"
          :type="isShowPassword ? 'text' : 'password'"
          :append-inner-icon="isShowPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append-inner="isShowPassword = !isShowPassword"
        />
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        variant="flat"
        color="blue-grey"
        @click="onClickLogin"
      >
        ログイン
      </v-btn>
    </v-card-actions>
  </v-card>
  <ErrorDialog
    :error-dialog="errorDialog"
    @on-click-close-error-dialog="onClickCloseErrorDialog"
  />
  <Loading v-show="isLoading" />
  <v-main>
    <RouterView />
  </v-main>
</template>
<style lang="scss" scoped>
  .login {
    &__title {
      font-size: 2.125rem !important;
      line-height: 2.5rem;
      letter-spacing: 0.0073529412em !important;
      font-weight: 400;
      font-family: Roboto, sans-serif !important;
    }
  }
</style>
