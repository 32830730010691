<script lang="ts" setup>
/**
 * ObjectList.vue
 * 物標一覧
 * 
 * 親コンポーネント
 * @/components/parts/aggregation/AggreMenu.vue
 */
// ==================================
// import
// ==================================
import { onMounted, watch, ref } from 'vue'

import { useAggregationFiltersStore } from '@/store/app'

// ==================================
// interface
// ==================================
interface Props {
  mode: number;
}

interface ObjList {
  kind: string;
  objectMarker: any;
  definition: string;
}

// ==================================
// data
// ==================================
const filterStore = useAggregationFiltersStore()

const props = defineProps<Props>()

// 物標一覧
const objList = ref<ObjList[]>([
  {
    kind: '-01',
    objectMarker: require('@/assets/img/object/question.svg'),
    definition: '種別不明'
  },
  {
    kind: '001',
    objectMarker: require('@/assets/img/object/human.svg'),
    definition: '歩行者'
  },
  {
    kind: '002',
    objectMarker: require('@/assets/img/object/bicycle.svg'),
    definition: '自転車'
  },
  {
    kind: '003',
    objectMarker: require('@/assets/img/object/bike.svg'),
    definition: '自動二輪車'
  },
  {
    kind: '004',
    objectMarker: require('@/assets/img/object/car.svg'),
    definition: '自動車'
  }
])

// チェックボックスフィルタリング一覧
const filteringObj = ref<string[]>([])

// ==================================
// watch
// ==================================
watch(() => filteringObj.value, () => {
  // チェックボックスのチェックが変わったら更新処理を実行する
  setObjectCheckBox()
})

// ==================================
// hook
// ==================================
onMounted(() => {
  // 物標一覧の種別コードをすべて追加する
  objList.value.forEach(obj => {
    filteringObj.value.push(obj.kind)
  })
  setObjectCheckBox()
})

// ==================================
// method
// ==================================
/**
 * 選択されたチェックボックスの値を更新する
 */
const setObjectCheckBox = () => {
  switch(props.mode) {
    // 通過台数、侵入速度
    case 0:
      filterStore.setFilterngObj(filteringObj.value)
      break
    // ヒヤリハット優先
    case 1:
      filterStore.setPriorityObj(filteringObj.value)
      break
    // ヒヤリハット非優先
    case 2:
      filterStore.setNonPriorityObj(filteringObj.value)
      break
    default:
  }
}
</script>
<template>
  <div class="object-list">
    <v-table
      density="compact"
      theme="light"
    >
      <tbody>
        <tr
          v-for="item in objList"
          :key="item.kind"
        >
          <td class="object-list__checkbox">
            <v-checkbox
              v-model="filteringObj"
              :value="item.kind"
              
              density="compact"
              hide-details
            >
              <template #label>
                <img
                  :src="item.objectMarker"
                  class="object-list__obj-icon"
                >
                <span class="object-list__obj-name">{{ item.definition }}</span>
              </template>
            </v-checkbox>
          </td>
        </tr>
      </tbody>
    </v-table>
  </div>
</template>
<style lang="scss">
  .object-list {
    &__checkbox {
      padding-left: 0px !important;
    }
    &__obj-icon {
      width: 16px;
      height: 16px;
    }
    &__obj-name {
      font-size: 0.8em;
    }
  }
  .v-input {
    text-align: center;
  }
  .v-input--density-compact {
    --v-input-control-height: 0px;
  }
</style>
