// ==================================
// 非同期処理
// ==================================
import { ref } from 'vue'

import { DateTime } from 'luxon'
const timer = ref()
export const timerFunction = {
  methods: {
    /**
     * 非同期処理開始関数
     * @param poleId - ポールID
     * @param interval - 実行間隔(秒)
     * @param callback - 実行処理
     * @param time - 時刻
     */
    start(poleId: number, interval: number, callback: any, time: any = '') {
      this.stop()
      if (time == '') {
        timer.value = setInterval(function () {
          callback(poleId)
        }, interval)
      } else {
        timer.value = setInterval(function () {
          callback(poleId, time)
        }, interval)
      }
    },

    /**
     * 非同期処理終了関数
     */
    stop() {
      if (timer.value != undefined) {
        clearInterval(timer.value)
        timer.value = undefined
      }
    },
  },
}

/**
 * 現在の日付と時刻を取得する
 * @returns 日付、時刻の文字列
 */
export const getDateTimeNow = (): string[] => {
  const now = DateTime.now()
  return [
    now.toFormat('yyyy-MM-dd'),
    now.toFormat('HH:mm:ss')
  ]
}

/**
 * 現在から指定された日数前の日付を取得する
 * @param day - 遡る日数
 * @returns 日付、時刻の文字列
 */
export const getBeforeDateTime = (day: number): string[] => {
  const initDate = DateTime.now().minus({ days: day })
  // 時刻は00:00:00固定で返す
  return [
    initDate.toFormat('yyyy-MM-dd'),
    '00:00:00'
  ]
}

/**
 * 現在の日付から先月の１日の日付を取得する
 * @returns 日付、時刻の文字列
 */
export const getInitFromDateTime = (): string[] => {
  // 現在の日付から先月の１日の日付を設定する
  const initDate = DateTime.now().minus({ month: 1 }).set({ day: 1})
  return [
    initDate.toFormat('yyyy-MM-dd'),
    '00:00:00'
  ]
}
