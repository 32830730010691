<script lang="ts" setup>
/**
 * LocationInfoTable.vue
 * 交差点物標情報一覧コンポーネント
 * 
 * 親コンポーネント
 * @/components/parts/realTime/InformationInTheIntersection.vue
 * @/components/parts/virtual/InformationInTheIntersection.vue
 */
// ==================================
// import
// ==================================
import { computed, watch, ref } from 'vue'

import { usePosListStore } from '@/store/app'

import {
  getObjectNameWithVehicleId,
  getShiftName,
} from '@/mixins/commonFunction'
import {
  getObjectMarker,
  DIRECTION_ICON,
  STEERING_ICON,
} from '@/mixins/mapFunction'

import { PosInfo, SensorIdData } from '@/types/Interfaces'

// ==================================
// interface
// ==================================
interface CheckBox {
  label: string;
  value: boolean;
}
interface ListHeader {
  title: string;
  key: string;
  shown: boolean;
}
interface Props {
  itemsPerPage: number;
  selectSensorList: SensorIdData[];
  checkBoxes: CheckBox[];
  listHeader: ListHeader[];
}

interface Emits {
  (e: 'select-object', value: number[]): void;
}

interface PosList {
  mark: any;
  time: string;
  sensor: string;
  kind: string;
  name:  string;
  coordinate: string;
  speed: string;
  direction: {
    width: string;
    height: string;
    transform: string;
  }
  shift: string;
  angle: {
    width: string;
    height: string;
    transform: string;
  }
}

// ==================================
// data
// ==================================
const props = defineProps<Props>()

// 物標情報リスト
const posListStore = usePosListStore()

const dialog = ref<boolean>(false)

const checkboxes = ref<CheckBox[]>(props.checkBoxes)

const listHeaders = ref<ListHeader[]>(props.listHeader)

const posList = ref<PosList[]>([])

// ==================================
// watch
// ==================================
watch(
  () => props.selectSensorList,
  () => {
    updateList()
  }
)

// ==================================
// computed
// ==================================
// 一覧表示数
const itemsPerPage = computed(() => {
  return props.itemsPerPage
})

// テーブル表示項目設定
const shownHeaders = computed(() => {
  return listHeaders.value.filter((h) => h.shown)
})

// ==================================
// method
// ==================================
const emit = defineEmits<Emits>()

/**
 * テーブル表示項目設定ダイアログ表示
 */
const columSelectModalOpen = () => {
  dialog.value = true
}

/**
 * 物標情報更新
 */
const updateList = () => {
  posList.value = []
  for (const pos of posListStore.posList) {
    for (const sensor of props.selectSensorList) {
      if (pos.sensorId === Number(sensor.value)) {
        setPosList(pos.posList, sensor.name, sensor.kind)
      }
    }
  }
}

/**
 * 物標情報設定
 * @param posListFromStore - 物標情報
 * @param sensorName - センサー名
 * @param kind - センサー種別
 */
const setPosList = (posListFromStore: PosInfo[], sensorName: string, kind: string) => {
  posListFromStore.forEach((pos: PosInfo) => {
    let mark = getObjectMarker(pos.vehicleSizeClassification, pos.vehicleRoleClassification)
    let name = getObjectNameWithVehicleId(
      pos.vehicleId,
      pos.vehicleSizeClassification,
      pos.vehicleRoleClassification
    )
    let time = pos.time.slice(5)
    let speed = pos.speed.toFixed(2) + ' m/sec'
    let coord = pos.latitude.toFixed(6) + '\n' + pos.longitude.toFixed(6)
    let shift = getShiftName(pos.transmissionState)
    let directionStyle = getDirectionStyle(pos.heading)
    let steeringStyle = getDirectionStyle(pos.heading)
    posList.value.push({
      mark: mark,
      time: time,
      sensor: sensorName,
      kind: kind,
      name: name,
      coordinate: coord,
      speed: speed,
      direction: directionStyle,
      shift: shift,
      angle: steeringStyle,
    })
  })
}

/**
 * 方向取得
 * @param direction - 回転角度
 */
const getDirectionStyle = (direction: number) => {
  return {
    width: '20px',
    height: '20px',
    transform: 'rotate(' + direction + 'deg)',
  }
}

/**
 * VIRTUAL映像をクリックした物標にフォーカスさせる
 * @param data - イベントオブジェクト
 * @param value - 緯度経度
 */
const onClickEvent = (data: any, value: any) => {
  let coord = value.item.coordinate.split('\n')
  let latlng = [Number(coord[0]), Number(coord[1])]
  emit('select-object', latlng)
}

/**
 * テーブル表示項目設定ダイアログ閉じる
 */
const columSelectModalClose = () => {
  for (let i = 0; i < checkboxes.value.length; i++) {
    listHeaders.value[i].shown = false
    if (checkboxes.value[i].value) {
      listHeaders.value[i].shown = true
    }
  }
  dialog.value = false
}

defineExpose({
  columSelectModalOpen,
  updateList,
})
</script>
<template>
  <v-container class="location-info-table">
    <v-data-table
      density="compact"
      :headers="shownHeaders"
      :items="posList"
      :icon="DIRECTION_ICON"
      :items-per-page="itemsPerPage"
      :footer-props="{ itemsPerPageOptions: [itemsPerPage] }"
      @click:row="onClickEvent"
    >
      <template #[`item.mark`]="{ item }: any">
        <img
          :src="item.mark"
          class="location-info-table__item"
        >
      </template>
      <template #[`item.direction`]="{ item }: any">
        <img
          :src="DIRECTION_ICON"
          :style="item.direction"
        >
      </template>
      <template #[`item.angle`]="{ item }: any">
        <img
          :src="STEERING_ICON"
          :style="item.angle"
        >
      </template>
    </v-data-table>

    <v-dialog
      v-model="dialog"
      persistent
      max-width="350"
    >
      <v-card
        elevation="20"
        raised
      >
        <v-card-title class="location-info-table__title">
          表示項目設定
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-checkbox
            v-for="check in checkboxes"
            :key="check.label"
            v-model="check.value"
            primary
            hide-details
            :label="check.label"
          />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            variant="flat"
            color="blue-grey"
            dark
            rounded
            @click="columSelectModalClose"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<style lang="scss" scoped>
  .location-info-table {
    &__title {
      height: 6vh;
      font-size: 17px;
      font-weight: bold;
    }
    &__item {
      width: 25px;
      height: 25px;
      filter: brightness(0);
    }
  }
</style>
<style>
  .v-data-table__th {
    background-color: lightgray !important;
    font-size: 11px !important;
  }
  .v-data-table__td {
    font-size: 11px !important;
  }
  .v-data-table-footer {
    display: flex !important;
    align-items: center !important;
    flex-wrap: wrap !important;
    padding: 0 0px !important;
    justify-content: flex-end !important;
    font-size: 11px !important;
  }
  .v-data-table-footer .v-input__control {
    max-height: 35px !important;
  }
  .v-data-table-footer .v-field__input {
    padding-top: 0% !important;
    padding-bottom: 0% !important;
    min-height: auto !important;
  }
</style>
