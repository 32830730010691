/* eslint-disable no-async-promise-executor */
// ==================================
// 外部機能接続共通処理
// ==================================
import { 
  useUserInfoStore,
  useAreaListStore,
  usePoleListStore,
  usePoleInfoStore,
  useSelectPoleStore,
  usePosListStore,
  useLedListStore,
  useNearMissListStore,
  useAccidentListStore,
  useAggregationDataStore,
  useMacroAnalysisInfoStore,
  useMacroAnalysisExcelDataStore,
  useWatchingListStore,
  usePassingObjectsCollectionListStore,
  useStopObjectsCollectionListStore,
  useVdiParamListStore,
  useGroupListStore,
  usePoleGroupListStore
} from '@/store/app'

import {
  AccessDirection,
  AccidentInfo,
  AreaInfo,
  ChannelName,
  DistanceFromPoleToVehicleByAreaId,
  MacroAnalysisDetailInfo,
  MacroAnalysisInfo,
  MacroAnalysisInfoDataForExcel,
  NearMissInfo,
  PassingObjectInfoAll,
  PoleInfo,
  PoleListStore,
  PosAndLedList,
  PosListBySensor,
  PosInfoWithPole,
  ResponseStatus,
  SummaryReports
 } from '@/types/Interfaces'

import { SERVER, POSLIST_DIFFTIME } from '@/setting/setting'

import { API } from 'aws-amplify'
import axios from 'axios'
import * as log from 'loglevel'

// ==================================
// 各種定義
// ==================================
// 各APIドメイン定義(src/setting/aws-exports.ts)参照
const apiDomain = {
  server: 'spitsServer',
  serverDevelop: 'spitsServerDev',
  serverCloud: 'spitsServerCloud',
  serverLocal: 'spitsServerLocal',
  gateway: 'getSignedUrl',
}

// APIルートリソース
const rootResource = '/spits_server/'

// APIリソース
const url = {
  // S3バケットspits-video-bucket(過去事故情報映像格納場所)アクセス認証
  signedUrl: '/default/create_cf_signed_url',
  // エリア情報
  areaInfo: rootResource + 'getAreaInfo',
  // ポール情報
  poleInfo: rootResource + 'getSmartPoleInfo',
  // 過去事故情報一覧
  historyInfo: rootResource + 'getHistoryDataInfo',
  // 過去物標情報
  posInfoWithPole: rootResource + 'getPositionInfoWithPole',
  // 最新物標情
  posInfoReal: rootResource + 'getPositionInfoReal',
  // ストリーミング開始要求
  streamingStart: rootResource + 'streamingStartReq',
  // ストリーミング応答確認
  streamingChkResp: rootResource + 'streamingChkResp',
  // ストリーミング再生中通知
  streamingActiveNotify: rootResource + 'streamingActiveNotify',
  // ヒヤリハット情報
  nearMisses: rootResource + 'getNearMissInfoByPoleId',
  nearMissSummary: rootResource + 'getNearMissSummary',
  // 事故情報
  accidentInfo: rootResource + 'getAccidentInfoByPoleId',
  // 駐停車情報
  parkingInfo: rootResource + 'getParkedInfoByPoleId',
  // 見守り情報
  watchingInfo: rootResource + 'getDistanceFromPoleToVehicleByAreaId',
  // 日時サマリー情報
  summaryDaily: rootResource + 'getDailySummariesByPoleId',
  // 週次サマリー情報
  summaryWeekly: rootResource + 'getWeeklySummariesByPoleId',
  // マクロ分析情報
  macroAnalysisInfo: rootResource + 'getMacroAnalysisInfo',
  // マクロ分析詳細情報
  macroAnalysisDetailInfo: rootResource + 'getMacroAnalysisDetailInfo',
  // マクロ分析データ作成
  createMacroAnalysisInfoDataForExcel: rootResource + 'createMacroAnalysisInfoDataForExcel',
  // マクロ分析データ作成ステータステーブルデータ情報
  getMacroAnalysisInfoDataForExcel: rootResource + 'getMacroAnalysisInfoDataForExcel',
  // 通過物標件数集計データ取得
  getPassingObjects: rootResource + 'getPassingObjects',
  // 一時停止件数集計データ取得
  getStopObjects: rootResource + 'getStopObjects',
  // 進入方路方位リスト取得
  getAccessDirection: rootResource + 'getAccessDirection',
  // グループ情報一覧
  groupInfo: rootResource + 'getGroupInfo',
  // グループ情報作成
  createGroupInfo: rootResource + 'createGroupInfo',
  // グループ情報更新
  updateGroupInfo: rootResource + 'updateGroupInfo',
  // グループ情報削除
  deleteGroupInfo: rootResource + 'deleteGroupInfo',
  // ポールのグループ情報一覧
  poleGroupInfo: rootResource + 'getPoleGroupInfo',
  // ポールのグループ情報更新
  updatePoleGroupInfo: rootResource + 'updatePoleGroupInfo'
}

// エラーコード種別
export const apiResponseErrorCode = {
  noContent: 204,
  badRequest: 400,
  internalServerError: 500,
}

// openstreetmap情報下記参照
// https://kazuki-nagasawa.hatenablog.com/entry/openstreetmap_nominatim
// https://nominatim.org/release-docs/latest/api/Reverse/
const mapAddress = {
  endpoint: 'https://nominatim.openstreetmap.org/reverse',
  format: 'jsonv2',
}
// ストリーミング状態種別
export const streamingCheckRespCode = {
  wait: 0,
  ok: 1,
  ng: 2,
}

// ==================================
// methods
// ==================================
/**
 * 呼び出しサーバのDomain取得
 * @param serverState - 環境ID
 * @returns サーバードメイン
 */
const getServer = (serverState: number = SERVER): string => {
  let server = ''
  switch (serverState) {
    case 0:
      server = apiDomain.server
      break
    case 1:
      server = apiDomain.serverDevelop
      break
    case 2:
      server = apiDomain.serverCloud
      break
    case 3:
      server = apiDomain.serverLocal
      break
    default:
      server = ''
  }
  return server
}

/**
 * openstreetmapから緯度・経度を指定し住所や地名を取得
 * @param lat - 緯度
 * @param lng - 経度
 * @returns API取得結果
 */
export const addressByCoordinates = async (lat: number, lng: number): Promise<any> => {
  return await axios.get(mapAddress.endpoint, {
    params: {
      format: mapAddress.format,
      lat: lat,
      lon: lng,
    },
  })
}

/**
 * エリア情報取得
 * @returns API取得結果
 */
export const updateAreaList = (): Promise<AreaInfo[]> => {
  return new Promise(async (resolve, reject) => {
    // ユーザー情報ストア
    const userInfoStore = useUserInfoStore()
    // エリア情報ストア
    const areaListStore = useAreaListStore()
    areaListStore.clearData()
    try {
      const accessToken = userInfoStore.getJwtToken
      const args = {
        headers: {
          'coginito-access-token': accessToken,
        },
      }
      await API.get(getServer(), url.areaInfo, args)
        .then((res: AreaInfo[]) => {
          if (res == null) {
            reject(apiResponseErrorCode.noContent)
          } else {
            areaListStore.setData(res)
            resolve(res)
          }
        })
        .catch((error: any) => {
          log.error('updateAreaList error ' + error)
          reject(apiResponseErrorCode.internalServerError)
        })
    } catch {
      log.error('updateAreaList error Can\'t call API')
      reject(apiResponseErrorCode.internalServerError)
    }
  })
}

interface GetPoleListArgs {
  headers: {
    'coginito-access-token': string;
  },
  queryStringParameters: {
    areaId?: string;
    poleId?: string;
    datetime?: string;
  }
}
/**
 * ポール一覧情報を取得する
 * @param areaId - エリアID
 * @param poleId - ポールID
 * @param datetime - 取得対象日時
 * @param isOutputError - エラー出力有無
 * @returns ポール一覧取得関数
 */
export const getPoleList = (areaId: number | null, poleId: number | null, datetime: string | null, isOutputError: boolean): Promise<PoleInfo[]> => {
  return new Promise(async(resolve, reject) => {
    // ユーザ情報
    const userInfoStore = useUserInfoStore()
    // ポール一覧情報
    const poleListStore = usePoleListStore()
    // ポール情報
    const poleInfoStore = usePoleInfoStore()
    // ポール選択情報
    const selectPoleStore = useSelectPoleStore()
    // エリアIDが設定されていたら、ポール一覧取得を行う
    if (areaId !== void 0 && areaId !== null) {
      poleListStore.clearData()
    // ポールIDが設定されていたら、ポール情報取得を行う
    } else if (poleId !== void 0 && poleId !== null) {
      poleInfoStore.clearData()
    }
    try {
      const accessToken = userInfoStore.getJwtToken
      // API向けのパラメータを設定
      const args: GetPoleListArgs = {
        headers: {
          'coginito-access-token': accessToken,
        },
        queryStringParameters: {}
      }
      if (areaId !== void 0 && areaId !== null) {
        args.queryStringParameters.areaId = areaId.toString()
      } else if (poleId !== void 0 && poleId !== null) {
        args.queryStringParameters.poleId = poleId.toString()
      }
      if (datetime !== void 0 && datetime !== null) {
        args.queryStringParameters.datetime = datetime
      }
      // APIを呼び出し、戻り値を取得する
      await API.get(getServer(), url.poleInfo, args)
        // .then((res: PoleInfo[]) => {
        .then((res) => {
          if (res === null) {
            // 戻り値が存在しない際にエラーを出力する場合、例外を発生させる
            if (isOutputError) {
              reject(apiResponseErrorCode.noContent)
            }
          } else {
            // 指定されたパラメータに応じて、適切なデータを設定する
            if (areaId !== void 0 && areaId !== null) {
              poleListStore.setData(res)
            } else if (poleId !== void 0 && poleId !== null) {
              poleInfoStore.setData(res, selectPoleStore.name)
            }
            resolve(res)
          }
        })
        .catch((error: any) => {
          log.error('getPoleList error ' + error)
          reject(apiResponseErrorCode.internalServerError)
        })      
    } catch {
      log.error('getPoleList error Can\'t call API')
      reject(apiResponseErrorCode.internalServerError)
    }
  })
}

/**
 * エリアに紐づくポール情報取得
 * @param areaId - エリアID
 * @returns API取得結果
 */
export const updatePoleListAsAreaId = (areaId: number): Promise<PoleInfo[]> => {
  return new Promise(async (resolve, reject) => {
    // ユーザー情報ストア
    const userInfoStore = useUserInfoStore()
    // ポール一覧情報ストア
    const poleListStore = usePoleListStore()
    poleListStore.clearData()
    try {
      const accessToken = userInfoStore.getJwtToken
      const args = {
        headers: {
          'coginito-access-token': accessToken,
        },
        queryStringParameters: {
          areaId: areaId.toString(),
        },
      }
      await API.get(getServer(), url.poleInfo, args)
        .then((res: PoleInfo[]) => {
          if (res == null) {
            reject(apiResponseErrorCode.noContent)
          } else {
            poleListStore.setData(res)
            resolve(res)
          }
        })
        .catch((error: any) => {
          log.error('updatePoleListAsAreaId error ' + error)
          reject(apiResponseErrorCode.internalServerError)
        })
    } catch {
      log.error('updatePoleListAsAreaId error Can\'t call API')
      reject(apiResponseErrorCode.internalServerError)
    }
  })
}

interface UpdatePoleListAsPoleIdArgs {
  headers: {
    'coginito-access-token': string;
  },
  queryStringParameters: {
    poleId: string;
    datetime?: string;
  }
}
/**
 * ポールIDに紐づくセンサー情報を取得
 * @param poleId - ポールID
 * @param datetime - 対象日時 YYYY/MM/DD HH:mm:ss 形式
 * @returns API取得結果
 */
export const updatePoleListAsPoleId = async (poleId: number, datetime: string | undefined = undefined): Promise<PoleInfo[]> => {
  return new Promise(async (resolve, reject) => {
    // ユーザー情報ストア
    const userInfoStore = useUserInfoStore()
    // ポール選択情報
    const selectPoleStore = useSelectPoleStore()
    // ポール情報ストア
    const poleInfoStore = usePoleInfoStore()
    poleInfoStore.clearData()
    try {
      const accessToken = userInfoStore.getJwtToken
      const args: UpdatePoleListAsPoleIdArgs = {
        queryStringParameters: {
          poleId: poleId.toString(),
        },
        headers: {
          'coginito-access-token': accessToken,
        },
      }
      if (datetime != undefined) {
        args.queryStringParameters.datetime = datetime
      }
      await API.get(getServer(), url.poleInfo, args)
        .then((res: PoleInfo[]) => {
          if (res == null) {
            reject(apiResponseErrorCode.noContent)
          } else {
            poleInfoStore.setData(res, selectPoleStore.name)
            resolve(res)
          }
        })
        .catch((error: any) => {
          log.error('updatePoleListAsPoleId error ' + error)
          reject(apiResponseErrorCode.internalServerError)
        })
    } catch {
      log.error('updatePoleListAsPoleId error Can\'t call API')
      reject(apiResponseErrorCode.internalServerError)
    }
  })
}

/**
 * ポールIDに紐づく最新物標情報取得後、物標情報更新
 * @param poleId - ポールID
 * @param sensorIds - センサーID一覧
 * @returns API取得結果
 */
export const updatePosListReal = (poleId: number, sensorIds: number[]): Promise<PosAndLedList> => {
  return new Promise(async (resolve, reject) => {
    // 物標情報リスト
    const posListStore = usePosListStore()
    posListStore.clearData()
    // LED表示板情報リスト
    const ledListStore = useLedListStore()
    ledListStore.clearData()
    try {
      const args = {
        queryStringParameters: {
          poleId: poleId.toString(),
          difftime: POSLIST_DIFFTIME,
          sensorIds: sensorIds.toString(),
        },
        headers: {
          'Cache-Control': 'no-cache',
        },
      }
      await API.get(getServer(), url.posInfoReal, args)
        .then((res: PosAndLedList) => {
          if (res == null) {
            reject(apiResponseErrorCode.noContent)
          } else {
            posListStore.setData(res.posList)
            ledListStore.setData(res.ledList)
            resolve(res)
          }
        })
        .catch((error: any) => {
          log.error('updatePosListReal error ' + error)
          reject(apiResponseErrorCode.internalServerError)
        })
    } catch {
      log.error('updatePosListReal error Can\'t call API')
      reject(apiResponseErrorCode.internalServerError)
    }
  })
}

interface UpdatePosListWithPoleArgs {
  headers: {
    'Cache-Control': string;
  };
  queryStringParameters: {
    poleId: string;
    diffTime: number;
    dateTime?: string;
    isMultiCamera?: boolean;
  };
}
/**
 * ポールIDに紐づく過去物標情報取得後、ポール情報及び物標情報更新
 * @param poleId - ポールID
 * @param dateTime - 対象日時 YYYY/MM/DD HH:mm:ss 形式
 * @param isMultiCamera - マルチ画面で必要なデータか判定
 * @param isSetPole - ポール情報有無判別フラグ
 * @returns API取得結果
 */
export const updatePosListWithPole = (poleId: number, dateTime: string = '', isMultiCamera: boolean = false, isSetPole: boolean = false): Promise<PosInfoWithPole> => {
  return new Promise(async (resolve, reject) => {
    // ポール選択情報
    const selectPoleStore = useSelectPoleStore()
    // ポール情報ストア
    const poleInfoStore = usePoleInfoStore()
    if (isSetPole) {
      poleInfoStore.clearData()
    }

    // 物標情報リスト
    const posListStore = usePosListStore()
    posListStore.clearData()
    // LED表示板情報リスト
    const ledListStore = useLedListStore()
    ledListStore.clearData()
    try {
      const args: UpdatePosListWithPoleArgs = {
        queryStringParameters: {
          poleId: poleId.toString(),
          diffTime: POSLIST_DIFFTIME,
          isMultiCamera: isMultiCamera,
        },
        headers: {
          'Cache-Control': 'no-cache',
        },
      }
      if (dateTime != '') {
        args.queryStringParameters.dateTime = dateTime
      }
      await API.get(getServer(), url.posInfoWithPole, args)
        .then((res: PosInfoWithPole) => {
          if (res == null) {
            reject(apiResponseErrorCode.noContent)
          } else {
            if (isSetPole) {
              poleInfoStore.setData(res.poleList, selectPoleStore.name)
            }

            posListStore.setData(res.posList)
            ledListStore.setData(res.ledList)
            resolve(res)
          }
        })
        .catch((error: any) => {
          log.error('updatePosListWithPole error ' + error)
          reject(apiResponseErrorCode.internalServerError)
        })
    } catch {
      log.error('updatePosListWithPole error Can\'t call API')
      reject(apiResponseErrorCode.internalServerError)
    }
  })
}

/**
 * ヒヤリハット情報取得
 * @param poleId - ポールID
 * @param dates - 日時 [YYYY/MM/DD,YYYY/MM/DD]
 * @param fromTime - 開始日時 YYYY/MM/DD HH:mm:ss 形式
 * @param toTime - 終了日時 YYYY/MM/DD HH:mm:ss 形式
 * @param threshold - 閾値
 * @param isDeduplication - 重複行排除可否
 * @param isRange - 範囲検索可否
 * @returns API取得結果
 */
export const updateNearMissesData = (
    poleId: number,
    dates: string[],
    fromTime: string,
    toTime: string,
    threshold: number,
    isDeduplication: boolean,
    isRange: boolean
  ): Promise<NearMissInfo[]> => {
  return new Promise(async (resolve, reject) => {
    const NearMissListStore = useNearMissListStore()
    NearMissListStore.clearData()
    try {
      const args = {
        queryStringParameters: {
          poleId: poleId.toString(),
          dates: dates.toString(),
          fromTime: fromTime,
          toTime: toTime,
          threshold: threshold.toString(),
          isDeduplication: isDeduplication,
          isRange: isRange,
        },
      }
      await API.get(getServer(), url.nearMisses, args)
        .then((res: NearMissInfo[]) => {
          if (res == null) {
            reject(apiResponseErrorCode.noContent)
          } else {
            NearMissListStore.setData(res)
            resolve(res)
          }
        })
        .catch((error) => {
          log.error('updateNearMissesList error ' + error)
          reject(apiResponseErrorCode.internalServerError)
        })
    } catch {
      log.error('updateNearMissesList error Can\'t call API')
      reject(apiResponseErrorCode.internalServerError)
    }
  })
}

/**
 * ヒヤリハット詳細情報取得
 * @param poleId - ポールID
 * @param obj1Id - 車両ID1
 * @param obj2Id - 車両ID2
 * @param occurredTime - ヒヤリハット発生時刻 YYYY/MM/DD HH:mm:ss 形式
 * @returns API取得結果
 */
/*
export const updateNearMissDetailInfo = (poleId: number, obj1Id: number, obj2Id: number, occurredTime: string): Promise<any> => {
  return new Promise(async (resolve, reject) => {
    const nearMissDetailInfoStore = useNearMissDetailInfoStore()
    nearMissDetailInfoStore.clearData()
    try {
      const args = {
        queryStringParameters: {
          poleId: poleId.toString(),
          obj1Id: obj1Id.toString(),
          obj2Id: obj2Id.toString(),
          occurredTime: occurredTime,
        },
      }
      await API.get(getServer(), url.nearMissDetailInfo, args)
        .then((res: any) => {
          if (res == null) {
            reject(apiResponseErrorCode.noContent)
          } else {
            nearMissDetailInfoStore.setData(res)
            resolve(res)
          }
        })
        .catch((error) => {
          log.error("updateNearMissDetailInfo error " + error)
          reject(apiResponseErrorCode.internalServerError)
        })
    } catch {
      log.error("updateNearMissDetailInfo error Can't call API")
      reject(apiResponseErrorCode.internalServerError)
    }
  })
}
*/
/**
 * ヒヤリハット件数を取得する
 * @param areaId - エリアID
 * @param poleId - ポールID
 * @param startTime - 取得開始日時
 * @param endTime - 取得終了日時
 * @returns 取得処理自体を同期
 */
/*
export const getNearMissSummary = async(areaId: number | null, poleId: number| null, startTime: string, endTime: string): Promise<void> => {
  // 他の処理と同時に行うことを想定して非同期制御できるようにする
  return new Promise(async (resolve, reject) => {
    // Vuexストア上の件数保管用ステートをリセットする
    const nearMissSummaryStore = useNearMissSummaryListStore()
    nearMissSummaryStore.clearData()
    try {
      // APIに送るパラメータの整理
      const params = {
        queryStringParameters: {}
      } as {
        queryStringParameters: any
      }
      // 日時設定フラグ
      let startFlag = false
      let endFlag = false
      // パラメータとして設定されている値のみを追加する
      if (areaId !== null) {
        params.queryStringParameters.areaId = areaId
      }
      if (poleId !== null) {
        params.queryStringParameters.poleId = poleId
      }
      if (startTime !== "") {
        params.queryStringParameters.startTime = startTime
        startFlag = true
      }
      if (endTime !== "") {
        params.queryStringParameters.endTime = endTime
        endFlag = true
      }
      // 取得開始日時と終了日時が設定されている場合、指定順序が逆になっていないかを確認する
      if (startFlag && endFlag) {
        const timeFormat = "YYYY/MM/DD HH:mm:ss"
        const start = DateTime.fromFormat(startTime, timeFormat)
        const end = DateTime.fromFormat(endTime, timeFormat)
        // 逆になっている場合は400エラーを返す
        if (end.diff(start).seconds < 0) {
          reject(apiResponseErrorCode.badRequest)
        }
      }
      // AWS API Gatewayにアクセスして件数取得をリクエストする
      await API.get(getServer(), url.nearMissSummary, params)
        // レスポンスが返ってきてデータが含まれていれば、Vuexストアに登録する
        .then(res => {
          if (res === null) {
            // 204エラー
            reject(apiResponseErrorCode.noContent)
          } else {
            // 正常
            // this.$store.dispatch(STORE_ACTIONS.setNearMissSummaryList, res)
            nearMissSummaryStore.setData(res)
            resolve()
          }
        })
        .catch(error => {
          // 例外は500エラーとして処理する
          log.error("updateAccidentsData error " + error)
          reject(apiResponseErrorCode.internalServerError)
        })
    } catch {
      // APIの呼び出しに失敗した場合、500エラーとして処理する
      log.error("updateAccidentsData error Can't call API")
      reject(apiResponseErrorCode.internalServerError)
    }
  })
}
*/

/**
 * 事故情報取得
 * @param poleId - ポールID
 * @param dates - 日時 [YYYY/MM/DD,YYYY/MM/DD]
 * @param fromTime - 開始日時 HH:mm:ss 形式
 * @param toTime - 終了日時  HH:mm:ss 形式
 * @param isRange - 範囲検索可否
 * @returns API取得結果
 */
export const updateAccidentsData = (poleId: number, dates: string[], fromTime: string, toTime: string, isRange: boolean): Promise<AccidentInfo[]> => {
  return new Promise(async (resolve, reject) => {
    const accidentListStore = useAccidentListStore()
    accidentListStore.clearData()
    try {
      const args = {
        queryStringParameters: {
          poleId: poleId.toString(),
          dates: dates.toString(),
          fromTime: fromTime,
          toTime: toTime,
          isRange: isRange,
        },
      }
      await API.get(getServer(), url.accidentInfo, args)
        .then((res: AccidentInfo[]) => {
          if (res == null) {
            reject(apiResponseErrorCode.noContent)
          } else {
            accidentListStore.setData(res)
            resolve(res)
          }
        })
        .catch((error) => {
          log.error('updateAccidentsData error ' + error)
          reject(apiResponseErrorCode.internalServerError)
        })
    } catch {
      log.error('updateAccidentsData error Can\'t call API')
      reject(apiResponseErrorCode.internalServerError)
    }
  })
}

interface UpdateAccidentListArgs {
  headers: {
    'coginito-access-token': string;
  },
  queryStringParameters: {
    poleId?: string;
  }
}
/**
 * 過去事故情報一覧更新
 * @param poleId - ポールID
 * @returns Promiseオブジェクト
 */
export const updateAccidentList = async (poleId?: number): Promise<void> => {
  return new Promise(async (resolve, reject) => {
    const userInfoStore = useUserInfoStore()
    const accidentListStore = useAccidentListStore()
    accidentListStore.clearData()
    try {
      const accessToken = userInfoStore.getJwtToken
      const args: UpdateAccidentListArgs = {
        headers: {
          'coginito-access-token': accessToken
        },
        queryStringParameters: {}
      }
      if (poleId !== void 0 && poleId !== null) {
        args.queryStringParameters.poleId = poleId.toString()
      }
      await API.get(getServer(), url.historyInfo, args)
        .then((res: AccidentInfo[]) => {
          if (res == null) {
            reject(apiResponseErrorCode.noContent)
          } else {
            accidentListStore.setData(res)
            resolve()
          }
        })
        .catch(error => {
          log.error('updateAccidentList error' + error)
          reject(apiResponseErrorCode.internalServerError)
        })
    } catch (e) {
      log.error('updateAccidentList error Can\'t call API' + e)
      reject(apiResponseErrorCode.internalServerError)
    }
  })
}

/**
 * ポールに対して、対象時間中の事故発生件数を返す
 * @param poleId - ポールID
 * @param dates - 日時 [YYYY/MM/DD,YYYY/MM/DD]
 * @param fromTime - 開始日時 HH:mm:ss 形式
 * @param toTime - 終了日時  HH:mm:ss 形式
 * @param isRange - 範囲検索可否
 * @returns 処理関数
 */
export const getAccidentNumber = async (poleId: number, dates: string[], fromTime: string, toTime: string, isRange: boolean): Promise<number | string> => {
  return new Promise(async(resolve, reject) => {
    const params = {
      queryStringParameters: {
        poleId: poleId.toString(),
        dates: dates.toString(),
        fromTime: fromTime,
        toTime: toTime,
        isRange: isRange
      }
    }
    await API.get(getServer(), url.accidentInfo, params)
      .then(res => {
        if (res !== void 0 && res !== null) {
          resolve(res.length)
        } else {
          reject(apiResponseErrorCode.noContent)
        }
      })
      .catch(error => {
        log.error('updateAccidentsData error ' + error)
        reject(apiResponseErrorCode.internalServerError)
      })
  })
}

/**
 * 物標通過台数を出力する
 * @param id - ポールID
 * @returns 取得用関数
 */
export const addGoneThroughNumber = async(id: number): Promise<number | string> => {
  return new Promise((resolve, reject) => {
    try {
      const sensorIdList: number[] = []
      let goneThroughNumber = 0
      // 物標情報取得のためのセンサーID一覧を設定する
      const poleListStore = usePoleListStore()
      // 取得済みのポール一覧に、該当するポールが存在しない場合は例外を発生させる
      const target = poleListStore.poleList.find((pole: PoleListStore) => pole.poleId === id)
      if (target === void 0) {
        throw new Error()
      }      
      for (const sensor of target.sensorList) {
        sensorIdList.push(sensor.sensorId)
      }
      // APIにアクセスして直近24時間の物標情報を取得する
      const positionListFunc = getLatestPositionList(id, sensorIdList, 86400)
      positionListFunc
        .then((res: PosListBySensor[] | string) => {
          if (res !== void 0 && typeof res !== 'string' && res.length > 0) {
            // 各センサーで取得した物標情報の数を合算する
            for (const pos of res) {
              goneThroughNumber += pos.posList.length
            }
            resolve(goneThroughNumber)
          } else {
            // 取得できなかった場合は0を返す
            resolve(0)
          }
        })
        .catch(() => {
          reject(apiResponseErrorCode.internalServerError)
        })          
    } catch {
      reject(apiResponseErrorCode.internalServerError)
    }
  })
}

/**
 * 最新の物標情報一覧取得
 * @param id - ポールID
 * @param sensorIdList - ポールに設置しているセンサーのID一覧
 * @param sec - 物標情報取得期間
 * @returns 処理関数
 */
const getLatestPositionList = async(id: number, sensorIdList: number[], sec: number): Promise<PosListBySensor[] | string> => {
  return new Promise(async(resolve, reject) => {
    const params = {
      queryStringParameters: {
        poleId: id.toString(),
        difftime: sec,
        sensorIds: sensorIdList.toString()
      },
      headers: {
        'Cache-Control': 'no-cache'
      }
    }
    await API.get(getServer(), url.posInfoReal, params)
      .then(res => {
        // 正常終了しても1件も取得できなかったらエラーを返す
        if (res === void 0 || res === null || res.length === 0) {
          reject(apiResponseErrorCode.noContent)
        } else {
          resolve(res.posList)
        }
      })
      .catch(error => {
        log.error('updatePosListReal error ' + error)
        reject(apiResponseErrorCode.internalServerError)
      })
  })
}

// ============================================================
// kinesisVideo関連
// ============================================================
/**
 * ストリーミング開始要求送信
 * @param poleId - ポールID
 * @param sensorId - センサーIDID
 * @returns API取得結果
 */
export const streamingStart = (poleId: number, sensorId: number): Promise<ChannelName> => {
  return new Promise(async (resolve, reject) => {
    try {
      const args = {
        queryStringParameters: {
          poleId: poleId,
          sensorId: sensorId,
        },
        headers: {
          'Cache-Control': 'no-cache',
        },
      }
      await API.post(getServer(), url.streamingStart, args)
        .then((res: ChannelName) => {
          if (res == null) {
            reject(apiResponseErrorCode.noContent)
          } else {
            resolve(res)
          }
        })
        .catch((error: any) => {
          log.error('streamingStart error ' + error)
          reject(apiResponseErrorCode.internalServerError)
        })
    } catch {
      log.error('streamingStart error Can\'t call API')
      reject(apiResponseErrorCode.internalServerError)
    }
  })
}

/**
 * ストリーミング応答確認情報取得
 * @param channel - KVSチャネル名
 * @returns API取得結果
 */
export const streamingChkResp = (channel: string): Promise<ResponseStatus> => {
  return new Promise(async (resolve, reject) => {
    try {
      const args = {
        queryStringParameters: {
          channel: channel,
        },
        headers: {
          'Cache-Control': 'no-cache',
        },
      }
      await API.get(getServer(), url.streamingChkResp, args)
        .then((res: ResponseStatus) => {
          if (res == null) {
            reject(apiResponseErrorCode.noContent)
          } else {
            resolve(res)
          }
        })
        .catch((error: any) => {
          log.error('streamingChkResp error ' + error)
          reject(apiResponseErrorCode.internalServerError)
        })
    } catch {
      log.error('streamingChkResp error Can\'t call API')
      reject(apiResponseErrorCode.internalServerError)
    }
  })
}

/**
 *  ストリーミング再生中通知送信
 * @param channel - KVSチャネル名
 * @returns API取得結果
 */
export const streamingActiveNotify = (channel: string): Promise<any> => {
  return new Promise(async (resolve, reject) => {
    try {
      const args = {
        queryStringParameters: {
          channel: channel,
        },
        headers: {
          'Cache-Control': 'no-cache',
        },
      }
      await API.post(getServer(), url.streamingActiveNotify, args)
        .then((res: any) => {
          resolve(res)
        })
        .catch((error: any) => {
          log.error('streamingActiveNotify error ' + error)
          reject(apiResponseErrorCode.internalServerError)
        })
    } catch {
      log.error('streamingActiveNotify error Can\'t call API')
      reject(apiResponseErrorCode.internalServerError)
    }
  })
}

/**
 * 日時サマリー情報取得
 * @param poleId - ポールID
 * @param summariesDate - 対象日時 YYYY/MM/DD HH:mm:ss 形式
 * @param nmaThr - ヒヤリハット閾値
 * @param parkedThr - 駐停車状況閾値
 * @returns API取得結果
 */
export const updateSummariesDailyData = (async(poleId: number, summariesDate: string, nmaThr: number, parkedThr: number): Promise<SummaryReports> => {
  return new Promise(async (resolve, reject) => {
    // this.$store.dispatch(STORE_ACTIONS.setSumariesDailyList, [])
    const aggregationDataStore = useAggregationDataStore()
    aggregationDataStore.updateSummary(0, <SummaryReports>{})
    try {
      let params = {
        queryStringParameters: {
          poleId: poleId.toString(),
          SummariesDate: summariesDate,
          nmaThr: nmaThr,
          parkedThr: parkedThr
        }
      }
      await API.get(getServer(), url.summaryDaily, params)
        .then((res: SummaryReports[]) => {
          if (res == null) {
            reject(apiResponseErrorCode.noContent)
          } else {
            // this.$store.dispatch(STORE_ACTIONS.setSumariesDailyList, res[0])
            aggregationDataStore.updateSummary(0, res[0])
            resolve(res[0])
          }
        })
        .catch(error => {
          log.error('updateSummariesDailyData error ' + error)
          reject(apiResponseErrorCode.internalServerError)
        })
    } catch {
      log.error('updateSummariesDailyData error Can\'t call API')
      reject(apiResponseErrorCode.internalServerError)
    }
  })
})

/**
 * 週次サマリー情報取得
 * @param poleId - ポールID
 * @param summariesDate - 対象日時 YYYY/MM/DD HH:mm:ss 形式
 * @param nmaThr - ヒヤリハット閾値
 * @param parkedThr - 駐停車状況閾値
 * @returns API取得結果
 */
export const updateSummariesWeeklyData = (async(poleId: number, summariesDate: string, nmaThr: number, parkedThr: number): Promise<SummaryReports> => {
  return new Promise(async (resolve, reject) => {
    // this.$store.dispatch(STORE_ACTIONS.setSumariesWeeklyList, [])
    const aggregationDataStore = useAggregationDataStore()
    aggregationDataStore.updateSummary(1, <SummaryReports>{})
    try {
      let queryParams = {
        queryStringParameters: {
          poleId: poleId.toString(),
          SummariesDate: summariesDate,
          nmaThr: nmaThr,
          parkedThr: parkedThr
        }
      }
      await API.get(getServer(), url.summaryWeekly, queryParams)
        .then((res: SummaryReports[]) => {
          if (res == null) {
            reject(apiResponseErrorCode.noContent)
          } else {
            // this.$store.dispatch(STORE_ACTIONS.setSumariesWeeklyList, res[0])
            aggregationDataStore.updateSummary(1, res[0])
            resolve(res[0])
          }
        })
        .catch(error => {
          log.error('updateSumariesWeeklyData error ' + error)
          reject(apiResponseErrorCode.internalServerError)
        })
    } catch {
      log.error('updateSumariesWeeklyData error Can\'t call API')
      reject(apiResponseErrorCode.internalServerError)
    }
  })
})

/**
 * マクロ分析関連
 */

/**
 * マクロ分析情報取得
 * @param poleId - ポールID
 * @param aggregationPeriodStart - 集計期間A 開始日 YYYY/MM/DD HH:mm:ss 形式
 * @param aggregationPeriodEnd - 集計期間A 終了日 YYYY/MM/DD HH:mm:ss 形式
 * @param comparisonPeriodStart - 比較期間B 開始日 YYYY/MM/DD HH:mm:ss 形式
 * @param comparisonPeriodEnd - 比較期間B 終了日 YYYY/MM/DD HH:mm:ss 形式
 * @param direction - 方路
 * @param led - LED
 * @param aggregationTarget - 集計対象
 * @param aggregationMethod - 集計方法
 * @returns API取得結果
 */
export const updateMacroAnalysisInfo = (
  poleId: number,
  aggregationPeriodStart: string,
  aggregationPeriodEnd: string,
  comparisonPeriodStart: string,
  comparisonPeriodEnd: string,
  direction: string,
  led: string,
  aggregationTarget: string,
  aggregationMethod: string
): Promise<MacroAnalysisInfo> => {
  return new Promise(async (resolve, reject) => {
    const macroAnalysisInfoStore = useMacroAnalysisInfoStore()
    // this.$store.dispatch(STORE_ACTIONS.setMacroAnalysisInfo, {})
    macroAnalysisInfoStore.setMacroAnalysisInfo({})
    try {
      let params = {
        queryStringParameters: {
          poleId: poleId.toString(),
          aggregationPeriodStart: aggregationPeriodStart,
          aggregationPeriodEnd: aggregationPeriodEnd,
          comparisonPeriodStart: comparisonPeriodStart,
          comparisonPeriodEnd: comparisonPeriodEnd,
          direction: direction,
          led: led,
          aggregationTarget: aggregationTarget,
          aggregationMethod: aggregationMethod
        }
      }
      await API.get(getServer(), url.macroAnalysisInfo, params)
        .then(res => {
          if (res == null) {
            reject(apiResponseErrorCode.noContent)
          } else {
            // this.$store.dispatch(STORE_ACTIONS.setMacroAnalysisInfo, res)
            macroAnalysisInfoStore.setMacroAnalysisInfo(res)
            resolve(res)
          }
        })
        .catch(error => {
          log.error('updateMacroAnalysisInfo error ' + error)
          reject(apiResponseErrorCode.internalServerError)
        })
    } catch (e) {
      log.error('updateMacroAnalysisInfo error Can\'t call API' + e)
      reject(apiResponseErrorCode.internalServerError)
    }
  })
}

/**
 * マクロ分析詳細情報取得
 * @param section - 区間
 * @param poleId - ポールID
 * @param aggregationPeriodStart - 集計期間A 開始日 YYYY/MM/DD HH:mm:ss 形式
 * @param aggregationPeriodEnd - 集計期間A 終了日 YYYY/MM/DD HH:mm:ss 形式
 * @param comparisonPeriodStart - 比較期間B 開始日 YYYY/MM/DD HH:mm:ss 形式
 * @param comparisonPeriodEnd - 比較期間B 終了日 YYYY/MM/DD HH:mm:ss 形式
 * @param direction - 方路
 * @param led - LED
 * @param aggregationTarget - 集計対象
 * @param aggregationMethod - 集計方法
 * @returns API取得結果
 */
export const updateMacroAnalysisDetaiInfo = (
  section: string,
  poleId: number,
  aggregationPeriodStart: string,
  aggregationPeriodEnd: string,
  comparisonPeriodStart: string,
  comparisonPeriodEnd: string,
  direction: string,
  led: string,
  aggregationTarget: string,
  aggregationMethod: string
): Promise<MacroAnalysisDetailInfo> => {
  return new Promise(async (resolve, reject) => {
    const macroAnalysisInfoStore = useMacroAnalysisInfoStore()
    // this.$store.dispatch(STORE_ACTIONS.setMacroAnalysisDetaiInfo, {})
    macroAnalysisInfoStore.setMacroAnalysisDetailInfo({})
    try {
      let params = {
        queryStringParameters: {
          section: section,
          poleId: poleId.toString(),
          aggregationPeriodStart: aggregationPeriodStart,
          aggregationPeriodEnd: aggregationPeriodEnd,
          comparisonPeriodStart: comparisonPeriodStart,
          comparisonPeriodEnd: comparisonPeriodEnd,
          direction: direction,
          led: led,
          aggregationTarget: aggregationTarget,
          aggregationMethod: aggregationMethod
        }
      }
      await API.get(getServer(), url.macroAnalysisDetailInfo, params)
        .then(res => {
          if (res == null) {
            reject(apiResponseErrorCode.noContent)
          } else {
            // this.$store.dispatch(STORE_ACTIONS.setMacroAnalysisDetaiInfo, res)
            macroAnalysisInfoStore.setMacroAnalysisDetailInfo(res)
            resolve(res)
          }
        })
        .catch(error => {
          log.error('updateNearMissDetailInfo error ' + error)
          reject(apiResponseErrorCode.internalServerError)
        })
    } catch (e) {
      log.error('updateNearMissDetailInfo error Can\'t call API' + e)
      reject(apiResponseErrorCode.internalServerError)
    }
  })
}

/**
 * マクロ分析データ作成ステータステーブルデータ取得
 * @param poleId - ポールID
 * @returns API取得結果
 */
export const updateMacroAnalysisInfoDataForExcel = (poleId:number): Promise<MacroAnalysisInfoDataForExcel> => {
  return new Promise(async (resolve, reject) => {
    const macroAnalysisExcelDataStore = useMacroAnalysisExcelDataStore()
    const userInfoStore = useUserInfoStore()
    // ストアのステートをリセット
    macroAnalysisExcelDataStore.setMacroAnalysisExcelData([])
    try {
      const accessToken = userInfoStore.getJwtToken
      let params = {
        queryStringParameters: {
          poleId: poleId.toString()
        },
        headers: {
          'coginito-access-token': accessToken
        }
      }
      await API.get(getServer(), url.getMacroAnalysisInfoDataForExcel, params)
        .then(res => {
          if (res === void 0 || res.length === 0) {
            reject(apiResponseErrorCode.noContent)
          } else {
            macroAnalysisExcelDataStore.setMacroAnalysisExcelData(res)
            resolve(res)
          }
        })
        .catch(error => {
          log.error('updateMacroAnalysisInfoDataForExcel error ' + error)
          reject(apiResponseErrorCode.internalServerError)
        })
    } catch (e) {
      log.error('updateMacroAnalysisInfoDataForExcel error Can\'t call API' + e)
      reject(apiResponseErrorCode.internalServerError)
    }
  })
}

/**
 * Excel出力用データの更新
 * @param cityAddress - ポール住所
 * @param poleId - ポールID
 * @param aggregationPeriodStart - 集計期間A 開始日 YYYY/MM/DD HH:mm:ss 形式
 * @param aggregationPeriodEnd - 集計期間A 終了日 YYYY/MM/DD HH:mm:ss 形式
 * @param comparisonPeriodStart - 比較期間B 開始日 YYYY/MM/DD HH:mm:ss 形式
 * @param comparisonPeriodEnd - 比較期間B 終了日 YYYY/MM/DD HH:mm:ss 形式
 * @param direction - 方路
 * @param led - LED
 * @param aggregationTarget - 集計対象
 * @param aggregationMethod - 集計方法
 * @returns API実行関数
 */
export const createMacroAnalysisInfoDataForExcel = (
  cityAddress: string,
  poleId: number,
  aggregationPeriodStart: string,
  aggregationPeriodEnd: string,
  comparisonPeriodStart: string,
  comparisonPeriodEnd: string,
  direction: string,
  led: string,
  aggregationTarget: string,
  aggregationMethod: string
) => {
  return new Promise(async (resolve, reject) => {
    const macroAnalysisInfoStore = useMacroAnalysisInfoStore()
    const userInfoStore = useUserInfoStore()
    // マクロ分析ストアの内容をリセットする
    macroAnalysisInfoStore.setMacroAnalysisDetailInfo({})
    try {
      // JWTトークンを取得
      const accessToken = userInfoStore.getJwtToken
      // パラメータを設定
      const args = {
        body: {
          cityAddress: cityAddress,
          poleId: poleId.toString(),
          aggregationPeriodStart: aggregationPeriodStart,
          aggregationPeriodEnd: aggregationPeriodEnd,
          comparisonPeriodStart: comparisonPeriodStart,
          comparisonPeriodEnd: comparisonPeriodEnd,
          direction: direction,
          led: led,
          aggregationTarget: aggregationTarget,
          aggregationMethod: aggregationMethod
        },
        headers: {
          'coginito-access-token': accessToken
        }
      }
      await API.post(getServer(), url.createMacroAnalysisInfoDataForExcel, args)
        .then(() => {
          // 更新に成功した時点で終了する
          resolve(void 0)
        })
        .catch(error => {
          log.error('createMacroAnalysisInfoDataForExcel error ' + error)
          reject(apiResponseErrorCode.internalServerError)
        })
    } catch (e) {
      log.error('createMacroAnalysisInfoDataForExcel error Can\'t call API' + e)
      reject(apiResponseErrorCode.internalServerError)
    }
  })
}

/**
 * 見回り情報の取得
 * @param areaId - エリアID
 * @param from - 取得開始日時
 * @param to - 取得終了日時
 * @returns API関数
 */
export const updateWatchingData = async(areaId: number, from: string, to: string): Promise<DistanceFromPoleToVehicleByAreaId> => {
  return new Promise(async(resolve, reject) => {
    const watchingListStore = useWatchingListStore()
    watchingListStore.setWatchingList([])
    try {
      const params = {
        queryStringParameters: {
          areaId: areaId.toString(),
          from: from,
          to: to
        }
      }
      await API.get(getServer(), url.watchingInfo, params)
        .then((res: DistanceFromPoleToVehicleByAreaId) => {
          if (res === null) {
            reject(apiResponseErrorCode.noContent)
          } else {
            watchingListStore.setWatchingList(res)
            resolve(res)
          }
        })
        .catch(error => {
          log.error('updateWatchingData error ' + error)
          reject(apiResponseErrorCode.internalServerError)
        })
    } catch {
      log.error('updateWatchingData error Can\'t call API')
      reject(apiResponseErrorCode.internalServerError)
    }
  })
}

/**
 * 通過物標件数データを取得する
 * @param startDate - 取得開始日
 * @param endDate - 取得終了日
 * @param poleId - ポールID
 * @returns API呼び出し関数
 */
export const getPassingObjectCollectionData = (startDate: string | null = null, endDate: string | null = null, poleId: number): Promise<PassingObjectInfoAll> => {
  return new Promise(async (resolve, reject) => {
    try {
      // ポールIDが指定されていない場合はエラーとする
      if (poleId === void 0 || poleId === null) {
        throw new Error()
      }
      const params = {
        queryStringParameters: {
          poleId: poleId
        } as {
          poleId: number
          startDate?: string
          endDate?: string
        }
      }
      // 取得開始日、終了日が指定されている場合は、パラメータに追加する
      if (startDate !== void 0 && startDate !== null) {
        params.queryStringParameters['startDate'] = startDate
      }
      if (endDate !== void 0 && endDate !== null) {
        params.queryStringParameters['endDate'] = endDate
      }
      // Vuexストアをクリアする
      const passingObjectListStore = usePassingObjectsCollectionListStore()
      passingObjectListStore.resetData()
      await API.get(getServer(), url.getPassingObjects, params)
        .then(res => {
          // APIからの戻り値が存在する場合は、Vuexストアに格納して終了する
          if (res !== void 0 && res !== null && res.objects.length > 0) {
            passingObjectListStore.setPoleId(poleId)
            if (startDate !== void 0 && startDate !== null && endDate !== void 0 && endDate !== null) {
              passingObjectListStore.setDate(startDate, endDate)
            }
            passingObjectListStore.setPassingObjectsCollectionList(res.objects)
            resolve(res)
          } else {
            reject(apiResponseErrorCode.internalServerError)
          }
        })
        .catch(error => {
          log.error('getPassingObjectCollectionData error ' + error)
          if (error === apiResponseErrorCode.internalServerError) {
            reject(apiResponseErrorCode.internalServerError)
          } else {
            reject(apiResponseErrorCode.badRequest)
          }
          
        })
    } catch {
      log.error('getPassingObjectCollectionData error Can\'t call API')
      reject(apiResponseErrorCode.badRequest)
    }
  })
}

/**
 * 一時停止件数データを取得する
 * @param startDate - 取得開始日
 * @param endDate - 取得終了日
 * @param poleId - ポールID
 * @returns API呼び出し関数
 */
export const getStopObjectCollectionData = (startDate: string | null = null, endDate: string | null = null, poleId: number): Promise<any> => {
  return new Promise(async (resolve, reject) => {
    try {
      // ポールIDが指定されていない場合はエラーとする
      if (poleId === void 0 || poleId === null) {
        throw new Error()
      }
      const params = {
        queryStringParameters: {
          poleId: poleId
        } as {
          poleId: number
          startDate?: string
          endDate?: string
        }
      }
      // 取得開始日、終了日が指定されている場合は、パラメータに追加する
      if (startDate !== void 0 && startDate !== null) {
        params.queryStringParameters['startDate'] = startDate
      }
      if (endDate !== void 0 && endDate !== null) {
        params.queryStringParameters['endDate'] = endDate
      }
      // Vuexストアをクリアする
      const stopObjectListStore = useStopObjectsCollectionListStore()
      stopObjectListStore.resetData()
      await API.get(getServer(), url.getStopObjects, params)
        .then(res => {
          // APIからの戻り値が存在する場合は、Vuexストアに格納して終了する
          if (res !== void 0 && res.stopaction !== void 0 && res.stopaction.length > 0) {
            stopObjectListStore.setPoleId(poleId)
            if (startDate !== void 0 && startDate !== null && endDate !== void 0 && endDate !== null) {
              stopObjectListStore.setDate(startDate, endDate)
            }
            stopObjectListStore.setStopObjectsCollectionList(res.stopaction)
            resolve(res)
          } else {
            reject(apiResponseErrorCode.internalServerError)
          }
        })
        .catch(error => {
          log.error('getStopObjectCollectionData error ' + error)
          if (error === apiResponseErrorCode.internalServerError) {
            reject(apiResponseErrorCode.internalServerError)
          } else {
            reject(apiResponseErrorCode.badRequest)
          }
          
        })
    } catch {
      log.error('getStopObjectCollectionData error Can\'t call API')
      reject(apiResponseErrorCode.badRequest)
    }
  })
}

/**
 * グループ情報一覧の取得
 * @returns API取得結果
 */
export const getGroupInfoList = async(): Promise<any> => {
  return new Promise(async (resolve, reject) => {
    const groupListStore = useGroupListStore()
    const userInfoStore = useUserInfoStore()
    groupListStore.setGroupList([])
    try {
      const accessToken = userInfoStore.getJwtToken
      const args = {
        headers: {
          'coginito-access-token': accessToken
        }
      }
      await API.get(getServer(), url.groupInfo, args)
        .then(res => {
          if (res == null) {
            reject(apiResponseErrorCode.noContent)
          } else {
            // this.$store.dispatch(STORE_ACTIONS.setGroupList, res);
            groupListStore.setGroupList(res)
            resolve(res)
          }
        })
        .catch(error => {
          log.error('getGroupInfoList error ' + error)
          reject(apiResponseErrorCode.internalServerError)
        })
    } catch {
      log.error('getGroupInfoList error Can\'t call API')
      reject(apiResponseErrorCode.internalServerError)
    }
  })
}

/**
 * ポールのグループ情報一覧取得
 * @returns API取得結果
 */
export const getPoleGroupInfoList = async(): Promise<any> => {
  return new Promise(async (resolve, reject) => {
    const poleGroupListStore = usePoleGroupListStore()
    const userInfoStore = useUserInfoStore()
    poleGroupListStore.setPoleGroupList([])
    try {
      const accessToken = userInfoStore.getJwtToken
      const args = {
        headers: {
          'coginito-access-token': accessToken
        }
      }
      await API.get(getServer(), url.poleGroupInfo, args)
        .then(res => {
          if (res == null) {
            reject(apiResponseErrorCode.noContent)
          } else {
            poleGroupListStore.setPoleGroupList(res)
            resolve(res)
          }
        })
        .catch(error => {
          log.error('getPoleGroupInfoList error ' + error)
          reject(apiResponseErrorCode.internalServerError)
        })
    } catch {
      log.error('getPoleGroupInfoList error Can\'t call API')
      reject(apiResponseErrorCode.internalServerError)
    }
  })
}

/**
 * 方位リストの取得
 * @param poleId - ポールID
 * @returns API呼び出し関数
 */
export const getVdiParametersList = (poleId: number): Promise<AccessDirection> => {
  return new Promise(async (resolve, reject) => {
    try {
      if (poleId === void 0 || poleId === null) {
        throw new Error()
      }
      const params = {
        queryStringParameters: {
          poleId: poleId
        }
      }
      // Vuexストアをクリアする
      const vdiParamListStore = useVdiParamListStore()
      vdiParamListStore.resetData()
      await API.get(getServer(), url.getAccessDirection, params)
        .then(res => {
          // APIからの戻り値が存在する場合は、Vuexストアに格納して終了する
          if (res !== void 0 && res !== null && res.direction.length > 0) {
            vdiParamListStore.setPoleId(poleId)
            vdiParamListStore.setVdiParamList(res.direction)
            resolve(res)
          } else {
            reject(apiResponseErrorCode.internalServerError)
          }
        })
        .catch(error => {
          log.error('getVdiParametersList error ' + error)
          if (error === apiResponseErrorCode.internalServerError) {
            reject(apiResponseErrorCode.internalServerError)
          } else {
            reject(apiResponseErrorCode.badRequest)
          }
        })
    } catch {
      log.error('getVdiParametersList error Can\'t call API')
      reject(apiResponseErrorCode.badRequest)
    }
  })
}

/**
 * グループ情報作成
 * @param groupname - グループ名
 * @returns 非同期化取得処理
 */
export const createGroupInfo = (groupname: string): Promise<any> => {
  return new Promise(async (resolve, reject) => {
    const userInfoStore = useUserInfoStore()
    try {
      const accessToken = userInfoStore.getJwtToken
      // パラメータを設定
      const args = {
        body: {
          groupName: groupname
        },
        headers: {
          'coginito-access-token': accessToken
        }
      }
      await API.post(getServer(), url.createGroupInfo, args)
        .then(() => {
          resolve(null)
        })
        .catch(error => {
          // API呼び出し時の例外処理
          log.error('createGroupInfo error ' + error)
          reject(apiResponseErrorCode.internalServerError)
        })
    } catch (e) {
      // API呼び出しに失敗
      log.error('createGroupInfo error Can\'t call API' + e)
      reject(apiResponseErrorCode.internalServerError)
    }
  })
}

/**
 * グループ情報更新
 * @param groupid - グループID
 * @param groupname - グループ名
 * @returns 非同期化取得処理
 */
export const updateGroupInfo = (groupid: number, groupname: string): Promise<any> => {
  return new Promise(async (resolve, reject) => {
    const userInfoStore = useUserInfoStore()
    try {
      const accessToken = userInfoStore.getJwtToken
      // パラメータを設定
      const args = {
        body: {
          groupId: groupid,
          groupName: groupname
        },
        headers: {
          'coginito-access-token': accessToken
        }
      }
      console.log(args)
      await API.post(getServer(), url.updateGroupInfo, args)
        .then(() => {
          resolve(null)
        })
        .catch(error => {
          // API呼び出し時の例外処理
          log.error('updateGroupInfo error ' + error)
          reject(apiResponseErrorCode.internalServerError)
        })
    } catch (e) {
      // API呼び出しに失敗
      log.error('updateGroupInfo error Can\'t call API' + e)
      reject(apiResponseErrorCode.internalServerError)
    }
  })
}

/**
 * グループ情報削除
 * @param groupid - グループID
 * @returns 非同期化取得処理
 */
export const deleteGroupInfo = (groupid: number): Promise<any> => {
  const userInfoStore = useUserInfoStore()
  return new Promise(async (resolve, reject) => {
    try {
      const accessToken = userInfoStore.getJwtToken
      // パラメータを設定
      const args = {
        body: {
          groupId: groupid
        },
        headers: {
          'coginito-access-token': accessToken
        }
      }
      await API.post(getServer(), url.deleteGroupInfo, args)
        .then(() => {
          resolve(null)
        })
        .catch(error => {
          // API呼び出し時の例外処理
          log.error('deleteGroupInfo error ' + error)
          reject(apiResponseErrorCode.internalServerError)
        })
    } catch (e) {
      // API呼び出しに失敗
      log.error('deleteGroupInfo error Can\'t call API' + e)
      reject(apiResponseErrorCode.internalServerError)
    }
  })
}

/**
 * ポールのグループ情報更新
 * @param poleid - ポールID
 * @param groupid - グループID
 * @returns 非同期化取得処理
 */
export const updatePoleGroupInfo = (poleid: number, groupid: number): Promise<any> => {
  const userInfoStore = useUserInfoStore()
  return new Promise(async (resolve, reject) => {
    try {
      const accessToken = userInfoStore.getJwtToken
      // パラメータを設定
      const args = {
        body: {
          poleId: poleid,
          groupId: groupid
        },
        headers: {
          'coginito-access-token': accessToken
        }
      }
      await API.post(getServer(), url.updatePoleGroupInfo, args)
        .then(() => {
          resolve(null)
        })
        .catch(error => {
          // API呼び出し時の例外処理
          log.error('updatePoleGroupInfo error ' + error)
          reject(apiResponseErrorCode.internalServerError)
        })
    } catch (e) {
      // API呼び出しに失敗
      log.error('updatePoleGroupInfo error Can\'t call API' + e)
      reject(apiResponseErrorCode.internalServerError)
    }
  })
}
