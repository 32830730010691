<script lang="ts" setup>
/**
 * MultiCamera.vue
 * マルチカメラモニター
 */
// ==================================
// import
// ==================================
import { ref, onMounted } from 'vue'

import ErrorDialog from '@/components/parts/common/ErrorDialog.vue'
import Loading from '@/components/parts/common/Loading.vue'
import TitleHeader from '@/components/parts/common/TitleHeader.vue'
import MultiCameraFootage from '@/components/parts/multiCamera/MultiCameraFootage.vue'

import { useSelectPoleStore } from '@/store/app'

import { DIALOG_ERROR_INFO, getCameraList } from '@/mixins/commonFunction'
import { getPoleList } from '@/mixins/communicationFunction'

import { CameraInfo, ErrorDialogInfo, TitleInfo } from '@/types/Interfaces'

import { MENU } from '@/setting/setting'

import * as log from 'loglevel'

// ==================================
// data
// ==================================
// 選択ポール情報ストア
const selectPoleStore = useSelectPoleStore()

const cameraFootageComponent1 = ref()
const cameraFootageComponent2 = ref()
const cameraFootageComponent3 = ref()
const cameraFootageComponent4 = ref()

// タイトル
const titleinfo = ref<TitleInfo>({
  title: selectPoleStore.name + ' マルチカメラモニター',
  pointList: [],
  menuList: MENU.realtime,
  show: {
    realtime: true,
    virtual: true,
    menu: true,
  },
})

// エラーダイアログ情報
const errorDialog = ref<ErrorDialogInfo>({
  message: '',
  title: '',
  isShow: false,
})

// 画面項目制御
const isLoading = ref<boolean>(true)

// ==================================
// hook
// ==================================
onMounted(async () => {
  // ポールIDに紐づくセンサー情報を取得
  // await updatePoleListAsPoleId(selectPoleStore.poleId)
  await getPoleList(null, selectPoleStore.poleId, null, true)
    .then(() => {
      const initCameraList1: CameraInfo[] = []
      const initCameraList2: CameraInfo[] = []
      const initCameraList3: CameraInfo[] = []
      const initCameraList4: CameraInfo[] = []
      const cameraList = getCameraList()
      cameraList.forEach((value: CameraInfo, index: number) => {
        switch (index) {
          case 0:
            initCameraList1.push(value)
            break
          case 1:
            initCameraList2.push(value)
            break
          case 2:
            initCameraList3.push(value)
            break
          default:
            initCameraList4.push(value)
        }
      })
      initCamera(initCameraList1, initCameraList2, initCameraList3, initCameraList4)
      isLoading.value = false
    })
    .catch((err) => {
      log.error(err)
      errorDialog.value.title = DIALOG_ERROR_INFO.title.getError
      errorDialog.value.message = DIALOG_ERROR_INFO.message.getErrorSensorInfo
      errorDialog.value.isShow = true
      isLoading.value = false
    })
})

// onBeforeUnmount(() => {
//   timerFunction.methods.stop();
// });

// ==================================
// method
// ==================================
/**
 * カメラリスト初期化
 * @param initCameraList1 - カメラリスト1
 * @param initCameraList2 - カメラリスト2
 * @param initCameraList3 - カメラリスト3
 * @param initCameraList4 - カメラリスト4
 */
const initCamera = (initCameraList1: CameraInfo[], initCameraList2: CameraInfo[], initCameraList3: CameraInfo[], initCameraList4: CameraInfo[]) => {
  cameraFootageComponent1.value.initCamera(initCameraList1)
  cameraFootageComponent1.value.initCamera(initCameraList2)
  cameraFootageComponent1.value.initCamera(initCameraList3)
  cameraFootageComponent1.value.initCamera(initCameraList4)
}

/**
 * エラーダイアログを閉じる
 */
const onClickCloseErrorDialog = () => {
  errorDialog.value.isShow = false
}
</script>
<template>
  <TitleHeader :title-info="titleinfo" />
  <v-container
    fluid
    fill-height
  >
    <v-row dense>
      <v-col cols="6">
        <MultiCameraFootage
          ref="cameraFootageComponent1"
          :is-selct-sensor="false"
        />
      </v-col>
      <v-col cols="6">
        <MultiCameraFootage
          ref="cameraFootageComponent2"
          :is-selct-sensor="false"
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="6">
        <MultiCameraFootage
          ref="cameraFootageComponent3"
          :is-selct-sensor="false"
        />
      </v-col>
      <v-col cols="6">
        <MultiCameraFootage
          ref="cameraFootageComponent4"
          :is-selct-sensor="true"
        />
      </v-col>
    </v-row>
  </v-container>
  <Loading v-show="isLoading" />
  <ErrorDialog
    :error-dialog="errorDialog"
    @on-click-close-error-dialog="onClickCloseErrorDialog"
  />
</template>
